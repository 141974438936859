export enum Departments {
  GMCH = "GMCH",
  CHL = "CHL",
  HOSPICARE = "HOSPI_CARE",
  GAWELLNESS = "GA_WELLNESS",
  GOVAXX = "GOVAXX",
  OPTIMUS = "OPTIMUS",
  TORRENT = "TORRENT",
  ANCHORICON = "ANCHOR_ICON",
  ASPIRAPLUS = "ASPIRA_PLUS",
  ASPIRANEW = "ASPIRA_NEW",
  ICONPRO = "ICON_PRO",
  ICONTX = "ICON_TX",
}
