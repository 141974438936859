import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteUnlockUser } from "../../../data/usecases/user-management/remote-unlock-user";

export interface UnlockUserInterface {
  unlockUser: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useUnlockUserSlice: StateCreator<UnlockUserInterface> = (
  set,
  get
) => ({
  unlockUser: async (user_id: number) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteUnblockUser = new RemoteUnlockUser(
      `${BASE_URL}${Endpoints.UNLOCK_USER}`,
      axiosHttpClient
    );
    let result = await remoteUnblockUser.unlock(user_id.toString());
  },
});
