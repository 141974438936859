import {
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Typography,
  TextField,
  MenuItem,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import moment from "moment";
import { TerritoryData } from "../../../../../domain/models/territory-data";
import { ManagerData } from "../../../../../domain/models/user-management/manager-date";
import { UserManagementData } from "../../../../../domain/models/user-management/user-management-data";
import { ServiceProvider } from "../../../../../domain/models/service-provider";
import { UserDepartment } from "../../../../../domain/models/user-department";
import Roles from "../../../../../common/enums/Roles";
import RolesId from "../../../../../common/enums/RoleIds";
import { UserManagementStore } from "../../../../../store/main/UserManagementStore";
import { TerritoryDataStore } from "../../../../../store/main/TerritoryDataStore";
import { FetchState } from "../../../../../domain/models/fetch-state";
import { MobileModalStyle } from "../../../../../common/constants/custom-style";

type CreateUser = {
  emp_Code: number | string;
  mobile: number | string;
  fullName: string;
  joiningDate: string;
  email: string;
  dateOfBirth: string;
  addressLine: string;
  landmark: string;
  cityName: string;
  pincode: number | string;
  serviceProvider: number | string;
  department: string | number;
  role: TerritoryData | null;
  username: string;
  password: string;
  confirmPassword: string;
  manager: ManagerData | null;
  territory: string;
  officialEmail: string;
};

type Props = {
  CreateUserModal: boolean;
  closeModals: Function;
  selectedUser: UserManagementData;
  serviceProvider: ServiceProvider[];
  departmentData: UserDepartment[];
  userRolesMasterData: TerritoryData[];
};

const AddNewUserMobile: React.FC<Props> = ({
  CreateUserModal,
  closeModals,
  serviceProvider,
  departmentData,
  userRolesMasterData,
}) => {
  const { handleSubmit, control, setValue, watch } = useForm<CreateUser>({
    mode: "onChange",
  });

  const {
    createNewUser,
    fetchUserManagementData,
    fetchManagerBasedOnUserRole,
    filterStore,
    managerData,
    fetchManagerStatus,
  } = UserManagementStore();

  const {
    fetchAreaMaster,
    fetchRegionMaster,
    fetchHeadquarterMaster,
    headquarterMasterData,
    areaMasterData,
    regionMasterData,
  } = TerritoryDataStore();
  const [openJoiningDateFilter, setOpenJoiningDateFilter] =
    useState<boolean>(false);
  const [openDOBFilter, setOpenDOBFilter] = useState<boolean>(false);
  const [territory, setTerritory] = useState<TerritoryData[]>([]);
  const [joiningDate, setJoiningDate] = useState<Dayjs | null>(
    dayjs(new Date())
  );
  const [birthDate, setBirthDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    setValue("addressLine", "");
    setValue("cityName", "");
    setValue("dateOfBirth", "");
    setValue("department", "");
    setValue("confirmPassword", "");
    setValue("email", "");
    setValue("emp_Code", "");
    setValue("fullName", "");
    setValue("joiningDate", moment(""?.toString()).format("YYYY-MM-DD"));
    setValue("landmark", "");
    setValue("manager", null);
    setValue("mobile", "");
    setValue("officialEmail", "");
    setValue("password", "");
    setValue("pincode", "");
    setValue("role", null);
    setValue("serviceProvider", "");
    setValue("territory", "");
    setValue("username", "");
    setBirthDate(null);
  }, [CreateUserModal]);

  const activeManagers = (data: ManagerData[]) => {
    let tempArr: ManagerData[] = [];
    data.map((data) => {
      if (data.status == "active") {
        tempArr.push(data);
      }
    });
    return tempArr;
  };

  useEffect(() => {
    if (watch("role")) {
      let roleId = 0;
      if (watch("role")?.name == Roles.HEADQUATER_MANAGER) {
        roleId = RolesId.AREA_MANAGER;
      } else if (watch("role")?.name == Roles.AREA_MANAGER) {
        roleId = RolesId.REGION_MANAGER;
      } else if (watch("role")?.name == Roles.REGION_MANAGER) {
        roleId = RolesId.ZONE_MANAGER;
      }
      let payload = {
        role_id: roleId,
        manager_id: watch("manager")?.id,
        status: filterStore.status,
        // search: filterStore.search,
      };
      fetchManagerBasedOnUserRole(payload);
    }
  }, [watch("role")]);

  useEffect(() => {
    if (managerData.length > 0) {
      if (watch("role") && watch("manager")) {
        if (watch("role")?.id == 3) {
          fetchHeadquarterMaster([watch("manager")?.hq_area_id]);
        } else if (watch("role")?.id == 4) {
          fetchAreaMaster([watch("manager")?.hq_region_id]);
        } else if (watch("role")?.id == 5) {
          fetchRegionMaster([watch("manager")?.hq_zone_id]);
        }
      }
    }
  }, [watch("manager")]);

  useEffect(() => {
    if (watch("role")?.id == 3 && headquarterMasterData.length > 0) {
      setTerritory(headquarterMasterData);
    } else if (watch("role")?.id == 4 && areaMasterData.length > 0) {
      setTerritory(areaMasterData);
    } else if (watch("role")?.id == 5 && regionMasterData.length > 0) {
      setTerritory(regionMasterData);
    }
  }, [regionMasterData, areaMasterData, headquarterMasterData]);

  const createUser = (data: CreateUser) => {
    let payload = {
      birth_date: moment(birthDate?.toString()).format("YYYY-MM-DD"),
      city: data.cityName,
      confirm_password: data.confirmPassword,
      department_ids: data.department,
      email: data.email,
      emp_code: data.emp_Code,
      full_name: data.fullName,
      hq_area_id: data.territory,
      hq_country_id: data.manager?.hq_country_id
        ? data.manager?.hq_country_id
        : 1,
      hq_headquarter_id: data.territory,
      hq_region_id: data.territory,
      hq_zone_id: data.territory,
      joining_date: moment(joiningDate?.toString()).format("YYYY-MM-DD"),
      landmark: data.landmark,
      line: data.addressLine,
      manager_id: data.manager?.id,
      mobile: data.mobile,
      official_email: data.email,
      password: data.password,
      pincode: data.pincode,
      role: data.role?.name,
      service_provider_id: data.serviceProvider,
      username: data.username,
    };

    createNewUser(payload, closeModals("CreateUser"));
    fetchUserManagementData();
  };
  return (
    <>
      <Modal
        open={CreateUserModal}
        onClose={() => {
          closeModals("CreateUser");
        }}
      >
        <Box sx={MobileModalStyle} height={"80vh"} overflow={"scroll"}>
          <Typography variant="h6" fontWeight={600} gutterBottom>
            Create User
          </Typography>

          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="body2" fontWeight={600} gutterBottom>
                Personal Details
              </Typography>
              <Controller
                name="emp_Code"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="Emp Code"
                    value={value}
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                  pattern: {
                    value: /^[0-9]/,
                    message: "Enter Correct Code Only",
                  },
                }}
              />
              <Controller
                name="fullName"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="FullName"
                    value={value}
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              <Controller
                name="mobile"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="Mobile"
                    value={value}
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: "Enter Correct Number Only",
                  },
                }}
              />
              <Controller
                name="email"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="Email"
                    value={value}
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                  pattern: {
                    value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                    message: "Enter Correct Email",
                  },
                }}
              />
              <Typography
                variant="caption"
                fontSize={12}
                color={"info.dark"}
                gutterBottom
              >
                Joining Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  open={openJoiningDateFilter}
                  onClose={() => {
                    setOpenJoiningDateFilter(false);
                  }}
                  onOpen={() => {
                    setOpenJoiningDateFilter(true);
                  }}
                  views={["day"]}
                  value={joiningDate}
                  onChange={(newValue) => {
                    setJoiningDate(newValue);
                  }}
                  inputFormat={"ll"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      size="small"
                      onClick={() => setOpenJoiningDateFilter(true)}
                    />
                  )}
                />
              </LocalizationProvider>
              <Typography
                variant="caption"
                fontSize={12}
                color={"info.light"}
                gutterBottom
              >
                Date of Birth
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  open={openDOBFilter}
                  onClose={() => {
                    setOpenDOBFilter(false);
                  }}
                  onOpen={() => {
                    setOpenDOBFilter(true);
                  }}
                  views={["day"]}
                  value={birthDate}
                  onChange={(newValue) => {
                    setBirthDate(newValue);
                  }}
                  inputFormat={"ll"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      size="small"
                      onClick={() => setOpenDOBFilter(true)}
                    />
                  )}
                />
              </LocalizationProvider>
              <Controller
                name="addressLine"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="Address Line"
                    value={value}
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              <Controller
                name="landmark"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="Landmark"
                    value={value}
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              <Controller
                name="cityName"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="City Name"
                    value={value}
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              <Controller
                name="pincode"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="Pincode"
                    value={value}
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                  pattern: {
                    value: /^[1-9][0-9]{5}$/,
                    message: "Enter Correct Pincode",
                  },
                }}
              />
              <Controller
                name="serviceProvider"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    select
                    fullWidth
                    label="Select Service Provider"
                    value={value}
                    size="small"
                    onChange={onChange}
                    error={error && true}
                    helperText={error?.message}
                  >
                    {serviceProvider.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              <Controller
                name="department"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    select
                    fullWidth
                    label="Select Department"
                    value={value}
                    size="small"
                    onChange={onChange}
                    error={error && true}
                    helperText={error?.message}
                  >
                    {departmentData.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              <Controller
                name="officialEmail"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="Enter Office Email"
                    value={value}
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                  pattern: {
                    value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                    message: "Enter Correct Mail",
                  },
                }}
              />
              <Controller
                name="role"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    autoComplete
                    options={userRolesMasterData}
                    value={value}
                    onChange={(event: any, newValue: TerritoryData | null) => {
                      onChange(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <Stack direction="row" spacing={2} alignItems="center">
                        <TextField
                          {...params}
                          label="Roles"
                          size="small"
                          fullWidth
                        />
                        {fetchManagerStatus == FetchState.LOADING && (
                          <CircularProgress size={25} />
                        )}
                      </Stack>
                    )}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              {watch("role") &&
                activeManagers(managerData).length == 0 &&
                fetchManagerStatus == FetchState.SUCCESS && (
                  <Typography color="error" variant="caption">
                    No Active Managers
                  </Typography>
                )}
              {activeManagers(managerData).length > 0 &&
                fetchManagerStatus == FetchState.SUCCESS && (
                  <Grid item xs={6}>
                    <Controller
                      name="manager"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          autoComplete
                          options={activeManagers(managerData)}
                          value={value}
                          onChange={(
                            event: any,
                            newValue: ManagerData | null
                          ) => {
                            onChange(newValue);
                          }}
                          getOptionLabel={(option) => option.full_name}
                          renderInput={(params) => (
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                            >
                              <TextField
                                {...params}
                                label="Managers"
                                size="small"
                                fullWidth
                              />
                              {territory.length <= 0 && watch("manager") && (
                                <CircularProgress size={25} />
                              )}
                            </Stack>
                          )}
                        />
                      )}
                      rules={{
                        required: { value: true, message: "Field Required" },
                      }}
                    />
                  </Grid>
                )}
              {watch("manager") && territory.length > 0 && (
                <Grid item xs={6}>
                  <Controller
                    name="territory"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        select
                        fullWidth
                        label="Select Territory"
                        value={value}
                        size="small"
                        onChange={onChange}
                        error={error && true}
                        helperText={error?.message}
                      >
                        {territory.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    rules={{
                      required: { value: true, message: "Field Required" },
                    }}
                  />
                </Grid>
              )}
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" fontWeight={600}>
                Login Details
              </Typography>
              <Controller
                name="username"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="Username"
                    value={value}
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              <Controller
                name="password"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="Password"
                    value={value}
                    type="password"
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              <Controller
                name="confirmPassword"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    value={value}
                    type="password"
                    onChange={onChange}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              {watch("confirmPassword") != "" &&
                watch("password") !== watch("confirmPassword") && (
                  <Typography variant="caption" color="error.main">
                    New Password and Confirm Password should be the Same!
                  </Typography>
                )}
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            marginTop={3}
          >
            <Button
              variant="contained"
              disabled={
                watch("addressLine") == "" ||
                watch("cityName") == "" ||
                watch("confirmPassword") == "" ||
                watch("department") == "" ||
                watch("email") == "" ||
                watch("emp_Code") == "" ||
                watch("fullName") == "" ||
                watch("landmark") == "" ||
                watch("manager") == ({} as ManagerData) ||
                watch("mobile") == "" ||
                watch("username") == "" ||
                watch("territory") == "" ||
                watch("serviceProvider") == "" ||
                watch("role") == ({} as TerritoryData) ||
                watch("pincode") == "" ||
                watch("password") == "" ||
                watch("officialEmail") == "" ||
                watch("mobile") == ""
              }
              color="primary"
              onClick={handleSubmit(createUser)}
            >
              Create
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => closeModals("CreateUser")}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default AddNewUserMobile;
