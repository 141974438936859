import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import Endpoints from "../../domain/endpoints";
import { RemoteFetchUserDepartment } from "./../../data/usecases/remote-fetch-user-department";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { UserDepartment } from "../../domain/models/user-department";
import { FetchUserDepartment } from "../../domain/useages/fetch-user-department";

export interface FetchUserDepartmentInterface {
  departmentData: UserDepartment[];
  fetchDepartment: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

const initialStates = {
  departmentData: [],
};

export const useFetchUserDepartmentSlice: StateCreator<
  FetchUserDepartmentInterface
> = (set, get) => ({
  ...initialStates,
  fetchDepartment: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchUserDepartment: FetchUserDepartment =
      new RemoteFetchUserDepartment(
        `${BASE_URL}${Endpoints.FETCH_DEPARTMENTS}`,
        axiosHttpClient
      );

    let result = await remoteFetchUserDepartment.get();
    if (result) {
      set({ departmentData: result.departments });
    }
  },
});
