import { Search } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import debounce from "@mui/material/utils/debounce";
import { useEffect, useState } from "react";
import { UserSummaryReportStore } from "../../../../store/main/UserSummaryReportStore";
import { UserChildrenType } from "../../../../domain/models/user-summary-report/user-children";
import RolesId from "../../../../common/enums/RoleIds";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import UserListLoading from "../../desktop/user-summary-report/sub-components/loading/UserListLoading";
import { FetchState } from "../../../../domain/models/fetch-state";
import {
  Territories,
  TerritoryIDs,
} from "../../../../common/constants/Territories";

// eslint-disable-next-line react-hooks/rules-of-hooks

const FiltersMobile = () => {
  const [listSelected, setListSelected] = useState<boolean>(true);
  const [activeUser, setActiveUser] = useState<number>();
  const [activeUserData, setActiveUserData] = useState<UserChildrenType>();
  const [filterProps, setFilterProps] = useState({
    search: "",
    role_id: "",
  });
  const {
    userData,
    fetchDeviationReport,
    fetchSummaryReport,
    fetchMonthSummaryReport,
    fetchMonthsReport,
    userSummaryFilter,
    fetchUserChildren,
    fetchUserChildrenState,
  } = UserSummaryReportStore();
  const radioType = {
    border: "1px solid lightGrey",
    borderRadius: "10px",
    paddingRight: "6px",
    margin: "6px",
  };
  useEffect(() => {
    // This block will be executed whenever activeUser changes
    const activeData = userData?.find(
      (user: UserChildrenType) => activeUser === user.id
    );
    setActiveUserData(activeData);
  }, [activeUser, userData]);
  const applySearchFilter = (e: any) => {
    filterProps.search = e.target.value;
    fetchUserChildren(filterProps);
  };
  const applyRadioFilter = (e: any) => {
    filterProps.role_id = TerritoryIDs[e.target.value];
    fetchUserChildren(filterProps);
  };
  const showUser = () => {
    setListSelected(!listSelected);
    filterProps.search = "";
    fetchUserChildren(filterProps);
  };
  const debounceOnChangeFilter = debounce(applySearchFilter, 500);
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const fetchUserData = (user_id: number, organization_id: number) => {
    setActiveUser(user_id);
    setListSelected(!listSelected);
    const currentDate = new Date();
    const currentMonth = userSummaryFilter?.month
      ? userSummaryFilter.month
      : currentDate.getMonth() + 1;

    const currentYear = userSummaryFilter?.year
      ? userSummaryFilter.year
      : currentDate.getFullYear();
    userSummaryFilter.user_id = user_id;
    userSummaryFilter.department = organization_id;

    fetchDeviationReport(currentMonth, currentYear, user_id, organization_id);
    fetchSummaryReport(currentMonth, currentYear, user_id, organization_id);
    fetchMonthSummaryReport(
      currentMonth,
      currentYear,
      user_id,
      organization_id
    );
    fetchMonthsReport(currentYear, user_id, organization_id);

    setSelectedCard(user_id);
  };
  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          variant="h6"
          alignItems={"center"}
          paddingLeft={"10px"}
          fontWeight={500}
        >
          User Summary Report
        </Typography>
        {!listSelected ? (
          <Button variant="contained" size="small" onClick={() => showUser()}>
            Show All User
          </Button>
        ) : (
          ""
        )}
      </Stack>
      {listSelected ? (
        <Stack>
          <Stack spacing={1} paddingX={"20px"} marginTop={"10px"}>
            <TextField
              onChange={debounceOnChangeFilter}
              variant="outlined"
              placeholder="Search"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />

            <Stack>
              <FormControl>
                <RadioGroup
                  sx={{ justifyContent: "space-around" }}
                  row
                  onChange={(e) => applyRadioFilter(e)}
                >
                  {Territories.map((data: any) => {
                    return (
                      <FormControlLabel
                        value={data}
                        sx={radioType}
                        label={
                          <Typography variant="caption">{data}</Typography>
                        }
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 16,
                              },
                            }}
                          />
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Stack>
          </Stack>
          <Box overflow="scroll" height="40vh">
            {userData.length == 0 &&
              fetchUserChildrenState == FetchState.ERROR && (
                <Typography textAlign={"center"} variant="subtitle2">
                  No Users Found
                </Typography>
              )}
            {fetchUserChildrenState == FetchState.LOADING && (
              <UserListLoading />
            )}
            {userData.length &&
              fetchUserChildrenState == FetchState.SUCCESS &&
              userData.map((data: UserChildrenType) => {
                return (
                  <Card
                    sx={{
                      width: "90%",
                      boxShadow: "none",
                      borderRadius: "0.7rem",
                      marginX: "auto",
                      marginTop: "10px",
                    }}
                    onClick={() => fetchUserData(data.id, data.organization_id)}
                  >
                    <Stack
                      direction={"row"}
                      sx={{
                        backgroundColor:
                          selectedCard === data.id ? "#cae0e8" : "#F0F3F2",
                        transition: "background-color 0.3s ease",
                        "&:hover": {
                          backgroundColor: "#d9dedc",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Avatar
                        sx={{
                          marginY: "auto",
                          marginLeft: "15px",
                        }}
                      >
                        {data?.full_name[0]
                          ? data?.full_name[0].toUpperCase()
                          : "A"}
                      </Avatar>
                      <CardContent>
                        <Typography component="div" variant="subtitle1">
                          {data?.full_name ? data?.full_name : "-"}
                        </Typography>
                        {data?.role_id === RolesId.COUTNRY_MANAGER && (
                          <Typography variant="caption">ADMIN</Typography>
                        )}
                        {data?.role_id === RolesId.ZONE_MANAGER && (
                          <Typography variant="caption">ZBM</Typography>
                        )}
                        {data?.role_id === RolesId.REGION_MANAGER && (
                          <Stack direction={"column"}>
                            <Typography variant="caption">RBM</Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {data?.hq_region?.name}
                            </Typography>
                          </Stack>
                        )}

                        {data?.role_id === RolesId.AREA_MANAGER && (
                          <>
                            <Typography variant="caption">ABM</Typography>
                            <Stack
                              alignItems={"Center"}
                              direction={"row"}
                              spacing={1}
                            >
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {data?.hq_region?.name
                                  ? data?.hq_region?.name
                                  : "-"}
                              </Typography>
                              <FiberManualRecordIcon
                                sx={{ fontSize: "10px", color: "lightGray" }}
                              />
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {data?.hq_area?.name
                                  ? data?.hq_area?.name
                                  : "-"}
                              </Typography>
                            </Stack>
                          </>
                        )}

                        {data?.role_id === RolesId.HEADQUATER_MANAGER && (
                          <>
                            <Typography variant="caption">TSO</Typography>
                            <Stack
                              alignItems={"Center"}
                              direction={"row"}
                              spacing={0.2}
                            >
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {data?.hq_region?.name}
                              </Typography>
                              <FiberManualRecordIcon
                                sx={{ fontSize: "10px", color: "lightGray" }}
                              />
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {data?.hq_area?.name}
                              </Typography>
                              <FiberManualRecordIcon
                                sx={{ fontSize: "10px", color: "lightGray" }}
                              />
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {data?.hq_headquarter?.name}
                              </Typography>
                            </Stack>
                          </>
                        )}
                      </CardContent>
                    </Stack>
                  </Card>
                );
              })}
          </Box>
        </Stack>
      ) : (
        <Stack sx={{ marginX: "auto" }}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "none",
              borderRadius: "0.7rem",
              marginTop: "20px",
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                backgroundColor: "#F0F3F2",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#d9dedc",
                  cursor: "pointer",
                },
              }}
            >
              <Avatar
                sx={{
                  marginY: "auto",
                  marginLeft: "15px",
                }}
              >
                {activeUserData?.full_name[0]
                  ? activeUserData?.full_name[0].toUpperCase()
                  : "A"}
              </Avatar>
              <CardContent>
                <Typography component="div" variant="subtitle1">
                  {activeUserData?.full_name ? activeUserData?.full_name : "-"}
                </Typography>
                {activeUserData?.role_id === RolesId.COUTNRY_MANAGER && (
                  <Typography variant="caption">ADMIN</Typography>
                )}
                {activeUserData?.role_id === RolesId.ZONE_MANAGER && (
                  <Typography variant="caption">ZBM</Typography>
                )}
                {activeUserData?.role_id === RolesId.REGION_MANAGER && (
                  <>
                    <Stack direction={"column"}>
                      <Typography variant="caption">RBM</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {activeUserData?.hq_region?.name}
                      </Typography>
                    </Stack>
                  </>
                )}

                {activeUserData?.role_id === RolesId.AREA_MANAGER && (
                  <>
                    <Typography variant="caption">ABM</Typography>
                    <Stack alignItems={"Center"} direction={"row"} spacing={1}>
                      <Typography variant="caption" color="text.secondary">
                        {activeUserData?.hq_region?.name
                          ? activeUserData?.hq_region?.name
                          : "-"}
                      </Typography>
                      <FiberManualRecordIcon
                        sx={{ fontSize: "10px", color: "lightGray" }}
                      />
                      <Typography variant="caption" color="text.secondary">
                        {activeUserData?.hq_area?.name
                          ? activeUserData?.hq_area?.name
                          : "-"}
                      </Typography>
                    </Stack>
                  </>
                )}

                {activeUserData?.role_id === RolesId.HEADQUATER_MANAGER && (
                  <>
                    <Typography variant="caption">TSO</Typography>
                    <Stack
                      alignItems={"Center"}
                      direction={"row"}
                      spacing={0.2}
                    >
                      <Typography variant="caption" color="text.secondary">
                        {activeUserData?.hq_region?.name}
                      </Typography>
                      <FiberManualRecordIcon
                        sx={{ fontSize: "10px", color: "lightGray" }}
                      />
                      <Typography variant="caption" color="text.secondary">
                        {activeUserData?.hq_area?.name}
                      </Typography>
                      <FiberManualRecordIcon
                        sx={{ fontSize: "10px", color: "lightGray" }}
                      />
                      <Typography variant="caption" color="text.secondary">
                        {activeUserData?.hq_headquarter?.name}
                      </Typography>
                    </Stack>
                  </>
                )}
              </CardContent>
            </Stack>
          </Card>
        </Stack>
      )}
    </>
  );
};
export default FiltersMobile;
