import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { useState } from "react";
import Panels from "../../../../common/enums/Panels";
import Status from "../../../../common/enums/Status";
import { CustomerSummaryType } from "../../../../domain/models/customer-database/customer-summary";
import { TerritoryDataStore } from "../../../../store/main/TerritoryDataStore";

type Props = {
  setIsRestricted: Function;
  setButtonAccess: Function;
};
const CustomerSummaryMobile: React.FC<Props> = ({
  setButtonAccess,
  setIsRestricted,
}) => {
  const {
    fetchCustomerData,
    filterStore,
    customerSummaryCounts,
    panelName,
    handlePageNumber,
    handleClearSelectedCustomers,
  } = CustomerDatabaseStore();

  const { customerTypesData } = TerritoryDataStore();

  const [selectedSummaryCard, setSelectedSummaryCard] = useState<
    number | string
  >(0);
  const [selectedCustomerType, setSelectedCustomerType] = useState<string>("");
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const fetchStockists = (
    id: number | string,
    customerType: string,
    cardName?: string
  ) => {
    handleClearSelectedCustomers();

    let value = customerTypesData.find((type) => type.id === id);
    if (value?.button_access == 1) {
      setButtonAccess(true);
    } else {
      setButtonAccess(false);
    }
    if (value?.is_restricted == 1) {
      setIsRestricted(true);
    } else {
      setIsRestricted(false);
    }
    if (cardName === "ALL") {
      setIsAllSelected(!isAllSelected);
      setIsRestricted(true);
    } else {
      setIsAllSelected(false);
    }
    handlePageNumber(1);

    if (selectedCustomerType === customerType) {
      if (isSelected === false) {
        filterStore.customer_type_id = id;
      } else if (isSelected) {
        filterStore.customer_type_id = "";
      }
      setIsSelected(!isSelected);
    } else {
      setIsSelected(true);
      filterStore.customer_type_id = id;
    }

    filterStore.page = 1;
    if (panelName === Panels.PENDING_MAPPING) {
      filterStore.status = Status.ACTIVE;
      filterStore.mapping_status = Status.PENDING;
    } else if (panelName === Panels.PENDING_APPROVAL) {
      filterStore.status = Status.PENDING;
      filterStore.mapping_status = "";
      filterStore.status = Status.PENDING;
    } else if (panelName === Panels.ARCHIVED) {
      filterStore.status = Status.ACTIVE;
      filterStore.mapping_status = "";
      filterStore.status = Status.IN_ACTIVE;
    }
    setSelectedSummaryCard(id);
    fetchCustomerData();
    setSelectedCustomerType(customerType);
  };

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card
            onClick={() => fetchStockists("", "", "ALL")}
            sx={{
              backgroundColor:
                isAllSelected || !isSelected ? "#cae0e8" : "#F0F3F2",
              cursor: "pointer",
            }}
          >
            <CardContent>
              <Stack>
                <Typography
                  variant="body1"
                  color="primary.light"
                  overflow="visible"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  All
                </Typography>
                <Typography fontSize={25} fontWeight={600} color="primary">
                  {customerSummaryCounts.total_customers
                    ? customerSummaryCounts.total_customers
                    : "0"}
                </Typography>
                <Typography variant="body1" color="primary.light">
                  {customerSummaryCounts.coverage
                    ? `Coverage ${customerSummaryCounts.coverage}%`
                    : `Coverage 0.00%`}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {customerSummaryCounts.customer_type?.map(
          (count: CustomerSummaryType, index: number) => {
            return (
              <>
                <Grid item xs={6}>
                  <Card
                    onClick={() => fetchStockists(count.id, count.name)}
                    sx={{
                      backgroundColor:
                        customerSummaryCounts.customer_type[index].id ===
                          selectedSummaryCard && isSelected
                          ? "#cae0e8"
                          : "#F0F3F2",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Stack>
                        <Typography
                          variant="body1"
                          color="primary.light"
                          overflow="visible"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {count.name ? count.name : ""}
                        </Typography>
                        <Typography
                          fontSize={25}
                          fontWeight={600}
                          color="primary"
                        >
                          {count.total_customers ? count.total_customers : "0"}
                        </Typography>
                        <Typography variant="body1" color="primary.light">
                          {count.coverage
                            ? `Coverage ${count.coverage}%`
                            : `Coverage 0.00%`}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            );
          }
        )}
      </Grid>
    </Stack>
  );
};
export default CustomerSummaryMobile;
