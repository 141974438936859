import { FetchHeadquarterMaster } from '../../domain/useages/fetch-headquarter';
import { HttpConstants } from '../protocols/http/http-constants';
import { HttpGetClient } from './../protocols/http/http-get-client';

export class RemoteFetchHeadquarterMaster implements FetchHeadquarterMaster
{
    constructor(
        private readonly url: string,
        private readonly httpGetClient:HttpGetClient
    ) { }
    async fetch(params?: FetchHeadquarterMaster.Params): Promise<any> {
        const httpResponse = await this.httpGetClient.get({
            url: this.url,
            query: params,
            headers: {
                [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
                [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
            }, authHeaders: true,
        });
        if (httpResponse.status === 200) {
            return httpResponse.data;
        }
    }
}