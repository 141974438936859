import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Segoe UI, Roboto, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#0D71B7",
      light: "#464F60",
      dark: "#4D4D4D",
    },
    secondary: {
      light: "#252A33",
      main: "#6B6B6B",
      dark: "#B36B84",
    },
    error: {
      main: "#EF5466",
      light: "#DEF7E1",
      dark: "#FFE2E5",
    },
    info: {
      main: "#004E64",
      dark: "#A3BAC3",
      light: "#B5C7CF",
      contrastText: "#7AA1B8",
    },
  },
});
export default theme;
