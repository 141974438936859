export const AUTH_HEADER = process.env.REACT_APP_AUTH_HEADER!;
export const BASE_URL_KEY = process.env.REACT_APP_BASE_URL_KEY!;
export const AUTH_TOKEN_KEY = process.env.REACT_APP_AUTH_URL_KEY!;
export const DEACTIVATE_CUSTOMERS = process.env.REACT_APP_ZONE_MASTER_STAGING!;
export const CUSTOMER_SERVICE = process.env.REACT_APP_CUSTOMER_SERVICE!;
export const AUTHORIZATION_HEADER = process.env.REACT_APP_AUTHORIZATION_HEADER!;
export const DASHBOARD_AUTH_URL = process.env.REACT_APP_DASHBOARD_AUTH_URL!;
export const GA_CODE_KEY = process.env.REACT_APP_CODE!;
export const RAISE_COMPLAINT_URL = process.env.REACT_APP_RAISE_COMPLAIN!;
export const FRESHDESK_AUTH_HEADER =
  process.env.REACT_APP_FRESHDESK_AUTH_HEADER!;
export const API_KEY = process.env.REACT_APP_API_KEY!;
