import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { FetchState } from "../../../domain/models/fetch-state";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { SearchHeadquarterType } from "../../../domain/models/unmapped-stockist/unmapped-stockist-data";
import { FetchSearchHeadquarter } from "../../../domain/useages/unmapped-stockist/fetch-search-headquarter";
import { RemoteFetchSearchHeadquarter } from "../../../data/usecases/unmapped-stockist/remote-fetch-search-headquarter";

export interface FetchSearchHeadquarterInterface {
  searchHeadquarter: SearchHeadquarterType[];
  fetchSearchHeadquarter: Function;
  clearSearchHeadquarter: Function;
}

const initialStates = {
  searchHeadquarter: [],
  searchHeadquarterState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchsearchHeadquarterSlice: StateCreator<
  FetchSearchHeadquarterInterface
> = (set) => ({
  ...initialStates,
  fetchSearchHeadquarter: async (filters: FetchSearchHeadquarter.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remotesearchHeadquarter = new RemoteFetchSearchHeadquarter(
      `${BASE_URL}${Endpoints.SEARCH_HEADQUARTER}`,
      axiosHttpClient
    );

    let result = await remotesearchHeadquarter.fetch(filters);
    if (result) {
      set(() => ({
        searchHeadquarter: result,
      }));
    }
  },
  clearSearchHeadquarter: () => {
    set(() => ({ searchHeadquarter: [] }));
  },
});
