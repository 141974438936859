import {
  Box,
  FormControl,
  Modal,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Pagination,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CustomerSummary from "../../../customer-database/CustomerSummary";
import CustomerDetails from "../../../customer-database/CustomerDetails";
import { CustomerDatabaseStore } from "../../../../../../store/main/CustomerDatabaseStore";
import { FetchState } from "../../../../../../domain/models/fetch-state";
import ReportScreen from "../../../../../../common/enums/ReportScreens";
import { useEffect, useState } from "react";
import Status from "../../../../../../common/enums/Status";
import Panels from "../../../../../../common/enums/Panels";
import CustomerTableLoading from "../../../CustomerTableLoading";
import CustomerTable from "../../../customer-database/sub-component/CustomerTable";
import { BricksType } from "../../../../../../domain/models/brick-management-report/brick-management-data";

interface Props {
  open: boolean;
  handleClose: Function;
  successCallback: Function;
  row: BricksType;
  hq: string;
}

type FormFields = {
  brick: string;
  distance: string;
  station: string;
  expected: string;
  headquarter: string;
};

const DeactivateBrickModal: React.FC<Props> = ({
  open,
  handleClose,
  successCallback,
  row,
  hq,
}) => {
  const {
    selectedPanel,
    fetchSummaryState,
    filterStore,
    handleSelectedPanel,
    handleClearSelectedCustomers,
    fetchCustomerData,
    fetchCustomerTableState,
    pageData,
    currentPageNumber,
    handlePageNumber,
    fetchCustomerSummaryCount,
    customerSummaryCounts,
  } = CustomerDatabaseStore();
  const handleUpdate = () => {
    successCallback();
    handleClose();
  };
  const { handleSubmit, control } = useForm<FormFields>({
    mode: "onChange",
  });

  useEffect(() => {
    fetchCustomerSummaryCount(filterStore);
    FetchCustomerData();
  }, []);

  const disabledRemove = () => {
    const stockistCount = customerSummaryCounts.customer_type.find(
      (count) => count.id === 1
    )?.total_customers;
    return (
      stockistCount === customerSummaryCounts.total_customers ||
      customerSummaryCounts.total_customers === 0
    );
  };
  const FetchCustomerData = (pageno: number = 1) => {
    handleSelectedPanel(false);
    filterStore.mapping_status = "";
    filterStore.approved_status = "";
    filterStore.download = 0;
    filterStore.status = Status.ACTIVE;
    filterStore.page = pageno;
    handleClearSelectedCustomers();
    fetchCustomerData();
    handleSelectedPanel(false, Panels.ALL);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    handlePageNumber(value);
    FetchCustomerData(value);
  };

  const [buttonAccess, setButtonAccess] = useState<boolean>(false);
  const [isRestricted, setIsRestricted] = useState<boolean>(false);
  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box
        position={"absolute" as "absolute"}
        top={"50%"}
        left={"50%"}
        bgcolor={"background.paper"}
        borderRadius={"22px"}
        sx={{ transform: "translate(-50%, -50%)" }}
        width={"80%"}
        px={3}
        py={2}
      >
        <Typography
          fontWeight={500}
          variant="h6"
          textAlign="center"
          marginBottom={2}
        >
          Deactivate Brick
        </Typography>
        <Stack direction={"row"} spacing={2}>
          <Controller
            name="brick"
            control={control}
            defaultValue={row.brick}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  value={value}
                  label="Brick Name"
                  size="small"
                  onChange={onChange}
                  disabled
                />
              </FormControl>
            )}
          />
          <Controller
            name="headquarter"
            control={control}
            defaultValue={hq}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  value={value}
                  label="Headquarter Name"
                  size="small"
                  onChange={onChange}
                  disabled
                />
              </FormControl>
            )}
          />
          <Controller
            name="distance"
            control={control}
            defaultValue={row.bricks.distance_from_hq?.toString() ?? "-"}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  value={value}
                  size="small"
                  onChange={onChange}
                  label="Distance from HQ"
                  disabled
                />
              </FormControl>
            )}
          />
          <Controller
            name="station"
            control={control}
            defaultValue={row.bricks.station ?? "-"}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  value={value}
                  size="small"
                  onChange={onChange}
                  label="EX/OS/HQ"
                  disabled
                />
              </FormControl>
            )}
          />

          <Controller
            name="expected"
            control={control}
            defaultValue={row.bricks.expected_business?.toString() ?? "-"}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  value={value}
                  size="small"
                  onChange={onChange}
                  label="Expected Business"
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            )}
          />
        </Stack>
        <Stack paddingY={1}>
          <Typography variant="subtitle1" textAlign="center">
            Please transfer the following customers before Deactivating the
            Brick.
          </Typography>
          <Typography
            variant="subtitle1"
            textAlign="center"
            fontWeight={600}
            color="red"
          >
            *NOTE: All Stockist will be unmapped.
          </Typography>
        </Stack>

        <Box>
          {!selectedPanel && fetchSummaryState === FetchState.SUCCESS && (
            <CustomerSummary
              setIsRestricted={setIsRestricted}
              setButtonAccess={setButtonAccess}
              type={ReportScreen.BRICK_MANAGEMENT}
            />
          )}
          <Stack py={1}>
            <CustomerDetails
              buttonAccess={buttonAccess}
              isRestricted={isRestricted}
              type={ReportScreen.BRICK_MANAGEMENT}
            />
          </Stack>
          {fetchCustomerTableState === FetchState.LOADING && (
            <CustomerTableLoading />
          )}
          {fetchCustomerTableState === FetchState.SUCCESS && (
            <CustomerTable
              type={ReportScreen.BRICK_MANAGEMENT}
              panelName={"All"}
            />
          )}
          <Stack alignItems="center">
            {selectedPanel === false && (
              <Pagination
                count={Math.ceil(pageData.total / pageData.per_page)}
                variant="outlined"
                page={currentPageNumber}
                onChange={handlePageChange}
              />
            )}
          </Stack>
        </Box>
        <Stack
          paddingTop={1}
          direction={"row"}
          gap={3}
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleUpdate)}
            disabled={!disabledRemove()}
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "20%",
            }}
          >
            Deactivate Brick
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClose()}
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "15%",
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default DeactivateBrickModal;
