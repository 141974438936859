import { Stack } from "@mui/material";
import ReportScreen from "../../../../common/enums/ReportScreens";
import { FetchState } from "../../../../domain/models/fetch-state";
import Header from "../../Header";
import ReportFiltersComponent from "../ReportFiltersComponent";
import ReportFiltersLoading from "../ReportFiltersLoading";
import CustomerDetails from "./CustomerDetails";
import CustomerSummary from "./CustomerSummary";
import ColorInfo from "./sub-component/ColorInfo";
import SummaryLoading from "./sub-component/Loading/SummaryLoading";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { useEffect, useState } from "react";
import { ReportFilters } from "../../../../domain/models/report-filters";
import { CoverageReportsStore } from "../../../../store/main/CoverageReportsStore";
import { TerritoryDataStore } from "../../../../store/main/TerritoryDataStore";
import { TerritoryData } from "../../../../domain/models/territory-data";
import Roles from "../../../../common/enums/Roles";

const CustomerDatabaseDesktop = () => {
  const {
    fetchCustomerData,
    fetchCustomerSummaryCount,
    fetchSummaryState,
    filterStore,
    fetchHqSummaryData,
    decodeToken,
    selectedPanel,
    userDetails,
    userRole,
    handleFilterSegement,
    handleDepartmentCode,
    fetchMetadata,
    handleHeadquarterCode,
  } = CustomerDatabaseStore();

  const { fetchCustomerTypes, headquarterMasterData } = TerritoryDataStore();

  const { fetchSpecialities } = CoverageReportsStore();

  useEffect(() => {
    filterStore.zone_ids = [];
    filterStore.region_ids = [];
    filterStore.area_ids = [];
    filterStore.headquarter_ids = [];
    filterStore.territory_ids = [];
    filterStore.download = 0;
    filterStore.brick_ids = [];
    filterStore.q = "";
    filterStore.department = "";
    filterStore.segement = [];
    decodeToken();
    fetchCustomerSummaryCount(filterStore);
    fetchCustomerData();
    fetchHqSummaryData();
    fetchSpecialities();
    fetchCustomerTypes();
    fetchMetadata();
  }, []);
  const setHeadquarterCode = (hq: string) => {
    const arr = headquarterMasterData.find(
      (name: TerritoryData) => name.id === parseInt(hq)
    );
    handleHeadquarterCode(arr?.ga_code);
  };
  const filterData = (filters: ReportFilters) => {
    filterStore.zone_ids = filters.zone;
    filterStore.region_ids = filters.region;
    filterStore.area_ids = filters.area;
    filterStore.headquarter_ids = filters.headquarter;
    filterStore.brick_ids = filters.brick;
    filterStore.q = filters.search;
    filterStore.customer_type_id = "";
    filterStore.department = filters.department;
    filterStore.segement = filters.segement;
    handleFilterSegement(filters.segement);
    handleDepartmentCode(filters.department);
    setHeadquarterCode(filters.headquarter[0]);
    fetchCustomerSummaryCount(filterStore);
    fetchCustomerData();
  };

  const clearData = (filters: ReportFilters) => {
    if (userRole.role === Roles.COUTNRY_MANAGER) {
      filterStore.zone_ids = [];
      filterStore.region_ids = [];
      filterStore.area_ids = [];
      filterStore.headquarter_ids = [];
      filterStore.brick_ids = [];
    } else if (userRole.role === Roles.ZONE_MANAGER) {
      filterStore.region_ids = [];
      filterStore.area_ids = [];
      filterStore.headquarter_ids = [];
      filterStore.brick_ids = [];
    } else if (userRole.role === Roles.REGION_MANAGER) {
      filterStore.area_ids = [];
      filterStore.headquarter_ids = [];
      filterStore.brick_ids = [];
    } else if (userRole.role === Roles.AREA_MANAGER) {
      filterStore.headquarter_ids = [];
      filterStore.brick_ids = [];
    } else if (userRole.role === Roles.HEADQUATER_MANAGER) {
      filterStore.brick_ids = [];
    }
    filterStore.q = "";
    filterStore.customer_type_id = "";
    filterStore.department = "";
    filterStore.segement = [];
    handleFilterSegement([]);
    fetchCustomerSummaryCount(filterStore);
    fetchCustomerData();
  };

  const [buttonAccess, setButtonAccess] = useState<boolean>(false);
  const [isRestricted, setIsRestricted] = useState<boolean>(false);
  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingX={2}
      >
        <Header title="Customer Database" />
        <Stack direction={"row"} gap={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"center"}
            gap={1}
          >
            <ColorInfo
              color="#FFEBCC"
              label="System suggestion: Doctor should be removed from MVL"
            />
          </Stack>
        </Stack>
      </Stack>
      {!selectedPanel &&
        (Object.keys(userDetails).length > 0 ? (
          <ReportFiltersComponent
            type={ReportScreen.CUSTOMER_DATABASE}
            successCallback={filterData}
            clearSuccessCallBack={clearData}
            userRole={userRole.role}
            userDetails={userDetails}
            gridColumns={22}
          />
        ) : (
          <ReportFiltersLoading />
        ))}

      {!selectedPanel && fetchSummaryState === FetchState.LOADING && (
        <SummaryLoading />
      )}
      {!selectedPanel && fetchSummaryState === FetchState.SUCCESS && (
        <CustomerSummary
          setIsRestricted={setIsRestricted}
          setButtonAccess={setButtonAccess}
          type={ReportScreen.CUSTOMER_DATABASE}
        />
      )}

      <CustomerDetails
        buttonAccess={buttonAccess}
        isRestricted={isRestricted}
        type={ReportScreen.CUSTOMER_DATABASE}
      />
    </Stack>
  );
};
export default CustomerDatabaseDesktop;
