import BrickWorkingReportTable from "./BrickWorkingReportTable";
import ReportFiltersComponent from "../ReportFiltersComponent";
import ReportFiltersLoading from "../ReportFiltersLoading";
import { useEffect } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import BrickWorkingReportTabs from "./BrickWorkingReportTabs";
import dayjs from "dayjs";
import ReportScreen from "../../../../common/enums/ReportScreens";
import Roles from "../../../../common/enums/Roles";
import { FetchState } from "../../../../domain/models/fetch-state";
import { ReportFilters } from "../../../../domain/models/report-filters";
import { BrickWorkingReportStore } from "../../../../store/main/BrickWorkingReportStore";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";

type Props = {};

const BrickWorkingReportComponent = (props: Props) => {
  const { decodeToken, userDetails, userRole } = CustomerDatabaseStore();
  const {
    fetchBrickWorkingReport,
    brickReportData,
    brickReportLoading,
    brickReportFilters,
    downloadBrickWorkingReport,
  } = BrickWorkingReportStore();

  useEffect(() => {
    if (
      userRole.role &&
      userRole.role != Roles.COUTNRY_MANAGER &&
      userRole.role != Roles.ZONE_MANAGER
    ) {
      fetchBrickWorkingReport();
    }
  }, [userRole.role]);

  useEffect(() => {
    decodeToken();
  }, []);

  const handleFilterBrickWorkingReport = (filters: ReportFilters) => {
    brickReportFilters.year = filters.year;
    brickReportFilters.customer_type_id = filters.customerType;
    brickReportFilters.product_ids = filters.products;
    fetchBrickWorkingReport();
  };

  const clearFilterBrickWorkingReport = (filters: ReportFilters) => {
    brickReportFilters.year = dayjs(new Date());
    brickReportFilters.customer_type_id = "";
    brickReportFilters.product_ids = [];
    fetchBrickWorkingReport();
  };

  const handleDownload = () => {
    downloadBrickWorkingReport();
  };
  return (
    <Stack spacing={3} marginX={2}>
      {Object.keys(userDetails).length > 0 ? (
        <Stack
          direction={"row"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {(userRole.role == Roles.COUTNRY_MANAGER ||
            userRole.role == Roles.ZONE_MANAGER) && (
            <BrickWorkingReportTabs userDetails={userDetails} />
          )}
          <Stack marginRight={8} width={"100%"}>
            <ReportFiltersComponent
              type={ReportScreen.BRICK_WORKING_REPORT}
              successCallback={handleFilterBrickWorkingReport}
              clearSuccessCallBack={clearFilterBrickWorkingReport}
              gridColumns={13}
              userRole={userRole.role}
              userDetails={userDetails}
              justifyContent={"end"}
            />
          </Stack>
        </Stack>
      ) : (
        <ReportFiltersLoading />
      )}
      {brickReportLoading === FetchState.LOADING && (
        <Stack alignItems={"center"} height={100} justifyContent={"center"}>
          <CircularProgress />
        </Stack>
      )}
      {brickReportLoading === FetchState.SUCCESS &&
        (brickReportData.length > 0 ? (
          // <Stack spacing={2}>
          //   <Stack justifyContent={"end"} alignItems={"end"}>
          //     <LoadingButton variant="contained" onClick={handleDownload}>
          //       <Typography textTransform={"none"}>Download Report</Typography>
          //     </LoadingButton>
          //   </Stack>

          <BrickWorkingReportTable />
        ) : (
          // </Stack>
          <Typography variant="h6">No Records Found</Typography>
        ))}
    </Stack>
  );
};

export default BrickWorkingReportComponent;
