import { create } from "zustand";
import { useFetchDistrictSlice } from "../slices/BrickManagementSlices/fetchDistrictSlice";
import { useFetchClusterSlice } from "../slices/BrickManagementSlices/fetchClusterSlice";
import { useAddBrickSlice } from "../slices/BrickManagementSlices/addBrick";
import { useUpdateBrickSlice } from "../slices/BrickManagementSlices/updateBrick";
import { useFetchBrickManagementSlice } from "../slices/BrickManagementSlices/fetchBrickManagement";
import { useDeactivateBrickSlice } from "../slices/BrickManagementSlices/deactivateBrick";

interface BrickManagementInterface
  extends ReturnType<typeof useFetchDistrictSlice>,
    ReturnType<typeof useFetchClusterSlice>,
    ReturnType<typeof useAddBrickSlice>,
    ReturnType<typeof useUpdateBrickSlice>,
    ReturnType<typeof useFetchBrickManagementSlice>,
    ReturnType<typeof useDeactivateBrickSlice> {}

export const BrickManagementStore = create<BrickManagementInterface>(
  (...a) => ({
    ...useFetchDistrictSlice(...a),
    ...useFetchClusterSlice(...a),
    ...useAddBrickSlice(...a),
    ...useUpdateBrickSlice(...a),
    ...useFetchBrickManagementSlice(...a),
    ...useDeactivateBrickSlice(...a),
  })
);
