import { create } from "zustand";
import { useFetchUserInputPlanningSlice } from "../slices/UserInputPlanningSlice/fetchUserInputPlanningSlice";
import { useUpdateUserInputPlanningSlice } from "../slices/UserInputPlanningSlice/updateUserInputPlanningSlice";

interface UserInputPlanningInterface
  extends ReturnType<typeof useUpdateUserInputPlanningSlice>,
    ReturnType<typeof useFetchUserInputPlanningSlice> {}

export const UserInputPlanningStore = create<UserInputPlanningInterface>(
  (...a) => ({
    ...useFetchUserInputPlanningSlice(...a),
    ...useUpdateUserInputPlanningSlice(...a),
  })
);
