enum CustomerType {
  STOCKIST = "Stockist",
  SEMI_STOCKIST = "Semi Stockist",
  RETAILERS = "Retailers",
  SURGICAL_SEMIS = "Surgical Semis",
  HEALTH_CARE_PROVIDERS = "Healthcare Provider",
  HUB_CHEMIST = "Hub Chemist",
}

export default CustomerType;
