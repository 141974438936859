import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { FetchState } from "../../../domain/models/fetch-state";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { UnmappedStockistType } from "../../../domain/models/unmapped-stockist/unmapped-stockist-data";
import { RemoteFetchUnmappedStockist } from "../../../data/usecases/unmapped-stockist/remote-fetch-unmapped-stockist";
import { FetchUnmappedStockist } from "../../../domain/useages/unmapped-stockist/fetch-unmapped-stockist";

export interface FetchUnmappedStockistInterface {
  unmappedStockist: UnmappedStockistType[];
  unmappedStockistState: FetchState;
  fetchUnmappedStockist: Function;
}

const initialStates = {
  unmappedStockist: [],
  unmappedStockistState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchUnmappedStockistSlice: StateCreator<
  FetchUnmappedStockistInterface
> = (set) => ({
  ...initialStates,
  fetchUnmappedStockist: async (filters: FetchUnmappedStockist.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteUnmappedStockist = new RemoteFetchUnmappedStockist(
      `${BASE_URL}${Endpoints.FETCH_UNMAPPED_STOCKIST}`,
      axiosHttpClient
    );

    set(() => ({ unmappedStockistState: FetchState.LOADING }));
    let result = await remoteUnmappedStockist.fetch(filters);
    if (result && result.length > 0) {
      set(() => ({
        unmappedStockist: result,
        unmappedStockistState: FetchState.SUCCESS,
      }));
    } else {
      set(() => ({ unmappedStockistState: FetchState.ERROR }));
    }
  },
});
