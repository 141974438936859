import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteFetchUserInputPlanning } from "../../../data/usecases/user-input-planning/remote-fetch-user-input-planning";
import Endpoints from "../../../domain/endpoints";
import { UserInputPlanningModel } from "../../../domain/models/user-input-acknowledgement/user-input-planning-model";
import { FetchState } from "../../../domain/models/fetch-state";
import Swal from "sweetalert2";
import { FetchUserInputPlanning } from "../../../domain/useages/user-input-planning/fetch-user-input-planning";
import moment from "moment";
import dayjs from "dayjs";

export interface FetchUserInputPlanningInterface {
  fetchUserInputPlanning: Function;
  userInputData: UserInputPlanningModel;
  fetchInputState: FetchState;
  currentPage: number;
  handlePageNumber: Function;
  filterStore: FetchUserInputPlanning.Params;
}

const initialStates = {
  userInputData: {} as UserInputPlanningModel,
  fetchInputState: FetchState.DEFAULT,
  currentPage: 1,
  filterStore: {
    zone_ids: [],
    region_ids: [],
    area_ids: [],
    headquarter_ids: [],
    brick_ids: [],
    month_year: moment(dayjs(new Date()).toString()).format("yyMM"),
    customer_type_id: "",
    page: 1,
  } as FetchUserInputPlanning.Params,
};

export const useFetchUserInputPlanningSlice: StateCreator<
  FetchUserInputPlanningInterface
> = (set, get) => ({
  ...initialStates,
  fetchUserInputPlanning: async (payload: any) => {
    set(() => ({ fetchInputState: FetchState.LOADING }));

    const axiosHttpClient = AxiosHttpClient.getInstance();
    const storage = LocalJsonStorage.getInstance();

    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchBrandsMaster = new RemoteFetchUserInputPlanning(
      `${BASE_URL}${Endpoints.USER_INPUT_PLANNING}`,
      axiosHttpClient
    );

    // let fiters = {
    //   zone_ids: get().filterStore.zone_ids,
    //   region_ids: get().filterStore.region_ids,
    //   area_ids: get().filterStore.area_ids,
    //   headquarter_ids: get().filterStore.headquarter_ids,
    //   brick_ids: get().filterStore.brick_ids,
    //   month_year: get().filterStore.date,
    //   customer_type_id: get().filterStore.customer_type_id,
    //   page: get().filterStore.page,
    // };

    let result = await remoteFetchBrandsMaster.fetch(get().filterStore);
    if (result.success) {
      set(() => ({ userInputData: result.data }));
      set(() => ({ fetchInputState: FetchState.SUCCESS }));
    } else {
      set(() => ({ fetchInputState: FetchState.ERROR }));
      Swal.fire({
        icon: "error",
        text: "There Was Some Error in getting the Inputs",
        timer: 3000,
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },
  handlePageNumber: (pageNo: number) => {
    set(() => ({ currentPage: pageNo }));
  },
});
