import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteFetchCustomersCounts } from "../../../data/usecases/remote-fetch-customer-count";
import { CustomerSummaryResponse } from "../../../domain/models/customer-database/customer-summary";
import { FetchState } from "../../../domain/models/fetch-state";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { customerDatabaseFilters } from "../../../domain/models/customer-database/customer-database-filters";

export interface FetchCustomerSummaryCountInterface {
  customerSummaryCounts: CustomerSummaryResponse;
  fetchCustomerSummaryCount: Function;
  fetchSummaryState: FetchState;
}

const initialStates = {
  customerSummaryCounts: {} as CustomerSummaryResponse,
  fetchSummaryState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchCustomerSummaryCountSlice: StateCreator<
  FetchCustomerSummaryCountInterface
> = (set) => ({
  ...initialStates,
  fetchCustomerSummaryCount: async (summaryStore: customerDatabaseFilters) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchCustomersCounts = new RemoteFetchCustomersCounts(
      `${BASE_URL}${Endpoints.FETCH_CUSTOMER_COUNT}`,
      axiosHttpClient
    );

    let filters = {
      mapping_status: summaryStore.mapping_status,
      status: summaryStore.status,
      zone_ids: summaryStore.zone_ids,
      region_ids: summaryStore.region_ids,
      area_ids: summaryStore.area_ids,
      headquarter_ids: summaryStore.headquarter_ids,
      territory_ids: summaryStore.territory_ids,
      brick_ids: summaryStore.brick_ids,
    };

    set(() => ({ fetchSummaryState: FetchState.LOADING }));
    let result = await remoteFetchCustomersCounts.fetch(filters);
    if (result) {
      set(() => ({ customerSummaryCounts: result.customers }));
      set(() => ({ fetchSummaryState: FetchState.SUCCESS }));
    }
  },
});
