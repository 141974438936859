import { Box, Stack, Typography } from "@mui/material";
import ReportFiltersComponent from "../components/desktop/ReportFiltersComponent";
import { CustomerDatabaseStore } from "../../store/main/CustomerDatabaseStore";
import ReportScreen from "../../common/enums/ReportScreens";
import { ReportFilters } from "../../domain/models/report-filters";
import ReportFiltersLoading from "../components/desktop/ReportFiltersLoading";
import HQDistanceReportTable from "../components/desktop/hq-distance-report/HQDistanceReportTable";
import React, { useEffect } from "react";
import { HqDistanceStore } from "../../store/main/HqDistanceStore";
import { FetchState } from "../../domain/models/fetch-state";
import { TerritoryDataStore } from "../../store/main/TerritoryDataStore";
import CustomerTableLoading from "../components/desktop/CustomerTableLoading";
import NoRecordFound from "../components/desktop/NoRecordFound";

const HQDistanceReport = () => {
  const { userDetails, userRole, decodeToken } = CustomerDatabaseStore();
  const { fetchHqDistance, fetchHqDistanceState, hqDistanceFilter } =
    HqDistanceStore();
  const {
    zoneMasterData,
    regionMasterData,
    areaMasterData,
    headquarterMasterData,
  } = TerritoryDataStore();

  useEffect(() => {
    decodeToken();
    fetchHqDistance();
  }, []);

  const filterData = (filters: ReportFilters) => {
    let gaCode: string = "";
    if (filters.headquarter.length !== 0) {
      gaCode =
        headquarterMasterData.find(
          (hq) => hq.id === parseInt(filters.headquarter[0])
        )?.ga_code ?? "";
    } else if (filters.area.length !== 0) {
      gaCode =
        areaMasterData.find((area) => area.id === parseInt(filters.area[0]))
          ?.ga_code ?? "";
    } else if (filters.region.length !== 0) {
      gaCode =
        regionMasterData.find(
          (region) => region.id === parseInt(filters.region[0])
        )?.ga_code ?? "";
    } else if (filters.zone.length !== 0) {
      gaCode =
        zoneMasterData.find((zone) => zone.id === parseInt(filters.zone[0]))
          ?.ga_code ?? "";
    }
    hqDistanceFilter.ga_code = gaCode;
    hqDistanceFilter.q = filters.search;
    fetchHqDistance(hqDistanceFilter);
  };
  const clearData = (filters: ReportFilters) => {
    fetchHqDistance();
  };

  return (
    <>
      <Box padding={2}>
        <Typography variant="h5" fontWeight={600}>
          Headquarter Distance
        </Typography>
        <Stack paddingY={2}>
          {Object.keys(userDetails).length > 0 ? (
            <ReportFiltersComponent
              type={ReportScreen.HQ_DISTANCE_REPORT}
              successCallback={filterData}
              clearSuccessCallBack={clearData}
              userRole={userRole.role}
              userDetails={userDetails}
              gridColumns={14}
              territoryMultiSelect={false}
            />
          ) : (
            <ReportFiltersLoading />
          )}
        </Stack>
        {fetchHqDistanceState === FetchState.SUCCESS && (
          <HQDistanceReportTable />
        )}
        {fetchHqDistanceState === FetchState.LOADING && (
          <CustomerTableLoading />
        )}
        {fetchHqDistanceState === FetchState.ERROR && (
          <NoRecordFound height="60vh" />
        )}
      </Box>
    </>
  );
};

export default HQDistanceReport;
