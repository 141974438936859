import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import Endpoints from "../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { Speciality } from "../../domain/models/speciality";
import { RemoteFetchDepartmentMaster } from "../../data/usecases/remote-fetch-department-master";
import { FetchDepartmentMaster } from "../../domain/useages/fetch-department-master";

export interface FetchDepartmentInterface {
  departmentData: Speciality[];
  fetchDepartment: Function;
}

const initialStates = {
  departmentData: [],
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchDepartmentSlice: StateCreator<
FetchDepartmentInterface
> = (set) => ({
  ...initialStates,
  fetchDepartment: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchDepartmentMaster: FetchDepartmentMaster =
      new RemoteFetchDepartmentMaster(
        `${BASE_URL}${Endpoints.FETCH_DEPARTMENTS}`,
        axiosHttpClient
      );

    set(() => ({ departmentData: [] }));

    let result = await remoteFetchDepartmentMaster.fetch();
    if (result.departments) {
      set(() => ({ departmentData: result.departments }));
    }
  },
});
