import { CircularProgress, Stack } from "@mui/material";
import { BatchWiseInputReportTable } from "./BatchWiseInputReportTable";
import { CustomerWiseInputReportTable } from "./CustomerWiseInputReportTable";
import { FetchState } from "../../../../domain/models/fetch-state";
import { InputUsageSummaryStore } from "../../../../store/main/InputUsageReportStore";
type Props = {
  type: string;
};

export const InputUtilizationReportComponent: React.FC<Props> = ({ type }) => {
  const { fetchCustomerWiseDataState, fetchBatchWiseDataState } =
    InputUsageSummaryStore();

  return (
    <>
      <Stack justifyContent={"center"} alignItems={"center"}>
        {type == "batchWise" &&
        fetchBatchWiseDataState == FetchState.SUCCESS ? (
          <Stack width={"100%"}>
            <BatchWiseInputReportTable />
          </Stack>
        ) : (
          <Stack mt={4}>{type == "batchWise" && <CircularProgress />}</Stack>
        )}
        {type == "customerWise" &&
        fetchCustomerWiseDataState == FetchState.SUCCESS ? (
          <Stack width={"100%"}>
            <CustomerWiseInputReportTable />
          </Stack>
        ) : (
          type == "customerWise" && <CircularProgress />
        )}
      </Stack>
    </>
  );
};
