import { Button, Stack, Typography } from "@mui/material";
import Header from "./../components/Header";
import ZoneMasterData from "../components/desktop/zone-master-report/ZoneMasterData";
import { useEffect, useState } from "react";
import { ZoneMasterReportStore } from "../../store/main/ZoneMasterReportStore";
import { FetchState } from "../../domain/models/fetch-state";
import ZoneMasterDataLoading from "../components/desktop/zone-master-report/ZoneMasterDataLoading";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CustomerDatabaseStore } from "../../store/main/CustomerDatabaseStore";
import ReportFiltersComponent from "../components/desktop/ReportFiltersComponent";
import ReportFiltersLoading from "../components/desktop/ReportFiltersLoading";
import ReportScreen from "../../common/enums/ReportScreens";
import { ReportFilters } from "../../domain/models/report-filters";
import Roles from "../../common/enums/Roles";

const ZoneMasterReport = () => {
  const { fetchZoneMaster, zoneMasterState, zoneMasterData } =
    ZoneMasterReportStore();
  const { userDetails, userRole, filterStore, decodeToken } =
    CustomerDatabaseStore();
  useEffect(() => {
    filterStore.zone_ids = [];
    filterStore.area_ids = [];
    filterStore.region_ids = [];
    filterStore.headquarter_ids = [];
    fetchZoneMaster(filterStore);
    decodeToken();
  }, []);
  const createDownloadData = () => {
    let downloadData: any[] = [];
    if (zoneMasterData?.length) {
      {
        zoneMasterData.map((regiondata) => {
          regiondata.areas.map((areadata) => {
            areadata.headquarters.map((hqdata) => {
              hqdata.bricks.map((brickdata) => {
                let tempdata = {
                  region: regiondata.name,
                  area: areadata.name,
                  hq: hqdata.name,
                  brick: brickdata.name,
                  district: brickdata.district,
                  brickcode: brickdata.brick_code,
                  distance_from_hq: brickdata.distance_from_hq,
                  expected_business: brickdata.expected_business,
                  workind_days: brickdata.no_of_work_days,
                  status: brickdata.status,
                  station: brickdata.station,
                  vacantRegion: regiondata.vacantRegion,
                  vacantAreas: areadata.vacantArea,
                  vacantHQs: hqdata.vacantHQ,
                };
                downloadData.push(tempdata);
              });
            });
          });
        });
      }
    }
    return downloadData;
  };

  const filterData = (filters: ReportFilters) => {
    filterStore.zone_ids = filters.zone;
    filterStore.region_ids = filters.region;
    filterStore.area_ids = filters.area;
    filterStore.headquarter_ids = filters.headquarter;
    fetchZoneMaster(filterStore);
  };

  const clearData = (filters: ReportFilters) => {
    if (userRole.role === Roles.COUTNRY_MANAGER) {
      filterStore.zone_ids = [];
      filterStore.area_ids = [];
      filterStore.region_ids = [];
      filterStore.headquarter_ids = [];
    } else if (userRole.role === Roles.ZONE_MANAGER) {
      filterStore.area_ids = [];
      filterStore.region_ids = [];
      filterStore.headquarter_ids = [];
    } else if (userRole.role === Roles.REGION_MANAGER) {
      filterStore.area_ids = [];
      filterStore.headquarter_ids = [];
    } else if (userRole.role === Roles.AREA_MANAGER) {
      filterStore.headquarter_ids = [];
    }
    fetchZoneMaster(filterStore);
  };

  const { downloadExcelTable } = CustomerDatabaseStore();
  const downloadFile = async () => {
    let result = await createDownloadData();
    downloadExcelTable(result);
  };

  return (
    <Stack gap={3} justifyContent="center" marginTop={1}>
      <Stack direction={"row"} justifyContent={"space-between"} paddingX={3}>
        <Header title={"Zone Master"} />
        {userDetails &&
          userDetails.role &&
          userDetails.role.visibility == 1 && (
            <Button
              variant="contained"
              startIcon={<FileDownloadOutlinedIcon />}
              sx={{ borderRadius: "10px", width: "180px", height: "40px" }}
              onClick={downloadFile}
              color="primary"
            >
              <Typography variant="subtitle2" textTransform={"none"}>
                Download Excel
              </Typography>
            </Button>
          )}
      </Stack>
      <Stack paddingX={3}>
        {Object.keys(userDetails).length > 0 ? (
          <ReportFiltersComponent
            type={ReportScreen.ZONE_MASTER}
            successCallback={filterData}
            clearSuccessCallBack={clearData}
            userRole={userRole.role}
            userDetails={userDetails}
            gridColumns={12}
          />
        ) : (
          <ReportFiltersLoading />
        )}
      </Stack>

      {zoneMasterState == FetchState.SUCCESS && <ZoneMasterData />}
      {zoneMasterState == FetchState.LOADING && <ZoneMasterDataLoading />}
      {zoneMasterState == FetchState.SUCCESS && zoneMasterData?.length == 0 && (
        <Typography variant="h6">No Data Found</Typography>
      )}
    </Stack>
  );
};

export default ZoneMasterReport;
