import { StateCreator } from "zustand";
import { FindById } from "../../../domain/models/find-by-id";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteFindById } from "../../../data/usecases/customer-database/remote-find-by-id";
import { FetchState } from "../../../domain/models/fetch-state";
import Swal from "sweetalert2";

export interface FindCustomerByIdInterface {
  fetchCustomer: Function;
  customerDataById: FindById;
  customerDatafetchState: FetchState;
  handleFindCustomerLoading: Function;
}

const initialStaes = {
  customerDataById: {} as FindById,
  customerDatafetchState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFindCustomerByIdSlice: StateCreator<
  FindCustomerByIdInterface
> = (set, get) => ({
  ...initialStaes,
  handleFindCustomerLoading: (value: boolean) => {
    if (value) {
      Swal.fire({
        didOpen: () => {
          if (value) {
            Swal.showLoading();
          }
        },
        customClass: {
          container: "swal2-style",
        },
        title: "Please wait... fetching customer data ",
      });
    } else {
      Swal.close();
    }
  },
  fetchCustomer: async (id: string) => {
    set(() => ({ customerDatafetchState: FetchState.LOADING }));
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFindCustomerById = new RemoteFindById(
      `${BASE_URL}${Endpoints.FIND_BY_UUID}`,
      axiosHttpClient
    );
    get().handleFindCustomerLoading(true);
    let payload = {
      id: id,
      identification: true,
    };

    let result = await remoteFindCustomerById.fetch(payload);
    if (result && result.success) {
      get().handleFindCustomerLoading(false);
      set(() => ({ customerDatafetchState: FetchState.SUCCESS }));
      set(() => ({ customerDataById: result }));
    } else {
      set(() => ({ customerDatafetchState: FetchState.DEFAULT }));
      get().handleFindCustomerLoading(false);
      Swal.fire({
        timer: 3000,
        icon: "error",
        text: "Customer Not Found",
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },
});
