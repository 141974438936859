import React from "react";
import { Box, Typography } from "@mui/material";

interface ColorInfoProps {
  color: string;
  label: string;
  textColor?: string;
}

const ColorInfo: React.FC<ColorInfoProps> = ({ color, label, textColor }) => {
  return (
    <Box display="flex" alignItems={"center"} paddingTop={2}>
      <Box
        width={15}
        height={15}
        sx={{
          backgroundColor: color,
        }}
      />
      <Typography
        variant="subtitle2"
        fontWeight={550}
        marginLeft={1}
        style={{ color: textColor }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default ColorInfo;
