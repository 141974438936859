import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY, BASE_URL_KEY, AUTH_HEADER } from "../../../base";
import { RemoteFetchDistrict } from "../../../data/usecases/brick-management/remote-fetch-district";
import Endpoints from "../../../domain/endpoints";
import { FetchState } from "../../../domain/models/fetch-state";
import { DistrictType } from "../../../domain/models/brick-management-report/brick-management-data";

export interface FetchDistrictInterface {
  districtData: DistrictType[];
  fetchDistrict: Function;
  fetchDistrictState: FetchState;
}

const initialValue = {
  districtData: [],
  fetchDistrictState: FetchState.DEFAULT,
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchDistrictSlice: StateCreator<FetchDistrictInterface> = (
  set
) => ({
  ...initialValue,
  fetchDistrict: async (id: number) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    set(() => ({ fetchDistrictState: FetchState.LOADING }));

    const remoteFetchDistrict = new RemoteFetchDistrict(
      `${BASE_URL}${Endpoints.FETCH_DISTRICT_MASTER}`,
      axiosHttpClient
    );
    const payload = { hq_id: id };
    let result = await remoteFetchDistrict.fetch(payload);
    if (result) {
      set(() => ({
        districtData: result,
        fetchDistrictState: FetchState.SUCCESS,
      }));
    }
  },
});
