import { RemoveMappings } from "../../domain/useages/remove-mapping";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteRemoveMappings implements  RemoveMappings{
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async remove(params: RemoveMappings.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
        url: this.url,
        body: params.customer_info,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    
      return httpResponse.body ? httpResponse.body:httpResponse.data;
     }
}
