import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import IMAGES from "../../../../../../assets/images/images";
import { CustomerFetchDetails } from "../../../../../../domain/models/customer-database/customerFetchDetails";
import { CustomerDatabaseStore } from "../../../../../../store/main/CustomerDatabaseStore";
import { MobileModalStyle } from "../../../../../../common/constants/custom-style";

interface Props {
  open: boolean;
  handleClose: Function;
}

const MapCustomerModalMobile: React.FC<Props> = ({ open, handleClose }) => {
  const { customerFetchedData, fetchCustomerData } = CustomerDatabaseStore();
  const { handleSubmit, control, setValue } = useForm<any>({
    mode: "onChange",
  });

  const [customerName, setCustomerName] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setCustomerName(event.target.value as string);
    // setCustomerID(event.target.value);
  };

  return (
    <>
      <Modal open={open}>
        <Box sx={MobileModalStyle}>
          <Stack spacing={1}>
            <Typography
              textAlign="center"
              variant="h6"
              fontFamily="Segoe UI"
              fontWeight={600}
              color="#4D4D4D"
            >
              Map Customer
            </Typography>
            <Typography variant="subtitle2">Select Customer</Typography>

            {/* <TextField select variant="outlined" fullWidth size="small">
                {customerFetchedData.length > 0 &&
                  customerFetchedData.map((data: CustomerFetchDetails, index) => (
                    <>
                      {data.firmNames.map((names) => (
                        <MenuItem value={names.name} key={names._id}>
                          <Typography color="secondary.light" variant="caption">
                            {names.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </>
  
                    // {data.firmNames.map((firmNameData) => (
  
                    // ))}
                  ))}
              </TextField> */}
            {/* 
              <Autocomplete
                options={customerFetchedData?.map((data) => {
                  data?.firmNames?.map((names) => names?.name);
                })}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
              /> */}

            <FormControl fullWidth>
              <Select value={customerName} onChange={handleChange} size="small">
                {customerFetchedData.map((data: CustomerFetchDetails) =>
                  data.firmNames.map((names) => (
                    <MenuItem value={names.name} key={names._id}>
                      {names.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
            <Typography variant="subtitle2">Phone Number</Typography>
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled
                  value={value}
                  error={error && true}
                  helperText={error?.message}
                  onChange={onChange}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />
            <Typography variant="subtitle2">GSTIN</Typography>
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled
                  value={value}
                  error={error && true}
                  helperText={error?.message}
                  onChange={onChange}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />
            <Stack
              direction="row"
              gap={2}
              justifyContent="space-around"
              alignItems="center"
            >
              <Typography variant="subtitle2">Shop Image</Typography>
              <Typography variant="subtitle2">Invoice Image</Typography>
            </Stack>
            <Stack
              direction="row"
              gap={2}
              justifyContent="space-around"
              alignItems="center"
            >
              <img src={IMAGES.UploadImage} alt="+" />
              <img src={IMAGES.UploadImage} alt="+" />
            </Stack>
            <Stack direction="row" gap={4} justifyContent="center">
              <Button
                variant="contained"
                sx={{ textTransform: "unset", borderRadius: "30px" }}
              >
                <Typography variant="subtitle1">Map Customer</Typography>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "unset",
                  borderRadius: "30px",
                  minWidth: "20%",
                }}
                onClick={() => handleClose()}
              >
                <Typography variant="subtitle1">Cancel</Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default MapCustomerModalMobile;
