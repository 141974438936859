import { StateCreator } from "zustand";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { RemoteRemoveMappings } from "../../data/usecases/remote-remove-mappings";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import Endpoints from "../../domain/endpoints";
import { RemoveMapping } from "../../domain/models/remove-mapping";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import Swal from "sweetalert2";

export interface RemoveMappingInterface {
  removeCustomerMapping: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useRemoveMapping: StateCreator<RemoveMappingInterface> = (
  set,
  get
) => ({
  removeCustomerMapping: async (
    customer_info: RemoveMapping,
    handleClose: Function,
    title: string,
    fetchCustomerData: Function,
    handleClearSelectedCustomers: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteRemoveMappings = new RemoteRemoveMappings(
      `${BASE_URL}${Endpoints.REMOVE_MAPPINGS}`,
      axiosHttpClient
    );
    let result = await remoteRemoveMappings.remove({ customer_info });
    if (result.success) {
      handleClose(title);
      handleClearSelectedCustomers()
      fetchCustomerData();
      Swal.fire({
        icon: "success",
        title: result.message,
        timer: 3000,
        customClass: {
          container: "swal2-style",
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Some Error Occured",
        timer: 3000,
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },
});
