import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography, Box, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import UserInputPlanningTableData from "./UserInputPlanningTableData";
import { UserInputPlanningStore } from "../../../../store/main/UserInputPlanningStore";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import Swal from "sweetalert2";
import { DataArrayRounded } from "@mui/icons-material";

type Props = {
  allowEdit: boolean;
};

const UserInputPlanningTable: React.FC<Props> = ({ allowEdit }) => {
  const [tableData, setTableData] = useState<(string | number)[][]>([[""]]);
  const [inputData, setInputData] = useState<any[]>([]);
  const [headerData, setHeaderData] = useState<any[]>([]);
  const [quantityTotal, setQuantityTotal] = useState<any[]>([]);
  const [customerData, setCustomerData] = useState<any[]>([]);
  const { userInputData, updateUserInputPlanning, currentPage, filterStore } =
    UserInputPlanningStore();

  useEffect(() => {
    const countObj: any = {};
    if (userInputData?.customers?.length > 0) {
      userInputData?.customers[0]?.input_data.forEach((obj) => {
        countObj[obj.product_type] = (countObj[obj.product_type] || 0) + 1;
      });

      const resultArray = Object.keys(countObj).map((key) => ({
        title: key,
        count: countObj[key],
      }));
      setHeaderData(resultArray);

      let tableDatatemp: any[][] = [];
      const AcknowledgeTableData = userInputData.customers.map((data, key) => {
        let tableCellDatatemp: string[] = [];
        userInputData.customers[key].input_data.map((plan) => {
          tableCellDatatemp.push(plan.planned_count ? plan.planned_count : "0");
        });
        tableDatatemp.push([
          key + 1,
          `${data.firm_name} (${data.customer_type.name})`,
          data.id,
          ...tableCellDatatemp,
        ]);
      });
      setTableData(tableDatatemp);

      let temp: any[] = [];
      userInputData?.customers[0]?.input_data.map((data) => {
        const tempData = {
          title: data.input_name,
          count: 1,
        };
        temp = [...temp, tempData];
      });
      setInputData(temp);

      let quantityTemp: any[] = [];
      userInputData?.inputs?.map((data, key) => {
        const matchedItem = inputData.find(
          (item) => item.title === data.input_name
        );

        quantityTemp.push();
        if (matchedItem) {
          quantityTemp.push(
            `${data.planned_count ? data.planned_count : "0"}/${
              data.dispatched
            }`
          );
        }
      });
      setQuantityTotal([
        "-",
        "Planned Quantity/Total Dispatched",
        ...quantityTemp,
      ]);
    }
  }, [inputData.length]);

  const handleChangeCell = async (
    id: number,
    newValue: string,
    index: number,
    customer_id: string
  ) => {
    const matchedItem = userInputData?.inputs.find(
      (item) => item.input_name === inputData[index - 3].title
    );

    let payload = {
      customer_id: customer_id,
      input_id: matchedItem?.input_id,
      quantity: newValue,
      month_year: filterStore.month_year,
      user_id: filterStore.user_id,
    };

    let result = await updateUserInputPlanning(payload);

    if (result) {
      const updatedArrayOfArrays: (string | number)[][] = [];

      let tempQuantity = quantityTotal[index - 1].split("/");
      let quant = quantityTotal;

      tableData.map((subArray) => {
        if (quant[index - 1])
          if (subArray[0] === id) {
            if (parseInt(subArray[index].toString()) < parseInt(newValue)) {
              quant[index - 1] = `${
                parseInt(tempQuantity[0]) +
                (parseInt(newValue) - parseInt(subArray[index].toString()))
              }/${tempQuantity[1]}`;
            }
            if (parseInt(subArray[index].toString()) > parseInt(newValue)) {
              quant[index - 1] = `${
                parseInt(tempQuantity[0]) -
                (parseInt(subArray[index].toString()) - parseInt(newValue))
              }/${tempQuantity[1]}`;
            }
            subArray[index] = newValue;
            updatedArrayOfArrays.push(subArray);
          } else {
            updatedArrayOfArrays.push(subArray);
          }
      });
      setQuantityTotal(quant);
      setTableData(updatedArrayOfArrays);
    }
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Box
          sx={{
            overflow: "scroll",
            height: "70vh",
            backgroundColor: "white",
          }}
        >
          <Table size="small" stickyHeader>
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                opacity: 2,
              }}
            >
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    bgcolor: "#cccccc",
                    borderLeft: "1px solid white",
                    width: "10%",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="#424242"
                    align="center"
                  >
                    Sr. No.
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    bgcolor: "#cccccc",
                    borderLeft: "1px solid white",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="#424242"
                    align="center"
                  >
                    Customer Name
                  </Typography>
                </TableCell>
                {headerData.map((data) => {
                  return (
                    <TableCell
                      sx={{
                        bgcolor: "#cccccc",
                        borderLeft: "1px solid white",
                      }}
                      colSpan={data.count}
                      align="center"
                    >
                      <Typography
                        variant="subtitle2"
                        color="#424242"
                        align="center"
                      >
                        {data.title}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                {inputData.map((data) => {
                  const columns: any = [];
                  for (let i = 0; i < data.count; i++) {
                    columns.push(
                      <TableCell
                        sx={{
                          bgcolor: "#cccccc",
                          borderLeft: "1px solid white",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="#424242  "
                          align="center"
                        >
                          {data.title}
                        </Typography>
                      </TableCell>
                    );
                  }
                  return columns;
                })}
              </TableRow>
              <TableRow>
                {quantityTotal.map((data) => {
                  return (
                    <TableCell sx={{ borderLeft: "1px solid #B2BEB5" }}>
                      <Typography variant="subtitle2" align="center">
                        {data}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody sx={{ marginTop: 2 }}>
              {tableData.length > 0 &&
                tableData.map((data, key) => {
                  return (
                    <UserInputPlanningTableData
                      data={data}
                      allowEdit={allowEdit}
                      value={customerData[key]}
                      id={data[0]}
                      handleChangeCell={handleChangeCell}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </>
  );
};

export default UserInputPlanningTable;
