import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { CoverageReportsStore } from "../../../../store/main/CoverageReportsStore";
import MonthlyHeadquarterSummaryTableMobile from "./sub-components/MonthlyHeadquarterSummaryTableMobile";
import VisitSummaryTableMobile from "./sub-components/VisitSummaryTableMobile";
import SpecialitySummaryTableMobile from "./sub-components/SpecialtySummaryTableMobile";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 3, mt: 2 }} bgcolor={"#f9f9f9"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function CoverageReportsTabsMobile() {
  const {
    fetchMonthlySpecialitySummary,
    fetchMonthlyHeadquarterSummary,
    fetchVisitSummary,
    specialitySummaryFilters,
    monthlyHeadquarterFilters,
    visitSummaryFilters,
  } = CoverageReportsStore();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchTableData = (name: string) => {
    if (name === "HQWiseSummary") {
      monthlyHeadquarterFilters.monthYear = dayjs(new Date());
      fetchMonthlyHeadquarterSummary();
    } else if (name === "SpecialtySummary") {
      specialitySummaryFilters.monthYear = dayjs(new Date());
      fetchMonthlySpecialitySummary();
    } else if (name == "visitSummary") {
      visitSummaryFilters.monthYear = dayjs(new Date());
      visitSummaryFilters.page = 1;
      fetchVisitSummary();
    }
  };
  React.useEffect(() => {
    fetchMonthlyHeadquarterSummary();
  }, []);

  return (
    <Box sx={{ height: "120vh", overflow: "scroll" }}>
      <Box paddingX={2}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={
              <Typography fontWeight={550} variant="caption">
                HQ Wise Summary
              </Typography>
            }
            sx={{ paddingX: 0.8 }}
            value={0}
            onClick={() => {
              fetchTableData("HQWiseSummary");
            }}
          />
          <Tab
            label={
              <Typography fontWeight={550} variant="caption">
                Visit Summary
              </Typography>
            }
            sx={{ paddingX: 0.8 }}
            value={1}
            onClick={() => {
              fetchTableData("visitSummary");
            }}
          />
          <Tab
            label={
              <Typography fontWeight={550} variant="caption">
                Specialty Summary
              </Typography>
            }
            sx={{ paddingX: 0.8 }}
            value={2}
            onClick={() => {
              fetchTableData("SpecialtySummary");
            }}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <MonthlyHeadquarterSummaryTableMobile />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <VisitSummaryTableMobile />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SpecialitySummaryTableMobile />
      </TabPanel>
    </Box>
    // </Box>
  );
}
