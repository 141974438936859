import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { FetchState } from "../../../domain/models/fetch-state";
import { RemoteFetchInputUsageSummary } from "../../../data/usecases/input-utilization/remote-fetch-input-usage-summary";
import { FetchInputUsageSummary } from "../../../domain/useages/input-utilization/fetch-input-usage-summary";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { InputUsageSummary } from "../../../domain/models/input-utilization/input-usage-summary";

export interface FetchInputUsageSummaryInterface {
  fetchInputUsageSummary: Function;
  inputUsageSummaryLoading: FetchState;
  inputUsageSummaryFilters: {
    role_id: string;
    input_ids: string[];
  };
  inputUsageSummaryData: InputUsageSummary[];
}

const initialStates = {
  inputUsageSummaryLoading: FetchState.DEFAULT,
  inputUsageSummaryFilters: {
    role_id: "",
    input_ids: [],
  },
  inputUsageSummaryData: [],
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchInputUsageSummary: StateCreator<
  FetchInputUsageSummaryInterface
> = (set, get) => ({
  ...initialStates,
  fetchInputUsageSummary: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    set(() => ({ inputUsageSummaryLoading: FetchState.LOADING }));
    const remoteFetchInputUsageSummary: FetchInputUsageSummary =
      new RemoteFetchInputUsageSummary(
        `${BASE_URL}${Endpoints.FETCH_INPUT_USAGE_SUMMARY}`,

        axiosHttpClient
      );
    let params = {
      role_id: get().inputUsageSummaryFilters.role_id,
      input_ids: get().inputUsageSummaryFilters.input_ids,
    };
    let result = await remoteFetchInputUsageSummary.fetch(params);

    if (result) {
      set(() => ({ inputUsageSummaryData: result.summary }));
      set(() => ({ inputUsageSummaryLoading: FetchState.SUCCESS }));
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: "Error Occured While Fetching Input Usage Summary Data",
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },
});
