import { StateCreator } from "zustand";
import { RemoteDeactivateCustomers } from "../../data/usecases/remove-deactivate-customers";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import Endpoints from "../../domain/endpoints";
import { DeactivateCustomersType } from "../../domain/models/customer-database/deactivate-customers-type";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import Swal from "sweetalert2";
import { CustomerDatabaseStore } from "../main/CustomerDatabaseStore";

export interface DeactivateCustomersInterface {
  deactivateCustomer: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useDeactivateCustomer: StateCreator<
  DeactivateCustomersInterface
> = (set, get) => ({
  deactivateCustomer: async (
    customer_info: DeactivateCustomersType,
    handleClose: Function,
    title: string,
    fetchCustomerData: Function,
    handleClearSelectedCustomers: Function,
    fetchCustomerCount: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteDeactivateCustomers = new RemoteDeactivateCustomers(
      `${BASE_URL}${Endpoints.DEACTIVATE_CUSTOMERS}`,
      axiosHttpClient
    );

    let result = await remoteDeactivateCustomers.remove({ customer_info });
    if (result.success) {
      handleClose(title);
      handleClearSelectedCustomers();
      fetchCustomerCount(CustomerDatabaseStore.getState().filterStore);
      fetchCustomerData();
      Swal.fire({
        icon: "success",
        timer: 3000,
        text: result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        title: "Deactivate customer request failed",
        text: result.errors.message,
      });
    }
  },
});
