import { Box, Stack, Typography } from "@mui/material";
import ReportFiltersComponent from "../ReportFiltersComponent";
import moment from "moment";
import CustomerCountLoading from "./sub-components/loading/CustomerCountLoading";
import ReportScreen from "../../../../common/enums/ReportScreens";
import { FetchState } from "../../../../domain/models/fetch-state";
import { ReportFilters } from "../../../../domain/models/report-filters";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { UserSummaryReportStore } from "../../../../store/main/UserSummaryReportStore";
import { CustomerCountDataType } from "../../../../domain/models/user-summary-report/common-modals";

const CustomerCount = () => {
  const { userDetails, userRole } = CustomerDatabaseStore();
  const {
    fetchSummaryReport,
    fetchMonthSummaryReport,
    fetchDeviationReport,
    userSummaryFilter,
    deviationReportData,
    monthSummaryReportData,
    fetchMonthsReport,
    monthSummaryState,
    fetchDeviationReportState,
    monthsReportData,
  } = UserSummaryReportStore();

  const filterData = (filters: ReportFilters) => {
    const month = moment(filters.date?.toString()).format("MM");
    const year = moment(filters.date?.toString()).format("yy");
    fetchSummaryReport(
      month,
      year,
      userSummaryFilter.user_id,
      filters.department ? filters.department : 0
    );
    fetchMonthSummaryReport(
      month,
      year,
      userSummaryFilter.user_id,
      filters.department ? filters.department : 0
    );
    fetchDeviationReport(
      month,
      year,
      userSummaryFilter.user_id,
      filters.department ? filters.department : 0
    );
    fetchMonthsReport(
      year,
      userSummaryFilter.user_id,
      filters.department ? filters.department : 0
    );
  };

  const clearData = (filters: ReportFilters) => {
    const currentDate = new Date();
    fetchDeviationReport(
      currentDate.getMonth(),
      currentDate.getFullYear(),
      userSummaryFilter.user_id,
      filters.department
    );
    fetchSummaryReport(
      currentDate.getMonth(),
      currentDate.getFullYear(),
      userSummaryFilter.user_id,
      filters.department
    );
    fetchMonthSummaryReport(
      currentDate.getMonth(),
      currentDate.getFullYear(),
      userSummaryFilter.user_id,
      filters.department
    );
    fetchMonthsReport(
      currentDate.getFullYear(),
      userSummaryFilter.user_id,
      filters.department
    );
  };

  let data: CustomerCountDataType[] = [];
  const totalFieldWoking = monthSummaryReportData?.attendances?.find(
    (item: any) => item.work_type_id === 2
  )?.att_count;
  const calculateOrder: () => CustomerCountDataType[] = () => {
    data = [
      {
        name: "Stockist",
        callCount: 0,
        coverageCount: 0,
      },
      {
        name: "Semi-Stockist",
        callCount: 0,
        coverageCount: 0,
      },
      {
        name: "Retailer",
        callCount: 0,
        coverageCount: 0,
      },
      {
        name: "Control Chemist",
        callCount: 0,
        coverageCount: 0,
      },
      {
        name: "Healthcare Provider",
        callCount: 0,
        coverageCount: 0,
      },
    ];
    if (deviationReportData?.visits) {
      for (const visits of deviationReportData.visits) {
        const index = visits.customer_type_id - 1;
        if (index >= 0 && index < data.length) {
          data[index].callCount += visits.visit_count;
        }
      }
    }
    if (monthSummaryReportData?.all_visits) {
      for (const visits of monthSummaryReportData.all_visits) {
        const index = visits.customer_type_id - 1;
        if (index >= 0 && index < data.length) {
          data[index].coverageCount += visits.customer_count;
        }
      }
    }
    return data;
  };
  const countSummary = calculateOrder();

  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h5" paddingBottom="10px" fontWeight={500}>
          User Summary Report
        </Typography>

        <Stack direction={"row"}>
          <ReportFiltersComponent
            type={ReportScreen.USER_SUMMARY_REPORT}
            successCallback={filterData}
            clearSuccessCallBack={clearData}
            gridColumns={10}
            userRole={userRole.role}
            userDetails={userDetails}
            justifyContent="end"
          />
        </Stack>
      </Stack>

      {monthSummaryState == FetchState.SUCCESS &&
        fetchDeviationReportState == FetchState.SUCCESS && (
          <Stack
            padding={2}
            bgcolor={"#FBFBFD"}
            borderRadius={"10px"}
            direction="row"
            justifyContent="space-between"
            marginY={"20px"}
          >
            {countSummary.map((data: CustomerCountDataType, index: number) => {
              return (
                <>
                  <Stack marginX={"auto"}>
                    <Typography variant="body2">{data.name}</Typography>
                    <Typography
                      color="primary.main"
                      fontWeight={600}
                      variant="h6"
                    >
                      {data.callCount}
                    </Typography>
                    <Typography variant="body2" fontWeight={550}>
                      Call Average{" "}
                      {typeof totalFieldWoking !== "undefined" &&
                      totalFieldWoking !== 0
                        ? (data.callCount / totalFieldWoking).toFixed(1)
                        : "0"}
                    </Typography>
                    <Typography variant="body2" fontWeight={550}>
                      Coverage{" "}
                      {monthsReportData.customer_total && data.coverageCount
                        ? (
                            (data.coverageCount /
                              monthsReportData.customer_total) *
                            100
                          ).toFixed(2)
                        : "0"}
                    </Typography>
                  </Stack>
                  <Box
                    borderRight={
                      index < countSummary.length - 1 ? "2px solid #e1e3e2" : ""
                    }
                  />
                </>
              );
            })}
          </Stack>
        )}

      {(monthSummaryState == FetchState.LOADING ||
        fetchDeviationReportState == FetchState.LOADING) && (
        <CustomerCountLoading />
      )}

      {monthSummaryState == FetchState.ERROR &&
        fetchDeviationReportState == FetchState.ERROR && (
          <Stack
            padding={2}
            bgcolor={"#fafafa"}
            borderRadius={"10px"}
            direction="row"
            justifyContent="space-between"
            marginY={"20px"}
          >
            {countSummary.map((data: CustomerCountDataType, index: number) => {
              return (
                <>
                  <Stack marginX={"auto"}>
                    <Typography variant="body2">{data.name}</Typography>
                    <Typography
                      color="primary.main"
                      fontWeight={600}
                      variant="h6"
                    >
                      -
                    </Typography>
                    <Typography variant="body2" fontWeight={550}>
                      Call Average -
                    </Typography>
                    <Typography variant="body2" fontWeight={550}>
                      Coverage -
                    </Typography>
                  </Stack>
                  <Box
                    borderRight={
                      index < countSummary.length - 1 ? "2px solid #e1e3e2" : ""
                    }
                  />
                </>
              );
            })}
          </Stack>
        )}
    </>
  );
};

export default CustomerCount;
