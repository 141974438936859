import { Skeleton, Stack } from "@mui/material";

type Props = {};

const MonthSummaryLoading = (props: Props) => {
  return (
    <Stack spacing={2} justifyContent="center" direction={"row"}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={200}
        width="60vw"
        sx={{ borderRadius: "0.7rem" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={200}
        width="40vw"
        sx={{ borderRadius: "0.7rem" }}
      />
    </Stack>
  );
};

export default MonthSummaryLoading;
