import { create } from "zustand";
import { useUserManagementDataSlice } from "../slices/UserManagementSlices/userManagementDataSlice";
import { useUpdateUserManagementDataSlice } from "../slices/UserManagementSlices/updateUserManagementDataSlice";
import { useDeactivateUserSlice } from "../slices/UserManagementSlices/deactivateUserSlice";
import { useDeleteUserManagementReportSlice } from "../slices/UserManagementSlices/deleteUserManagementReportSlice";
import { useResetUserPasswordSlice } from "../slices/UserManagementSlices/resetUserPasswordSlice";
import { useCreateNewUserSlice } from "../slices/UserManagementSlices/createNewUserSlice";
import { useFetchServiceProviderSlice } from "../slices/fetchServiceProvidersSlice";
import { useFetchUserDepartmentSlice } from "../slices/fetchUserDepartmentSlice";
import { useFetchManagerBasedOnUserRoleSlice } from "../slices/UserManagementSlices/fetchManagersBasedonUserRoleSlice";
import { useUnlockUserSlice } from "../slices/UserManagementSlices/unlockUserSlice";

interface UserManagementInterface
  extends ReturnType<typeof useUserManagementDataSlice>,
    ReturnType<typeof useUpdateUserManagementDataSlice>,
    ReturnType<typeof useDeactivateUserSlice>,
    ReturnType<typeof useResetUserPasswordSlice>,
    ReturnType<typeof useCreateNewUserSlice>,
    ReturnType<typeof useFetchServiceProviderSlice>,
    ReturnType<typeof useFetchUserDepartmentSlice>,
    ReturnType<typeof useFetchManagerBasedOnUserRoleSlice>,
    ReturnType<typeof useUnlockUserSlice>,
    ReturnType<typeof useDeleteUserManagementReportSlice> {}

export const UserManagementStore = create<UserManagementInterface>((...a) => ({
  ...useUserManagementDataSlice(...a),
  ...useUpdateUserManagementDataSlice(...a),
  ...useDeactivateUserSlice(...a),
  ...useDeleteUserManagementReportSlice(...a),
  ...useResetUserPasswordSlice(...a),
  ...useCreateNewUserSlice(...a),
  ...useFetchServiceProviderSlice(...a),
  ...useFetchUserDepartmentSlice(...a),
  ...useFetchManagerBasedOnUserRoleSlice(...a),
  ...useUnlockUserSlice(...a),
}));
