import { Chip, Stack, Tooltip } from "@mui/material";
import { Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import { Pagination, TabContext, TabList, TabPanel } from "@mui/lab";
import CustomerTable from "./sub-component/CustomerTable";
import CustomerTableLoading from "../CustomerTableLoading";
import HQSummaryData from "./sub-component/hq-summary/HQSummaryData";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import Panels from "../../../../common/enums/Panels";
import Status from "../../../../common/enums/Status";
import { FetchState } from "../../../../domain/models/fetch-state";
import ReportScreen from "./../../../../common/enums/ReportScreens";

const ChipStyle = {
  color: "secondary.dark",
  backgroundColor: "error.dark",
  borderRadius: "10px",
  minWidth: "40px",
};

const CustomersInfoTabs = () => {
  const [value, setValue] = useState("2");
  const [panelName, setPanelName] = useState("All");
  const {
    handleSelectedPanel,
    fetchCustomerData,
    fetchCustomerTableState,
    filterStore,
    pageData,
    fetchCustomerSummaryCount,
    fetchHqSummaryData,
    selectedPanel,
    handlePageNumber,
    currentPageNumber,
    handleClearSelectedCustomers,
  } = CustomerDatabaseStore();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const FetchCustomerData = (
    name: string,
    pageno: number,
    isNotPage: Boolean,
    tabClicked: boolean
  ) => {
    handleSelectedPanel(false);
    setPanelName(name);

    if (tabClicked) {
      filterStore.customer_type_id = "";
    }
    if (name === Panels.PENDING_MAPPING) {
      filterStore.mapping_status = Status.PENDING;
      filterStore.approved_status = "";
      filterStore.status = Status.ACTIVE;
      filterStore.download = 0;
      filterStore.page = pageno;
      handleClearSelectedCustomers();
      fetchCustomerData();
      handleSelectedPanel(false, Panels.PENDING_MAPPING);
      isNotPage && fetchCustomerSummaryCount(filterStore);
    } else if (name === Panels.PENDING_APPROVAL) {
      filterStore.mapping_status = "";
      filterStore.download = 0;
      filterStore.status = Status.PENDING;
      filterStore.page = pageno;
      handleClearSelectedCustomers();
      fetchCustomerData();
      handleSelectedPanel(false, Panels.PENDING_APPROVAL);
      isNotPage && fetchCustomerSummaryCount(filterStore);
    } else if (name === Panels.ALL) {
      filterStore.mapping_status = "";
      filterStore.approved_status = "";
      filterStore.download = 0;
      filterStore.status = Status.ACTIVE;
      filterStore.page = pageno;
      handleClearSelectedCustomers();
      fetchCustomerData();
      handleSelectedPanel(false, Panels.ALL);
      isNotPage && fetchCustomerSummaryCount(filterStore);
    } else if (name === Panels.ARCHIVED) {
      filterStore.status = Status.IN_ACTIVE;
      filterStore.download = 0;
      filterStore.mapping_status = "";
      filterStore.approved_status = "";
      filterStore.page = pageno;
      handleClearSelectedCustomers();
      handleSelectedPanel(false, Panels.ARCHIVED);
      fetchCustomerData();
      isNotPage && fetchCustomerSummaryCount(filterStore);
    } else if (name === Panels.HQSUMMARY) {
      filterStore.zone_ids = [];
      filterStore.region_ids = [];
      filterStore.area_ids = [];
      filterStore.download = 0;
      filterStore.headquarter_ids = [];
      filterStore.territory_ids = [];
      filterStore.brick_ids = [];
      filterStore.q = "";
      handleClearSelectedCustomers();
      fetchHqSummaryData();
      handleSelectedPanel(true);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    handlePageNumber(value);
    FetchCustomerData(panelName, value, false, false);
  };

  return (
    <Stack>
      <TabContext value={value}>
        <Stack direction="row" justifyContent="space-between">
          <TabList onChange={handleChange}>
            <Tab
              label={
                <Typography variant="subtitle2" color="secondary">
                  HQ SUMMARY
                </Typography>
              }
              value="1"
              onClick={() => {
                FetchCustomerData(Panels.HQSUMMARY, 1, true, true);
              }}
            />
            <Tab
              label={
                <Tooltip title={"Must Visit List"}>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Typography variant="subtitle2" color="secondary">
                      ALL (MVL)
                    </Typography>
                    {value === "2" && (
                      <Chip
                        label={pageData?.total ? pageData?.total : "0"}
                        sx={ChipStyle}
                      />
                    )}
                  </Stack>
                </Tooltip>
              }
              onClick={() => {
                FetchCustomerData(Panels.ALL, 1, true, true);
              }}
              value="2"
            />

            <Tab
              label={
                <Tooltip title={"Pending Mapping"}>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Typography variant="subtitle2" color="secondary">
                      PENDING MAPPING
                    </Typography>
                    {value === "3" && (
                      <Chip
                        label={pageData?.total ? pageData?.total : "0"}
                        sx={ChipStyle}
                      />
                    )}
                  </Stack>
                </Tooltip>
              }
              onClick={() => {
                FetchCustomerData(Panels.PENDING_MAPPING, 1, true, true);
              }}
              value="3"
            />

            <Tab
              label={
                <Tooltip title={"Pending Approval"}>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Typography variant="subtitle2" color="secondary">
                      PENDING APPROVAL
                    </Typography>
                    {value === "4" && (
                      <Chip
                        label={pageData?.total ? pageData?.total : "0"}
                        sx={ChipStyle}
                      />
                    )}
                  </Stack>
                </Tooltip>
              }
              onClick={() => {
                FetchCustomerData(Panels.PENDING_APPROVAL, 1, true, true);
              }}
              value="4"
            />

            <Tab
              label={
                <Tooltip title={"Deleted Customers"}>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Typography variant="subtitle2" color="secondary">
                      ARCHIVED
                    </Typography>
                    {value === "5" && (
                      <Chip
                        label={pageData?.total ? pageData?.total : "0"}
                        sx={ChipStyle}
                      />
                    )}
                  </Stack>
                </Tooltip>
              }
              onClick={() => {
                FetchCustomerData(Panels.ARCHIVED, 1, true, true);
              }}
              value="5"
            />
          </TabList>
        </Stack>

        <TabPanel value="1">
          <HQSummaryData />
        </TabPanel>
        <TabPanel value="2">
          {fetchCustomerTableState === FetchState.LOADING && (
            <CustomerTableLoading />
          )}
          {fetchCustomerTableState === FetchState.SUCCESS && (
            <CustomerTable
              type={ReportScreen.CUSTOMER_DATABASE}
              panelName={panelName}
            />
          )}
        </TabPanel>
        <TabPanel value="3">
          {fetchCustomerTableState === FetchState.LOADING && (
            <CustomerTableLoading />
          )}
          {fetchCustomerTableState === FetchState.SUCCESS && (
            <CustomerTable
              type={ReportScreen.CUSTOMER_DATABASE}
              panelName={panelName}
            />
          )}
        </TabPanel>
        <TabPanel value="4">
          {fetchCustomerTableState === FetchState.LOADING && (
            <CustomerTableLoading />
          )}
          {fetchCustomerTableState === FetchState.SUCCESS && (
            <CustomerTable
              type={ReportScreen.CUSTOMER_DATABASE}
              panelName={panelName}
            />
          )}
        </TabPanel>
        <TabPanel value="5">
          {fetchCustomerTableState === FetchState.LOADING && (
            <CustomerTableLoading />
          )}
          {fetchCustomerTableState === FetchState.SUCCESS && (
            <CustomerTable
              type={ReportScreen.CUSTOMER_DATABASE}
              panelName={panelName}
            />
          )}
        </TabPanel>
      </TabContext>
      <Stack alignItems="center">
        {selectedPanel === false && (
          <Pagination
            count={Math.ceil(pageData.total / pageData.per_page)}
            variant="outlined"
            page={currentPageNumber}
            onChange={handlePageChange}
          />
        )}
      </Stack>
    </Stack>
  );
};
export default CustomersInfoTabs;
