import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useEffect, useState } from "react";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { TerritoryDataStore } from "../../../../store/main/TerritoryDataStore";
import Actions from "../../../../common/enums/Actions";
import Panels from "../../../../common/enums/Panels";
import {
  DisableApproveButton,
  DisableDeactivateButton,
  DisableReactivateButton,
  DisableTransferButton,
} from "../../../../common/utils/ReportsFilters";
import { Constants } from "../../../../common/constants";
import CustomersInfoMobileTabs from "./CustomerInfoMobileTabs";
import BulkReactivateAndTransferModalMobile from "./sub-components/Modals/BulkReactivateAndTransferModalMobile";
import CustomerActionsModalMobile from "./sub-components/Modals/CustomerActionsModalMobile";
import BulkApprovalAndRejectCustomerModalMobile from "./sub-components/Modals/BulkApproveAndRejectCustomerModalMobile";
import AddActivityChemistModalMobile from "./sub-components/Modals/AddActvityChemistModalMobile";
import RemoveActivityChemistModalMobile from "./sub-components/Modals/RemoveActivityChemistModalMobile";
import Roles from "../../../../common/enums/Roles";

type FormFields = {
  zone: string;
  region: string;
  area: string;
  hq: string;
  brick: string;
  department: string;
  reason: string;
  reasonSelect: string;
};
type props = {
  isRestricted: Boolean;
  buttonAccess: Boolean;
};

const CustomerDetailsMobile: React.FC<props> = ({
  isRestricted,
  buttonAccess,
}) => {
  const {
    downloadExcel,
    customerIds,
    selectedPanel,
    filterStore,
    userRole,
    panelName,
    reactivateUser,
    fetchCustomerData,
    updateCustomerMapping,
    fetchCustomerSummaryCount,
    showActivityChemistButton,
    showAddActivityChemistButton,
    filterSegement,
    addMetaData,
    removeMetaData,
    departmentCode,
    headquarterCode,
    handleClearSelectedCustomers,
    userDetails,
  } = CustomerDatabaseStore();

  const { fetchDepartment, departmentData } = TerritoryDataStore();

  const [openRemoveMapping, setOpenRemoveMapping] = useState<boolean>(false);
  const [openDeactivateUser, setOpenDeactivateUser] = useState<boolean>(false);
  const [openApproveCustomerModal, setOpenApproveCustomerModal] =
    useState<boolean>(false);
  const [openReactivateCustomerModal, setOpenReactivateCustomerModal] =
    useState<boolean>(false);
  const [openTransferCustomerModal, setOpenTransferCustomerModal] =
    useState<boolean>(false);
  const [openAddActivityChemist, setOpenAddActivityChemist] =
    useState<boolean>(false);
  const [openRemoveActivityChemist, setOpenRemoveActivityChemist] =
    useState<boolean>(false);

  const downloadFile = () => {
    filterStore.download = 1;
    downloadExcel(filterStore);
  };

  const handleOpenReactivateCustomerModal = () => {
    setOpenReactivateCustomerModal(true);
  };

  const handleCloseReactivateCustomerModal = () => {
    setOpenReactivateCustomerModal(false);
  };

  const handleOpenApproveCustomerModal = () => {
    setOpenApproveCustomerModal(true);
  };

  const handleCloseApproveCustomerModal = () => {
    setOpenApproveCustomerModal(false);
  };

  const handleOpenTransferCustomerModal = () => {
    setOpenTransferCustomerModal(true);
  };

  const handleCloseTransferCustomerModal = () => {
    setOpenTransferCustomerModal(false);
  };
  const handleOpenAddActivityChemistModal = () => {
    setOpenAddActivityChemist(true);
  };

  const handleCloseAddActivityChemistModal = () => {
    setOpenAddActivityChemist(false);
  };

  const handleOpenRemoveActivityChemistModal = () => {
    setOpenRemoveActivityChemist(true);
  };

  const handleCloseRemoveActivityChemistModal = () => {
    setOpenRemoveActivityChemist(false);
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleModal = (modalName: string) => {
    if (modalName === Actions.BULK_REMOVE_MAPPING) {
      setOpenRemoveMapping(!openRemoveMapping);
    } else if (modalName === Actions.BULK_DEACTIVATE_CUSTOMERS) {
      setOpenDeactivateUser(!openDeactivateUser);
    } else if (modalName === Actions.BULK_APPROVE_REJECT_CUSTOMER) {
      setOpenApproveCustomerModal(!setOpenApproveCustomerModal);
    }
  };

  const handleTransferCustomers = (payload: FormFields) => {
    const transferPayload = {
      old_headquarter_id: filterStore.headquarter_ids,
      customer_ids: customerIds,
      reason:
        payload.reasonSelect == "Other" ? payload.reason : payload.reasonSelect,
      brick_id: payload.brick,
      headquarter_id: payload.hq,
    };
    updateCustomerMapping(
      transferPayload,
      fetchCustomerData,
      handleCloseTransferCustomerModal,
      fetchCustomerSummaryCount
    );
  };

  const handleReactivateCustomers = (payload: FormFields) => {
    const reactivatePayload = {
      old_headquarter_id: filterStore.headquarter_ids
        ? filterStore?.headquarter_ids[0]
        : "",
      customer_ids: customerIds,
      reason:
        payload.reasonSelect == "Other" ? payload.reason : payload.reasonSelect,
      brick_id: payload.brick,
      headquarter_id: payload.hq,
      depaartment_id: payload.department,
    };
    reactivateUser(
      reactivatePayload,
      fetchCustomerData,
      handleCloseReactivateCustomerModal,
      fetchCustomerSummaryCount
    );
  };
  const handleAddActivityChemist = () => {
    const department_Code = departmentData.find(
      (type) => type.id === parseInt(departmentCode)
    );
    const addActivityChemistPayload = {
      customer_ids: customerIds,
      headquarter_code: headquarterCode,
      department_code: department_Code?.name,
      metadata_code: filterSegement[0],
    };
    addMetaData(addActivityChemistPayload, handleClearSelectedCustomers);
  };
  const handleRemoveActivityChemist = (reason: string) => {
    const department_Code = departmentData.find(
      (type) => type.id === parseInt(departmentCode)
    );
    const removeActivityChemistPayload = {
      customer_ids: customerIds,
      headquarter_code: headquarterCode,
      department_code: department_Code?.name,
      metadata_code: filterSegement[0],
      reason: reason,
    };
    removeMetaData(removeActivityChemistPayload, handleClearSelectedCustomers);
  };
  const GetSelectedMenuItem = () => {
    const option = [
      {
        condition:
          filterSegement.length === 0 &&
          buttonAccess &&
          panelName !== Panels.PENDING_APPROVAL &&
          panelName !== Panels.ARCHIVED &&
          panelName !== Panels.PENDING_MAPPING &&
          !DisableDeactivateButton(
            userRole.role,
            customerIds.length,
            selectedPanel,
            panelName,
            filterStore.customer_type_id
          ),
        component: (
          <MenuItem
            value={1}
            onClick={() => handleModal(Actions.BULK_DEACTIVATE_CUSTOMERS)}
            title="Click to Deactivate Customer"
          >
            Deactivate Customers
          </MenuItem>
        ),
      },
      {
        condition:
          filterSegement.length === 0 &&
          !isRestricted &&
          panelName === Panels.ARCHIVED &&
          !DisableReactivateButton(
            userRole.role,
            customerIds.length,
            selectedPanel
          ),
        component: (
          <MenuItem
            value={2}
            onClick={() => handleOpenReactivateCustomerModal()}
            title="Click to Reactivate Customer"
          >
            Reactivate Customers
          </MenuItem>
        ),
      },
      {
        condition:
          filterSegement.length === 0 &&
          !isRestricted &&
          panelName !== Panels.PENDING_APPROVAL &&
          panelName !== Panels.ARCHIVED &&
          !DisableTransferButton(
            userRole.role,
            customerIds.length,
            selectedPanel,
            filterStore.headquarter_ids
          ),
        component: (
          <MenuItem
            value={3}
            onClick={() => handleOpenTransferCustomerModal()}
            title="Click to Transfer Customer"
          >
            Transfer Customers
          </MenuItem>
        ),
      },
      {
        condition:
          filterSegement.length === 0 &&
          panelName === Panels.PENDING_APPROVAL &&
          !DisableApproveButton(
            userRole.role,
            customerIds.length,
            selectedPanel
          ),
        component: (
          <MenuItem
            value={4}
            onClick={() => handleOpenApproveCustomerModal()}
            title="Click to Approve And Reject Customer"
          >
            Approve And Reject Customers
          </MenuItem>
        ),
      },
      {
        condition: panelName === Panels.ALL && showActivityChemistButton,
        component: showAddActivityChemistButton ? (
          <MenuItem
            value={5}
            onClick={() => handleOpenAddActivityChemistModal()}
            title="Click to Add Activity Customer"
          >
            Add Activity Chemist
          </MenuItem>
        ) : (
          <MenuItem
            value={6}
            onClick={() => handleOpenRemoveActivityChemistModal()}
            title="Click to Remove Activity Customer"
          >
            Remove Activity Chemist
          </MenuItem>
        ),
      },
    ];
    return option
      .filter((option) => option.condition)
      .map((option) => option.component).length === 0 ? (
      <MenuItem disabled value={0}>
        No Action Available
      </MenuItem>
    ) : (
      option
        .filter((option) => option.condition)
        .map((option) => option.component)
    );
  };
  return (
    <Stack>
      {!selectedPanel && (
        <Stack>
          <Typography
            variant="h6"
            color="primary.dark"
            paddingY={2}
            fontWeight={550}
          >
            List of all Customers
          </Typography>

          <Stack gap={2}>
            {userDetails &&
              userDetails.role &&
              userDetails.role.visibility == 1 && (
                <Button
                  variant="contained"
                  startIcon={<FileDownloadOutlinedIcon />}
                  sx={{ borderRadius: "30px" }}
                  disabled={customerIds.length !== 0 || selectedPanel}
                  onClick={downloadFile}
                  color="primary"
                >
                  <Typography variant="subtitle2" textTransform={"none"}>
                    Download Excel
                  </Typography>
                </Button>
              )}

            <FormControl
              sx={{ width: "300px" }}
              size="small"
              disabled={
                customerIds.length === 0 ||
                filterStore.department?.length === 0 ||
                (filterStore?.headquarter_ids
                  ? filterStore?.headquarter_ids?.length === 0
                  : false)
              }
            >
              <InputLabel>Action</InputLabel>
              <Select label="Action">{GetSelectedMenuItem()}</Select>
            </FormControl>
          </Stack>
        </Stack>
      )}
      <CustomersInfoMobileTabs />
      {openReactivateCustomerModal && (
        <BulkReactivateAndTransferModalMobile
          open={openReactivateCustomerModal}
          handleClose={handleCloseReactivateCustomerModal}
          successCallback={handleReactivateCustomers}
          title="Reactivate"
          reasonOptions={Constants.REACTIVATE_REASONS}
        />
      )}
      {openTransferCustomerModal && (
        <BulkReactivateAndTransferModalMobile
          open={openTransferCustomerModal}
          handleClose={handleCloseTransferCustomerModal}
          successCallback={handleTransferCustomers}
          title="Transfer"
          reasonOptions={Constants.TRANSFER_REASONS}
        />
      )}
      {openDeactivateUser && (
        <CustomerActionsModalMobile
          open={openDeactivateUser}
          handleClose={handleModal}
          title={Actions.BULK_DEACTIVATE_CUSTOMERS}
          button="Deactivate"
          reasonOptions={Constants.DEACTIVATE_REASONS}
        />
      )}
      {openApproveCustomerModal && (
        <BulkApprovalAndRejectCustomerModalMobile
          open={openApproveCustomerModal}
          handleClose={handleModal}
          title={Actions.BULK_APPROVE_REJECT_CUSTOMER}
          button="Reject"
          reasonOptions={Constants.BULK_APPROVE_REJECT_CUSTOMERS}
        />
      )}
      {openAddActivityChemist && (
        <AddActivityChemistModalMobile
          open={openAddActivityChemist}
          handleClose={handleCloseAddActivityChemistModal}
          successCallback={handleAddActivityChemist}
          reasonOptions={Constants.REACTIVATE_REASONS}
        />
      )}
      {openRemoveActivityChemist && (
        <RemoveActivityChemistModalMobile
          open={openRemoveActivityChemist}
          handleClose={handleCloseRemoveActivityChemistModal}
          successCallback={handleRemoveActivityChemist}
          reasonOptions={Constants.REACTIVATE_REASONS}
        />
      )}
    </Stack>
  );
};
export default CustomerDetailsMobile;
