import { StateCreator } from "zustand";
import { BrickWiseData } from "../../../domain/models/standard-tour-program/brick-wise-data";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  BASE_URL_KEY,
} from "../../../base";
import { FetchBrickWise } from "../../../domain/useages/standard-tour-program/fetch_brick_wise";
import { RemoteFetchBrickWise } from "../../../data/usecases/standard-tour-program/remote-fetch-brick-wise";
import Endpoints from "../../../domain/endpoints";
import { FetchState } from "../../../domain/models/fetch-state";
import Roles from "../../../common/enums/Roles";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

export interface FetchBrickWiseInterface {
  brickWiseData: BrickWiseData[];
  fetchBrickWiseData: Function;
  brickWiseDataLoading: FetchState;
  brickWiseFilters: {
    zone: string;
    region: string;
    monthYear: Dayjs | null;
  };
}

const initialStates = {
  brickWiseData: [],
  brickWiseDataLoading: FetchState.DEFAULT,
  brickWiseFilters: { zone: "", region: "", monthYear: dayjs(new Date()) },
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchBrickWiseDataSlice: StateCreator<
  FetchBrickWiseInterface
> = (set, get) => ({
  ...initialStates,
  fetchBrickWiseData: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    let filterParams = {
      month_year: moment(
        (get().brickWiseFilters.monthYear as Dayjs).toString()
      ).format("YYYYMM"),
      zone: get().brickWiseFilters.zone,
      region: get().brickWiseFilters.region,
    };

    const remoteFetchBrickWiseData: FetchBrickWise = new RemoteFetchBrickWise(
      `${BASE_URL}${Endpoints.FETCH_STP_DATA}`,
      axiosHttpClient
    );

    set(() => ({ brickWiseDataLoading: FetchState.LOADING }));

    let result = await remoteFetchBrickWiseData.fetch(filterParams);
    if (result) {
      set(() => ({
        brickWiseData: result.brick_summary,
        brickWiseDataLoading: FetchState.SUCCESS,
      }));
    }
  },
});
