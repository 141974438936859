import { CircularProgress, Stack, Typography } from "@mui/material";
import ReportFiltersComponent from "../ReportFiltersComponent";
import { useEffect } from "react";
import moment from "moment";
import dayjs from "dayjs";
import ReportScreen from "../../../../common/enums/ReportScreens";
import { FetchState } from "../../../../domain/models/fetch-state";
import { ReportFilters } from "../../../../domain/models/report-filters";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { PrimarySalesStore } from "../../../../store/main/PrimarySalesStore";
import PivotTable from "../../PivotTable";
import Roles from "../../../../common/enums/Roles";

export default function HQandProductWiseReport() {
  const {
    HQandProductWiseData,
    fetchHQandProductWise,
    HQandProductWiseFilters,
    HQandProductWiseStatus,
  } = PrimarySalesStore();

  useEffect(() => {
    // fetchHQandProductWise();
  }, []);

  const getHQandProductWise = (filters: ReportFilters) => {
    HQandProductWiseFilters.monthYear = filters.date;
    fetchHQandProductWise();
  };

  const clearHQandProductWise = (filters: ReportFilters) => {
    HQandProductWiseFilters.monthYear = dayjs(new Date());
    fetchHQandProductWise();
  };

  const { userDetails, userRole } = CustomerDatabaseStore();

  const formattedData: any = {
    data: [],
    tableSizes: {
      columns: [
        {
          idx: 1,
          width: 120,
        },
        {
          idx: 2,
          width: 120,
        },
        {
          idx: 3,
          width: 120,
        },
        {
          idx: 4,
          width: 120,
        },
        {
          idx: 5,
          width: 120,
        },
        {
          idx: 6,
          width: 120,
        },
        {
          idx: 7,
          width: 120,
        },
        {
          idx: 8,
          width: 120,
        },
        {
          idx: 9,
          width: 120,
        },
        {
          idx: 10,
          width: 120,
        },
        {
          idx: 11,
          width: 120,
        },
      ],
    },
    rows: [
      { uniqueName: "zone" },
      { uniqueName: "region" },
      { uniqueName: "area" },
      { uniqueName: "headquarter" },
    ],
    columns: [{ uniqueName: "month" }, { uniqueName: "measures" }],

    measures: [
      {
        uniqueName: "target",
        caption: "Target",
        formula: "sum('0')",
        format: "precision",
      },
      {
        uniqueName: "total_net_amt",
        caption: "Total net amt",
        formula: "sum('total_net_amt')",
        format: "precision",
      },
      {
        uniqueName: "total_active_net_amt",
        caption: "Total active net amt",
        formula: "sum('total_active_net_amt')",
        format: "precision",
      },
      {
        uniqueName: "total_in_active_net_amt",
        caption: "Total inactive net amt",
        formula: "sum('total_in_active_net_amt')",
        format: "precision",
      },
      {
        uniqueName: "total_customers",
        caption: "Total customers",
        formula: "sum('total_customers')",
        format: "precision",
      },
    ],
    expands: {
      expandAll: "true",
    },
    grandTotalCaption: "",
    options: {
      grid: {
        showHeaders: false,
        showHierarchyCaptions: true,
      },
      showCalculatedValuesButton: true,
      sorting: "on",
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
      defaultHierarchySortName: "asc",
    },
    formats: [
      {
        name: "precision",
        decimalPlaces: 2,
        thousandsSeparator: ",",
      },
    ],
  };

  useEffect(() => {
    if (HQandProductWiseData.length > 0) {
      HQandProductWiseData.map((val) => {
        let str = val.month_year.toString();
        let date = str.slice(0, 4) + "/" + str.slice(4);

        if (val.headquarter) {
          var rowData = {
            month: moment(date).format("MMM YY"),
            zone: val.zone,
            region: val.region,
            area: val.area,
            headquarter: val.headquarter,
            total_active_net_amt: val.total_active_net_amt
              ? val.total_active_net_amt
              : 0,
            total_customers: val.total_customers ? val.total_customers : 0,
            total_in_active_net_amt: val.total_in_active_net_amt
              ? val.total_in_active_net_amt
              : 0,
            total_net_amt: val.total_net_amt,
          };
          formattedData.data.push(rowData);
        }
      });
    }
  }, [HQandProductWiseData]);

  const customizeToolbar = (toolbar: any) => {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      userDetails &&
        userDetails.role &&
        userDetails.role.visibility == 0 &&
        delete tabs[3];
      return tabs;
    };
  };

  return (
    <Stack spacing={2}>
      <ReportFiltersComponent
        type={ReportScreen.PRIMARY_SALES_HQ_PRODUCT_WISE_SUMMARY}
        successCallback={getHQandProductWise}
        clearSuccessCallBack={clearHQandProductWise}
        gridColumns={13.5}
        userRole={userRole.role}
        userDetails={userDetails}
      />
      {HQandProductWiseStatus === FetchState.LOADING && (
        <Stack alignItems={"center"} height={100} justifyContent={"center"}>
          <CircularProgress />
        </Stack>
      )}

      {HQandProductWiseStatus === FetchState.SUCCESS &&
        (HQandProductWiseData.length > 0 ? (
          <PivotTable
            formattedData={formattedData}
            toolbar={true}
            customizeToolbar={customizeToolbar}
          />
        ) : (
          <Typography variant="h6">No Records Found</Typography>
        ))}
    </Stack>
  );
}
