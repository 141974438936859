import { StateCreator } from "zustand";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export interface DownloadExcelTableInterface {
  downloadExcelTable: Function;
  downloadData: any;
  downloadingExcelTable: Boolean;
  setDownloadData: Function;
}

const initialStates = {
  downloadingExcelTable: false,
  downloadData: [],
};

export const useDownloadExcelTableSlice: StateCreator<
  DownloadExcelTableInterface
> = (set) => ({
  ...initialStates,
  setDownloadData: (data: any) => {
    set(() => ({ downloadData: data }));
  },
  downloadExcelTable: (data: any) => {
    try {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });

      saveAs(blob, "dowanload.xlsx");
    } catch (error) {
      console.error(error);
    }
  },
});
