import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import ReportScreen from "../../../../../../common/enums/ReportScreens";
import { CustomerDatabaseStore } from "../../../../../../store/main/CustomerDatabaseStore";
import { TerritoryDataStore } from "../../../../../../store/main/TerritoryDataStore";
import Roles from "../../../../../../common/enums/Roles";
import {
  BrickFieldDisable,
  BrickFieldVisible,
  HQFieldDisable,
  HQFieldVisible,
  areaFieldDisable,
  areaFieldVisible,
  regionFieldDisable,
  regionFieldVisible,
  zoneFieldDisable,
  zoneFieldVisible,
} from "../../../../../../common/utils/ReportsFilters";
import { MobileModalStyle } from "../../../../../../common/constants/custom-style";

interface Props {
  open: boolean;
  handleClose: Function;
  type?: string;
  title: string;
  successCallback: Function;
  reasonOptions: string[];
}

type FormFields = {
  zone: string;
  region: string;
  area: string;
  hq: string;
  brick: string;
  department: string;
  reason: string;
  reasonSelect: string;
};

const BulkReactivateAndTransferModalMobile: React.FC<Props> = ({
  open,
  handleClose,
  type = ReportScreen.CUSTOMER_DATABASE,
  title,
  successCallback,
  reasonOptions,
}) => {
  const { handleSubmit, control, watch, setValue } = useForm<FormFields>({
    mode: "onChange",
  });

  const { userRole, userDetails } = CustomerDatabaseStore();

  const {
    regionMasterData,
    fetchAreaMaster,
    areaMasterData,
    fetchHeadquarterMaster,
    headquarterMasterData,
    fetchBricksMaster,
    bricksMasterData,
    fetchZoneMaster,
    fetchRegionMaster,
    zoneMasterData,
    fetchDepartment,
    departmentData,
  } = TerritoryDataStore();

  const onSubmit = (formData: FormFields) => {
    successCallback(formData);
    handleClose();
  };

  useEffect(() => {
    fetchDepartment();
    roleBasedFilterValues();
  }, []);

  useEffect(() => {
    if (watch("zone")) {
      fetchRegionMaster([watch("zone")]);
    }
  }, [watch("zone")]);

  useEffect(() => {
    if (watch("region")) {
      fetchAreaMaster([watch("region")]);
    }
  }, [watch("region")]);

  useEffect(() => {
    if (watch("area")) {
      fetchHeadquarterMaster([watch("area")]);
    }
  }, [watch("area")]);

  useEffect(() => {
    if (watch("hq")) {
      fetchBricksMaster([watch("hq")]);
    }
  }, [watch("hq")]);

  const roleBasedFilterValues = () => {
    if (userRole.role === Roles.COUTNRY_MANAGER) {
      fetchZoneMaster();
    } else if (userRole.role === Roles.ZONE_MANAGER) {
      fetchZoneMaster();
      setValue(
        "zone",
        userDetails.hq_zone_id ? userDetails.hq_zone_id.toString() : ""
      );
      fetchRegionMaster([userDetails.hq_zone_id]);
    } else if (userRole.role === Roles.REGION_MANAGER) {
      fetchRegionMaster([userDetails.hq_zone_id]);
      setValue(
        "region",
        userDetails.hq_region_id ? userDetails.hq_region_id.toString() : ""
      );
      fetchAreaMaster([userDetails.hq_region_id]);
    } else if (userRole.role === Roles.AREA_MANAGER) {
      fetchAreaMaster([userDetails.hq_region_id]);
      setValue(
        "area",
        userDetails.hq_area_id ? userDetails.hq_area_id.toString() : ""
      );
      fetchHeadquarterMaster([userDetails.hq_area_id]);
    } else if (userRole.role === Roles.HEADQUATER_MANAGER) {
      fetchHeadquarterMaster([userDetails.hq_area_id]);
      setValue(
        "hq",
        userDetails.hq_headquarter_id
          ? userDetails.hq_headquarter_id.toString()
          : ""
      );
      fetchBricksMaster([userDetails.hq_headquarter_id]);
    }
  };

  return (
    <>
      <Modal open={open} onClose={() => handleClose()}>
        <Box sx={MobileModalStyle}>
          <Typography
            textAlign="center"
            variant="h6"
            fontFamily="Segoe UI"
            fontWeight={600}
            color="#4D4D4D"
            marginBottom={3}
          >
            {title} Customers
          </Typography>

          <Stack spacing={2}>
            {title == "Reactivate" && (
              <Controller
                name="department"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl size="small">
                    <InputLabel>Department</InputLabel>
                    <Select
                      value={value}
                      size="small"
                      label="Department"
                      onChange={onChange}
                    >
                      {departmentData?.map((name) => (
                        <MenuItem value={name.id.toString()}>
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
            {zoneFieldVisible(userRole.role, type) && (
              <Controller
                name="zone"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl size="small">
                    <InputLabel>Zone</InputLabel>
                    <Select
                      disabled={zoneFieldDisable(userRole.role)}
                      value={value}
                      size="small"
                      label="Zone"
                      onChange={onChange}
                    >
                      {zoneMasterData?.map((name) => (
                        <MenuItem value={name.id.toString()}>
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}

            {regionFieldVisible(userRole.role, type) && (
              <Controller
                name="region"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl size="small">
                    <InputLabel>Region</InputLabel>
                    <Select
                      disabled={regionFieldDisable(
                        userRole.role,
                        watch("zone") ? watch("zone").toString().length : 0
                      )}
                      value={value}
                      size="small"
                      label="Region"
                      onChange={onChange}
                    >
                      {regionMasterData?.map((name) => (
                        <MenuItem value={name.id.toString()}>
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
            {areaFieldVisible(userRole.role, type) && (
              <Controller
                name="area"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl size="small">
                    <InputLabel>Area</InputLabel>
                    <Select
                      disabled={areaFieldDisable(
                        userRole.role,
                        watch("region") ? watch("region").toString().length : 0
                      )}
                      value={value}
                      size="small"
                      label="Area"
                      onChange={onChange}
                    >
                      {areaMasterData?.map((name) => (
                        <MenuItem value={name.id.toString()}>
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
            {HQFieldVisible(userRole.role, type) && (
              <Controller
                name="hq"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl size="small">
                    <InputLabel>Headquarter</InputLabel>
                    <Select
                      disabled={HQFieldDisable(
                        userRole.role,
                        watch("area") ? watch("area").toString().length : 0
                      )}
                      value={value}
                      size="small"
                      label="Headquarter"
                      onChange={onChange}
                    >
                      {headquarterMasterData?.map((name) => (
                        <MenuItem value={name.id.toString()}>
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
            {BrickFieldVisible(userRole.role, type) && (
              <Controller
                name="brick"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl size="small">
                    <InputLabel>Brick</InputLabel>
                    <Select
                      disabled={BrickFieldDisable(
                        watch("hq") ? watch("hq").toString().length : 0
                      )}
                      value={value}
                      size="small"
                      label="Brick"
                      onChange={onChange}
                    >
                      {bricksMasterData?.map((name) => (
                        <MenuItem value={name.id.toString()}>
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
            <Controller
              name="reasonSelect"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl size="small">
                  <InputLabel>Select reason</InputLabel>
                  <Select
                    value={value}
                    size="small"
                    label="Select reason"
                    onChange={onChange}
                  >
                    {reasonOptions?.map((name) => (
                      <MenuItem value={name}>{name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {watch("reasonSelect") == "Other" && (
              <Controller
                name="reason"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    value={value}
                    size="small"
                    onChange={onChange}
                    placeholder="Please type reason..."
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  pattern: {
                    value: /^(?!.*[^a-zA-Z0-9 ]).{5,}$/,
                    message:
                      "Enter valid reason.  No special characters and minimum 5 characters",
                  },
                }}
              />
            )}

            <Stack direction={"row"} spacing={2} justifyContent={"center"}>
              <LoadingButton
                disabled={
                  !(
                    watch("brick") &&
                    ((watch("reasonSelect") &&
                      watch("reasonSelect") != "Other") ||
                      (watch("reasonSelect") == "Other" &&
                        watch("reason") &&
                        watch("reason").length > 4))
                  )
                }
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                <Typography
                  color="white"
                  fontWeight={550}
                  textTransform={"none"}
                >
                  {title}
                </Typography>
              </LoadingButton>
              <Button onClick={() => handleClose()}>
                <Typography
                  color="error.main"
                  fontWeight={550}
                  textTransform={"none"}
                >
                  Close
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default BulkReactivateAndTransferModalMobile;
