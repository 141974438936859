import Swal from "sweetalert2";
import { DeactivateUser } from "../../../domain/useages/user-management/deactivate-user";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPutClient } from "../../protocols/http/http-put-client";
import { CreateNewUser } from "../../../domain/useages/user-management/create-new-user";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteCreateNewUser implements CreateNewUser {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async create(params: CreateNewUser.Params): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == 200) {
      Swal.fire({
        timer: 3000,
        icon: "success",
        text: "User Created Successfully",
      });
      return httpResponse.body ? httpResponse.body : httpResponse.data;
    } else {
      Swal.fire({
        timer: 3000,
        icon: "error",
        text: "Some Error Occured",
      });
      return "";
    }
  }
}
