import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY, BASE_URL_KEY, AUTH_HEADER } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteFetchCluster } from "../../../data/usecases/brick-management/remote-fetch-cluster";
import { ClusterType } from "../../../domain/models/brick-management-report/brick-management-data";

export interface FetchClusterInterface {
  clusterData: ClusterType[];
  fetchCluster: Function;
}

const initialValue = {
  clusterData: [],
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchClusterSlice: StateCreator<FetchClusterInterface> = (
  set
) => ({
  ...initialValue,
  fetchCluster: async (code: string) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchCluster = new RemoteFetchCluster(
      `${BASE_URL}${Endpoints.FETCH_CLUSTER_MASTER}`,
      axiosHttpClient
    );
    const payload = { district_code: code };
    let result = await remoteFetchCluster.fetch(payload);
    if (result) {
      set(() => ({ clusterData: result }));
    }
  },
});
