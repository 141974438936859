import { EdiitCustomerDetails } from "../../domain/models/customer-database/edit-customer-details";
import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { RemoteEditCustomers } from "../../data/usecases/remote-edit-customers";
import Endpoints from "../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import Swal from "sweetalert2";

export interface EditCustomerInterface {
  editCustomer: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useEditCustomerSlice: StateCreator<EditCustomerInterface> = (
  set
) => ({
  editCustomer: async (
    id: number,
    customer_data: EdiitCustomerDetails,
    handleClose: Function,
    fetchCustomerData: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteEditCustomers = new RemoteEditCustomers(
      `${BASE_URL}${Endpoints.EDIT_CUSTOMERS}`,
      axiosHttpClient
    );
    let result = await remoteEditCustomers.edit(id.toString(), customer_data);

    if (result.success) {
      handleClose();
      fetchCustomerData();
      Swal.fire({
        icon: "success",
        text: "Updated successfully",
        timer: 3000,
        customClass: {
          container: "swal2-style",
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "Update Failed",
        timer: 3000,
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },
});
