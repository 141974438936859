import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import dayjs from "dayjs";
import { CoverageReportsStore } from "../../../../../store/main/CoverageReportsStore";
import { CustomerDatabaseStore } from "../../../../../store/main/CustomerDatabaseStore";
import { ReportFilters } from "../../../../../domain/models/report-filters";
import ReportScreen from "../../../../../common/enums/ReportScreens";
import { FetchState } from "../../../../../domain/models/fetch-state";
import PivotTable from "../../../PivotTable";
import ReportFiltersComponentMobile from "../../ReportFiltersComponentMobile";
import ReportFiltersLoading from "../../../desktop/ReportFiltersLoading";
import Roles from "../../../../../common/enums/Roles";

export default function SpecialitySummaryTableMobile() {
  const {
    monthlySpecialitySummaryData,
    specialitySummaryStatus,
    fetchSpecialities,
    fetchMonthlySpecialitySummary,
    specialitySummaryFilters,
  } = CoverageReportsStore();
  const { decodeToken, userDetails, userRole } = CustomerDatabaseStore();

  useEffect(() => {
    fetchSpecialities();
  }, []);

  const getMonthlySpecialitySummary = (filters: ReportFilters) => {
    specialitySummaryFilters.monthYear = filters.date;
    specialitySummaryFilters.specialities = filters.speciality;
    fetchMonthlySpecialitySummary();
  };
  const clearmonthlySpecialitySummary = (filters: ReportFilters) => {
    specialitySummaryFilters.monthYear = dayjs(new Date());
    specialitySummaryFilters.specialities = [];
    fetchMonthlySpecialitySummary();
  };

  const formattedData: any = {
    data: [],
    type: "json",
    tableSizes: {
      columns: [
        {
          idx: 2,
          width: 70,
        },
        {
          idx: 3,
          width: 70,
        },
        {
          idx: 4,
          width: 70,
        },
        {
          idx: 5,
          width: 70,
        },
        {
          idx: 6,
          width: 85,
        },
        {
          idx: 8,
          width: 70,
        },
        {
          idx: 9,
          width: 70,
        },
        {
          idx: 10,
          width: 70,
        },
        {
          idx: 11,
          width: 70,
        },
        {
          idx: 12,
          width: 85,
        },
        {
          idx: 14,
          width: 70,
        },
        {
          idx: 15,
          width: 70,
        },
        {
          idx: 16,
          width: 70,
        },
        {
          idx: 17,
          width: 70,
        },
        {
          idx: 18,
          width: 85,
        },
        {
          idx: 20,
          width: 70,
        },
        {
          idx: 21,
          width: 70,
        },
        {
          idx: 22,
          width: 70,
        },
        {
          idx: 23,
          width: 70,
        },
        {
          idx: 24,
          width: 85,
        },
        {
          idx: 26,
          width: 70,
        },
        {
          idx: 27,
          width: 70,
        },
        {
          idx: 28,
          width: 70,
        },
        {
          idx: 29,
          width: 70,
        },
        {
          idx: 30,
          width: 85,
        },
        {
          idx: 32,
          width: 70,
        },
        {
          idx: 33,
          width: 70,
        },
        {
          idx: 34,
          width: 70,
        },
        {
          idx: 35,
          width: 70,
        },
        {
          idx: 36,
          width: 85,
        },
        {
          idx: 38,
          width: 70,
        },
        {
          idx: 39,
          width: 70,
        },
        {
          idx: 40,
          width: 70,
        },
        {
          idx: 41,
          width: 70,
        },
        {
          idx: 42,
          width: 85,
        },
        {
          idx: 44,
          width: 70,
        },
        {
          idx: 45,
          width: 70,
        },
        {
          idx: 46,
          width: 70,
        },
        {
          idx: 47,
          width: 70,
        },
        {
          idx: 48,
          width: 85,
        },
      ],
    },
    rows: [
      {
        uniqueName: "zone",
      },
      {
        uniqueName: "region",
      },
      {
        uniqueName: "area",
      },
      {
        uniqueName: "headquarter",
      },
    ],
    columns: [
      {
        uniqueName: "speciality_group",
      },
      { uniqueName: "[Measure]" },
    ],
    measures: [
      {
        uniqueName: "total_active",
        caption: "Total",
        formula: "sum('total_active')",
        format: "separator",
      },

      {
        uniqueName: "visited_customers",
        caption: "Meet",
        formula: "sum('visited_customers')",
        format: "separator",
      },
      {
        uniqueName: "coverage",
        caption: "Cov(%)",
        format: "coverage",
        formula: " (sum('visited_customers') / sum('total_active')) * 100 ",
      },
      {
        uniqueName: "missed",
        caption: "Missed",
        formula: "sum('total_active') - sum('visited_customers') ",
        format: "separator",
      },
      {
        uniqueName: "twice_visited_customers",
        caption: "V2",
        formula: "sum('twice_visited_customers')",
        format: "separator",
      },
      {
        uniqueName: "twice_coverage",
        caption: "V2 Cov(%)",
        format: "coverage",
        formula:
          " (sum('twice_visited_customers') / sum('total_active')) * 100 ",
      },
    ],
    expands: {
      expandAll: "true",
    },
    grandTotalCaption: "",
    options: {
      columnsWidth: [
        { columnName: "total_active", width: 10 },
        { columnName: "visited_customers" },
      ],
      grid: {
        type: "flat",
        showHeaders: false,
        showHierarchyCaptions: true,
      },
      showCalculatedValuesButton: true,
      sorting: "on",
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
      defaultHierarchySortName: "asc",
    },
    conditions: [
      {
        formula: "#value >= 50 & #value <= 80",
        measure: "coverage",
        format: {
          color: "orange",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value >= 100",
        measure: "coverage",
        format: {
          color: "green",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value < 50",
        measure: "coverage",
        format: {
          color: "red",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value >= 50 && #value <= 80",
        measure: "twice_coverage",
        format: {
          color: "orange",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value >= 100",
        measure: "twice_coverage",
        format: {
          color: "green",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value < 50",
        measure: "twice_coverage",
        format: {
          color: "red",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
    ],
    formats: [
      {
        name: "coverage",
        decimalPlaces: 1,
      },
      {
        name: "separator",
        thousandsSeparator: ",",
      },
    ],
  };
  useEffect(() => {
    decodeToken();
    if (monthlySpecialitySummaryData.length > 0) {
      monthlySpecialitySummaryData.map((val, key) => {
        val.specialities.forEach(function (specialities) {
          if (val.headquarter_id) {
            let rowData = {
              zone: val.zone,
              region: val.region,
              area: val.area,
              headquarter: val.headquarter,
              speciality_group: specialities.speciality_group,

              total_active: specialities.total_active
                ? specialities.total_active
                : 0,
              visited_customers: specialities.visited_customers
                ? specialities.visited_customers
                : 0,
              twice_visited_customers: specialities.twice_visited_customers
                ? specialities.twice_visited_customers
                : 0,
              missed:
                specialities.total_active - specialities.visited_customers,
              coverage:
                specialities.visited_customers / specialities.total_active,
              coveragev2:
                specialities.twice_visited_customers /
                specialities.total_active,
            };

            formattedData.data.push(rowData);
          }
        });
      });
    }
  }, [monthlySpecialitySummaryData]);

  const customizeToolbar = (toolbar: any) => {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      userDetails &&
        userDetails.role &&
        userDetails.role.visibility == 0 &&
        delete tabs[3];
      return tabs;
    };
  };

  return (
    <Stack spacing={2}>
      {Object.keys(userDetails).length > 0 ? (
        <ReportFiltersComponentMobile
          type={ReportScreen.MONTHY_SPECIALITY_SUMMARY}
          successCallback={getMonthlySpecialitySummary}
          clearSuccessCallBack={clearmonthlySpecialitySummary}
          gridColumns={20}
          userRole={userRole.role}
          userDetails={userDetails}
        />
      ) : (
        <ReportFiltersLoading />
      )}
      {specialitySummaryStatus == FetchState.LOADING && (
        <Stack alignItems={"center"} height={100} justifyContent={"center"}>
          <CircularProgress />
        </Stack>
      )}
      <Box>
        {specialitySummaryStatus == FetchState.SUCCESS &&
          (monthlySpecialitySummaryData.length > 0 ? (
            <PivotTable
              formattedData={formattedData}
              toolbar={true}
              customizeToolbar={customizeToolbar}
            />
          ) : (
            <Typography variant="h6">No Records Found</Typography>
          ))}
      </Box>
    </Stack>
  );
}
