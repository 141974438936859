import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { DeleteUserManagementReport } from "./../../../domain/useages/user-management/delete-user-management-report";
import { RemoteDeleteUserManagementReport } from "../../../data/usecases/user-management/remote-delete-user-management-report";

export interface DeleteUserManagementReportInterface {
  deleteUserManagementReport: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useDeleteUserManagementReportSlice: StateCreator<
  DeleteUserManagementReportInterface
> = (set, get) => ({
  deleteUserManagementReport: async (
    id: number,
    payload: DeleteUserManagementReport.Params,
    handleClose: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteDeleteUserManagementReport =
      new RemoteDeleteUserManagementReport(
        `${BASE_URL}${Endpoints.DELETE_USER_MANAGEMENT_REPORT}`,
        axiosHttpClient
      );
    let result = await remoteDeleteUserManagementReport.delete(
      id.toString(),
      payload
    );

    if (result) {
      handleClose();
    }
  },
});
