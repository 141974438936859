import { Skeleton, Stack } from "@mui/material";

type Props = {};

const UserDeviationReportLoading = (props: Props) => {
  return (
    <Stack spacing={2} justifyContent="center">
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="72vw"
        sx={{ borderRadius: "0.4rem" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="72vw"
        sx={{ borderRadius: "0.4rem" }}
      />{" "}
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="72vw"
        sx={{ borderRadius: "0.4rem" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="72vw"
        sx={{ borderRadius: "0.4rem" }}
      />{" "}
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="72vw"
        sx={{ borderRadius: "0.4rem" }}
      />
    </Stack>
  );
};

export default UserDeviationReportLoading;
