import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { FetchState } from "../../../domain/models/fetch-state";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { RemoteFetchHQandProductWiseMaster } from "../../../data/usecases/primary-sales/remote-fetch-hq-and-product-wise";
import { FetchHQandProductWiseSummary } from "../../../domain/useages/primary-sales/fetch-hq-and-product-wise";
import { HQandProductWise } from "../../../domain/models/primary sales/hq-and-product-wise";

export interface FetchHQandProductWiseInterface {
  HQandProductWiseData: HQandProductWise[];
  fetchHQandProductWise: Function;
  HQandProductWiseFilters: {
    monthYear: Dayjs | null;
  };
  HQandProductWiseStatus: FetchState;
}

const initialStates = {
  HQandProductWiseData: [],
  HQandProductWiseStatus: FetchState.DEFAULT,
  HQandProductWiseFilters: {
    monthYear: dayjs(new Date()),
  },
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchHQandProductWiseSlice: StateCreator<
  FetchHQandProductWiseInterface
> = (set, get) => ({
  ...initialStates,
  fetchHQandProductWise: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    set(() => ({ HQandProductWiseStatus: FetchState.LOADING }));

    let filterParams = {
      month_year: moment(
        (get().HQandProductWiseFilters.monthYear as Dayjs).toString()
      ).format("YYYYMM"),
    };

    const remoteFetchHQandProductWise: FetchHQandProductWiseSummary =
      new RemoteFetchHQandProductWiseMaster(
        `${BASE_URL}${Endpoints.FETCH_HQ_AND_PRODUCT_WISE}`,
        axiosHttpClient
      );

    let result = await remoteFetchHQandProductWise.fetch(filterParams);

    if (result) {
      set(() => ({
        HQandProductWiseData: result.sales,
        HQandProductWiseStatus: FetchState.SUCCESS,
      }));
    }
  },
});
