import { TerritoryData } from "../../../domain/models/territory-data";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { RemoteFetchRegionMaster } from "../../../data/usecases/remote-fetch-region";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { StateCreator } from "zustand";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";

export interface FetchRegionMasterInterface {
  regionMasterData: TerritoryData[];
  fetchRegionMaster: Function;
  clearRegionMaster: Function;
}

const intitalValue = {
  regionMasterData: [],
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchRegionMasterSlice: StateCreator<
  FetchRegionMasterInterface
> = (set) => ({
  ...intitalValue,
  fetchRegionMaster: async (id: number[]) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchRegionMaster = new RemoteFetchRegionMaster(
      `${BASE_URL}${Endpoints.FETCH_REGION_MASTER}`,
      axiosHttpClient
    );
    const payload = { zone_ids: id };
    let result = await remoteFetchRegionMaster.fetch(payload);
    if (result) {
      set(() => ({ regionMasterData: result.regions }));
    }
  },
  clearRegionMaster: () => {
    set(() => ({ regionMasterData: [] }));
  },
});
