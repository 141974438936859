import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import {
  Checkbox,
  IconButton,
  Snackbar,
  SnackbarContent,
  Stack,
  Typography,
} from "@mui/material";
import CustomerTableDetails from "./CustomerTableDetails";
import { useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import CustomerType from "../../../../../common/enums/CustomerType";
import Panels from "../../../../../common/enums/Panels";
import Roles from "../../../../../common/enums/Roles";
import { CheckForStockist } from "../../../../../common/utils/ReportsFilters";
import { CustomerDatabaseStore } from "../../../../../store/main/CustomerDatabaseStore";
import { CustomerDetails } from "../../../../../domain/models/customer-database/customer-details";
import ReportScreen from "../../../../../common/enums/ReportScreens";

type Props = {
  panelName: string;
  type: string;
};

const CustomerTable: React.FC<Props> = ({ panelName, type }) => {
  const {
    customerData,
    handleSelectedCustomers,
    userRole,
    filterSegement,
    handleShowActivityChemistButton,
    handleShowAddActivityChemistButton,
  } = CustomerDatabaseStore();
  const [openAlert, setOpenAlert] = useState(false);

  const [selectedCards, setSelectedCards] = useState<number[]>([]);

  const limitToSelectCustomer = 50;
  const [activityChemistFilter, setActivityChemistFilter] = useState(true);

  const checkCustomerTypes = () => {
    if (selectedCards.length == 0) return false;
    for (let i = 0; i < selectedCards.length; i++) {
      const match = customerData.find(
        (customer) => customer.id === selectedCards[i]
      );
      if (
        match &&
        (match.customer_type_id === 3 || match.customer_type_id === 4)
      ) {
        continue;
      } else {
        setActivityChemistFilter(false);
        return false;
      }
    }
    return true;
  };
  const showButton = () => {
    if (
      filterSegement[0] === "activity_chemist" &&
      selectedCards.length !== 0
    ) {
      const arr = customerData.filter(
        (data: CustomerDetails) => data.id === selectedCards[0]
      );
      handleShowAddActivityChemistButton(
        !arr[0]?.customer_metadata[0]?.metadata.activity_chemist
      );
      const result = checkCustomerTypes();
      handleShowActivityChemistButton(result);
    } else {
      handleShowActivityChemistButton(false);
    }
  };

  useEffect(() => {
    showButton();
  }, [customerData, selectedCards]);

  const selectAllRows = (checked: boolean) => {
    const values = [...selectedCards];
    values.splice(0, values.length);
    if (checked) {
      for (let i = 0; i < customerData.length; i++) {
        if (
          values.length < limitToSelectCustomer &&
          customerData[i].customer_type.name != CustomerType.STOCKIST
        ) {
          values.push(customerData[i].id);
        }
      }
    }
    setSelectedCards(values);
    handleSelectedCustomers(values);
  };

  const selectSingleRow = (checked: boolean, id: number) => {
    let value = [...selectedCards];
    if (value.length == limitToSelectCustomer) {
      setOpenAlert(true);
    }

    if (checked && value.length < limitToSelectCustomer) {
      value.push(id);
    } else {
      const filtered = value.filter((data) => data !== id);
      value = filtered;
    }
    setSelectedCards(value);
    handleSelectedCustomers(value);
  };

  const handleCloseSnackbar = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    if (selectedCards.length === limitToSelectCustomer) {
      setOpenAlert(true);
    }
  }, [selectedCards.length]);

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <SnackbarContent
          style={{
            backgroundColor: "lightcoral",
          }}
          message={
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <ErrorOutlineIcon color="inherit" />
              <Typography fontWeight={600} color={"white"}>
                You can select maximum {limitToSelectCustomer} customers only
              </Typography>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>
          }
        />
      </Snackbar>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Box sx={{ overflow: "scroll", height: "40vh" }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    disabled={
                      CheckForStockist(customerData).length == 0 ||
                      userRole.role == Roles.HEADQUATER_MANAGER ||
                      filterSegement.length !== 0
                    }
                    checked={
                      selectedCards.length > 0 &&
                      (selectedCards.length === limitToSelectCustomer ||
                        selectedCards.length === customerData.length)
                    }
                    onChange={(e) => selectAllRows(e.target.checked)}
                  />
                </TableCell>
                <TableCell>
                  <UnfoldMoreOutlinedIcon />
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle2" color="primary.dark">
                    Code
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle2" color="primary.dark">
                    Name
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle2" color="primary.dark">
                    Phone
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle2" color="primary.dark">
                    Type
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle2" color="primary.dark">
                    Brick/HQ
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle2" color="primary.dark">
                    Address
                  </Typography>
                </TableCell>
                {type === ReportScreen.CUSTOMER_DATABASE && (
                  <>
                    <TableCell align="center">
                      <Typography variant="subtitle2" color="primary.dark">
                        Speciality
                      </Typography>
                    </TableCell>
                    {filterSegement.length !== 0 &&
                      filterSegement?.map((segement: String) => {
                        if (segement === "activity_chemist") {
                          return (
                            <TableCell align="center">
                              <Typography
                                variant="subtitle2"
                                color="primary.dark"
                              >
                                Activity Chemist
                              </Typography>
                            </TableCell>
                          );
                        }
                      })}
                    {panelName === Panels.ALL ||
                    panelName === Panels.ARCHIVED ||
                    panelName === Panels.PENDING_MAPPING ||
                    panelName === Panels.PENDING_APPROVAL ? (
                      <TableCell align="center">
                        <Typography variant="subtitle2" color="primary.dark">
                          Actions
                        </Typography>
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        <Typography variant="subtitle2" color="primary.dark">
                          -
                        </Typography>
                      </TableCell>
                    )}
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {customerData.map((row) => {
                return (
                  <>
                    {!(
                      type === ReportScreen.BRICK_MANAGEMENT &&
                      row.customer_type_id === 1
                    ) && (
                      <CustomerTableDetails
                        type={type}
                        row={row}
                        selectedCards={selectedCards}
                        selectSingleRow={selectSingleRow}
                        panelName={panelName}
                        activityChemistFilter={activityChemistFilter}
                      />
                    )}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </>
  );
};
export default CustomerTable;
