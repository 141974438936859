import { StateCreator } from "zustand";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import Endpoints from "../../../domain/endpoints";
import { FetchCustomerwiseInputReport } from "../../../domain/useages/input-utilization/fetch-customerwise-input-report";
import { RemoteFetchCustomerWiseInputReport } from "../../../data/usecases/input-utilization/remote-fetch-customerwise-input-report";
import { FetchState } from "../../../domain/models/fetch-state";
import dayjs from "dayjs";
import moment from "moment";

interface FetchCustomerWiseInputReportInterface {
  fetchCustomerWiseInputReport: Function;
  customerWiseInputReportData: any[];
  fetchCustomerWiseDataState: FetchState;
  customerWiseDataFilters: {
    input_ids?: string[];
    role_id?: string;
    month_years: string[];
    length: number;
    page: number;
  };
  total_data: number;
}

const initialStates = {
  customerWiseInputReportData: [],
  fetchCustomerWiseDataState: FetchState.DEFAULT,
  customerWiseDataFilters: {
    page: 1,
    length: 100,
    month_years: [moment(dayjs(new Date())?.toString()).format("YYYYMM")],
  },
  total_data: 0,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchCustomerWiseInputReportSlice: StateCreator<
  FetchCustomerWiseInputReportInterface
> = (set, get) => ({
  ...initialStates,
  fetchCustomerWiseInputReport: async () => {
    set(() => ({ fetchCustomerWiseDataState: FetchState.LOADING }));
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchCustomerWiseInputReport: FetchCustomerwiseInputReport =
      new RemoteFetchCustomerWiseInputReport(
        `${BASE_URL}${Endpoints.FETCH_CUSTOMER_WISE_INPUT_REPORT}`,
        axiosHttpClient
      );

    let payload = {
      role_id: get().customerWiseDataFilters.role_id,
      length: get().customerWiseDataFilters.length,
      page: get().customerWiseDataFilters.page,
      input_ids: get().customerWiseDataFilters.input_ids,
      month_years: get().customerWiseDataFilters.month_years,
    };

    let result = await remoteFetchCustomerWiseInputReport.fetch(payload);
    if (result) {
      set(() => ({
        customerWiseInputReportData: result.customer_summary,
        fetchCustomerWiseDataState: FetchState.SUCCESS,
        total_data: result.total,
      }));
    }
  },
});
