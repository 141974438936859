import { create } from "zustand";
import { useFetchProductWiseDataSlice } from "../slices/PrimarySalesInvoiceSlices/fetchProductWise";
import { useFetchInvoiceSlice } from "../slices/PrimarySalesInvoiceSlices/fetchInvoice";
import { useFetchStockistWiseDataSlice } from "../slices/PrimarySalesInvoiceSlices/fetchStockistWise";

interface PrimarySalesInterface
  extends ReturnType<typeof useFetchProductWiseDataSlice>,
    ReturnType<typeof useFetchInvoiceSlice>,
    ReturnType<typeof useFetchStockistWiseDataSlice> {}

export const PrimarySalesInvoiceStore = create<PrimarySalesInterface>(
  (...a) => ({
    ...useFetchProductWiseDataSlice(...a),
    ...useFetchInvoiceSlice(...a),
    ...useFetchStockistWiseDataSlice(...a),
  })
);
