export const CustomerTypes = [
  "Stockist",
  "Semi",
  "Retailer",
  "Hub Chemist",
  "Healthcare Provider",
];

export const CustomerCountType = [
  {
    name: "Stockist",
    count: 0,
  },
  {
    name: "Semi-Stockist",
    count: 0,
  },
  {
    name: "Retailer",
    count: 0,
  },
  {
    name: "Control Chemist",
    count: 0,
  },
  {
    name: "Healthcare Provider",
    count: 0,
  },
];
