import { CircularProgress, Pagination, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import PivotTable from "../../PivotTable";
import { PrimarySalesInvoiceStore } from "../../../../store/main/PrimarySalesInvoiceStore";
import { FetchState } from "../../../../domain/models/fetch-state";
import moment from "moment";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import Roles from "../../../../common/enums/Roles";

const StockistTable = () => {
  const {
    stockistWiseData,
    stockistWiseDataLoading,
    fetchStockistWiseData,
    handleStockistPageNumber,
    dataLengthStockist,
    currentStockistPageNumber,
  } = PrimarySalesInvoiceStore();

  const { filterStore, userDetails } = CustomerDatabaseStore();

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    handleStockistPageNumber(value);
    let payload = {
      zone_id: filterStore.zone_ids,
      region_id: filterStore.region_ids,
      area_id: filterStore.area_ids,
      headquarter_id: filterStore.headquarter_ids,
      department_id: "",
      page: value,
    };

    let month = moment(filterStore.date?.toString()).format("MM");
    let year = moment(filterStore.date?.toString()).format("yy");
    fetchStockistWiseData(month, year, payload);
  };

  const formattedData: any = {
    data: [],
    rows: [
      { uniqueName: "code", caption: "Stockist Code" },
      { uniqueName: "stockist", caption: "Stockist Name" },
      { uniqueName: "product", caption: "Product" },
    ],
    columns: [{ uniqueName: "Measures" }],
    measures: [
      {
        uniqueName: "amount",
        caption: "Amount",
        formula: "sum('amount')",
      },
      {
        uniqueName: "quantity",
        caption: "Quantity",
        formula: "sum('quantity')",
      },
    ],
    expands: {
      expandAll: "true",
    },
    options: {
      grid: {
        type: "classic",
        showHeaders: false,
        showTotals: false,
        showHierarchyCaptions: true,
      },
      showCalculatedValuesBottom: false,
      sorting: "on",
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
      defaultHierarchySortName: "asc",
    },
    formats: [
      {
        name: "precision",
        decimalPlaces: 2,
      },
      {
        name: "comma",
        thousandsPlaces: 3,
      },
    ],
  };

  const customizeToolbar = (toolbar: any) => {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      userDetails &&
        userDetails.role &&
        userDetails.role.visibility == 0 &&
        delete tabs[3];
      return tabs;
    };
  };

  useEffect(() => {
    if (stockistWiseData.length > 0) {
      stockistWiseData.map((val, key) => {
        var rowData = {
          stockist: val.customer.firm_name,
          code: val.stockist_code,
          product: "-",
          amount: val.total_net_amount,
          quantity: val.total_qty,
        };
        console.log("QQ", rowData);
        formattedData.data.push(rowData);
      });
    }
  }, [stockistWiseData]);

  return (
    <Stack spacing={2}>
      {stockistWiseDataLoading == FetchState.LOADING && (
        <Stack alignItems={"center"} height={100} justifyContent={"center"}>
          <CircularProgress />
        </Stack>
      )}
      {stockistWiseDataLoading == FetchState.SUCCESS &&
        (stockistWiseData.length > 0 ? (
          <PivotTable
            formattedData={formattedData}
            toolbar={true}
            customizeToolbar={customizeToolbar}
          />
        ) : (
          <Typography variant="h6">No Records Found</Typography>
        ))}
      <Stack alignItems={"center"}>
        <Pagination
          count={Math.ceil(dataLengthStockist / 50)}
          variant="outlined"
          page={currentStockistPageNumber}
          onChange={handlePageChange}
        />
      </Stack>
    </Stack>
  );
};
export default StockistTable;
