import { UpdateSpeciality } from "../../../domain/useages/customer-database/update-speciality";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteUpdateSpeciality implements UpdateSpeciality {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async update(params: UpdateSpeciality.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
