import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteCreateFulfilmentAgent } from "../../../data/usecases/remote-create-fulfiment-agent";
import Endpoints from "../../../domain/endpoints";
import { CreateFulfilmentAgent } from "../../../domain/useages/create-fulfilment-agent";

export interface CreateFulfilmentAgentInterface {
  createFulfilmentAgent: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useCreateFulfilmentAgentSlice: StateCreator<
  CreateFulfilmentAgentInterface
> = (set, get) => ({
  createFulfilmentAgent: async (payload: CreateFulfilmentAgent) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteCreateFulfilmentAgent = new RemoteCreateFulfilmentAgent(
      `${BASE_URL}${Endpoints.CREATE_FULFILMENT_AGENT}`,
      axiosHttpClient
    );
    let result = await remoteCreateFulfilmentAgent.create(payload);
  },
});
