import React from "react";
import { useEffect } from "react";

const SupportPage = () => {
  const openWidget = () => {
    window.fwSettings = {
      widget_id: 89000000906,
    };
    if ("function" != typeof window.FreshworksWidget) {
      var n: any = function () {
        n.q.push(arguments);
      };
      n.q = [];
      window.FreshworksWidget = n;
    }

    const script = document.createElement("script");

    script.src = "https://ind-widget.freshworks.com/widgets/89000000906.js";
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
    window.FreshworksWidget("close");

    return () => {
      document.body.removeChild(script);
    };
  };
  useEffect(() => {
    openWidget();
  }, []);

  return <></>;
};

export default SupportPage;
