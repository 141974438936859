import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { RegionMasterTable } from "./RegionMasterTable";
import { ZoneMasterReportStore } from "../../../../store/main/ZoneMasterReportStore";
import {
  RegionMasterType,
  TitleType,
  ZoneMasterType,
} from "../../../../domain/models/zone-master/zone-master-data";
import { useState } from "react";

const ZoneMasterData = () => {
  const { zoneMasterData } = ZoneMasterReportStore();
  const [summaryData, setSummaryData] = useState<ZoneMasterType>({
    name: "",
    vacantZone: 0,
    totalVacantRegion: 0,
    totalVacantArea: 0,
    totalVacantHQ: 0,
    regionCount: 0,
    areaCount: 0,
    hqCount: 0,
    brickCount: 0,
  } as ZoneMasterType);

  const [selectedTerritoryName, setSelectedTerritoryName] = useState<TitleType>(
    {
      zone: "Regions",
      region: "",
      area: "",
      hq: "",
      brick: "",
    }
  );
  const [title, setTitle] = useState<string>(selectedTerritoryName.zone);
  const Columns = [
    { name: "Region", id: 1 },
    { name: "Total Area", id: 3 },
    { name: "Vacant Area", id: 7 },
    { name: "Total HQ", id: 4 },
    { name: "Vacant HQ", id: 8 },
    { name: "Total Bricks", id: 5 },
  ];

  return (
    <>
      <TableContainer component={Paper}>
        <Box sx={{ overflow: "scroll", height: "80vh" }}>
          <Table size="small" padding="normal" stickyHeader>
            <TableHead>
              <TableRow>
                {Columns.map((column: any) => {
                  return (
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#E9EDF5",
                        color: "#687182",
                      }}
                    >
                      {column.name}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" sx={{ paddingY: 2 }}>
                  <Typography
                    fontWeight={500}
                    color={"#687182"}
                    variant="subtitle1"
                  >
                    {title}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    fontWeight={550}
                    color={"#687182"}
                    variant="subtitle1"
                  >
                    {summaryData.areaCount}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    fontWeight={550}
                    color={"#687182"}
                    variant="subtitle1"
                  >
                    {summaryData.totalVacantArea}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    fontWeight={550}
                    color={"#687182"}
                    variant="subtitle1"
                  >
                    {summaryData.hqCount}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    fontWeight={550}
                    color={"#687182"}
                    variant="subtitle1"
                  >
                    {summaryData.totalVacantHQ}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    fontWeight={550}
                    color={"#687182"}
                    variant="subtitle1"
                  >
                    {summaryData.brickCount}
                  </Typography>
                </TableCell>
              </TableRow>
              {zoneMasterData?.map((row: RegionMasterType, index: number) => (
                <RegionMasterTable
                  data={row}
                  setTitle={setTitle}
                  title={title}
                  setSummaryData={setSummaryData}
                  selectedTerritoryName={selectedTerritoryName}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </>
  );
};

export default ZoneMasterData;
