import { TableRow, TableCell, Stack, Typography, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { BricksType } from "../../../../../domain/models/brick-management-report/brick-management-data";
import DeactivateBrickModal from "./Modals/DeactivateBrickModal";
import UpdateBrickModal from "./Modals/UpdateBrickModal";
import { CustomerDatabaseStore } from "../../../../../store/main/CustomerDatabaseStore";
import ReasonRemoveModal from "./Modals/ReasonRemoveModal";
import { BrickManagementStore } from "../../../../../store/main/BrickManagementStore";

type Props = {
  row: BricksType;
  hq: string;
  hq_id: number;
};

const BrickTableDetail: React.FC<Props> = ({ row, hq, hq_id }) => {
  const { filterStore, userDetails } = CustomerDatabaseStore();
  const { fetchDistrict } = BrickManagementStore();
  const [openUpdateBrick, setOpenUpdateBrick] = useState<boolean>(false);
  const [openRemoveBrick, setOpenRemoveBrick] = useState<boolean>(false);
  const [openReasonRemove, setOpenReasonRemove] = useState<boolean>(false);

  const successCallbackRemove = () => {
    setOpenRemoveBrick(false);
    setOpenReasonRemove(true);
  };

  const openUpdateBrickModal = () => {
    setOpenUpdateBrick(true);
    fetchDistrict(hq_id);
  };

  const removeBrick = () => {
    setOpenRemoveBrick(true);
    filterStore.brick_ids = [row.brick_id.toString()];
  };
  console.log(row);
  return (
    <>
      <TableRow
        sx={{
          bgcolor:
            row.bricks.district === null ||
            (row.bricks.cluster_mapping &&
              row.bricks.cluster_mapping.length === 0)
              ? "error.dark"
              : "",
        }}
      >
        <TableCell>
          <Typography textAlign={"center"} fontSize={12}>
            {hq}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"center"} fontSize={12}>
            {row.bricks.district != null ? row.bricks.district : "-"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"center"} fontSize={12}>
            {row.brick}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"center"} fontSize={12}>
            {row.bricks.station != null ? row.bricks.station : "-"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"center"} fontSize={12}>
            {row.bricks.no_of_work_days != null
              ? row.bricks.no_of_work_days
              : "-"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"center"} fontSize={12}>
            {row.bricks.expected_business != null
              ? row.bricks.expected_business
              : "-"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography textAlign={"center"} fontSize={12}>
            {row.bricks.distance_from_hq != null
              ? row.bricks.distance_from_hq
              : "-"}
          </Typography>
        </TableCell>
        <TableCell>
          <Stack direction={"row"} justifyContent={"center"}>
            <Button
              disabled={userDetails.button_access === 0}
              onClick={openUpdateBrickModal}
            >
              <EditIcon
                color={userDetails.button_access !== 0 ? "primary" : "disabled"}
              />
            </Button>
            <Button
              disabled={userDetails.button_access === 0}
              onClick={removeBrick}
            >
              <DeleteIcon
                color={userDetails.button_access !== 0 ? "error" : "disabled"}
              />
            </Button>
          </Stack>
        </TableCell>
      </TableRow>

      {userDetails.button_access !== 0 && openUpdateBrick && (
        <UpdateBrickModal
          open={openUpdateBrick}
          handleClose={() => setOpenUpdateBrick(false)}
          successCallback={() => {}}
          row={row}
          hq={hq}
          brick_id={row.brick_id}
          hq_id={hq_id}
        />
      )}
      {userDetails.button_access !== 0 && openRemoveBrick && (
        <DeactivateBrickModal
          open={openRemoveBrick}
          handleClose={() => setOpenRemoveBrick(false)}
          successCallback={successCallbackRemove}
          row={row}
          hq={hq}
        />
      )}
      {openReasonRemove && (
        <ReasonRemoveModal
          open={openReasonRemove}
          handleClose={() => setOpenReasonRemove(false)}
          id={row.brick_id}
        />
      )}
    </>
  );
};

export default BrickTableDetail;
