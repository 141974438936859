import {
  CustomerDetails,
  Pagination,
} from "../../../domain/models/customer-database/customer-details";
import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteFetchCustomers } from "../../../data/usecases/remote-fetch-customers";
import { FetchState } from "../../../domain/models/fetch-state";
import { FetchCustomers } from "../../../domain/useages/fetch-customers";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { customerDatabaseFilters } from "../../../domain/models/customer-database/customer-database-filters";

export interface FetchCustomerInterface {
  customerData: CustomerDetails[];
  fetchCustomerTableState: FetchState;
  fetchCustomerData: Function;
  filterStore: customerDatabaseFilters;
  pageData: Pagination;
  departmentCode: string;
  handleDepartmentCode: Function;
  filterSegement: string[];
  handleFilterSegement: Function;
  showActivityChemistButton: boolean;
  showAddActivityChemistButton: boolean;
  handleShowActivityChemistButton: Function;
  handleShowAddActivityChemistButton: Function;
}

const initialStates = {
  customerData: [],
  fetchCustomerTableState: FetchState.DEFAULT,
  filterStore: {
    status: "active",
    page: 1,
    download: 0,
  },
  pageData: {} as Pagination,
  departmentCode: "",
  filterSegement: [],
  showActivityChemistButton: false,
  showAddActivityChemistButton: false,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchCustomersSlice: StateCreator<FetchCustomerInterface> = (
  set,
  get
) => ({
  ...initialStates,
  handleDepartmentCode: (value: string) => {
    set(() => ({ departmentCode: value }));
  },
  handleShowActivityChemistButton: (value: boolean) => {
    set(() => ({ showActivityChemistButton: value }));
  },
  handleShowAddActivityChemistButton: (value: boolean) => {
    set(() => ({ showAddActivityChemistButton: value }));
  },
  handleFilterSegement: (value: string[]) => {
    set(() => ({ filterSegement: value }));
  },
  fetchCustomerData: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchCustomers: FetchCustomers = new RemoteFetchCustomers(
      `${BASE_URL}${Endpoints.FETCH_CUSTOMERS}`,
      axiosHttpClient
    );

    set(() => ({ customerData: [] }));
    set(() => ({ pageData: { total: 0 } as Pagination }));
    set(() => ({ fetchCustomerTableState: FetchState.LOADING }));

    let result = await remoteFetchCustomers.fetch(get().filterStore);
    if (result.success) {
      set(() => ({ customerData: result.customers }));
      set(() => ({ pageData: result.paginated }));
      set(() => ({ fetchCustomerTableState: FetchState.SUCCESS }));
    }
  },
});
