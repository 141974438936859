import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY, BASE_URL_KEY, AUTH_HEADER } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { FetchState } from "../../../domain/models/fetch-state";
import { AddHqDistance } from "../../../domain/useages/hq-distance-report/add-hq-distance";
import { RemoteAddHqDistance } from "../../../data/usecases/hq-distance-report/remote-add-hq-distance";
import { HqDistanceStore } from "../../main/HqDistanceStore";

export interface AddHqDistanceInterface {
  addHqDistance: Function;
  addHqDistanceState: FetchState;
}

const initialState = {
  addHqDistanceState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useAddHqDistanceSlice: StateCreator<AddHqDistanceInterface> = (
  set,
  get
) => ({
  ...initialState,
  addHqDistance: async (payload: AddHqDistance.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    set({ addHqDistanceState: FetchState.LOADING });

    const remoteAddHqDistance: AddHqDistance = new RemoteAddHqDistance(
      `${BASE_URL}${Endpoints.ADD_HQ_DISTANCE}`,
      axiosHttpClient
    );
    let result = await remoteAddHqDistance.add(payload);
    console.log(result);
    if (result.success === true) {
      Swal.fire({
        icon: "success",
        timer: 3000,
        text: result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
      set({ addHqDistanceState: FetchState.SUCCESS });
      HqDistanceStore.getState().fetchHqDistance(
        HqDistanceStore.getState().hqDistanceFilter
      );
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: result.errors.message ?? result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
      set({ addHqDistanceState: FetchState.SUCCESS });
    }
  },
});
