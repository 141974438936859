import { Card, Stack, Typography } from "@mui/material";
import { CustomerTypes } from "../../../../common/constants/Customers";
import CustomerVisitsColor from "../../../../common/enums/UserSummaryColor";
import { CustomerVisitData } from "../../../../domain/models/user-summary-report/common-modals";

type Props = {
  date: string;
  customerVisitData: CustomerVisitData[];
};

const CustomerVisitReport: React.FC<Props> = ({ date, customerVisitData }) => {
  return (
    <>
      <Stack spacing={2}>
        <Typography variant="body1">Customer Visit Report</Typography>
        <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
          {CustomerTypes.map((name) => {
            const data = customerVisitData.find(
              (item) => item.name === name && item.date === date
            );
            return (
              <Card
                key={name}
                sx={{
                  width: "100%",
                  borderRadius: "0.7rem",
                  padding: "15px",
                  backgroundColor: (CustomerVisitsColor as any)[name],
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography color={"white"} fontSize={15}>
                    {name} met
                  </Typography>
                  <Typography color={"white"} variant="h4">
                    {data ? data.count : 0}
                  </Typography>
                </Stack>
              </Card>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};

export default CustomerVisitReport;
