import { Box } from "@mui/material";
import CustomerMissingReportDesktop from "../components/desktop/customer-missing-report/CustomerMissingReportDesktop";
import { CustomerDatabaseStore } from "../../store/main/CustomerDatabaseStore";
import { useEffect } from "react";

const CustomerMissing = () => {
  const { decodeToken } = CustomerDatabaseStore();
  useEffect(() => {
    decodeToken();
  }, []);
  return (
    <>
      <Box p={2}>
        <CustomerMissingReportDesktop />
      </Box>
    </>
  );
};

export default CustomerMissing;
