import { StateCreator } from "zustand";
import { CustomerMissing } from "../../../domain/models/customer-missing-report/customer-missing-report";
import { FetchState } from "../../../domain/models/fetch-state";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchCustomerMissingReport } from "../../../domain/useages/customer-missing-report/customer-missing";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteFetchCustomerMissingReport } from "../../../data/usecases/customer-missing-report/remote-fetch-customer-missing";
import Endpoints from "../../../domain/endpoints";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

export interface FetchCustomerMissingInterface {
  customerMissingData: CustomerMissing[];
  fetchCustomerMissingData: Function;
  fetchCustomerMissingState: FetchState;
  customerMissingFilter: {
    month_year: Dayjs | null;
    zones: string[];
  };
}
const initialStates = {
  customerMissingData: {} as CustomerMissing[],
  fetchCustomerMissingState: FetchState.DEFAULT,
  customerMissingFilter: {
    month_year: dayjs(new Date()),
    zones: [],
  },
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchCustomerMissingDataSlice: StateCreator<
  FetchCustomerMissingInterface
> = (set, get) => ({
  ...initialStates,
  fetchCustomerMissingData: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    set({ fetchCustomerMissingState: FetchState.LOADING });

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchCustomerMissing: FetchCustomerMissingReport =
      new RemoteFetchCustomerMissingReport(
        `${BASE_URL}${Endpoints.CUSTOMER_MISSING_REPORT}`,
        axiosHttpClient
      );
    const payload = {
      month_year: moment(
        (get().customerMissingFilter.month_year as Dayjs).toString()
      ).format("YYYYMM"),
      zones: get().customerMissingFilter.zones,
    };
    let result = await remoteFetchCustomerMissing.fetch(payload);
    if (result.length > 0) {
      set({
        customerMissingData: result,
        fetchCustomerMissingState: FetchState.SUCCESS,
      });
    } else {
      set({ fetchCustomerMissingState: FetchState.ERROR });
    }
  },
});
