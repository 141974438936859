import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import Roles from "../../../../../common/enums/Roles";
import { UserManagementData } from "../../../../../domain/models/user-management/user-management-data";
import { UserManagementStore } from "../../../../../store/main/UserManagementStore";

type Props = {
  closeModals: Function;
  confirmDeactivate: boolean;
  confirmUnlock: boolean;
  deleteReport: boolean;
  selectedUser: UserManagementData;
  role?: string;
};

const ConfirmModal: React.FC<Props> = ({
  closeModals,
  confirmDeactivate,
  deleteReport,
  confirmUnlock,
  selectedUser,
  role = "",
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    bgcolor: "background.paper",
    borderRadius: "22px",
    boxShadow: 24,
    p: 4,
  };

  const [reportDeleteDate, setReportDeleteDate] = useState<Dayjs | null>(
    dayjs(new Date())
  );
  const [deactivateUserDate, setDeactivateUserDate] = useState<Dayjs | null>(
    dayjs(new Date())
  );
  const {
    deactivateUser,
    deleteUserManagementReport,
    unlockUser,
    fetchUserManagementData,
  } = UserManagementStore();
  const [openReportDeleteDate, setOpenReportDeleteDate] =
    useState<boolean>(false);
  const [openDeactivateUserDate, setOpenDeactivateUserDate] =
    useState<boolean>(false);

  const confirmUserAction = () => {
    if (confirmDeactivate) {
      deactivateUser(selectedUser.id, {
        leaving_date: moment(deactivateUserDate?.toString()).format(
          "YYYY-MM-DD"
        ),
      });
      closeModals("deactivateUser");
    } else if (deleteReport) {
      deleteUserManagementReport(selectedUser.id, {
        report_date: moment(reportDeleteDate?.toString()).format("YYYY-MM-DD"),
      });
      closeModals("deleteReport");
    } else if (confirmUnlock) {
      unlockUser(selectedUser.id);
      closeModals("confirmUnlock");
    }
    fetchUserManagementData();
  };

  useEffect(() => {
    if (confirmDeactivate) {
      setDeactivateUserDate(dayjs(new Date()));
    }
    if (deleteReport) {
      setReportDeleteDate(dayjs(new Date()));
    }
  }, [confirmDeactivate, deleteReport]);

  return (
    <>
      <Modal
        open={confirmDeactivate || deleteReport || confirmUnlock}
        onClose={() => {
          deleteReport && closeModals("deleteReport");
          confirmDeactivate && closeModals("deactivateUser");
        }}
      >
        <Box sx={style}>
          {confirmDeactivate && (
            <Stack>
              <Typography variant="h6" fontWeight={600} gutterBottom>
                Confirm Deactivate
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Leave Date:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  open={openDeactivateUserDate}
                  onClose={() => {
                    setOpenDeactivateUserDate(false);
                  }}
                  onOpen={() => {
                    setOpenDeactivateUserDate(true);
                  }}
                  views={["day"]}
                  inputFormat="LL | dddd"
                  value={deactivateUserDate}
                  minDate={moment(selectedUser.joining_date)
                    .add(1, "days")
                    .format("YYYY-MM-DD")
                    .toString()}
                  disableFuture
                  onChange={(newValue) => {
                    setDeactivateUserDate(dayjs(newValue));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      size="small"
                      onClick={() => setOpenDeactivateUserDate(true)}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
          )}

          {deleteReport && (
            <Stack>
              <Typography variant="h6" fontWeight={600} gutterBottom>
                Delete Report
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Report Delete Date:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  open={openReportDeleteDate}
                  onClose={() => {
                    setOpenReportDeleteDate(false);
                  }}
                  onOpen={() => {
                    setOpenReportDeleteDate(true);
                  }}
                  views={["day"]}
                  value={reportDeleteDate}
                  inputFormat="LL | dddd"
                  minDate={
                    role == Roles.COUTNRY_MANAGER
                      ? moment(selectedUser.joining_date)
                          .add(1, "days")
                          .format("YYYY-MM-DD")
                          .toString()
                      : moment().subtract(7, "day").toDate()
                  }
                  disableFuture
                  onChange={(newValue) => {
                    setReportDeleteDate(dayjs(newValue));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      size="small"
                      onClick={() => setOpenReportDeleteDate(true)}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
          )}

          {confirmUnlock && (
            <Stack>
              <Typography variant="h6" fontWeight={600} gutterBottom>
                Unblock User
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Please Confirm
              </Typography>
            </Stack>
          )}

          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            marginTop={3}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={confirmUserAction}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                deleteReport && closeModals("deleteReport");
                confirmDeactivate && closeModals("deactivateUser");
                confirmUnlock && closeModals("confirmUnlock");
              }}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmModal;
