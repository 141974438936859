import {
  Button,
  Stack,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { UserManagementStore } from "../../../../store/main/UserManagementStore";
import { InputUsageSummaryStore } from "../../../../store/main/InputUsageReportStore";
import { useEffect, useState } from "react";
import { UserManagementData } from "../../../../domain/models/user-management/user-management-data";
import { ReportFilters } from "../../../../domain/models/report-filters";
import moment from "moment";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import Roles from "../../../../common/enums/Roles";
import Header from "../../Header";
import AddNewUser from "./modals/AddNewUserModal";
import ResetPasswordModal from "./modals/ResetPasswordModal";
import ConfirmModal from "./modals/ConfirmModal";
import ReportFiltersComponent from "../ReportFiltersComponent";
import ReportScreen from "../../../../common/enums/ReportScreens";
import ReportFiltersLoading from "../ReportFiltersLoading";
import Pagination from "../../Pagination";
import UserManagementDataLoading from "./UserManagementDataLoading";
import TableComponent from "../../TableComponent";
import { FetchState } from "../../../../domain/models/fetch-state";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
export default function UserManagementReportDesktop() {
  const {
    getUserDetails,
    decodeToken,
    userDetails,
    userRole,
    downloadExcelTable,
  } = CustomerDatabaseStore();
  const {
    userManagementDataStatus,
    fetchUserManagementData,
    userManagementData,
    filterStore,
    DataLength,
    fetchServiceProvider,
    fetchDepartment,
    serviceProvider,
    departmentData,
  } = UserManagementStore();
  const { fetchUserRolesMaster, userRolesMasterData } =
    InputUsageSummaryStore();

  const [createUserModal, setCreateUserModal] = useState<boolean>(false);
  const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false);
  const [deactivateUser, setDeactivateUser] = useState<boolean>(false);
  const [deleteReport, setDeleteReport] = useState<boolean>(false);
  const [unlockUser, setUnlockUser] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<number>(0);
  const [selectedUserData, setSelectedUserData] = useState<UserManagementData>(
    {} as UserManagementData
  );

  const closeModals = (modalName: string) => {
    if (modalName == "CreateUser") {
      setCreateUserModal(false);
    } else if (modalName == "resetPasswordModal") {
      setResetPasswordModal(false);
    } else if (modalName == "deactivateUser") {
      setDeactivateUser(false);
    } else if (modalName == "deleteReport") {
      setDeleteReport(false);
    } else if (modalName == "confirmUnlock") {
      setUnlockUser(false);
    }
  };

  useEffect(() => {
    decodeToken();
    getUserDetails();
    fetchUserManagementData();
    fetchServiceProvider();
    fetchDepartment();
    fetchUserRolesMaster();
  }, []);

  const FilterData = (filters: ReportFilters) => {
    filterStore.zone_id = filters.zone;
    filterStore.region_id = filters.region;
    filterStore.area_id = filters.area;
    filterStore.role_id = filters.role;
    filterStore.status = filters.status;
    filterStore.search = filters.search;
    fetchUserManagementData();
  };

  const ClearUserManaagementData = () => {
    filterStore.zone_id = [];
    filterStore.region_id = [];
    filterStore.area_id = [];
    filterStore.role_id = "";
    filterStore.status = "active";
    filterStore.search = "";
    fetchUserManagementData();
  };

  const handlePagination = (page: number, limit: number) => {
    filterStore.page = page;
    filterStore.length = limit;
    fetchUserManagementData();
  };

  interface Column {
    id:
      | "zone"
      | "region"
      | "area"
      | "headquarter"
      | "full_name"
      | "code"
      | "role"
      | "username"
      | "mobile"
      | "joining_date"
      | "leaving_date"
      | "actions";

    label: string;
    minWidth?: number;
    align?: "center" | "right";
    format?: (value: number) => string;
  }

  const column: Column[] = [
    { id: "zone", label: "Zone", align: "center" },
    { id: "region", label: "Region", align: "center" },
    { id: "area", label: "Area", align: "center" },
    { id: "headquarter", label: "HQ", align: "center" },
    { id: "full_name", label: "Full Name", align: "center" },
    { id: "code", label: "Emp Code", align: "center" },
    { id: "role", label: "Role", align: "center" },
    { id: "username", label: "UserName", align: "center" },
    { id: "mobile", label: "Mobile", align: "center" },
    { id: "joining_date", label: "Joining Date", align: "center" },
    { id: "leaving_date", label: "Leaving Date", align: "center" },
    {
      id: "actions",
      label: "Actions",
      align: "center",
    },
  ];
  let temp: any = [];
  const createData = (row: UserManagementData[]) => {
    for (let i = 0; i < row.length; i++) {
      temp.push({
        zone: row[i].hq_zone?.name ? row[i].hq_zone.name : "-",
        region: row[i].hq_region?.name ? row[i].hq_region.name : "-",
        area: row[i].hq_area?.name ? row[i].hq_area.name : "-",
        headquarter: row[i].hq_headquarter?.name
          ? row[i].hq_headquarter.name
          : "-",
        full_name: row[i].full_name,
        code: row[i].emp_code ? row[i].emp_code : "-",
        role: row[i].role.name,
        username: row[i].username,
        mobile: row[i].mobile,
        joining_date: row[i].joining_date
          ? moment(
              row[i].joining_date as string,
              "YYYY/M/D" || "D/M/YYYY" || "YYYY-MM-DD" || "YYYY-MM-DD"
            ).format("ll")
          : "-",
        leaving_date: row[i].leaving_date
          ? moment(
              row[i].leaving_date as string,
              "YYYY/M/D" || "D/M/YYYY" || "YYYY-MM-DD" || "YYYY-MM-DD"
            ).format("ll")
          : "-",
        actions:
          userRole.role == Roles.HEADQUATER_MANAGER ? (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Tooltip title="Disabled">
                <IconButton color="secondary">
                  <DeleteOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Disabled">
                <IconButton color="secondary">
                  <LockPersonOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Disabled">
                <IconButton color="secondary">
                  <FolderDeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" justifyContent="center">
              {userRole.role == Roles.COUTNRY_MANAGER &&
                row[i].leaving_date == null && (
                  <Tooltip title="Delete User">
                    <IconButton color="error">
                      <DeleteOutlinedIcon
                        fontSize="small"
                        onClick={() => {
                          setDeactivateUser(true);
                          setSelectedUser(row[i].id);
                          setSelectedUserData(row[i]);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              {row[i].leaving_date == null ? (
                <Tooltip title="Reset Password">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setResetPasswordModal(true);
                      setSelectedUser(row[i].id);
                    }}
                  >
                    <LockPersonOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                "-"
              )}

              {userRole.role != Roles.HEADQUATER_MANAGER &&
                row[i].leaving_date == null && (
                  <Tooltip title="Delete Report">
                    <IconButton
                      color="error"
                      onClick={() => {
                        setDeleteReport(true);
                        setSelectedUser(row[i].id);
                        setSelectedUserData(row[i]);
                      }}
                    >
                      <FolderDeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              {(userRole.role == Roles.AREA_MANAGER ||
                userRole.role == Roles.COUTNRY_MANAGER ||
                userRole.role == Roles.HEADQUATER_MANAGER ||
                userRole.role == Roles.REGION_MANAGER ||
                userRole.role == Roles.ZONE_MANAGER) &&
                row[i].reporting_locked == 1 && (
                  <Tooltip title="Unlock User">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setUnlockUser(true);
                        setSelectedUser(row[i].id);
                        setSelectedUserData(row[i]);
                      }}
                    >
                      <LockOpenIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
            </Stack>
          ),
      });
    }
    return temp;
  };
  const downloadFile = () => {
    downloadExcelTable(temp);
  };
  return (
    <>
      <Stack gap={2} justifyContent="center">
        <Stack direction="row" spacing={3} alignItems="center" marginY={2}>
          <Header title="User Management" />
          {userRole.role == Roles.COUTNRY_MANAGER && (
            <Button
              variant="contained"
              size="small"
              startIcon={<Add />}
              sx={{
                "&:hover": {
                  backgroundColor: "#f73148",
                },
              }}
              color="error"
              onClick={() => {
                setCreateUserModal(true);
              }}
            >
              Add New
            </Button>
          )}
          {userDetails &&
            userDetails.role &&
            userDetails.role.visibility == 1 && (
              <Button
                variant="contained"
                startIcon={<FileDownloadOutlinedIcon />}
                sx={{ borderRadius: "10px", width: "180px", height: "40px" }}
                onClick={downloadFile}
                color="primary"
              >
                <Typography variant="subtitle2" textTransform={"none"}>
                  Download Excel
                </Typography>
              </Button>
            )}
        </Stack>
        <AddNewUser
          CreateUserModal={createUserModal}
          closeModals={closeModals}
          selectedUser={selectedUserData}
          serviceProvider={serviceProvider}
          departmentData={departmentData}
          userRolesMasterData={userRolesMasterData}
        />

        <ResetPasswordModal
          resetPasswordModal={resetPasswordModal}
          closeModals={closeModals}
          selectedUser={selectedUser}
        />
        <ConfirmModal
          confirmDeactivate={deactivateUser}
          closeModals={closeModals}
          deleteReport={deleteReport}
          confirmUnlock={unlockUser}
          selectedUser={selectedUserData}
          role={userRole.role}
        />

        {Object.keys(userDetails).length > 0 ? (
          <ReportFiltersComponent
            type={ReportScreen.USER_MANAGEMENT}
            successCallback={FilterData}
            clearSuccessCallBack={ClearUserManaagementData}
            gridColumns={16}
            userRole={userRole.role}
            userDetails={userDetails}
            territoryMultiSelect={false}
            justifyContent={"start"}
          />
        ) : (
          <ReportFiltersLoading />
        )}

        <Stack direction="row" justifyContent="end" alignItems="center">
          <Pagination
            totalPages={DataLength}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            currenPage={filterStore.page}
            rowsPerPage={filterStore.length}
            successCallback={handlePagination}
          />
        </Stack>
        {userManagementDataStatus === FetchState.LOADING && (
          <Box sx={{ overflow: "scroll", height: "65vh" }}>
            <UserManagementDataLoading columns={column} />
          </Box>
        )}

        {userManagementDataStatus === FetchState.SUCCESS &&
          userManagementData.length > 0 && (
            <TableComponent
              columns={column}
              rows={createData(userManagementData)}
              height="70vh"
            />
          )}
        {userManagementDataStatus === FetchState.SUCCESS &&
          userManagementData.length <= 0 && (
            <Typography variant="h6">No Records Found</Typography>
          )}
      </Stack>
    </>
  );
}
