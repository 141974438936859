import { Skeleton, Stack } from "@mui/material";

type Props = {};

const CustomerCountLoading = (props: Props) => {
  return (
    <Stack
      spacing={2}
      justifyContent={"space-between"}
      direction={"row"}
      padding={2}
      marginY={"20px"}
      sx={{ borderRadius: "0.7rem" }}
    >
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{ borderRadius: "0.7rem" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{ borderRadius: "0.7rem" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{ borderRadius: "0.7rem" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{ borderRadius: "0.7rem" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{ borderRadius: "0.7rem" }}
      />
    </Stack>
  );
};

export default CustomerCountLoading;
