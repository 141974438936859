import { useEffect, useState } from "react";
import { CustomerDatabaseStore } from "../../store/main/CustomerDatabaseStore";
import { Pagination, Stack, Typography } from "@mui/material";
import Header from "../components/Header";
import ReportFiltersComponent from "../components/desktop/ReportFiltersComponent";
import ReportFiltersLoading from "../components/desktop/ReportFiltersLoading";
import ReportScreen from "../../common/enums/ReportScreens";
import UserInputPlanningTable from "../components/desktop/user-input-planning/UserInputPlanningTable";
import { UserInputPlanningStore } from "../../store/main/UserInputPlanningStore";
import { useMediaQuery, useTheme } from "@mui/material";
import ReportFiltersComponentMobile from "../components/mobile/ReportFiltersComponentMobile";
import ReportFiltersLoadingMobile from "../components/mobile/ReportFilterLoadingMobile";
import { ReportFilters } from "../../domain/models/report-filters";
import dayjs from "dayjs";
import moment from "moment";
import { FetchState } from "../../domain/models/fetch-state";
import CircularProgress from "@mui/material/CircularProgress";
import { UserManagementStore } from "../../store/main/UserManagementStore";
const UserInputPlanning = () => {
  const { decodeToken, selectedPanel, userDetails, userRole } =
    CustomerDatabaseStore();
  const {
    fetchUserInputPlanning,
    userInputData,
    fetchInputState,
    handlePageNumber,
    filterStore,
    currentPage,
  } = UserInputPlanningStore();

  const { fetchUserManagementData } = UserManagementStore();

  const [allowEdit, setAllowEdit] = useState<boolean>(true);

  useEffect(() => {
    filterStore.zone_ids = [];
    filterStore.region_ids = [];
    filterStore.area_ids = [];
    filterStore.headquarter_ids = [];
    filterStore.brick_ids = [];
    filterStore.month_year = moment(dayjs(new Date()).toString()).format(
      "yyMM"
    );
    filterStore.customer_type_id = "";
    filterStore.page = 1;
    decodeToken();
    fetchUserInputPlanning(filterStore);
    fetchUserManagementData();
  }, []);

  const clearFilters = () => {
    filterStore.zone_ids = [];
    filterStore.region_ids = [];
    filterStore.area_ids = [];
    filterStore.headquarter_ids = [];
    filterStore.brick_ids = [];
    filterStore.month_year = moment(dayjs(new Date()).toString()).format(
      "yyMM"
    );
    filterStore.customer_type_id = "";
    filterStore.user_id = "";
    filterStore.page = 1;
    fetchUserInputPlanning(filterStore);
  };

  const fetchUserInput = (filters: ReportFilters) => {
    filterStore.zone_ids = filters.zone;
    filterStore.region_ids = filters.region;
    filterStore.area_ids = filters.area;
    filterStore.headquarter_ids = filters.headquarter;
    filterStore.brick_ids = filters.brick;
    filterStore.month_year = moment(filters.date?.toString()).format("yyMM");
    filterStore.customer_type_id = filters.customerType;
    filterStore.user_id = filters.user.toString();
    fetchUserInputPlanning(filterStore);
    if (
      moment(filters.date?.toString()).format("yyMM") ===
      moment(dayjs(new Date()).toString()).format("yyMM")
    ) {
      setAllowEdit(true);
    } else if (
      moment(filters.date?.toString()).format("yyMM") !==
      moment(dayjs(new Date()).toString()).format("yyMM")
    ) {
      setAllowEdit(false);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    filterStore.page = value;
    handlePageNumber(value);
    fetchUserInputPlanning();
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isMobile ? (
        <Stack gap={2} justifyContent="center" marginTop={1}>
          <Header title={"User Input Planning"} />
          <Stack paddingX={5}>
            {!selectedPanel &&
              (Object.keys(userDetails).length > 0 ? (
                <ReportFiltersComponentMobile
                  type={ReportScreen.USER_INPUT_PLANNING}
                  successCallback={fetchUserInput}
                  clearSuccessCallBack={clearFilters}
                  userRole={userRole.role}
                  userDetails={userDetails}
                  territoryMultiSelect={false}
                  gridColumns={20}
                />
              ) : (
                <ReportFiltersLoadingMobile />
              ))}
          </Stack>
          {fetchInputState == FetchState.SUCCESS &&
            userInputData.inputs.length == 0 && (
              <>
                <Stack>
                  <Typography variant="h5">
                    There are No Inputs for the Current Month!
                  </Typography>
                </Stack>
              </>
            )}

          {fetchInputState == FetchState.SUCCESS &&
            userInputData.inputs.length > 0 && (
              <>
                <UserInputPlanningTable allowEdit={allowEdit} />
                <Stack textAlign={"center"} mt={1} marginX="auto">
                  <Pagination
                    count={Math.ceil(
                      userInputData?.paginated?.total /
                        userInputData?.paginated?.per_page
                    )}
                    variant="outlined"
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </Stack>
              </>
            )}
        </Stack>
      ) : (
        <Stack gap={2} justifyContent="center" marginTop={1} width={"100vw"}>
          <Header title={"User Input Planning"} />
          <Stack paddingX={5}>
            {!selectedPanel &&
              (Object.keys(userDetails).length > 0 ? (
                <ReportFiltersComponent
                  type={ReportScreen.USER_INPUT_PLANNING}
                  successCallback={fetchUserInput}
                  clearSuccessCallBack={clearFilters}
                  userRole={userRole.role}
                  userDetails={userDetails}
                  territoryMultiSelect={false}
                  gridColumns={22}
                />
              ) : (
                <ReportFiltersLoading />
              ))}
          </Stack>
          {fetchInputState == FetchState.LOADING && (
            <Stack textAlign={"center"} mt={2} marginX="auto">
              <CircularProgress />
            </Stack>
          )}
          {fetchInputState == FetchState.SUCCESS &&
            userInputData.inputs.length == 0 && (
              <>
                <Stack textAlign={"center"} mt={2}>
                  <Typography variant="h6">
                    There are No Inputs for the Current Month!
                  </Typography>
                </Stack>
              </>
            )}
          {fetchInputState == FetchState.SUCCESS &&
            userInputData.inputs.length > 0 && (
              <>
                <UserInputPlanningTable allowEdit={allowEdit} />
                <Stack textAlign={"center"} mt={1} marginX="auto">
                  <Pagination
                    count={Math.ceil(
                      userInputData?.paginated?.total /
                        userInputData?.paginated?.per_page
                    )}
                    variant="outlined"
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </Stack>
              </>
            )}
        </Stack>
      )}
    </>
  );
};
export default UserInputPlanning;
