import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { HqDistanceStore } from "../../../../../store/main/HqDistanceStore";

type Props = {
  open: boolean;
  handleClose: Function;
  hq_name: string;
  hq_code: string;
};

type FormFields = {
  hq_name: string;
  station: string;
  distance: string;
};

const AddHqDistanceModal: React.FC<Props> = ({
  open,
  handleClose,
  hq_name,
  hq_code,
}) => {
  const { addHqDistance } = HqDistanceStore();
  const { handleSubmit, control, watch } = useForm<FormFields>({
    mode: "onChange",
  });

  const handleAdd = () => {
    const payload = {
      headquarter_code: hq_code,
      headquarter_type: watch("station"),
      distance_from_hq: watch("station") === "HQ" ? 0 : watch("distance"),
    };
    addHqDistance(payload);
    handleClose();
  };

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box
        position={"absolute" as "absolute"}
        top={"50%"}
        left={"50%"}
        bgcolor={"background.paper"}
        borderRadius={"22px"}
        sx={{ transform: "translate(-50%, -50%)" }}
        width={"25%"}
        p={3}
      >
        <Typography fontWeight={500} variant="h6" textAlign={"center"} mb={2}>
          Add Distance
        </Typography>
        <Stack spacing={2}>
          <Controller
            name="hq_name"
            defaultValue={hq_name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  id="outlined-disabled"
                  label="Headquarter Name"
                  value={value}
                  fullWidth
                  size="small"
                  disabled
                />
              </FormControl>
            )}
          />
          <Controller
            name="station"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">EX/OS/HQ</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="select"
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  label="EX/OS/HQ"
                >
                  <MenuItem value="EX">EX</MenuItem>
                  <MenuItem value="OS">OS</MenuItem>
                  <MenuItem value="HQ">HQ</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="distance"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Distance from HQ"
                  value={value}
                  size="small"
                  onChange={onChange}
                  error={!!error}
                  helperText={
                    watch("station") === "HQ" && watch("distance") !== "0" ? (
                      <Typography color={"red"} fontSize={12}>
                        *Note : For Headquarter Type HQ distance should be 0
                      </Typography>
                    ) : (
                      error?.message
                    )
                  }
                />
              </FormControl>
            )}
            rules={{
              pattern: {
                value: /^[0-9]+$/,
                message: "Only Number allowed",
              },
            }}
          />
        </Stack>
        <Stack
          paddingTop={3}
          direction={"row"}
          gap={3}
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleAdd)}
            disabled={
              !(watch("station") === "HQ"
                ? watch("distance") === "0"
                  ? true
                  : false
                : watch("distance") && watch("station"))
            }
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClose()}
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddHqDistanceModal;
