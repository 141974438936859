import { Stack, useMediaQuery, useTheme } from "@mui/material";
import Header from "../components/Header";
import BrickWorkingReportComponent from "../components/desktop/bricks-working-report/BrickWorkingReportComponent";
import BrickWorkingReportMobileComponent from "./../components/mobile/bricks-working-report/BrickWorkingReportMobileComponent";

type Props = {};

const BrickWorkingReport = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack gap={2} position={"fixed"} width={"100%"}>
      <Header title="Brick Working Report" />
      {isMobile ? (<BrickWorkingReportMobileComponent/>) : (<BrickWorkingReportComponent/>)}
    </Stack>
  );
};

export default BrickWorkingReport;
