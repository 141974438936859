import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ProductCatlogStore } from "../../../../store/main/ProductCatlogStore";
import { useEffect } from "react";
import { FetchState } from "../../../../domain/models/fetch-state";
import CustomerTableLoading from "../CustomerTableLoading";
import NoRecordFound from "../NoRecordFound";
import ReportScreen from "../../../../common/enums/ReportScreens";
import ReportFiltersComponent from "../ReportFiltersComponent";
import ReportFiltersLoading from "../ReportFiltersLoading";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { TerritoryDataStore } from "../../../../store/main/TerritoryDataStore";
import { ReportFilters } from "../../../../domain/models/report-filters";

const ProductCatlogDesktop = () => {
  const { fetchProductCatlog, ProductCatlog, ProductCatlogState } =
    ProductCatlogStore();
  const { decodeToken, userDetails, userRole } = CustomerDatabaseStore();
  const { fetchDepartment } = TerritoryDataStore();
  useEffect(() => {
    fetchProductCatlog();
    decodeToken();
    fetchDepartment();
  }, []);

  const filterData = (filters: ReportFilters) => {
    const payload = {
      department_id: filters.department,
    };
    fetchProductCatlog(payload);
  };

  const clearData = () => {
    fetchProductCatlog();
  };
  return (
    <Box p={2}>
      <Stack spacing={3}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h5" fontWeight={600}>
            Product Catlog
          </Typography>
          <Stack paddingY={2}>
            {Object.keys(userDetails).length > 0 ? (
              <ReportFiltersComponent
                type={ReportScreen.PRODUCT_CATLOG}
                successCallback={filterData}
                clearSuccessCallBack={clearData}
                userRole={userRole.role}
                userDetails={userDetails}
                gridColumns={6}
              />
            ) : (
              <ReportFiltersLoading />
            )}
          </Stack>
        </Stack>

        {ProductCatlogState === FetchState.SUCCESS && (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: "#0D71B7",
                        color: "white",
                        border: "1px solid #cacacc",
                        padding: 10,
                      }}
                    >
                      Product Name
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0D71B7",
                        color: "white",
                        border: "1px solid #cacacc",
                        padding: 10,
                      }}
                    >
                      UOM
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#0D71B7",
                        color: "white",
                        border: "1px solid #cacacc",
                        padding: 10,
                      }}
                    >
                      Price
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ProductCatlog.products.map((product) => (
                    <TableRow>
                      <TableCell
                        style={{
                          border: "1px solid #cacacc",
                          padding: 10,
                        }}
                      >
                        {product.name}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #cacacc",
                          padding: 10,
                        }}
                      >
                        {product.uoms.length === 0 ? "-" : product.uoms[0].name}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #cacacc",
                          padding: 10,
                        }}
                      >
                        {product.uoms.length === 0
                          ? 0
                          : product.uoms[0].unit_price}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {ProductCatlogState === FetchState.LOADING && <CustomerTableLoading />}
        {ProductCatlogState === FetchState.ERROR && (
          <NoRecordFound height="70vh" />
        )}
      </Stack>
    </Box>
  );
};

export default ProductCatlogDesktop;
