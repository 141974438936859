import { Box, Stack, Typography } from "@mui/material";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { UserSummaryReportStore } from "../../../../store/main/UserSummaryReportStore";
import { ReportFilters } from "../../../../domain/models/report-filters";
import moment from "moment";
import ReportScreen from "../../../../common/enums/ReportScreens";
import FiltersMobile from "./FiltersMobile";
import CustomerCountMobile from "./CustomerCountMobile";
import ReportFiltersComponentMobile from "../ReportFiltersComponentMobile";
import DeviationReportMobile from "./DeviationReportMobile";
import MonthlyGraphReportMobile from "./sub-components/MonthlyGraphReportMobile";
import AdditionInformationMobile from "./sub-components/AdditionalinformationMobile";
import { FetchState } from "../../../../domain/models/fetch-state";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

const UserSummaryMobile = () => {
  const { userDetails, userRole } = CustomerDatabaseStore();
  const {
    fetchSummaryReport,
    fetchMonthSummaryReport,
    fetchDeviationReport,
    userSummaryFilter,
    fetchMonthsReport,
    monthsReportDataState,
    monthSummaryState,
    fetchDeviationReportState,
  } = UserSummaryReportStore();

  const filterData = (filters: ReportFilters) => {
    fetchSummaryReport(
      moment(filters.date?.toString()).format("MM"),
      moment(filters.date?.toString()).format("yy"),
      userSummaryFilter.user_id,
      filters.department ? filters.department : 1
    );
    fetchMonthSummaryReport(
      moment(filters.date?.toString()).format("MM"),
      moment(filters.date?.toString()).format("yy"),
      userSummaryFilter.user_id,
      filters.department ? filters.department : 1
    );
    fetchDeviationReport(
      moment(filters.date?.toString()).format("MM"),
      moment(filters.date?.toString()).format("yy"),
      userSummaryFilter.user_id,
      filters.department ? filters.department : 1
    );
    fetchMonthsReport(
      moment(filters.date?.toString()).format("yy"),
      userSummaryFilter.user_id,
      filters.department ? filters.department : 1
    );
  };

  const clearData = (filters: ReportFilters) => {
    const currentDate = new Date();
    fetchDeviationReport(
      currentDate.getMonth(),
      currentDate.getFullYear(),
      userSummaryFilter.user_id,
      filters.department
    );
    fetchSummaryReport(
      currentDate.getMonth(),
      currentDate.getFullYear(),
      userSummaryFilter.user_id,
      filters.department
    );
    fetchMonthSummaryReport(
      currentDate.getMonth(),
      currentDate.getFullYear(),
      userSummaryFilter.user_id,
      filters.department
    );
    fetchMonthsReport(
      currentDate.getFullYear(),
      userSummaryFilter.user_id,
      filters.department
    );
  };
  return (
    <>
      <Stack
        padding={1}
        spacing={1}
        marginBottom={4}
        alignContent={"center"}
        justifyContent={"center"}
      >
        <FiltersMobile />
        {fetchDeviationReportState == FetchState.DEFAULT &&
        monthsReportDataState == FetchState.DEFAULT &&
        monthSummaryState == FetchState.DEFAULT ? (
          <Box
            sx={{
              paddingY: "30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <PermIdentityOutlinedIcon sx={{ fontSize: 148 }} color="primary" />
            <Typography variant="h5">PLEASE SELECT USER</Typography>
            <Typography variant="h5">FOR REVIEW</Typography>
          </Box>
        ) : (
          <Box>
            <Stack padding={1}>
              <ReportFiltersComponentMobile
                type={ReportScreen.USER_SUMMARY_REPORT}
                successCallback={filterData}
                clearSuccessCallBack={clearData}
                gridColumns={12}
                userRole={userRole.role}
                userDetails={userDetails}
                justifyContent="center"
              />
            </Stack>

            <CustomerCountMobile />
            <DeviationReportMobile />
            {monthSummaryState === FetchState.SUCCESS &&
              fetchDeviationReportState === FetchState.SUCCESS &&
              monthsReportDataState === FetchState.SUCCESS && (
                <Typography variant="h6" padding={"10px"}>
                  Month Summary
                </Typography>
              )}

            {monthsReportDataState === FetchState.SUCCESS && (
              <MonthlyGraphReportMobile />
            )}
            <AdditionInformationMobile />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default UserSummaryMobile;
