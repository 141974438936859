import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY, BASE_URL_KEY, AUTH_HEADER } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { FetchState } from "../../../domain/models/fetch-state";
import { MapStockist } from "../../../domain/useages/unmapped-stockist/map-stockist";
import { RemoteMapStockist } from "../../../data/usecases/unmapped-stockist/remote-map-stockist";
import { UnmappedStockistStore } from "../../main/UnmappedStockistStore";
import moment from "moment";

export interface mapStockistInterface {
  mapStockist: Function;
  mapStockistState: FetchState;
}

const initialState = {
  mapStockistState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const usemapStockistSlice: StateCreator<mapStockistInterface> = (
  set,
  get
) => ({
  ...initialState,
  mapStockist: async (payload: MapStockist.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    set({ mapStockistState: FetchState.LOADING });

    const remotemapStockist: MapStockist = new RemoteMapStockist(
      `${BASE_URL}${Endpoints.MAP_STOCKIST}`,
      axiosHttpClient
    );
    let result = await remotemapStockist.map(payload);
    if (result.success === true) {
      Swal.fire({
        icon: "success",
        timer: 3000,
        text: result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
      set({ mapStockistState: FetchState.SUCCESS });
      UnmappedStockistStore.getState().fetchUnmappedStockist({
        month_year: moment(new Date()).format("yyyyMM").toString(),
      });
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: result.errors.message ?? result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
      set({ mapStockistState: FetchState.SUCCESS });
    }
  },
});
