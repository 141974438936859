import { AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { User } from "../../domain/models/user-login-type";
import { LoggedInUser } from "../../domain/useages/logged-in-user";
import { JsonStorage } from "../protocols/storage/json-storage";

export class LocalLoggedInUser implements LoggedInUser {
  baseURLKey: string = BASE_URL_KEY;
  tokenKey: string = AUTH_TOKEN_KEY;

  constructor(private readonly jsonStorage: JsonStorage) {}
  getUser(): User | null {
    throw new Error("Method not implemented.");
  }
  setUser(user: User): void {
    throw new Error("Method not implemented.");
  }

  setBaseURL(url: string): void {
    this.jsonStorage.add(this.baseURLKey, url);
  }

  getURL(): string | null {
    let url = this.jsonStorage.get(this.baseURLKey);
    if (url && url !== "") return JSON.parse(this.decrypt(url));
    return null;
  }

  getToken(): string | null {
    let token = this.jsonStorage.get(this.tokenKey);
    if (token && token !== "") return this.decrypt(token);
    return null;
  }

  setToken(token: string): void {
    this.jsonStorage.add(this.tokenKey, this.encrypt(token));
  }

  logout(): void {
    this.jsonStorage.remove(this.baseURLKey);
    this.jsonStorage.remove(this.tokenKey);
  }

  resetUser(user: User): void {
    this.jsonStorage.add(this.baseURLKey, this.encrypt(JSON.stringify(user)));
  }

  encrypt(value: string) {
    return btoa(value);
  }

  decrypt(value: string) {
    return atob(value);
  }
}
