import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { RemoteFetchCustomerTypes } from "../../data/usecases/remote-fetch-customer-types";
import { FetchCustomerTypes } from "../../domain/useages/fetch-customer-types";
import Endpoints from "../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { Speciality } from "../../domain/models/speciality";
import { RemoteFetchProductsMaster } from "../../data/usecases/remote-fetch-products-master";
import { FetchProductsMaster } from "../../domain/useages/fetch-products-master";

export interface FetchProductsMasterInterface {
  productsMasterData: Speciality[];
  fetchProductsMaster: Function;
}

const initialStates = {
  productsMasterData: [],
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchProductsMasterSlice: StateCreator<
FetchProductsMasterInterface
> = (set, get) => ({
  ...initialStates,
  fetchProductsMaster: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchProductsMaster: FetchProductsMaster =
      new RemoteFetchProductsMaster(
        `${BASE_URL}${Endpoints.FETCH_PRODUCTS}`,
        axiosHttpClient
      );

    set(() => ({ productsMasterData: [] }));

    let result = await remoteFetchProductsMaster.fetch();
    if (result) {
      set(() => ({ productsMasterData: result.products }));
    }
  },
});
