import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import {
  API_KEY,
  FRESHDESK_AUTH_HEADER,
  RAISE_COMPLAINT_URL,
} from "../../base";
import Endpoints from "../../domain/endpoints";
import { RemoteRaiseTicket } from "../../data/usecases/remote-raise-tickets";
import { RaiseTicketType } from "../../domain/models/raise-ticket";

export interface RaiseTicketInterface {
  raiseTicket: Function;
}

const initialStates = {};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useRaiseTicketSlice: StateCreator<RaiseTicketInterface> = (
  set
) => ({
  ...initialStates,
  raiseTicket: async (payload: RaiseTicketType) => {
    const encodedApiKey = btoa(API_KEY + ":X");
    // const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = RAISE_COMPLAINT_URL;
    axiosHttpClient.setAuthHeaders({
      [FRESHDESK_AUTH_HEADER]: `Basic ${encodedApiKey}`,
    });

    const remoteRaiseTicket = new RemoteRaiseTicket(
      `${BASE_URL}${Endpoints.RAISE_COMPLAINT}`,
      axiosHttpClient
    );
    let data = {
      description: payload.description,
      subject: payload.subject,
      email: payload.email,
      priority: payload.priority,
      status: payload.status,
      cc_emails: payload.cc_emails,
      type: payload.type,
      custom_fields: {
        cf_assigned_to: payload.custom_fields.cf_assigned_to,
        cf_category_issues: payload.custom_fields.cf_category_issues,
        cf_bu: payload.custom_fields.cf_bu,
      },
    };

    try {
      let result = await remoteRaiseTicket.raise(data);
      if (result) {
        console.log("RESult", result);
      }
    } catch (error) {
      console.error(error);
    }
  },
});
