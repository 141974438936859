import { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ProductWise from "./ProductWiseTable";
import InvoiceTable from "./InvoiceTable";
import { PrimarySalesInvoiceStore } from "../../../../store/main/PrimarySalesInvoiceStore";
import StockistTable from "./StockistTable";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { CircularProgress, Stack } from "@mui/material";
import { FetchState } from "../../../../domain/models/fetch-state";
import InvoiceDetailsModal from "./InvoiceDetailsModal";

interface PanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function Panel(props: PanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 3, mt: 2 }} bgcolor={"#f9f9f9"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function PrimarySalesInvoiceTabs() {
  const [value, setValue] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const {
    stockistWiseData,
    invoiceData,
    productWiseData,
    stockistWiseDataLoading,
  } = PrimarySalesInvoiceStore();

  return (
    <Box sx={{ width: "100%" }}>
      <Box paddingX={2}>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab label="Product Wise" value={0} />
          <Tab label="Invoice" value={1} />
          <Tab label="Stockist Wise" value={2} />
        </Tabs>
      </Box>
      <Panel value={value} index={0}>
        {stockistWiseDataLoading == FetchState.LOADING && (
          <Stack alignItems={"center"} height={100} justifyContent={"center"}>
            <CircularProgress />
          </Stack>
        )}
        {stockistWiseDataLoading == FetchState.SUCCESS &&
          (productWiseData.length > 0 ? (
            <ProductWise />
          ) : (
            <Typography variant="h6">No Records Found</Typography>
          ))}
      </Panel>
      <Panel value={value} index={1}>
        {stockistWiseDataLoading == FetchState.LOADING && (
          <Stack alignItems={"center"} height={100} justifyContent={"center"}>
            <CircularProgress />
          </Stack>
        )}
        {stockistWiseDataLoading == FetchState.SUCCESS &&
          (invoiceData.length > 0 ? (
            <InvoiceTable
              setShowDetails={setShowDetails}
              setIndex={setSelectedIndex}
            />
          ) : (
            <Typography variant="h6">No Records Found</Typography>
          ))}
      </Panel>
      <Panel value={value} index={2}>
        {stockistWiseDataLoading == FetchState.LOADING && (
          <Stack alignItems={"center"} height={100} justifyContent={"center"}>
            <CircularProgress />
          </Stack>
        )}
        {stockistWiseDataLoading == FetchState.SUCCESS &&
          (stockistWiseData.length > 0 ? (
            <StockistTable />
          ) : (
            <Typography variant="h6">No Records Found</Typography>
          ))}
      </Panel>

      <InvoiceDetailsModal
        open={showDetails}
        setShowDetails={setShowDetails}
        selectedIndex={selectedIndex}
      />
    </Box>
  );
}
