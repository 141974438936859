import {
  Box,
  Button,
  Chip,
  CircularProgress,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { LoadingButton } from "@mui/lab";
import { FulfilmentAgentStore } from "../../../../../../store/main/FulfilmentAgentStore";
import { MobileModalStyle } from "../../../../../../common/constants/custom-style";
import { FetchState } from "../../../../../../domain/models/fetch-state";
import { UpdateFulfilment } from "../../../../../../domain/models/update-fulfilment";
import { CustomerDatabaseStore } from "../../../../../../store/main/CustomerDatabaseStore";
import { CustomerDetails } from "../../../../../../domain/models/customer-database/customer-details";

interface Props {
  open: boolean;
  handleClose: Function;
  data: CustomerDetails;
}

const FulfilmentAgentMobile: React.FC<Props> = ({
  open,
  handleClose,
  data,
}) => {
  const { userDetails } = CustomerDatabaseStore();

  const {
    updateFulfilmentAgent,
    createFulfilmentAgent,
    generateOtp,
    verifyOtp,
    verifyOTPResponse,
    customerDatafetchState,
    isEligibile,
    customerDataByUuid,
    verifiedMobile,
    verifiedWhatsapp,
    verifiedEmail,
    setEmpty,
    OtpStatus,
  } = FulfilmentAgentStore();

  const { handleSubmit, control, setValue, watch } = useForm<UpdateFulfilment>({
    mode: "onChange",
  });

  const style = {
    position: "absolute" as "absolute",
    top: "2%",
    right: "2%",
    cursor: "pointer",
    ":hover": { backgroundColor: "transparent" },
  };

  const [verifyMobile, setVerifyMobile] = useState<Boolean>(false);
  const [verifyWhatsapp, setVerifyWhatsapp] = useState<Boolean>(false);
  const [verifyEmail, setVerifyEmail] = useState<Boolean>(false);
  const [contactType, setContactType] = useState<string>("");
  const [mobileotp, setMobileOtp] = useState<number | string | undefined>("");
  const [whatsappotp, setWhatsappOtp] = useState<number | string | undefined>(
    ""
  );
  const [emailotp, setEmailOtp] = useState<number | string | undefined>("");
  const [disableMobile, setDisableMobile] = useState<boolean>(false);
  const [disableWhatsapp, setDisableWhatsapp] = useState<boolean>(false);
  const [disableEmail, setDisableEmail] = useState<boolean>(false);
  const [loadingMobile, setLoadingMobile] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingWhatsapp, setLoadingWhatsapp] = useState(false);
  const [mobilePresent, setMobilePresent] = useState<boolean>(false);
  const [whatsappPresent, setWhatsappPresent] = useState<boolean>(false);
  const [emailPresent, setEmailPresent] = useState<boolean>(false);

  const onVerify = (type: string, value: string | number) => {
    let payload = {
      type: type,
      customer_id: data.id,
      value: value,
      uuid: data.uu_id,
    };
    generateOtp(payload);
    if (type === "mobile") {
      setLoadingMobile(true);
    } else if (type === "whatsapp") {
      setLoadingWhatsapp(true);
    } else if (type === "email") {
      setLoadingEmail(true);
    }

    setContactType(type);
  };

  useEffect(() => {
    if (OtpStatus.success) {
      if (contactType === "mobile") {
        setVerifyMobile(true);
      } else if (contactType === "whatsapp") {
        setVerifyWhatsapp(true);
      } else if (contactType === "email") {
        setVerifyEmail(true);
      }
    } else if (!OtpStatus.success) {
      if (contactType === "mobile") {
        setLoadingMobile(false);
      } else if (contactType === "whatsapp") {
        setLoadingWhatsapp(false);
      } else if (contactType === "email") {
        setLoadingEmail(false);
      }
    }
  }, [OtpStatus]);

  const otpChange = (e: any, type: string) => {
    if (type === "mobile") {
      setMobileOtp(e.target.value);
    } else if (type === "whatsapp") {
      setWhatsappOtp(e.target.value);
    } else if (type === "email") {
      setEmailOtp(e.target.value);
    }
  };

  const ConfirmOTP = (
    type: string,
    value: string | number,
    otp?: number | string
  ) => {
    let payload = {
      type: type,
      customer_id: data.id,
      value: value,
      otp: otp,
    };

    if (otp) {
      verifyOtp(payload);
    }

    if (verifyOTPResponse.message.length > 0) {
      if (verifyOTPResponse.success === true && type === "mobile") {
        setVerifyMobile(false);
      } else if (verifyOTPResponse.success === true && type === "whatsapp") {
        setVerifyWhatsapp(false);
      } else if (verifyOTPResponse.success === true && type === "email") {
        setVerifyEmail(false);
      }
    }
  };

  const onSubmit = (Formdata: UpdateFulfilment) => {
    let payload: any = {
      uuid: data.uu_id,
      customer_id: data.id,
    };

    {
      if (!customerDataByUuid?.data?.gstNumber) {
        payload.gstNumber = Formdata.gstNumber;
      }
      if (!customerDataByUuid?.data?.panNumber) {
        payload.panNumber = Formdata.panNumber;
      }
      if (!customerDataByUuid?.data?.drugLicenseNumber) {
        payload.drugLicenseNumber = Formdata.drugLicenseNumber;
      }
      if (!mobilePresent) {
        payload.contacts = payload.contacts
          ? [...payload.contacts, { type: "mobile", value: Formdata.mobile }]
          : [{ type: "mobile", value: Formdata.mobile }];
      }
      if (!whatsappPresent) {
        payload.contacts = payload.contacts
          ? [
              ...payload.contacts,
              { type: "whatsapp", value: Formdata.whatsapp },
            ]
          : [{ type: "whatsapp", value: Formdata.whatsapp }];
      }
      if (!emailPresent) {
        payload.contacts = payload.contacts
          ? [...payload.contacts, { type: "email", value: Formdata.email }]
          : [{ type: "email", value: Formdata.email }];
      }
    }

    updateFulfilmentAgent(payload, handleClose);
  };

  const createAgent = (id: string) => {
    let payload = {
      mapping_id: id,
      uuid: data.uu_id,
      customer_id: data.id,
      userId: userDetails.id,
    };
    createFulfilmentAgent(payload);
  };

  useEffect(() => {
    setValue("firmName", data.firm_name);
    setValue(
      "gstNumber",
      customerDataByUuid?.data?.gstNumber
        ? customerDataByUuid.data.gstNumber
        : ""
    );
    setValue(
      "panNumber",
      customerDataByUuid?.data?.panNumber
        ? customerDataByUuid.data.panNumber
        : ""
    );
    setValue(
      "drugLicenseNumber",
      customerDataByUuid?.data?.drugLicenseNumber
        ? customerDataByUuid.data.drugLicenseNumber
        : ""
    );

    {
      customerDataByUuid?.data?.contacts?.map((data, index) => {
        if (data.type === "mobile") {
          setValue("mobile", data.value ? data.value : "");
          setDisableMobile(true);
          setMobilePresent(true);
        } else if (data.type === "whatsapp") {
          setValue("whatsapp", data.value ? data.value : "");
          setDisableWhatsapp(true);
          setWhatsappPresent(true);

          setMobilePresent(true);
        } else if (data.type === "email") {
          setValue("email", data.value ? data.value : "");
          setDisableEmail(true);
          setEmailPresent(true);
        }
      });
    }
    setEmpty();
  }, []);

  return (
    <>
      <Modal open={open}>
        {customerDatafetchState === FetchState.LOADING ? (
          <CircularProgress color="inherit" />
        ) : (
          <Box sx={MobileModalStyle}>
            <Typography fontWeight={500} variant="h6" textAlign="center">
              Fulfilment Agent
            </Typography>
            <Button
              endIcon={<CloseRoundedIcon color="secondary" />}
              sx={style}
              onClick={() => handleClose()}
            />
            <Stack spacing={2}>
              <Stack>
                <Typography variant="subtitle2" color="info.error">
                  Firm Name
                </Typography>
                <Controller
                  name="firmName"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={value}
                      disabled={data.firm_name ? true : false}
                      error={error && true}
                      helperText={error?.message}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Chip
                              label={data.customer_type.name}
                              sx={{
                                color: "white",
                                backgroundColor:
                                  data.customer_type.name === "Stockist"
                                    ? "info.main"
                                    : data.customer_type.name ===
                                      "Semi Stockist"
                                    ? "info.dark"
                                    : data.customer_type.name === "Retailers"
                                    ? "info.light"
                                    : data.customer_type.name ===
                                      "Surgical Semis"
                                    ? "info.contrastText"
                                    : "info.dark",
                                borderRadius: "8px",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle2" color="info.error">
                  GST Number
                </Typography>
                <Controller
                  name="gstNumber"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={value}
                      disabled={
                        customerDataByUuid?.data?.gstNumber ? true : false
                      }
                      error={error && true}
                      helperText={error?.message}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Field Required" },
                    pattern: {
                      value:
                        /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
                      message: "Enter Correct GST Number",
                    },
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack>
                  <Typography variant="subtitle2" color="info.error">
                    PAN Number
                  </Typography>
                  <Controller
                    name="panNumber"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={value}
                        disabled={
                          customerDataByUuid?.data?.panNumber ? true : false
                        }
                        error={error && true}
                        helperText={error?.message}
                        onChange={onChange}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Field Required" },
                      pattern: {
                        value: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
                        message: "Enter Correct PAN Number",
                      },
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography variant="subtitle2" color="info.error">
                    DL Number
                  </Typography>
                  <Controller
                    name="drugLicenseNumber"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={value}
                        disabled={
                          customerDataByUuid?.data?.drugLicenseNumber
                            ? true
                            : false
                        }
                        error={error && true}
                        helperText={error?.message}
                        onChange={onChange}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Field Required" },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                {verifiedMobile ? (
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      Mobile
                    </Typography>
                    <Controller
                      name="mobile"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={watch("mobile")}
                          error={error && true}
                          helperText={error?.message}
                          disabled={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DoneRoundedIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={onChange}
                        />
                      )}
                      rules={{
                        required: { value: true, message: "Field Required" },
                        pattern: {
                          value: /^[6-9]\d{9}$/,
                          message: "Enter Correct Mobile",
                        },
                      }}
                    />
                  </Stack>
                ) : verifyMobile ? (
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      Verify [{watch("mobile")}]
                      {disableMobile === false && (
                        <Button
                          onClick={() => {
                            setVerifyMobile(false);
                            setLoadingMobile(false);
                          }}
                          color="info"
                          size="small"
                        >
                          <EditRoundedIcon fontSize="inherit" />
                        </Button>
                      )}
                    </Typography>
                    <Controller
                      name="mobileotp"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          placeholder="Enter OTP"
                          value={mobileotp}
                          error={error && true}
                          helperText={error?.message}
                          onChange={(e) => otpChange(e, "mobile")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {mobileotp?.toString().length === 4 ? (
                                  <Button
                                    size="small"
                                    variant="text"
                                    onClick={(e) =>
                                      ConfirmOTP(
                                        "mobile",
                                        watch("mobile"),
                                        mobileotp
                                      )
                                    }
                                  >
                                    Submit
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Stack>
                ) : (
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      Mobile
                    </Typography>
                    <Controller
                      name="mobile"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={value}
                          error={error && true}
                          helperText={error?.message}
                          disabled={disableMobile}
                          onChange={onChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {!error && watch("mobile") && !disableMobile ? (
                                  loadingMobile ? (
                                    <LoadingButton
                                      variant="text"
                                      size="small"
                                      loading={loadingMobile}
                                    ></LoadingButton>
                                  ) : (
                                    <Button
                                      variant="text"
                                      size="small"
                                      onClick={() => onVerify("mobile", value)}
                                    >
                                      VERIFY
                                    </Button>
                                  )
                                ) : (
                                  ""
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      rules={{
                        required: { value: true, message: "Field Required" },
                        pattern: {
                          value: /^[6-9]\d{9}$/,
                          message: "Enter Correct Mobile Number",
                        },
                      }}
                    />
                  </Stack>
                )}

                {verifiedWhatsapp ? (
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      Whatsapp
                    </Typography>
                    <Controller
                      name="whatsapp"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={watch("whatsapp")}
                          error={error && true}
                          helperText={error?.message}
                          disabled={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <DoneRoundedIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={onChange}
                        />
                      )}
                      rules={{
                        required: { value: true, message: "Field Required" },
                        pattern: {
                          value: /^[6-9]\d{9}$/,
                          message: "Enter Correct Whatsapp Number",
                        },
                      }}
                    />
                  </Stack>
                ) : verifyWhatsapp ? (
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      Verify [{watch("whatsapp")}]
                      {disableWhatsapp === false && (
                        <Button
                          onClick={() => {
                            setVerifyWhatsapp(false);
                            setLoadingWhatsapp(false);
                          }}
                          color="info"
                          size="small"
                        >
                          <EditRoundedIcon fontSize="inherit" />
                        </Button>
                      )}
                    </Typography>
                    <Controller
                      name="whatsappotp"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          placeholder="Enter OTP"
                          value={whatsappotp}
                          error={error && true}
                          helperText={error?.message}
                          onChange={(e) => otpChange(e, "whatsapp")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {whatsappotp?.toString().length === 4 ? (
                                  <Button
                                    size="small"
                                    variant="text"
                                    onClick={(e) =>
                                      ConfirmOTP(
                                        "whatsapp",
                                        watch("whatsapp"),
                                        whatsappotp
                                      )
                                    }
                                  >
                                    Submit
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Stack>
                ) : (
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      WhatsApp
                    </Typography>
                    <Controller
                      name="whatsapp"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={value}
                          error={error && true}
                          helperText={error?.message}
                          disabled={disableWhatsapp}
                          onChange={onChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {!error &&
                                watch("whatsapp") &&
                                !disableWhatsapp ? (
                                  loadingWhatsapp ? (
                                    <LoadingButton
                                      variant="text"
                                      size="small"
                                      loading={loadingWhatsapp}
                                    ></LoadingButton>
                                  ) : (
                                    <Button
                                      variant="text"
                                      size="small"
                                      onClick={() =>
                                        onVerify("whatsapp", value)
                                      }
                                    >
                                      VERIFY
                                    </Button>
                                  )
                                ) : (
                                  ""
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      rules={{
                        required: { value: true, message: "Field Required" },
                        pattern: {
                          value: /^[6-9]\d{9}$/,
                          message: "Enter Correct Mobile Number",
                        },
                      }}
                    />
                  </Stack>
                )}
              </Stack>

              {verifiedEmail ? (
                <Stack>
                  <Typography variant="subtitle2" color="info.error">
                    E-Mail
                  </Typography>
                  <Controller
                    name="email"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={watch("email")}
                        error={error && true}
                        helperText={error?.message}
                        disabled={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <DoneRoundedIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={onChange}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Field Required" },
                      pattern: {
                        value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/,
                        message: "Enter Correct Email ID",
                      },
                    }}
                  />
                </Stack>
              ) : verifyEmail ? (
                <Stack>
                  <Typography variant="subtitle2" color="info.error">
                    Verify [{watch("email")}]
                    {disableEmail === false && (
                      <Button
                        onClick={() => {
                          setVerifyEmail(false);
                          setLoadingEmail(false);
                        }}
                        color="info"
                        size="small"
                      >
                        <EditRoundedIcon fontSize="inherit" />
                      </Button>
                    )}
                  </Typography>
                  <Controller
                    name="emailotp"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Enter OTP"
                        value={emailotp}
                        error={error && true}
                        helperText={error?.message}
                        onChange={(e) => otpChange(e, "email")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {emailotp?.toString().length === 4 ? (
                                <Button
                                  size="small"
                                  variant="text"
                                  onClick={(e) =>
                                    ConfirmOTP(
                                      "email",
                                      watch("email"),
                                      emailotp
                                    )
                                  }
                                >
                                  Submit
                                </Button>
                              ) : (
                                ""
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Stack>
              ) : (
                <Stack>
                  <Typography variant="subtitle2" color="info.error">
                    E-Mail
                  </Typography>
                  <Controller
                    name="email"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={value}
                        error={error && true}
                        helperText={error?.message}
                        disabled={disableWhatsapp}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {!error &&
                              watch("email") &&
                              disableWhatsapp === false ? (
                                loadingEmail ? (
                                  <LoadingButton
                                    variant="text"
                                    size="small"
                                    loading={loadingEmail}
                                  ></LoadingButton>
                                ) : (
                                  <Button
                                    variant="text"
                                    size="small"
                                    onClick={() => onVerify("email", value)}
                                  >
                                    VERIFY
                                  </Button>
                                )
                              ) : (
                                ""
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Field Required" },
                      pattern: {
                        value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/,
                        message: "Enter Correct Email ID",
                      },
                    }}
                  />
                </Stack>
              )}

              {isEligibile.success === true ? (
                <Stack spacing={1} height="15vh" overflow="scroll">
                  {customerDataByUuid.data.mappings.map((data, key) => {
                    if (data.source !== "clitix" && data.status === "active") {
                      return (
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography variant="body1">
                            {`Create fulfilment agent/supplier for ${data.orgCode} in ${data.deptCode} division as ${data.type} for ${data.source}`}
                          </Typography>
                          <Button
                            variant="text"
                            onClick={() => createAgent(data._id)}
                          >
                            Create
                          </Button>
                        </Stack>
                      );
                    }
                  })}
                </Stack>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "unset",
                    borderRadius: "30px",
                    alignSelf: "center",
                    maxWidth: "70%",
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </Button>
              )}
            </Stack>
          </Box>
        )}
      </Modal>
    </>
  );
};

export default FulfilmentAgentMobile;
