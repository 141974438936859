import { CustomerDetails } from "../../domain/models/customer-database/customer-details";
import { ReportFilters } from "../../domain/models/report-filters";
import { pageRoutes } from "../../routes";
import { CustomerDatabaseStore } from "../../store/main/CustomerDatabaseStore";
import CustomerType from "../enums/CustomerType";
import Panels from "../enums/Panels";
import ReportScreen from "../enums/ReportScreens";
import Roles from "../enums/Roles";
import { Departments } from "../enums/department";

export const screenNavigation = (type: string | null) => {
  if (type === ReportScreen.CUSTOMER_DATABASE) {
    return pageRoutes.CustomerDatabase;
  } else if (type === ReportScreen.COVERAGE_REPORT) {
    return pageRoutes.CoverageReport;
  } else if (type === ReportScreen.STANDARD_TOUR_PROGRAM) {
    return pageRoutes.StandardTourProgram;
  } else if (type === ReportScreen.PRIMARY_SALES_HQ_PRODUCT_WISE_SUMMARY) {
    return pageRoutes.PrimarySales;
  } else if (type === ReportScreen.INPUT_UTILIZATION) {
    return pageRoutes.InputUtilizationReport;
  } else if (type === ReportScreen.BRICK_WORKING_REPORT) {
    return pageRoutes.BrickWorkingReport;
  } else if (type === ReportScreen.DAILY_SALES_REPORT) {
    return pageRoutes.DailySalesReport;
  } else if (type === ReportScreen.USER_MANAGEMENT) {
    return pageRoutes.UserManagementReport;
  } else if (type === ReportScreen.DATA_DASHBOARD_REPORT) {
    return pageRoutes.DataDashboardReport;
  } else if (type === ReportScreen.USER_SUMMARY_REPORT) {
    return pageRoutes.UserSummaryReport;
  } else if (type === ReportScreen.BUSINESS_PLANNING) {
    return pageRoutes.BusinessPlanning;
  } else if (type === ReportScreen.CUSTOMER_DETAILING_REPORT) {
    return pageRoutes.CustomerDetailingReport;
  } else if (type === ReportScreen.CUSTOMER_MISSING) {
    return pageRoutes.CustomerMissing;
  } else if (type === ReportScreen.BRICK_MANAGEMENT) {
    return pageRoutes.BrickManagement;
  } else if (type === ReportScreen.PRODUCT_CATLOG) {
    return pageRoutes.ProductCatlog;
  } else if (type === ReportScreen.UNMAPPED_STOCKIST) {
    return pageRoutes.UnmappedStockist;
  } else if (type === ReportScreen.USER_INPUT_PLANNING) {
    return pageRoutes.UserInputPanning;
  } else if (type === ReportScreen.ZONE_MASTER) {
    return pageRoutes.ZoneMasterReport;
  } else if (type === ReportScreen.PRIMARY_SALES_INVOICE) {
    return pageRoutes.PrimarySalesInvoice;
  } else if (type === ReportScreen.HQ_DISTANCE_REPORT) {
    return pageRoutes.HQDistanceReport;
  } else {
    return "";
  }
};

export const CustomizeDepartmentName = (department: string) => {
  if (department === Departments.TORRENT) {
    return "GOAPPTIV_PLATFORM_TEAM";
  } else {
    return department;
  }
};

export const specialityFieldVisible = (type: string) => {
  if (type === ReportScreen.MONTHY_SPECIALITY_SUMMARY) {
    return true;
  }
  return false;
};

export const customerTypeFieldVisible = (type: string) => {
  if (
    type === ReportScreen.BRICK_WORKING_REPORT ||
    type === ReportScreen.USER_INPUT_PLANNING
  ) {
    return true;
  }
  return false;
};
export const inputproductFieldVisible = (type: string) => {
  if (
    type === ReportScreen.INPUT_UTILIZATION ||
    type === ReportScreen.INPUT_UTILIZATION_BATCH_WISE_SUMMARY ||
    type === ReportScreen.INPUT_UTILIZATION_CUSTOMER_WISE_SUMMARY
  ) {
    return true;
  }
  return false;
};
export const productFieldVisible = (type: string) => {
  if (type === ReportScreen.BRICK_WORKING_REPORT) {
    return true;
  }
  return false;
};

export const roleFieldVisible = (type: string) => {
  if (
    type === ReportScreen.INPUT_UTILIZATION ||
    type === ReportScreen.USER_MANAGEMENT ||
    type === ReportScreen.INPUT_UTILIZATION_BATCH_WISE_SUMMARY ||
    type === ReportScreen.INPUT_UTILIZATION_CUSTOMER_WISE_SUMMARY
  ) {
    return true;
  }
  return false;
};

export const searchFieldVisible = (type: string) => {
  if (
    type === ReportScreen.CUSTOMER_DATABASE ||
    type === ReportScreen.MONTHY_CUSTOMER_VISIT_SUMMARY ||
    type === ReportScreen.USER_MANAGEMENT ||
    type === ReportScreen.BRICK_MANAGEMENT ||
    type === ReportScreen.HQ_DISTANCE_REPORT
  ) {
    return true;
  }
  return false;
};

export const templateFieldVisible = (type: string) => {
  if (type === ReportScreen.BUSINESS_PLANNING) {
    return true;
  }
  return false;
};

export const monthYearDateFieldVisible = (type: string) => {
  if (
    type === ReportScreen.MONTHY_SPECIALITY_SUMMARY ||
    type === ReportScreen.MONTHY_HQ_WISE_SUMMARY_REPORT ||
    type === ReportScreen.MONTHY_CUSTOMER_VISIT_SUMMARY ||
    type === ReportScreen.INPUT_UTILIZATION_CUSTOMER_WISE_SUMMARY ||
    type === ReportScreen.STANDARD_TOUR_PROGRAM ||
    type === ReportScreen.PRIMARY_SALES_HQ_PRODUCT_WISE_SUMMARY ||
    type === ReportScreen.USER_SUMMARY_REPORT ||
    type === ReportScreen.BUSINESS_PLANNING ||
    type === ReportScreen.CUSTOMER_DETAILING_REPORT ||
    type === ReportScreen.CUSTOMER_MISSING ||
    type === ReportScreen.USER_INPUT_PLANNING ||
    type === ReportScreen.PRIMARY_SALES_INVOICE
  ) {
    return true;
  }
  return false;
};

export const yearFieldVisible = (type: string) => {
  if (type === ReportScreen.BRICK_WORKING_REPORT) {
    return true;
  }
  return false;
};

export const zoneFieldVisible = (role: string, type: string) => {
  if (
    (type === ReportScreen.CUSTOMER_DATABASE ||
      type === ReportScreen.MONTHY_CUSTOMER_VISIT_SUMMARY ||
      type === ReportScreen.DAILY_SALES_REPORT ||
      type === ReportScreen.USER_MANAGEMENT ||
      type === ReportScreen.BUSINESS_PLANNING ||
      type === ReportScreen.BRICK_MANAGEMENT ||
      type === ReportScreen.ZONE_MASTER ||
      type === ReportScreen.PRIMARY_SALES_INVOICE ||
      type === ReportScreen.USER_INPUT_PLANNING ||
      type === ReportScreen.HQ_DISTANCE_REPORT) &&
    (role === Roles.COUTNRY_MANAGER || role === Roles.ZONE_MANAGER)
  ) {
    return true;
  }
  return false;
};

export const singleSelectZoneFieldVisible = (role: string, type: string) => {
  if (
    (type === ReportScreen.STANDARD_TOUR_PROGRAM ||
      type === ReportScreen.USER_MANAGEMENT) &&
    (role === Roles.COUTNRY_MANAGER || role === Roles.ZONE_MANAGER)
  ) {
    return true;
  }
  return false;
};

export const showCustomerId = (customerId: number, baseUrl: string) => {
  if (baseUrl === "https://gawellnessapi.channelitix.com" && customerId === 5) {
    return true;
  }
  return false;
};

export const zoneFieldDisable = (role: string) => {
  if (role === Roles.ZONE_MANAGER) {
    return true;
  }
  return false;
};

export const regionFieldVisible = (role: string, type: string) => {
  if (
    (type === ReportScreen.CUSTOMER_DATABASE ||
      type === ReportScreen.MONTHY_CUSTOMER_VISIT_SUMMARY ||
      type === ReportScreen.DAILY_SALES_REPORT ||
      type === ReportScreen.USER_MANAGEMENT ||
      type === ReportScreen.BUSINESS_PLANNING ||
      type === ReportScreen.BRICK_MANAGEMENT ||
      type === ReportScreen.ZONE_MASTER ||
      type === ReportScreen.PRIMARY_SALES_INVOICE ||
      type === ReportScreen.USER_INPUT_PLANNING ||
      type === ReportScreen.HQ_DISTANCE_REPORT) &&
    (role === Roles.COUTNRY_MANAGER ||
      role === Roles.ZONE_MANAGER ||
      role === Roles.REGION_MANAGER)
  ) {
    return true;
  }
  return false;
};

export const singleSelectRegionFieldVisible = (role: string, type: string) => {
  if (
    (type === ReportScreen.STANDARD_TOUR_PROGRAM ||
      type === ReportScreen.USER_MANAGEMENT) &&
    (role === Roles.COUTNRY_MANAGER ||
      role === Roles.ZONE_MANAGER ||
      role === Roles.REGION_MANAGER)
  ) {
    return true;
  }
  return false;
};

export const regionFieldDisable = (role: string, zoneArrayLength: number) => {
  if (role === Roles.REGION_MANAGER || zoneArrayLength === 0) {
    return true;
  }
  return false;
};

export const areaFieldVisible = (role: string, type: string) => {
  if (
    (type === ReportScreen.CUSTOMER_DATABASE ||
      type === ReportScreen.MONTHY_CUSTOMER_VISIT_SUMMARY ||
      type === ReportScreen.DAILY_SALES_REPORT ||
      type === ReportScreen.USER_MANAGEMENT ||
      type === ReportScreen.BUSINESS_PLANNING ||
      type === ReportScreen.BRICK_MANAGEMENT ||
      type === ReportScreen.ZONE_MASTER ||
      type === ReportScreen.PRIMARY_SALES_INVOICE ||
      type === ReportScreen.USER_INPUT_PLANNING ||
      type === ReportScreen.HQ_DISTANCE_REPORT) &&
    (role === Roles.COUTNRY_MANAGER ||
      role === Roles.ZONE_MANAGER ||
      role === Roles.REGION_MANAGER ||
      role === Roles.AREA_MANAGER)
  ) {
    return true;
  }
  return false;
};

export const areaFieldDisable = (role: string, regionArrayLength: number) => {
  if (role === Roles.AREA_MANAGER || regionArrayLength === 0) {
    return true;
  }
  return false;
};

export const HQFieldVisible = (role: string, type: string) => {
  if (
    (type === ReportScreen.CUSTOMER_DATABASE ||
      type === ReportScreen.MONTHY_CUSTOMER_VISIT_SUMMARY ||
      type === ReportScreen.DAILY_SALES_REPORT ||
      type === ReportScreen.BUSINESS_PLANNING ||
      type === ReportScreen.BRICK_MANAGEMENT ||
      type === ReportScreen.ZONE_MASTER ||
      type === ReportScreen.PRIMARY_SALES_INVOICE ||
      type === ReportScreen.USER_INPUT_PLANNING ||
      type === ReportScreen.HQ_DISTANCE_REPORT) &&
    (role === Roles.COUTNRY_MANAGER ||
      role === Roles.ZONE_MANAGER ||
      role === Roles.REGION_MANAGER ||
      role === Roles.AREA_MANAGER ||
      role === Roles.HEADQUATER_MANAGER)
  ) {
    return true;
  }
  return false;
};

export const HQFieldDisable = (role: string, areaArrayLength: number) => {
  if (role === Roles.HEADQUATER_MANAGER || areaArrayLength === 0) {
    return true;
  }
  return false;
};

export const BrickFieldVisible = (role: string, type: string) => {
  if (
    (type === ReportScreen.CUSTOMER_DATABASE ||
      type === ReportScreen.MONTHY_CUSTOMER_VISIT_SUMMARY ||
      type === ReportScreen.USER_INPUT_PLANNING) &&
    (role === Roles.COUTNRY_MANAGER ||
      role === Roles.ZONE_MANAGER ||
      role === Roles.REGION_MANAGER ||
      role === Roles.AREA_MANAGER ||
      role === Roles.HEADQUATER_MANAGER)
  ) {
    return true;
  }
  return false;
};

export const BrickFieldDisable = (hqArrayLength: number) => {
  if (hqArrayLength === 0) {
    return true;
  }
  return false;
};
export const segementFieldVisible = (type: string) => {
  const { showDepartmentSegementFilter } = CustomerDatabaseStore();
  if (type === ReportScreen.CUSTOMER_DATABASE && showDepartmentSegementFilter) {
    return true;
  }
  return false;
};
export const segementFieldDisable = (hqArrayLength: number) => {
  if (hqArrayLength === 0) return true;
  return false;
};

export const CheckForStockist = (data: CustomerDetails[]) => {
  const nonStockistData = data.filter(
    (obj) => obj.customer_type.name !== CustomerType.STOCKIST
  );
  return nonStockistData;
};

export const DisableRemoveMappingButton = (
  role: string,
  customerIdsLength: number,
  selectedPanel: boolean
) => {
  if (
    role === Roles.COUTNRY_MANAGER ||
    role === Roles.ZONE_MANAGER ||
    role === Roles.REGION_MANAGER
  ) {
    if (customerIdsLength || selectedPanel) {
      return false;
    } else {
      return true;
    }
  }
  return true;
};

export const DisableTransferButton = (
  role: string,
  customerIdsLength: number,
  selectedPanel: boolean,
  hq_id?: string | string[]
) => {
  if (hq_id) {
    if (
      role === Roles.COUTNRY_MANAGER ||
      role === Roles.ZONE_MANAGER ||
      role === Roles.REGION_MANAGER ||
      role === Roles.AREA_MANAGER
    ) {
      if (customerIdsLength || selectedPanel) {
        return false;
      } else {
        return true;
      }
    }
  }
  return true;
};

export const DisableReactivateButton = (
  role: string,
  customerIdsLength: number,
  selectedPanel: boolean
) => {
  if (
    role === Roles.COUTNRY_MANAGER ||
    role === Roles.ZONE_MANAGER ||
    role === Roles.REGION_MANAGER ||
    role === Roles.AREA_MANAGER
  ) {
    if (customerIdsLength || selectedPanel) {
      return false;
    } else {
      return true;
    }
  }
  return true;
};

export const DisableApproveButton = (
  role: string,
  customerIdsLength: number,
  selectedPanel: boolean
) => {
  if (
    role === Roles.COUTNRY_MANAGER ||
    role === Roles.ZONE_MANAGER ||
    role === Roles.REGION_MANAGER ||
    role === Roles.AREA_MANAGER
  ) {
    if (customerIdsLength || selectedPanel) {
      return false;
    } else {
      return true;
    }
  }
  return true;
};

export const brandsFieldVisible = (type: string) => {
  if (
    type === ReportScreen.DAILY_SALES_REPORT ||
    type === ReportScreen.CUSTOMER_DETAILING_REPORT
  ) {
    return true;
  }
  return false;
};

export const usersFieldVisible = (type: string) => {
  if (type === ReportScreen.USER_INPUT_PLANNING) {
    return true;
  }
  return false;
};

export const departmentFieldVisible = (type: string) => {
  if (
    type === ReportScreen.DAILY_SALES_REPORT ||
    type === ReportScreen.USER_SUMMARY_REPORT ||
    type === ReportScreen.CUSTOMER_DATABASE ||
    type === ReportScreen.PRODUCT_CATLOG
  ) {
    return true;
  }
  return false;
};
export const DisableDeactivateButton = (
  role: string,
  customerIdsLength: number,
  selectedPanel: boolean,
  panelName: string,
  customerType?: string | number,
  buttonAccess?: boolean
) => {
  if (
    (role === Roles.COUTNRY_MANAGER ||
      role === Roles.ZONE_MANAGER ||
      role === Roles.REGION_MANAGER) &&
    panelName !== Panels.ARCHIVED
  ) {
    if (customerIdsLength || selectedPanel) {
      return false;
    } else {
      return true;
    }
  }
  return true;
};

export const DisableSubmitButton = (
  filter: ReportFilters,
  role: string,
  type: string
) => {
  if (type === ReportScreen.CUSTOMER_DATABASE) {
    if (
      role === Roles.COUTNRY_MANAGER &&
      filter.zone.length === 0 &&
      filter.region.length === 0 &&
      filter.area.length === 0 &&
      filter.headquarter.length === 0 &&
      filter.brick.length === 0
    ) {
      return true;
    } else if (
      role === Roles.ZONE_MANAGER &&
      filter.region.length === 0 &&
      filter.area.length === 0 &&
      filter.headquarter.length === 0 &&
      filter.brick.length === 0
    ) {
      return true;
    } else if (
      role === Roles.REGION_MANAGER &&
      filter.area.length === 0 &&
      filter.headquarter.length === 0 &&
      filter.brick.length === 0
    ) {
      return true;
    } else if (
      role === Roles.AREA_MANAGER &&
      filter.headquarter.length === 0 &&
      filter.brick.length === 0
    ) {
      return true;
    } else if (role === Roles.HEADQUATER_MANAGER && filter.brick.length === 0) {
      return true;
    }
    return false;
  } else if (type === ReportScreen.BUSINESS_PLANNING) {
    if (
      role === Roles.COUTNRY_MANAGER &&
      (filter.zone.length === 0 ||
        filter.region.length === 0 ||
        filter.area.length === 0 ||
        filter.headquarter.length === 0 ||
        filter.template.length === 0)
    ) {
      return true;
    } else if (
      role === Roles.ZONE_MANAGER &&
      (filter.region.length === 0 ||
        filter.area.length === 0 ||
        filter.headquarter.length === 0 ||
        filter.template.length === 0)
    ) {
      return true;
    } else if (
      role === Roles.REGION_MANAGER &&
      (filter.area.length === 0 ||
        filter.headquarter.length === 0 ||
        filter.template.length === 0)
    ) {
      return true;
    } else if (
      role === Roles.AREA_MANAGER &&
      (filter.headquarter.length === 0 || filter.template.length === 0)
    ) {
      return true;
    } else if (
      role === Roles.HEADQUATER_MANAGER &&
      filter.template.length === 0
    ) {
      return true;
    }
    return false;
  } else if (
    type === ReportScreen.INPUT_UTILIZATION ||
    type === ReportScreen.INPUT_UTILIZATION_BATCH_WISE_SUMMARY ||
    type === ReportScreen.INPUT_UTILIZATION_CUSTOMER_WISE_SUMMARY
  ) {
    if (filter.inputProducts.length === 0) {
      return true;
    }
  }
  return false;
};

export const StatusFieldVisible = (type: string) => {
  if (type === ReportScreen.USER_MANAGEMENT) {
    return true;
  }
  return false;
};

export const RenderMultiSelectValues = (
  selectedValue: any[],
  options: any[]
) => {
  if (selectedValue.includes("All")) {
    return "All";
  }
  const filteredValue = options
    .filter((obj) => selectedValue.includes(obj.id.toString()))
    .map((obj) => obj.name);

  return filteredValue.join(", ");
};
