import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import RegionTabsLoading from "../RegionTabsLoading";
import Roles from "../../../../common/enums/Roles";
import { UserDetails } from "../../../../domain/models/uset-details";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { StandardProgramTourStore } from "../../../../store/main/StandardTourProgramStore";
import { TerritoryDataStore } from "../../../../store/main/TerritoryDataStore";
import ZoneTabsLoading from "../ZoneTabsLoading";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 1, mt: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type Props = {
  userDetails: UserDetails;
};

const StandardTourProgramTabs: React.FC<Props> = ({
  userDetails = {} as UserDetails,
}) => {
  const {
    zoneMasterData,
    regionMasterData,
    fetchZoneMaster,
    fetchRegionMaster,
  } = TerritoryDataStore();

  const { brickWiseFilters, fetchBrickWiseData } = StandardProgramTourStore();
  const { userRole } = CustomerDatabaseStore();

  const [zone, setZone] = React.useState(0);
  const [region, setRegion] = React.useState("");

  const handleZoneChange = (event: React.SyntheticEvent, newValue: number) => {
    setZone(newValue);
    const filteredZone = zoneMasterData.filter((item) => item.id === newValue);
    brickWiseFilters.zone = filteredZone[0].name;
  };

  const handleSelectedTerritory = (name: string) => {
    if (name == "zone") {
      if (userRole.role == Roles.COUTNRY_MANAGER) {
        fetchRegionMaster([zoneMasterData[0].id]);
        setZone(zoneMasterData[0].id);
        brickWiseFilters.zone = zoneMasterData[0].name;
      } else {
        const filteredZone = zoneMasterData.filter(
          (item) => item.id === userDetails.hq_zone_id
        );
        fetchRegionMaster([filteredZone[0].id.toString()]);
        setZone(filteredZone[0].id);
        brickWiseFilters.zone = filteredZone[0].name;
      }
    } else {
      const filteredRegion = regionMasterData.filter(
        (item) => item.id === userDetails.hq_region_id
      );

      setRegion(
        filteredRegion.length > 0
          ? filteredRegion[0].name
          : regionMasterData[0].name
      );

      brickWiseFilters.region =
        filteredRegion.length > 0
          ? filteredRegion[0].name
          : regionMasterData[0].name;
      fetchBrickWiseData();
    }
  };

  React.useEffect(() => {
    if (regionMasterData.length > 0) {
      handleSelectedTerritory("region");
    } else if (zoneMasterData.length > 0) {
      handleSelectedTerritory("zone");
    }
  }, [zoneMasterData, regionMasterData]);

  const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegion((event.target as HTMLInputElement).value);
    brickWiseFilters.region = (event.target as HTMLInputElement).value;
    fetchBrickWiseData();
  };

  React.useEffect(() => {
    fetchZoneMaster();
  }, []);

  const handleSelectRegionBasedonZone = (id: number) => {
    fetchRegionMaster([id]);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box paddingX={2}>
        {zoneMasterData.length > 0 ? (
          <Tabs
            value={zone ? zone : zoneMasterData[0].id}
            onChange={handleZoneChange}
            aria-label="basic tabs example"
          >
            {zoneMasterData &&
              zoneMasterData.map((zone) => {
                return (
                  <Tab
                    key={zone.id}
                    label={zone.name}
                    value={zone.id}
                    onClick={() => {
                      handleSelectRegionBasedonZone(zone.id);
                    }}
                  />
                );
              })}
          </Tabs>
        ) : (
          <ZoneTabsLoading />
        )}
      </Box>
      {regionMasterData.length > 0 ? (
        <FormControl>
          <RadioGroup
            row
            value={region ? region : regionMasterData[0].name}
            onChange={handleRegionChange}
          >
            {regionMasterData &&
              regionMasterData.map((region) => {
                return (
                  <TabPanel value={0} index={0}>
                    <FormControlLabel
                      value={region.name}
                      control={<Radio />}
                      label={region.name}
                    />
                  </TabPanel>
                );
              })}
          </RadioGroup>
        </FormControl>
      ) : (
        <RegionTabsLoading />
      )}
    </Box>
  );
};
export default StandardTourProgramTabs;
