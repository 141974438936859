import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY, BASE_URL_KEY, AUTH_HEADER } from "../../../base";
import { UpdateBrick } from "../../../domain/useages/brick-management/update-brick";
import { RemoteUpdateBrick } from "../../../data/usecases/brick-management/remote-update-brick";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { BrickManagementStore } from "../../main/BrickManagementStore";
import { FetchState } from "../../../domain/models/fetch-state";

export interface UpdateBrickInterface {
  updateBrick: Function;
  updateBrickState: FetchState;
}

const initialState = { updateBrickState: FetchState.DEFAULT };

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useUpdateBrickSlice: StateCreator<UpdateBrickInterface> = (
  set,
  get
) => ({
  ...initialState,
  updateBrick: async (id: number, payload: UpdateBrick.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    set({ updateBrickState: FetchState.LOADING });

    const remoteUpdateBrick: UpdateBrick = new RemoteUpdateBrick(
      `${BASE_URL}${Endpoints.UPDATE_BRICK}`,
      axiosHttpClient
    );

    let result = await remoteUpdateBrick.update(id, payload);
    if (result.success) {
      Swal.fire({
        icon: "success",
        timer: 3000,
        text: result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
      set({ updateBrickState: FetchState.SUCCESS });
      BrickManagementStore.getState().fetchBrickManagement(
        BrickManagementStore.getState().brickManagementFilter
      );
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: result.errors.message ?? result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
      set({ updateBrickState: FetchState.SUCCESS });
    }
  },
});
