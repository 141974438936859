import TableRow from "@mui/material/TableRow";
import React, { useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Table,
  Checkbox,
  TableBody,
  TableCell,
  TextField,
  Typography,
  Button,
  MenuItem,
  FormControl,
  Select,
  Tooltip,
} from "@mui/material";
import { Stack } from "@mui/system";
import CreateIcon from "@mui/icons-material/Create";
import { useState } from "react";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import EditCustomerModal from "./Modals/EditCustomerModal";
import MapCustomerModal from "./Modals/MapCustomerModal";
import CustomerActionsModal from "./Modals/CustomerActionsModal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CustomerHistory from "./CustomerHistory";
import FulfilmentAgent from "./Modals/FulfilmentAgentModal";
import Modalloading from "./Loading/ModalLoading";
import CloseIcon from "@mui/icons-material/Close";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReactivateCustomerModal from "./Modals/ReactivateCustomerModal";
import TransferCustomerModal from "./Modals/TransferCustomerModal";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import CustomerViewModal from "./Modals/CustomerViewModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonOffSharpIcon from "@mui/icons-material/PersonOffSharp";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import { CustomerDetails } from "../../../../../domain/models/customer-database/customer-details";
import { CustomerDatabaseStore } from "../../../../../store/main/CustomerDatabaseStore";
import { BASE_URL_KEY } from "../../../../../base";
import { Constants } from "../../../../../common/constants";
import Actions from "../../../../../common/enums/Actions";
import CustomerType from "../../../../../common/enums/CustomerType";
import Panels from "../../../../../common/enums/Panels";
import Roles from "../../../../../common/enums/Roles";
import Status from "../../../../../common/enums/Status";
import { showCustomerId } from "../../../../../common/utils/ReportsFilters";
import { CustomerMapping } from "../../../../../domain/models/customer-database/customer-mapping";
import { FetchState } from "../../../../../domain/models/fetch-state";
import { LocalJsonStorage } from "../../../../../infra/http/local-json-storage";
import { CoverageReportsStore } from "../../../../../store/main/CoverageReportsStore";
import { FulfilmentAgentStore } from "../../../../../store/main/FulfilmentAgentStore";
import { TerritoryDataStore } from "../../../../../store/main/TerritoryDataStore";
import ReportScreen from "../../../../../common/enums/ReportScreens";

type Props = {
  type: string;
  row: CustomerDetails;
  selectedCards: number[];
  selectSingleRow: Function;
  panelName: string;
  activityChemistFilter: boolean;
};

const CustomerTableDetails: React.FC<Props> = ({
  type,
  row,
  selectedCards,
  selectSingleRow,
  panelName,
  activityChemistFilter,
}) => {
  const [openSingleDeactivateUser, setOpenSingleDeactivateUser] =
    useState<boolean>(false);
  const [zoneIds, setZoneIds] = useState<number[]>([row.mappings.zone_id]);
  const [regionIds, setRegionIds] = useState<number[]>([
    row.mappings.region_id,
  ]);
  const [areaIds, setAreaIds] = useState<number[]>([row.mappings.area_id]);
  const [hqIds, setHqIds] = useState<number[]>([row.mappings.headquarter_id]);
  const [open, setOpen] = React.useState(false);
  const [openEditCustomerModal, setOpenEditCustomerModal] =
    useState<boolean>(false);
  const [openReactivateCustomerModal, setOpenReactivateCustomerModal] =
    useState<boolean>(false);
  const [openTransferCustomerModal, setOpenTransferCustomerModal] =
    useState<boolean>(false);
  const [openMapCustomerModal, setOpenMapCustomerModal] =
    useState<boolean>(false);
  const [openFulfilmentAgentModal, setOpenFulfilmentAgentModal] =
    useState<boolean>(false);
  const [brickId, setBrickId] = useState<number>();
  const [selectedSpeciality, setSelectedSpeciality] = useState<string>("");
  const [selectSpeciality, setSelectSpeciality] = useState<boolean>(false);
  const [openViewCustomerDetails, setOpenViewCustomerDetails] =
    useState<boolean>(false);

  const {
    customerIds,
    mapCustomerToBrick,
    customerFetchData,
    mapCustomerData,
    userRole,
    fetchCustomerData,
    handleClearSelectedCustomers,
    updateCustomerSpeciality,
    filterStore,
    filterSegement,
    customerData,
  } = CustomerDatabaseStore();

  const {
    fetchRegionMaster,
    fetchAreaMaster,
    fetchHeadquarterMaster,
    fetchBricksMaster,
    bricksMasterData,
    customerTypesData,
  } = TerritoryDataStore();

  const { specialitiesData } = CoverageReportsStore();

  const { customerDatafetchState } = FulfilmentAgentStore();

  const [buttonAccess, setButtonAccess] = useState(false);
  const [isRestricted, setIsRestricted] = useState(false);
  const storage = LocalJsonStorage.getInstance();
  const BASE_URL = storage.get(BASE_URL_KEY);

  useEffect(() => {
    let value = customerTypesData.find(
      (type) => type.id === row?.customer_type.id
    );

    if (value?.button_access == 1) {
      setButtonAccess(true);
    }
    if (value?.is_restricted == 1) {
      setIsRestricted(true);
    }
  }, []);

  const handleOpenReactivateCustomerModal = (id: CustomerMapping) => {
    zoneIds.push(id.zone_id);
    regionIds.push(id.region_id);
    areaIds.push(id.area_id);
    hqIds.push(id.headquarter_id);

    setOpenReactivateCustomerModal(true);
    fetchRegionMaster(zoneIds);
    fetchAreaMaster(regionIds);
    fetchHeadquarterMaster(areaIds);
    fetchBricksMaster(hqIds);
  };

  const handleOpenTransferCustomerModal = (id: CustomerMapping) => {
    zoneIds?.push(id?.zone_id);
    regionIds?.push(id?.region_id);
    areaIds?.push(id?.area_id);
    hqIds?.push(id?.headquarter_id);

    setOpenTransferCustomerModal(true);
    fetchRegionMaster(zoneIds);
    fetchAreaMaster(regionIds);
    fetchHeadquarterMaster(areaIds);
    fetchBricksMaster(hqIds);
  };

  const handleCloseReactivateCustomerModal = () => {
    setOpenReactivateCustomerModal(false);
  };
  const handleCloseTransferCustomerModal = () => {
    setOpenTransferCustomerModal(false);
  };
  const handleCloseViewCustomerDetails = () => {
    setOpenViewCustomerDetails(false);
  };
  const handleCloseEditCustomerModal = () => {
    setOpenEditCustomerModal(false);
  };

  const mapCustomer = (name: string) => {
    mapCustomerData.q = name;
    mapCustomerData.type = row.customer_type.name.toLowerCase();
    customerFetchData(name);
    setOpenMapCustomerModal(!openMapCustomerModal);
  };

  const disableActionIndividual = () => {
    if (filterSegement.length != 0 && filterSegement[0] == "activity_chemist") {
      if (
        (row.customer_type_id === 3 || row.customer_type_id === 4) &&
        row.customer_metadata?.length !== 0
      ) {
        return row.customer_metadata[0]?.metadata?.activity_chemist === 1;
      }
      return false;
    }
    return false;
  };
  const handleModal = (modalName: string) => {
    if (!disableActionIndividual()) {
      if (modalName === Actions.DEACTIVATE_CUSTOMER) {
        customerIds.splice(0, customerIds.length);
        customerIds.push(row.id);
        setOpenSingleDeactivateUser(!openSingleDeactivateUser);
      }
      if (
        modalName === Actions.VIEW_CUSTOMER_DETAILS ||
        modalName === Actions.REJECT_CUSTOMER
      ) {
        setOpenViewCustomerDetails(!openViewCustomerDetails);
      }
    }
  };

  const closeFulfilmentAgent = () => {
    setOpenFulfilmentAgentModal(false);
  };

  const OnMapCustomer = (event: any) => {
    setBrickId(event.target.value);
    if (event.target.value) {
      let payload = {
        customer_id: row.id,
        brick_id: event.target.value,
      };
      mapCustomerToBrick(
        payload,
        fetchCustomerData,
        handleClearSelectedCustomers
      );
    }
  };
  const isSelected = () => {
    if (
      (row.customer_type_id == 3 || row.customer_type_id == 4) &&
      filterSegement[0] == "activity_chemist" &&
      selectedCards.length != 0
    ) {
      const arr = customerData.find(
        (data: CustomerDetails) => data.id === selectedCards[0]
      );
      if (
        arr?.customer_metadata[0]?.metadata.activity_chemist ===
          row?.customer_metadata[0]?.metadata.activity_chemist ||
        ((arr?.customer_metadata[0]?.metadata.activity_chemist === 0 ||
          arr?.customer_metadata.length === 0) &&
          (row?.customer_metadata.length === 0 ||
            row?.customer_metadata[0]?.metadata.activity_chemist === 0))
      )
        return false;
      return true;
    }
  };

  useEffect(() => {
    setSelectedSpeciality(row.speciality);
  }, []);

  const handleSelectSpeciality = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedSpeciality(e.target.value);

    let payload = {
      customer_id: row.id,
      speciality: e.target.value,
    };
    updateCustomerSpeciality(
      payload,
      fetchCustomerData,
      handleClearSelectedCustomers
    );
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "0.5px solid lightgrey" },
          bgcolor:
            row.status === "active"
              ? row.no_of_patient === "Below 20"
                ? "#FEF7DD"
                : row.mapping_status === Status.APPROVED &&
                  row.approved_status === Status.APPROVED
                ? "error.light"
                : "error.dark"
              : row.status === Status.PENDING
              ? "#FFF"
              : "#e8ebe8",
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            sx={{ borderRadius: "15px" }}
            color="primary"
            disabled={
              row.customer_type.name === CustomerType.STOCKIST ||
              userRole.role == Roles.HEADQUATER_MANAGER ||
              isSelected()
            }
            checked={selectedCards.includes(row.id)}
            onChange={(e) => selectSingleRow(e.target.checked, row.id)}
          />
        </TableCell>

        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <ArrowCircleDownOutlinedIcon />
            ) : (
              <ArrowCircleRightOutlinedIcon />
            )}
          </IconButton>
        </TableCell>

        <TableCell align="center" width="10%">
          <Typography color="secondary.light" variant="caption">
            {showCustomerId(row.customer_type_id, BASE_URL)
              ? row.id
              : row.code !== "N/A" && row.code
              ? row.code
              : "-"}
          </Typography>
        </TableCell>

        <TableCell align="center" width="20%">
          <Typography color="secondary.light" variant="caption">
            {row.firm_name}
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Typography color="secondary.light" variant="caption">
            {row.mobile
              ? "xxxxxx" + row.mobile.slice(row.mobile.length - 4)
              : "-"}
          </Typography>
        </TableCell>

        <TableCell align="center" width="15%">
          <Typography
            color="white"
            fontSize={11}
            paddingY={0.5}
            paddingX={1}
            sx={{
              backgroundColor:
                row.customer_type.name === CustomerType.STOCKIST
                  ? "info.main"
                  : row.customer_type.name === CustomerType.SEMI_STOCKIST
                  ? "info.dark"
                  : row.customer_type.name === CustomerType.RETAILERS
                  ? "info.light"
                  : row.customer_type.name === CustomerType.SURGICAL_SEMIS
                  ? "info.contrastText"
                  : "info.dark",
              borderRadius: "8px",
            }}
          >
            {row.customer_type.name}
          </Typography>
        </TableCell>

        <TableCell align="center" width="20%">
          <Stack direction={"row"} justifyContent={"center"}>
            {row.mappings.brick_id ? (
              <Typography fontSize={11}>{row.mappings.brick}</Typography>
            ) : (
              <FormControl sx={{ width: "50%" }}>
                <Select
                  value={brickId}
                  size="small"
                  variant="standard"
                  onChange={OnMapCustomer}
                  onMouseEnter={() =>
                    row.mappings.headquarter_id
                      ? fetchBricksMaster([row.mappings.headquarter_id])
                      : ""
                  }
                >
                  {bricksMasterData.length > 0 &&
                    bricksMasterData.map((data) => (
                      <MenuItem value={data.id} key={data.id}>
                        <Typography
                          color="secondary.light"
                          variant="caption"
                          onClick={OnMapCustomer}
                        >
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            /{" "}
            {row.mappings.headquarter_id ? (
              <Typography fontSize={11}>{row.mappings.headquarter}</Typography>
            ) : (
              "-"
            )}
          </Stack>
        </TableCell>

        <TableCell align="center" width="10%">
          {row.address &&
          (row.address.line || row.address.landmark || row.address.pincode) ? (
            <Stack direction="row" justifyContent="center">
              <Typography color="secondary.light" variant="caption">
                {row.address.line ? row.address.line + " " : " "}
                {row.address.landmark ? row.address.landmark + " " : " "}
                {row.address.pincode ? row.address.pincode : " "}
              </Typography>
            </Stack>
          ) : (
            "-"
          )}
        </TableCell>
        {type === ReportScreen.CUSTOMER_DATABASE && (
          <>
            <TableCell align="center" width="10%">
              {row.customer_type.name === CustomerType.HEALTH_CARE_PROVIDERS ? (
                selectSpeciality || row.speciality == null ? (
                  <Stack direction={"row"} alignItems={"center"}>
                    <FormControl fullWidth>
                      <TextField
                        select
                        value={selectedSpeciality}
                        fullWidth
                        variant="standard"
                        label={
                          !selectSpeciality ? (
                            <Typography variant="caption" fontSize={11}>
                              select speciality
                            </Typography>
                          ) : (
                            ""
                          )
                        }
                        onChange={(e) => handleSelectSpeciality(e)}
                      >
                        {specialitiesData.length > 0 &&
                          specialitiesData.map((data) => (
                            <MenuItem value={data.name} key={data.id}>
                              <Typography
                                color="secondary.light"
                                variant="caption"
                              >
                                {data.name}
                              </Typography>
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                    {row.speciality && (
                      <CloseIcon
                        fontSize="small"
                        color="error"
                        sx={{ cursor: "pointer" }}
                        onClick={() => setSelectSpeciality(false)}
                      />
                    )}
                  </Stack>
                ) : (
                  <Tooltip title="Click to edit">
                    <Typography
                      sx={{ cursor: "pointer" }}
                      color="secondary.light"
                      variant="caption"
                      onClick={() => setSelectSpeciality(true)}
                    >
                      {row.speciality}
                    </Typography>
                  </Tooltip>
                )
              ) : (
                "-"
              )}
            </TableCell>
            {filterSegement?.length != 0 &&
              filterSegement?.map((segement: string) => {
                if (segement === "activity_chemist") {
                  return (
                    <TableCell align="center">
                      {(row && row.customer_type.id === 3) ||
                      row.customer_type.id === 4 ? (
                        row.customer_metadata &&
                        row.customer_metadata.length == 0 ? (
                          <Tooltip title={"Not an Activity Chemist"}>
                            <PersonOffSharpIcon color="error" />
                          </Tooltip>
                        ) : row.customer_metadata[0].metadata
                            ?.activity_chemist == 1 ? (
                          <Tooltip title={"Activity Chemist"}>
                            <PersonSharpIcon color="primary" />
                          </Tooltip>
                        ) : (
                          <Tooltip title={"Not an Activity Chemist"}>
                            <PersonOffSharpIcon color="error" />
                          </Tooltip>
                        )
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  );
                }
              })}
            <TableCell align="center" width="5%">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {userRole.role !== Roles.HEADQUATER_MANAGER &&
                  userRole.role !== Roles.AREA_MANAGER &&
                  panelName === Panels.ALL && (
                    <Box borderRadius="5px">
                      <Button disabled={true || disableActionIndividual()}>
                        <CreateIcon color="disabled" />
                      </Button>
                    </Box>
                  )}

                {userRole.role !== Roles.HEADQUATER_MANAGER &&
                  (panelName === Panels.ALL ||
                    panelName === Panels.PENDING_MAPPING) && (
                    <Tooltip
                      title={
                        filterStore?.headquarter_ids?.length === 0
                          ? "Please select headquarter filter to enable."
                          : "Click to Deactivate Customer"
                      }
                    >
                      <Box borderRadius="5px">
                        <Button
                          onClick={() =>
                            handleModal(Actions.DEACTIVATE_CUSTOMER)
                          }
                          disabled={
                            buttonAccess == false ||
                            filterStore?.headquarter_ids?.length === 0 ||
                            disableActionIndividual()
                          }
                        >
                          <HighlightOffIcon
                            color={
                              !(
                                buttonAccess == false ||
                                filterStore?.headquarter_ids?.length === 0 ||
                                disableActionIndividual()
                              )
                                ? "error"
                                : "disabled"
                            }
                          />
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
                {userRole.role != Roles.HEADQUATER_MANAGER &&
                  row.customer_type.name !== CustomerType.STOCKIST &&
                  panelName === Panels.ARCHIVED && (
                    <Tooltip title={"Click to reactivate"}>
                      <Box borderRadius="5px">
                        <Button
                          onClick={() =>
                            handleOpenReactivateCustomerModal(row.mappings)
                          }
                          disabled={
                            ((row.mapping_status === Status.PENDING ||
                              row.approved_status === Status.PENDING ||
                              row.customer_type.name ==
                                CustomerType.STOCKIST) &&
                              disableActionIndividual()) ||
                            isRestricted
                          }
                        >
                          <ManageAccountsIcon
                            color={
                              ((row.mapping_status === Status.PENDING ||
                                row.approved_status === Status.PENDING ||
                                row.customer_type.name ==
                                  CustomerType.STOCKIST) &&
                                disableActionIndividual()) ||
                              isRestricted
                                ? "disabled"
                                : "primary"
                            }
                          />
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
                {userRole.role != Roles.HEADQUATER_MANAGER &&
                  row.customer_type.name !== CustomerType.STOCKIST &&
                  (panelName === Panels.ALL ||
                    panelName === Panels.PENDING_MAPPING) && (
                    <Tooltip
                      title={
                        filterStore?.headquarter_ids?.length === 0
                          ? "Please select headquarter filter to enable."
                          : "Click to transfer customer"
                      }
                    >
                      <Box borderRadius="5px">
                        <Button
                          onClick={() =>
                            handleOpenTransferCustomerModal(row.mappings)
                          }
                          disabled={
                            row.customer_type.name == CustomerType.STOCKIST ||
                            buttonAccess == false ||
                            filterStore?.headquarter_ids?.length === 0 ||
                            disableActionIndividual()
                          }
                        >
                          <TransferWithinAStationIcon
                            color={
                              row.customer_type.name == CustomerType.STOCKIST ||
                              buttonAccess == false ||
                              filterStore?.headquarter_ids?.length === 0 ||
                              disableActionIndividual()
                                ? "disabled"
                                : "primary"
                            }
                          />
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
                {userRole.role != Roles.HEADQUATER_MANAGER &&
                  row.customer_type.name !== CustomerType.STOCKIST &&
                  panelName === Panels.PENDING_APPROVAL && (
                    <Tooltip title={"View Customer Details"}>
                      <Box borderRadius="5px">
                        <Button
                          onClick={() => handleModal(Actions.REJECT_CUSTOMER)}
                          disabled={
                            row.customer_type.name == CustomerType.STOCKIST &&
                            disableActionIndividual()
                          }
                        >
                          <InfoOutlinedIcon
                            color={
                              row.customer_type.name == CustomerType.STOCKIST &&
                              disableActionIndividual()
                                ? "disabled"
                                : "primary"
                            }
                          />
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
              </Stack>
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} height={200} overflow={"scroll"}>
              <Table size="small">
                <TableBody>
                  <CustomerHistory row={row} />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <EditCustomerModal
        data={row}
        open={openEditCustomerModal}
        handleClose={handleCloseEditCustomerModal}
      />
      {openReactivateCustomerModal && (
        <ReactivateCustomerModal
          data={row}
          title={"Reactivate"}
          open={openReactivateCustomerModal}
          customerID={[row.id]}
          handleClose={handleCloseReactivateCustomerModal}
          reasonOptions={Constants.REACTIVATE_REASONS}
        />
      )}
      {openTransferCustomerModal && (
        <TransferCustomerModal
          data={row}
          title={"Transfer"}
          open={openTransferCustomerModal}
          customerID={[row.id]}
          handleClose={handleCloseTransferCustomerModal}
          reasonOptions={Constants.TRANSFER_REASONS}
        />
      )}

      <MapCustomerModal open={openMapCustomerModal} handleClose={mapCustomer} />
      {customerDatafetchState === FetchState.LOADING && <Modalloading />}
      {customerDatafetchState === FetchState.SUCCESS && (
        <FulfilmentAgent
          open={openFulfilmentAgentModal}
          handleClose={closeFulfilmentAgent}
          data={row}
        />
      )}
      <CustomerActionsModal
        open={openSingleDeactivateUser}
        handleClose={handleModal}
        customer_id={row.id}
        title={Actions.DEACTIVATE_CUSTOMER}
        button="Deactivate"
        reasonOptions={Constants.DEACTIVATE_REASONS}
      />
      {openViewCustomerDetails && (
        <CustomerViewModal
          customer={row}
          title={Actions.REJECT_CUSTOMER}
          open={openViewCustomerDetails}
          handleClose={handleCloseViewCustomerDetails}
        />
      )}
    </React.Fragment>
  );
};
export default CustomerTableDetails;
