import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ReportFiltersComponent from "../ReportFiltersComponent";
import { PrimarySalesInvoiceStore } from "../../../../store/main/PrimarySalesInvoiceStore";
import { ProductWiseFilters } from "../../../../domain/models/primary-sales-invoice/product-wise-filters";
import PivotTable from "../../PivotTable";
import { FetchState } from "../../../../domain/models/fetch-state";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import Roles from "../../../../common/enums/Roles";

const ProductWise = () => {
  const {
    productWiseData,
    fetchProductWiseData,
    productWiseDataLoading,
    productWiseFilters,
  } = PrimarySalesInvoiceStore();

  const { userDetails } = CustomerDatabaseStore();

  const getProductWiseData = (filters: ProductWiseFilters) => {
    productWiseFilters.monthYear = filters.date;
    fetchProductWiseData();
  };

  useEffect(() => {
    if (productWiseData.length > 0) {
      productWiseData.map((val, key) => {
        var rowData = {
          index: key + 1,
          product: val.product.name ? val.product.name : "-",
          code: val.prd_code ? val.prd_code : "-",
          quantity: val.total_qty,
          total_amount: val.total_net_amount,
        };
        formattedData.data.push(rowData);
      });
    }
  }, [productWiseData]);

  const formattedData: any = {
    data: [],
    tableSizes: {
      columns: [
        {
          idx: 0,
          width: 367,
        },
        {
          idx: 1,
          width: 350,
        },
      ],
    },
    rows: [
      { uniqueName: "product", caption: "Product" },
      { uniqueName: "code", caption: "Code" },
    ],
    columns: [{ uniqueName: "Measures" }],
    measures: [
      { uniqueName: "quantity", caption: "Quantity", format: "precision" },
      {
        uniqueName: "total_amount",
        caption: "Total Amount (₹)",
        format: "separator",
      },
    ],
    expands: {
      expandAll: "true",
    },
    options: {
      grid: {
        type: "classic",
        showHeaders: false,
        showTotals: false,
        showHierarchyCaptions: true,
      },
      showCalculatedValuesBottom: false,
      sorting: "on",
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
      defaultHierarchySortName: "asc",
    },
    formats: [
      {
        name: "precision",
        decimalPlaces: 2,
      },
      {
        name: "seperator",
        thousandsplace: 3,
      },
    ],
  };

  const stringToNum = (str: string) => {
    return str.replace(/\D/g, "");
  };

  const calculatePerformance: any = (sales: string, target: string) => {
    let tempSales = sales.replace(/\D/g, "");
    let tempTarget = target.replace(/\D/g, "");
    return (parseInt(tempSales) / parseInt(tempTarget)) * 100;
  };

  const customizeToolbar = (toolbar: any) => {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      userDetails &&
        userDetails.role &&
        userDetails.role.visibility == 0 &&
        delete tabs[3];
      return tabs;
    };
  };
  return (
    <Stack spacing={2}>
      {/* <ReportFiltersComponent
        type={ReportScreen.PRODUCT_WISE}
        successCallback={getProductWiseData}
        gridColumns={12}
      /> */}
      {productWiseDataLoading == FetchState.LOADING && (
        <Stack alignItems={"center"} height={100} justifyContent={"center"}>
          <CircularProgress />
        </Stack>
      )}
      {productWiseDataLoading == FetchState.SUCCESS &&
        (productWiseData.length > 0 ? (
          <PivotTable
            formattedData={formattedData}
            toolbar={true}
            customizeToolbar={customizeToolbar}
          />
        ) : (
          <Typography variant="h6">No Records Found</Typography>
        ))}
    </Stack>
  );
};

export default ProductWise;
