import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteUpdateUserInputPlanning } from "../../../data/usecases/user-input-planning/remote-update-user-input-planning";
import { FetchState } from "../../../domain/models/fetch-state";
import Swal from "sweetalert2";
import { UpdateUserInputPlanning } from "../../../domain/useages/user-input-planning/update-user-input-planning";

export interface UpdateUserInputPlanningInterface {
  updateUserInputPlanning: Function;
  updateResponse: Boolean;
  updateStatus: FetchState;
}

const initialStates = {
  updateResponse: false,
  updateStatus: FetchState.DEFAULT,
};

export const useUpdateUserInputPlanningSlice: StateCreator<
  UpdateUserInputPlanningInterface
> = (set) => ({
  ...initialStates,
  updateUserInputPlanning: async (data: any) => {
    set(() => ({ updateStatus: FetchState.LOADING }));

    const axiosHttpClient = AxiosHttpClient.getInstance();
    const storage = LocalJsonStorage.getInstance();

    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteUpdateUserInputPlanning = new RemoteUpdateUserInputPlanning(
      `${BASE_URL}${Endpoints.USER_INPUT_PLANNING}`,
      axiosHttpClient
    );

    let payload = {} as UpdateUserInputPlanning.Params;
    if (data.user_id !== "") {
      payload = {
        customer_id: data.customer_id,
        input_id: data.input_id,
        quantity: data.quantity,
        month_year: data.month_year,
        user_id: data.user_id,
      };
    } else {
      payload = {
        customer_id: data.customer_id,
        input_id: data.input_id,
        quantity: data.quantity,
        month_year: data.month_year,
      };
    }

    let result = await remoteUpdateUserInputPlanning.update(payload);
    if (result.success) {
      set(() => ({ updateResponse: true }));
      set(() => ({ updateStatus: FetchState.SUCCESS }));
      return true;
    } else {
      set(() => ({ updateResponse: false }));
      set(() => ({ updateStatus: FetchState.ERROR }));
      Swal.fire({
        icon: "error",
        text: result.errors.message
          ? result.errors.message
          : result.errors.month_year[0],
        confirmButtonColor: "#0D71B7",
        customClass: {
          container: "swal2-style",
        },
      });
      return false;
    }
  },
});
