import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { CustomerDatabaseStore } from "../../../../../../store/main/CustomerDatabaseStore";
import { RemoveMapping } from "../../../../../../domain/models/remove-mapping";
import { Controller, useForm } from "react-hook-form";
import Actions from "../../../../../../common/enums/Actions";
import { MobileModalStyle } from "../../../../../../common/constants/custom-style";

interface Props {
  open: boolean;
  handleClose: Function;
  title: string;
  button: string;
  customer_id?: number;
  reasonOptions: string[];
}

const BulkApprovalAndRejectCustomerModalMobile: React.FC<Props> = ({
  open,
  handleClose,
  title,
  button,
  reasonOptions,
}) => {
  const {
    customerIds,
    rejectCustomer,
    approveCustomer,
    fetchCustomerData,
    fetchCustomerSummaryCount,
    handleClearSelectedCustomers,
  } = CustomerDatabaseStore();

  const { handleSubmit, control, setValue, watch } = useForm<RemoveMapping>({
    mode: "onChange",
  });

  const style = {
    position: "absolute" as "absolute",
    top: "5%",
    right: "1%",
    cursor: "pointer",
    ":hover": { backgroundColor: "transparent" },
  };
  const RejectCustomer = (data: RemoveMapping) => {
    let payload = {
      customer_ids: customerIds,
      reason: data.reasonSelect == "Other" ? data.reason : data.reasonSelect,
    };

    rejectCustomer(
      payload,
      handleClose,
      title,
      fetchCustomerData,
      handleClearSelectedCustomers,
      fetchCustomerSummaryCount
    );
  };

  const ApproveCustomer = (data: RemoveMapping) => {
    let payload = {
      customer_ids: customerIds,
      reason: data.reasonSelect == "Other" ? data.reason : data.reasonSelect,
    };
    approveCustomer(
      payload,
      handleClose,
      title,
      fetchCustomerData,
      handleClearSelectedCustomers,
      fetchCustomerSummaryCount
    );
  };

  useEffect(() => {
    setValue("reason", "");
    setValue("reasonSelect", "");
  }, [open]);

  return (
    <>
      <Modal open={open}>
        <Box sx={MobileModalStyle}>
          <Stack gap={2}>
            <Typography
              textAlign="center"
              variant="h6"
              fontFamily="Segoe UI"
              fontWeight={600}
              color="#4D4D4D"
            >
              {title}
            </Typography>

            <Typography fontFamily="Segoe UI" fontWeight={600} color="#4D4D4D">
              Select Reason to {title}
            </Typography>
            <Button
              endIcon={<CloseRoundedIcon color="secondary" />}
              sx={style}
              onClick={() => handleClose(Actions.BULK_APPROVE_REJECT_CUSTOMER)}
            />

            <Controller
              name="reasonSelect"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth size="small">
                  <InputLabel>Reason</InputLabel>
                  <Select
                    value={value}
                    size="small"
                    label="Reason"
                    onChange={onChange}
                  >
                    {reasonOptions?.map((name) => (
                      <MenuItem value={name}>{name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              rules={{
                required: { value: true, message: "There must be a Reason" },
              }}
            />
            {watch("reasonSelect") == "Other" && (
              <Controller
                name="reason"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    value={value}
                    size="small"
                    onChange={onChange}
                    placeholder="Please type reason..."
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  pattern: {
                    value: /^(?!.*[^a-zA-Z0-9 ]).{5,}$/,
                    message:
                      "Enter valid reason.  No special characters and minimum 5 characters",
                  },
                }}
              />
            )}

            <Stack direction="row" gap={3} justifyContent="center">
              <Button
                disabled={
                  !(
                    (watch("reasonSelect") &&
                      watch("reasonSelect") != "Other") ||
                    (watch("reasonSelect") == "Other" &&
                      watch("reason") &&
                      watch("reason").length > 4)
                  )
                }
                variant="contained"
                color="success"
                sx={{
                  textTransform: "unset",
                  borderRadius: "30px",
                  height: "40px",
                  minWidth: "30%",
                }}
                onClick={handleSubmit(ApproveCustomer)}
              >
                <Typography
                  variant="subtitle2"
                  fontFamily="Segoe UI"
                  color="white"
                >
                  Approve
                </Typography>
              </Button>
              <Button
                disabled={
                  !(
                    (watch("reasonSelect") &&
                      watch("reasonSelect") != "Other") ||
                    (watch("reasonSelect") == "Other" &&
                      watch("reason") &&
                      watch("reason").length > 4)
                  )
                }
                variant="contained"
                color="error"
                sx={{
                  textTransform: "unset",
                  borderRadius: "30px",
                  height: "40px",
                  bgcolor: "#EF5466",
                  minWidth: "30%",
                }}
                onClick={handleSubmit(RejectCustomer)}
              >
                <Typography
                  variant="subtitle2"
                  fontFamily="Segoe UI"
                  color="white"
                >
                  Reject
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default BulkApprovalAndRejectCustomerModalMobile;
