import { Box, Stack, Typography } from "@mui/material";

const SkeletonModalStyle = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  borderRadius: "22px",
  p: 4,
  border: "solid 1px black",
};

const ModalloadingMobile = () => {
  return (
    <Box sx={SkeletonModalStyle}>
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Typography variant="h6" fontWeight={500}>
          Please Wait...
        </Typography>
        <Typography variant="subtitle1">The Data is Loading</Typography>
      </Stack>
    </Box>
  );
};

export default ModalloadingMobile;
