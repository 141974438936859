import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { SortedDataType } from "../../../../../domain/models/bricks-working-report/sorted-data-type";
import { MobileModalStyle } from "../../../../../common/constants/custom-style";

type Props = {
  openFilter: boolean;
  handleFilter: Function;
  uniqueList: Function;
  handleSelectedOptions: Function;
  filteredArray: SortedDataType;
};

export const HeadquarterFilterMobileModal: React.FC<Props> = ({
  openFilter,
  handleFilter,
  uniqueList,
  handleSelectedOptions,
  filteredArray,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [searchedData, setSearchedData] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

  const handleChange = (value: string) => () => {
    setSelectedOptions((prevState) => {
      if (prevState?.includes(value)) {
        return prevState.filter((item) => item !== value);
      } else {
        return [...prevState, value];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedOptions?.length === sortedItems()?.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(sortedItems());
    }
  };

  const handleSearchFilter = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchedData(e.target.value.toLowerCase());
      const filtered = uniqueList("hq").filter((item: any) =>
        item.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredItems(filtered);
    },
    2000
  );

  const sortedItems = () => {
    return searchedData.length == 0
      ? [...uniqueList("hq")]
      : [...filteredItems];
  };
  if (searchedData !== "") {
    const searchedItemIndex = sortedItems().findIndex(
      (item) => item.toLowerCase() === searchedData
    );
    if (searchedItemIndex !== -1) {
      const searchedItem = sortedItems().splice(searchedItemIndex, 1);
      sortedItems().unshift(searchedItem[0]);
    }
  }

  useEffect(() => {
    setSearchedData("");
    let matchedValues: any[] = [];
    matchedValues = filteredArray.map((item) => item.headquarter);
    const matchedValuesSet = new Set(matchedValues);
    setSelectedOptions(Array.from(matchedValuesSet));
  }, [openFilter]);

  useEffect(() => {
    setSelectedOptions(sortedItems());
  }, [searchedData]);

  return (
    <Modal open={openFilter} onClose={() => handleFilter("Headquarter", false)}>
      <Box sx={MobileModalStyle}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={3}
        >
          <Typography fontWeight={550} variant="h6">
            Select Headquater
          </Typography>
          <Stack direction={"row"} gap={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleSelectedOptions(selectedOptions, "Headquarter");
              }}
            >
              Apply
            </Button>
            <Button
              onClick={() => {
                handleFilter("Headquarter", false);
              }}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Stack>
        </Stack>
        <TextField
          fullWidth
          placeholder="Search"
          sx={{ mb: 2 }}
          size="small"
          onChange={(e) => {
            handleSearchFilter(e);
          }}
          InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
        />
        <Box maxHeight={"60vh"} overflow={"scroll"}>
          {sortedItems().length !== 0 && (
            <FormControlLabel
              sx={{ mb: 1 }}
              control={
                <Checkbox
                  checked={selectedOptions?.length === sortedItems()?.length}
                  onChange={() => handleSelectAll()}
                  color="primary"
                />
              }
              label="Select All"
            />
          )}
          <FormGroup>
            {sortedItems().length == 0 ? (
              <Typography variant="h6">No headquarter found !</Typography>
            ) : (
              sortedItems()?.map((row: any) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOptions?.includes(row)}
                      onChange={handleChange(row)}
                    />
                  }
                  label={row}
                />
              ))
            )}
          </FormGroup>
        </Box>
      </Box>
    </Modal>
  );
};
