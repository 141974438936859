import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { RemoteFetchAreaMaster } from "../../../data/usecases/remote-fetch-areas";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { TerritoryData } from "../../../domain/models/territory-data";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";

export interface FetchAreaMasterInterface {
  areaMasterData: TerritoryData[];
  fetchAreaMaster: Function;
  clearAreaMaster: Function;
}

const initialStates = {
  areaMasterData: [],
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchAreaMasterSlice: StateCreator<FetchAreaMasterInterface> = (
  set
) => ({
  ...initialStates,
  fetchAreaMaster: async (id: string) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchAreaMasterv = new RemoteFetchAreaMaster(
      `${BASE_URL}${Endpoints.FETCH_AREA_MASTER}`,
      axiosHttpClient
    );
    const payload = { region_ids: id };
    set(() => ({ areaMasterData: [] }));
    let result = await remoteFetchAreaMasterv.fetch(payload);
    if (result) {
      set(() => ({ areaMasterData: result.areas }));
    }
  },
  clearAreaMaster: () => {
    set(() => ({ areaMasterData: [] }));
  },
});
