import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { RemoteFetchCustomerTypes } from "../../data/usecases/remote-fetch-customer-types";
import { FetchCustomerTypes } from "../../domain/useages/fetch-customer-types";
import Endpoints from "../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { CustomerType } from "../../domain/models/customer-type";

export interface FetchCustomerTypesInterface {
  customerTypesData: CustomerType[];
  fetchCustomerTypes: Function;
}

const initialStates = {
  customerTypesData: [],
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchCustomerTypesSlice: StateCreator<
  FetchCustomerTypesInterface
> = (set, get) => ({
  ...initialStates,
  fetchCustomerTypes: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchCustomerTypes: FetchCustomerTypes =
      new RemoteFetchCustomerTypes(
        `${BASE_URL}${Endpoints.FETCH_CUSTOMER_TYPES}`,
        axiosHttpClient
      );

    set(() => ({ customerTypesData: [] }));

    let result = await remoteFetchCustomerTypes.fetch();
    if (result.success) {
      set(() => ({ customerTypesData: result.customerTypes }));
    }
  },
});
