import { Card, Stack, Typography } from "@mui/material";
import {
  MonthSummaryReportType,
  VisitType,
} from "../../../../../domain/models/user-summary-report/month-summary-report";
import { UserSummaryReportStore } from "../../../../../store/main/UserSummaryReportStore";

const AdditionInformation = () => {
  const { monthSummaryReportData } = UserSummaryReportStore();

  const totalFieldWoking = monthSummaryReportData?.attendances?.find(
    (item: any) => item.work_type_id === 2
  )?.att_count;

  const leaveCount =
    monthSummaryReportData?.leave_counts == null
      ? 0
      : monthSummaryReportData?.leave_counts[0]?.att_count;

  const sumPOBOrderDayTotalCount = (data: MonthSummaryReportType): number => {
    return data?.orders?.reduce((sum, order) => {
      const orderCount: number = parseFloat(order.order_day_total_count);
      return isNaN(orderCount) ? sum : sum + orderCount;
    }, 0);
  };
  const sumPOBCSMOrderDayTotalCount = (
    data: MonthSummaryReportType
  ): number => {
    return data?.orders_csm?.reduce((sum, order) => {
      const orderCount: number = parseFloat(order.order_total_count_for_csm);
      return isNaN(orderCount) ? sum : sum + orderCount;
    }, 0);
  };
  const calculateSumOfAllVisitCount = (
    data: MonthSummaryReportType
  ): number => {
    const visits: VisitType[] = data?.visits || [];
    let sum: number = 0;
    for (const visit of visits) {
      sum += visit.visit_count || 0;
    }
    return sum;
  };

  const sumOfAllVisitCount = calculateSumOfAllVisitCount(
    monthSummaryReportData
  );
  const totalPOB =
    isNaN(sumPOBOrderDayTotalCount(monthSummaryReportData)) ||
    sumPOBOrderDayTotalCount(monthSummaryReportData) === 0
      ? 0
      : (sumPOBOrderDayTotalCount(monthSummaryReportData) / 1000)?.toFixed(1) +
        "K";
  const totalJointPOB =
    isNaN(sumPOBCSMOrderDayTotalCount(monthSummaryReportData)) ||
    sumPOBCSMOrderDayTotalCount(monthSummaryReportData) === 0
      ? 0
      : (sumPOBCSMOrderDayTotalCount(monthSummaryReportData) / 1000)?.toFixed(
          1
        ) + "K";
  const data = [
    {
      name: "Total Fieldwork today",
      value: totalFieldWoking ? totalFieldWoking : 0,
    },
    {
      name: "Total Visit",
      value: sumOfAllVisitCount ? sumOfAllVisitCount : 0,
    },
    {
      name: "Total POB(Self)",
      value: totalPOB,
    },
    {
      name: "Total POB(Jointwork)",
      value: totalJointPOB,
    },
    {
      name: "Total Leave",
      value: leaveCount ? leaveCount : 0,
    },
  ];
  let totalSum: number = 0;
  for (const item of data) {
    totalSum += item.value !== undefined ? +item.value : 0;
  }
  return (
    <>
      {totalSum !== 0 ? (
        <Stack
          width={"100%"}
          spacing={1.5}
          borderRadius={"10px"}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
          padding={"20px"}
        >
          <Typography variant="h6" textAlign={"center"}>
            Additional Information
          </Typography>
          {data.map((data: any) => {
            return (
              <Card
                sx={{
                  borderRadius: "0.5rem",
                  padding: "10px",
                  backgroundColor:
                    data.name === "Total Fieldwork Today"
                      ? "#A3BAC3"
                      : data.name === "Total Visit"
                      ? "#819AA8"
                      : data.name === "Total POB(Self)"
                      ? "#B5C7CF"
                      : data.name === "Total POB(Jointwork)"
                      ? "#7DA1B6"
                      : "#A3BAC3",
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography color={"white"} variant="body1">
                    {data.name}
                  </Typography>
                  <Typography color={"white"} variant="h5" fontWeight={600}>
                    {data.value}
                  </Typography>
                </Stack>
              </Card>
            );
          })}
        </Stack>
      ) : (
        ""
      )}
    </>
  );
};
export default AdditionInformation;
