import { Stack } from "@mui/material";
import Header from "../components/Header";
import DailySalesReportComponent from "../components/desktop/daily-sales-report/DailySalesReports";

type Props = {};

const DailySalesReport = (props: Props) => {
  return (
    <Stack gap={2} position={"fixed"} width={"100%"}>
      <Header title="Daily Sales Report" />
      <DailySalesReportComponent />
    </Stack>
  );
};

export default DailySalesReport;
