import { Search } from "@mui/icons-material";
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import debounce from "@mui/material/utils/debounce";
import { useState } from "react";
import { UserSummaryReportStore } from "../../../../store/main/UserSummaryReportStore";
import { TerritoryIDs, Territories } from "../../../../common/constants/Territories";

const Filters = () => {
  const [filterProps, setFilterProps] = useState({
    search: "",
    role_id: "",
  });
  const { fetchUserChildren } = UserSummaryReportStore();
  const radioType = {
    border: "1px solid lightGrey",
    borderRadius: "10px",
    paddingRight: "6px",
    margin: "6px",
  };
  const applySearchFilter = (e: any) => {
    filterProps.search = e.target.value;
    fetchUserChildren(filterProps);
  };
  const applyRadioFilter = (e: any) => {
    filterProps.role_id = TerritoryIDs[e.target.value];
    fetchUserChildren(filterProps);
  };
  const debounceOnChangeFilter = debounce(applySearchFilter, 3000);

  return (
    <Stack spacing={1} padding={"10px"} marginTop={"10px"}>
      <TextField
        onChange={debounceOnChangeFilter}
        variant="outlined"
        placeholder="Search"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <Stack>
        <FormControl>
          <RadioGroup
            sx={{ justifyContent: "space-around" }}
            row
            onChange={(e) => applyRadioFilter(e)}
          >
            {Territories.map((data: any) => {
              return (
                <FormControlLabel
                  value={data}
                  sx={radioType}
                  label={<Typography variant="caption">{data}</Typography>}
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 16,
                        },
                      }}
                    />
                  }
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Stack>
    </Stack>
  );
};
export default Filters;
