import { createElement } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import indexRoutes, { pageRoutes } from "./routes";
import SupportPage from "./presentation/pages/Support";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={pageRoutes.Authenticate} />} />
          {indexRoutes.map((prop, key) => {
            return (
              <Route
                key={key}
                path={prop.path}
                element={createElement(prop.component)}
              >
                {prop.children?.map((prop, key) => {
                  return (
                    <Route
                      key={key}
                      path={prop.path}
                      element={createElement(prop.component)}
                    ></Route>
                  );
                })}
              </Route>
            );
          })}
        </Routes>
      </BrowserRouter>
      <SupportPage />
    </>
  );
}

export default App;
