import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { HqDistanceStore } from "../../../../store/main/HqDistanceStore";
import HqDistanceDetail from "./HqDistanceDetail";

const HQDistanceReportTable = () => {
  const { HqDistanceData } = HqDistanceStore();

  const Columns = [
    { name: "Region", id: 5 },
    { name: "Area", id: 6 },
    { name: "Headquarter", id: 1 },
    { name: "HQ Status", id: 2 },
    { name: "HQ Type", id: 7 },
    { name: "Distance to HQ", id: 3 },
    { name: "Action", id: 4 },
  ];

  return (
    <>
      <TableContainer component={Paper}>
        <Box sx={{ overflow: "scroll", maxHeight: "80vh" }}>
          <Table size="small" padding="normal" stickyHeader>
            <TableHead>
              <TableRow>
                {Columns.map((column: { name: string; id: number }) => {
                  return (
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#E9EDF5",
                        color: "#687182",
                      }}
                    >
                      {column.name}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {HqDistanceData.map((val, key) => (
                <HqDistanceDetail data={val} />
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </>
  );
};

export default HQDistanceReportTable;
