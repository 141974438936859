import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteUpdateFulfilmentAgent } from "../../../data/usecases/remote-update-fulfilment-agent";
import { UpdateFulfilment } from "../../../domain/models/update-fulfilment";
import Swal from "sweetalert2";
import { UpdateUserManagementData } from "../../../domain/useages/user-management/update-user-management-data";
import { RemoteUpdateUserManagementData } from "../../../data/usecases/user-management/remote-update-user-management-data";

export interface UpdateUserManagementDataInterface {
  updateUserManagementData: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useUpdateUserManagementDataSlice: StateCreator<
  UpdateUserManagementDataInterface
> = (set, get) => ({
  updateUserManagementData: async (
    id: number,
    payload: UpdateUserManagementData.Params,
    handleClose: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteUpdateUserManagementData = new RemoteUpdateUserManagementData(
      `${BASE_URL}${Endpoints.UPDATE_USER_MANAGEMENT_DATA}`,
      axiosHttpClient
    );
    let result = await remoteUpdateUserManagementData.edit(
      id.toString(),
      payload
    );

    if (result) {
      handleClose();
    }
  },
});
