import { create } from "zustand";
import { useCheckEligibilitySlice } from "../slices/FulfilmentAgentSlices/checkEligibilitySlice";
import { useFindCustomerByUuidSlice } from "../slices/FulfilmentAgentSlices/findCustomerByUuid";
import { useUpdateFulfilmentAgentSlice } from "../slices/FulfilmentAgentSlices/updateFulfilmentAgentSlice";
import { useCreateFulfilmentAgentSlice } from "../slices/FulfilmentAgentSlices/createFulfilmentAgentSlice";
import { useGenerateOTPSlice } from "../slices/FulfilmentAgentSlices/generateOTPSlice";
import { useVerifyOTPSlice } from "../slices/FulfilmentAgentSlices/verifyOTP";

interface FulfilmentAgentInterface
  extends ReturnType<typeof useCheckEligibilitySlice>,
    ReturnType<typeof useFindCustomerByUuidSlice>,
    ReturnType<typeof useUpdateFulfilmentAgentSlice>,
    ReturnType<typeof useCreateFulfilmentAgentSlice>,
    ReturnType<typeof useGenerateOTPSlice>,
    ReturnType<typeof useVerifyOTPSlice> {}

export const FulfilmentAgentStore = create<FulfilmentAgentInterface>(
  (...a) => ({
    ...useCheckEligibilitySlice(...a),
    ...useFindCustomerByUuidSlice(...a),
    ...useUpdateFulfilmentAgentSlice(...a),
    ...useCreateFulfilmentAgentSlice(...a),
    ...useGenerateOTPSlice(...a),
    ...useVerifyOTPSlice(...a),
  })
);
