import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { TerritoryData } from "../../../domain/models/territory-data";
import { RemoteFetchBricksMaster } from "../../../data/usecases/remote-fetch-bricks";
import { FetchState } from "../../../domain/models/fetch-state";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";

export interface FetchBricksMasterInterface {
  bricksMasterData: TerritoryData[];
  fetchBricksMaster: Function;
  clearBrickMaster: Function;
  fetchBrickState: FetchState;
}

const initialStates = {
  bricksMasterData: [],
  fetchBrickState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchBricksMasterSlice: StateCreator<
  FetchBricksMasterInterface
> = (set, get) => ({
  ...initialStates,

  fetchBricksMaster: async (id: string) => {
    set(() => ({ fetchBrickState: FetchState.LOADING }));

    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchBricksMaster = new RemoteFetchBricksMaster(
      `${BASE_URL}${Endpoints.FETCH_BRICK_MASTER}`,
      axiosHttpClient
    );
    set(() => ({ bricksMasterData: [] }));
    let payload = { headquarter_ids: id };
    let result = await remoteFetchBricksMaster.fetch(payload);
    if (result) {
      set(() => ({ bricksMasterData: result.bricks }));
      set(() => ({ fetchBrickState: FetchState.SUCCESS }));
    }
  },
  clearBrickMaster: () => {
    set(() => ({ bricksMasterData: [] }));
  },
});
