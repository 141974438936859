import { BrickManagementResponse } from "../../../domain/models/brick-management-report/brick-management-data";
import { FetchBrickManagement } from "../../../domain/useages/brick-management/fetch-brick-management";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchBrickManagementReport implements FetchBrickManagement {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    params: FetchBrickManagement.Params
  ): Promise<BrickManagementResponse> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status === 200) {
      return httpResponse.data;
    } else {
      throw new Error("Failed to fetch customer data");
    }
  }
}
