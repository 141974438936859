import { create } from "zustand";
import { useZoneMasterSlice } from "../slices/ZoneMasterReportSlices/fetchZoneMaster";
interface ZoneMasterReportInterface
  extends ReturnType<typeof useZoneMasterSlice> {}

export const ZoneMasterReportStore = create<ZoneMasterReportInterface>(
  (...a) => ({
    ...useZoneMasterSlice(...a),
  })
);
