import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteFetchVisitSummary } from "../../../data/usecases/coverage-reports/remote-fetch-visit-summary";
import { FetchVisitSummary } from "../../../domain/useages/coverage-reports/fetch-customer-visit-summary";
import { FetchState } from "../../../domain/models/fetch-state";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import { MonthlyVisitSummary } from "../../../domain/models/coverage-reports/monthly-visit-summary";

export interface FetchVisitSummaryInterface {
  fetchVisitSummary: Function;
  visitSummaryData: MonthlyVisitSummary[];
  visitSummaryDataLoading: FetchState;
  currentPage: number;
  lastPage: number;
  visitSummaryFilters: {
   page: number;
    limit: number;
    monthYear: Dayjs | null;
    zone?: string[];
    region?: string[];
    area?: string[];
    hq?: string[];
    brick?: string[];
    q?: string;
  };
}

const initialStates = {
  visitSummaryData: [],
  visitSummaryDataLoading: FetchState.DEFAULT,
  visitSummaryFilters: { page: 1, limit: 100, monthYear: dayjs(new Date()) },
  currentPage: 1,
  lastPage: 1,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchVisitSummarySlice: StateCreator<
  FetchVisitSummaryInterface
> = (set, get) => ({
  ...initialStates,
  fetchVisitSummary: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchVisitSummary: FetchVisitSummary =
      new RemoteFetchVisitSummary(
        `${BASE_URL}${Endpoints.VISIT_TABLE}`,
        axiosHttpClient
      );
    let payload = {
      month_year: moment(
        (get().visitSummaryFilters.monthYear as Dayjs).toString()
      ).format("YYYYMM"),
      length: get().visitSummaryFilters.limit,
      page: get().visitSummaryFilters.page,
      zones: get().visitSummaryFilters.zone,
      regions: get().visitSummaryFilters.region,
      areas: get().visitSummaryFilters.area,
      headquarters: get().visitSummaryFilters.hq,
      brick_ids: get().visitSummaryFilters.brick,
      q: get().visitSummaryFilters.q,
    };
    set(() => ({
      visitSummaryDataLoading: FetchState.LOADING,
    }));

    let result = await remoteFetchVisitSummary.fetch(payload);

    if (result) {
      set(() => ({
        visitSummaryData: result.data,
        visitSummaryDataLoading: FetchState.SUCCESS,
        currentPage: result.current_page,
        lastPage: result.last_page,
      }));
    }
  },
});
