enum Actions {
  BULK_REMOVE_MAPPING = "Bulk Remove Mapping",
  BULK_DEACTIVATE_CUSTOMERS = "Bulk Deactivate Customers",
  DEACTIVATE_CUSTOMER = "Deactivate Customer",
  REJECT_CUSTOMER = "Reject Customer",
  BULK_APPROVE_REJECT_CUSTOMER = "Approve And Reject Customers",
  VIEW_CUSTOMER_DETAILS = "View Customer Details",
}

export default Actions;
