import {
  Box,
  Button,
  FormControl,
  Modal,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { BrickManagementStore } from "../../../../../../store/main/BrickManagementStore";
import { BricksType } from "../../../../../../domain/models/brick-management-report/brick-management-data";
import { useEffect, useState } from "react";
import { FetchState } from "../../../../../../domain/models/fetch-state";

interface Props {
  open: boolean;
  handleClose: Function;
  successCallback: Function;
  row: BricksType;
  hq: string;
  brick_id: number;
  hq_id: number;
}

type FormFields = {
  brick: string;
  distance: string;
  station: string;
  expected: string;
  headquarter: string;
  workdays: string;
  district: string;
  cluster: (number | string)[];
  reason: string;
  otherReason: string;
};

const UpdateBrickModal: React.FC<Props> = ({
  open,
  handleClose,
  successCallback,
  row,
  hq,
  brick_id,
  hq_id,
}) => {
  const {
    fetchDistrictState,
    fetchCluster,
    districtData,
    clusterData,
    updateBrick,
  } = BrickManagementStore();

  const handleUpdate = () => {
    const payload = {
      hq_headquarter_id: hq_id,
      no_of_work_days: parseInt(watch("workdays")),
      expected_business: parseInt(watch("expected")),
      distance_from_hq:
        watch("station") === "HQ" ? "0" : parseInt(watch("distance")),
      station: watch("station"),
      district: watch("district"),
      clusters:
        watch("cluster")[0] === "All"
          ? clusterData.map((item) => item.id)
          : watch("cluster"),
      reason:
        watch("reason") === "Other" ? watch("otherReason") : watch("reason"),
    };
    updateBrick(brick_id, payload);
    successCallback();
    handleClose();
  };
  const { handleSubmit, control, watch, setValue } = useForm<FormFields>({
    mode: "onChange",
  });

  const [clusterIds, setClusterIds] = useState<number[]>([]);
  const clusterId: number[] = [];

  useEffect(() => {
    if (
      row.bricks.distance_from_hq !== null &&
      watch("station") === row.bricks.station
    ) {
      setValue("distance", row.bricks.distance_from_hq.toString());
    } else {
      setValue("distance", "0");
    }
  }, [watch("station")]);

  useEffect(() => {
    row.bricks.cluster_mapping.forEach((cluster) => {
      clusterId.push(cluster.cluster_town_id);
    });
    setClusterIds(clusterId);
    setValue(
      "cluster",
      watch("district") === row.bricks.district
        ? clusterId.length > 0
          ? clusterId
          : []
        : []
    );
  }, [row, watch("district")]);

  useEffect(() => {
    if (fetchDistrictState === FetchState.SUCCESS && watch("district")) {
      fetchCluster(watch("district"));
    }
  }, [watch("district"), fetchDistrictState]);

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box
        position={"absolute" as "absolute"}
        top={"50%"}
        left={"50%"}
        bgcolor={"background.paper"}
        borderRadius={"22px"}
        sx={{ transform: "translate(-50%, -50%)" }}
        width={"25%"}
        p={3}
      >
        <Typography
          fontWeight={500}
          variant="h6"
          textAlign="center"
          marginBottom={2}
        >
          Update Brick
        </Typography>
        <Stack spacing={2}>
          <Controller
            name="brick"
            control={control}
            defaultValue={row.brick}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  id="outlined-disabled"
                  label="Brick Name"
                  value={value}
                  fullWidth
                  size="small"
                  disabled
                />
              </FormControl>
            )}
          />
          <Controller
            name="headquarter"
            defaultValue={hq}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  id="outlined-disabled"
                  label="Headquarter Name"
                  value={value}
                  fullWidth
                  size="small"
                  disabled
                />
              </FormControl>
            )}
          />
          <Controller
            name="workdays"
            control={control}
            defaultValue={row.bricks.no_of_work_days?.toString()}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  value={value}
                  id="outlined-required"
                  label="No of Work Days"
                  size="small"
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              </FormControl>
            )}
            rules={{
              pattern: {
                value: /^[0-9]+$/,
                message: "Only Number allowed",
              },
            }}
          />
          <Stack direction={"row"} gap={2}>
            <Controller
              name="station"
              control={control}
              defaultValue={row.bricks.station ? row.bricks.station : ""}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    EX/OS/HQ
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="select"
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    label="EX/OS/HQ"
                  >
                    <MenuItem value="EX">EX</MenuItem>
                    <MenuItem value="OS">OS</MenuItem>
                    <MenuItem value="HQ">HQ</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="distance"
              control={control}
              defaultValue={
                watch("station") === "HQ"
                  ? "0"
                  : row.bricks.distance_from_hq?.toString()
              }
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl size="small" fullWidth>
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="Distance from HQ"
                    value={watch("station") === "HQ" ? "0" : value}
                    size="small"
                    disabled={watch("station") === "HQ"}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
              rules={{
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Only Number allowed",
                },
              }}
            />
          </Stack>

          <Controller
            name="expected"
            control={control}
            defaultValue={
              row.bricks.expected_business != null
                ? row.bricks.expected_business.toString()
                : ""
            }
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Expected Business"
                  value={value}
                  size="small"
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            )}
            rules={{
              pattern: {
                value: /^[0-9]+$/,
                message:
                  "Enter valid reason. No special characters and minimum 5 characters",
              },
            }}
          />
          <Stack direction={"row"} gap={2}>
            <Controller
              name="district"
              control={control}
              defaultValue={row.bricks.district}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-label2">District</InputLabel>
                  <Select
                    labelId="select-label2"
                    id="select"
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    label="District"
                  >
                    {districtData?.map((name) => (
                      <MenuItem value={name.district_code}>
                        {name.district_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="cluster"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-label3">Cluster</InputLabel>
                  <Select
                    labelId="select-label3"
                    id="select"
                    label="Cluster"
                    multiple
                    value={value}
                    onChange={(e) => {
                      const selectedValues = Array.isArray(e.target.value)
                        ? e.target.value
                        : [e.target.value];
                      if (e.target.value.length === 0) {
                        onChange(
                          watch("district") === row.bricks.district
                            ? clusterIds
                            : []
                        );
                      } else if (
                        selectedValues[e.target.value.length - 1] === "All"
                      ) {
                        onChange(["All"]);
                      } else {
                        const filteredValues = selectedValues.filter(
                          (val) => val !== "All"
                        );
                        onChange(filteredValues);
                      }
                    }}
                    displayEmpty
                    disabled={!(watch("district") || row.bricks.district)}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {clusterData?.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Stack>
          <Controller
            name="reason"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="select"
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  label="Reason"
                >
                  <MenuItem value="Update Brick">Update Brick</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {watch("reason") === "Other" && (
            <Controller
              name="otherReason"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl size="small" fullWidth>
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label="Other Reason"
                    value={value}
                    size="small"
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
              rules={{
                pattern: {
                  value: /^(?:[a-zA-Z0-9]+\s){1,}[a-zA-Z0-9]+$/,
                  message: "Enter more than 1 words",
                },
              }}
            />
          )}
        </Stack>

        <Stack
          paddingTop={3}
          direction={"row"}
          gap={3}
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleUpdate)}
            disabled={
              !(
                watch("brick") &&
                watch("headquarter") &&
                watch("distance") &&
                watch("station") &&
                watch("expected") &&
                watch("district") &&
                watch("cluster").length > 0 &&
                ((watch("reason") && watch("reason") !== "Other") ||
                  (watch("reason") === "Other" &&
                    watch("otherReason") &&
                    watch("otherReason").length > 4))
              )
            }
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClose()}
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UpdateBrickModal;
