import { Stack, useMediaQuery, useTheme } from "@mui/material";
import Header from "../components/Header";
import { InputUtilizationTabsMobile } from "../components/mobile/input-utilization-report/InputUtilizationTabsMobile";
import { InputUtilizationTabs } from "../components/desktop/input-utilization-report/InputUtilizationTabs";

type Props = {};

const InputUtilizationReport = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack gap={2} position={"fixed"} width={"100%"}>
      <Header title="Input Utilization Reports" />
      {isMobile ? <InputUtilizationTabsMobile /> : <InputUtilizationTabs />}
    </Stack>
  );
};

export default InputUtilizationReport;
