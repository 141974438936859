import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap.min.css";
import { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { CoverageReportsStore } from "../../../../../store/main/CoverageReportsStore";
import { CustomerDatabaseStore } from "../../../../../store/main/CustomerDatabaseStore";
import { TerritoryDataStore } from "../../../../../store/main/TerritoryDataStore";
import { ReportFilters } from "../../../../../domain/models/report-filters";
import ReportScreen from "../../../../../common/enums/ReportScreens";
import Pagination from "../../../Pagination";
import { FetchState } from "../../../../../domain/models/fetch-state";
import ReportFiltersComponentMobile from "../../ReportFiltersComponentMobile";
import ReportFiltersLoading from "../../../desktop/ReportFiltersLoading";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Roles from "../../../../../common/enums/Roles";

export default function VisitSummaryTableMobile() {
  const {
    fetchVisitSummary,
    visitSummaryData,
    visitSummaryDataLoading,
    visitSummaryFilters,
    lastPage,
  } = CoverageReportsStore();
  const { decodeToken, userDetails, userRole, downloadExcelTable } =
    CustomerDatabaseStore();

  const {
    zoneMasterData,
    areaMasterData,
    regionMasterData,
    headquarterMasterData,
  } = TerritoryDataStore();

  useEffect(() => {
    decodeToken();
  }, []);

  const rows = visitSummaryData;
  interface Column {
    id:
      | "zone"
      | "region"
      | "area"
      | "headquarter"
      | "brick"
      | "firm_name"
      | "customer_type"
      | "visitor_name"
      | "visitor_role"
      | "total_visits"
      | "visit_days";
    label: string;
    minWidth?: number;
    align?: "left";
    format?: (value: number) => string;
  }

  const handlePagination = (page: number, limit: number) => [
    (visitSummaryFilters.page = page),
    (visitSummaryFilters.limit = limit),
    fetchVisitSummary(),
  ];
  const getVisitSummary = (filters: ReportFilters) => {
    if (filters.zone.length > 0) {
      const matchingZones = zoneMasterData.filter((zone) =>
        filters.zone.includes(zone.id.toString())
      );
      const selectedZoneNames = matchingZones.map((zone) => zone.name);
      visitSummaryFilters.zone = selectedZoneNames;
    }

    if (filters.region.length > 0) {
      const matchingRegions = regionMasterData.filter((region) =>
        filters.region.includes(region.id.toString())
      );
      const selectedRegionNames = matchingRegions.map((region) => region.name);
      visitSummaryFilters.region = selectedRegionNames;
    }
    if (filters.area.length > 0) {
      const matchingAreas = areaMasterData.filter((area) =>
        filters.area.includes(area.id.toString())
      );
      const selectedAreaNames = matchingAreas.map((area) => area.name);
      visitSummaryFilters.area = selectedAreaNames;
    }
    if (filters.headquarter.length > 0) {
      const matchingHQ = headquarterMasterData.filter((HQ) =>
        filters.headquarter.includes(HQ.id.toString())
      );
      const selectedHQNames = matchingHQ.map((hq) => hq.name);
      visitSummaryFilters.hq = selectedHQNames;
    }
    visitSummaryFilters.brick = filters.brick;
    visitSummaryFilters.monthYear = filters.date;
    visitSummaryFilters.q = filters.search;

    fetchVisitSummary();
  };

  const clearVisitSummary = (filters: ReportFilters) => {
    visitSummaryFilters.zone = [];
    visitSummaryFilters.region = [];
    visitSummaryFilters.area = [];
    visitSummaryFilters.hq = [];
    visitSummaryFilters.brick = [];
    visitSummaryFilters.monthYear = dayjs(new Date());
    visitSummaryFilters.q = "";

    fetchVisitSummary();
  };

  const columns: readonly Column[] = [
    { id: "zone", label: "Zone" },
    { id: "region", label: "Region" },
    {
      id: "area",
      label: "Area",
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "headquarter",
      label: "Headquarter",
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "brick",
      label: "Brick",
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "firm_name",
      label: "Firm name",
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "customer_type",
      label: "Customer type",
      align: "left",
      minWidth: 90,
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "visitor_name",
      label: "Visitor name",
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "visitor_role",
      label: "Visitor role",
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "total_visits",
      label: "Total visits",
      align: "left",
      minWidth: 70,
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "visit_days",
      label: "Visit days",
      align: "left",
      format: (value: number) => value.toFixed(2),
    },
  ];

  const downloadFile = () => {
    if (visitSummaryData && visitSummaryData.length > 0)
      downloadExcelTable(visitSummaryData);
  };

  return (
    <Stack spacing={2}>
      {Object.keys(userDetails).length > 0 ? (
        <ReportFiltersComponentMobile
          type={ReportScreen.MONTHY_CUSTOMER_VISIT_SUMMARY}
          successCallback={getVisitSummary}
          clearSuccessCallBack={clearVisitSummary}
          gridColumns={13}
          userRole={userRole.role}
          userDetails={userDetails}
        />
      ) : (
        <ReportFiltersLoading />
      )}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={2}
      >
        {userDetails &&
          userDetails.role &&
          userDetails.role.visibility == 1 && (
            <Tooltip title="This will only download the current Records Visible on this table">
              <Button
                variant="contained"
                startIcon={<FileDownloadOutlinedIcon />}
                sx={{ borderRadius: "10px", width: "180px", height: "40px" }}
                onClick={downloadFile}
                color="primary"
              >
                <Typography variant="subtitle2" textTransform={"none"}>
                  Download Excel
                </Typography>
              </Button>
            </Tooltip>
          )}
        <Pagination
          totalPages={lastPage}
          successCallback={handlePagination}
          currenPage={visitSummaryFilters.page}
          rowsPerPage={visitSummaryFilters.limit}
          rowsPerPageOptions={[50, 60, 70, 80, 90, 100]}
        />
      </Stack>
      {visitSummaryDataLoading == FetchState.LOADING && (
        <Stack alignItems={"center"} height={80} justifyContent={"center"}>
          <CircularProgress />
        </Stack>
      )}
      <Box sx={{ height: "100vh", overflow: "scroll" }}>
        {visitSummaryDataLoading == FetchState.SUCCESS &&
          visitSummaryData &&
          visitSummaryData.length > 0 && (
            <>
              <TableContainer sx={{ maxHeight: 440, maxWidth: 1500 }}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <Typography variant="subtitle2" fontWeight={700}>
                            {" "}
                            {column.label}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.region}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.zone}</TableCell>
                        <TableCell>{row.region}</TableCell>
                        <TableCell>{row.area}</TableCell>
                        <TableCell>{row.headquarter}</TableCell>
                        <TableCell>{row.brick}</TableCell>
                        <TableCell>{row.firm_name}</TableCell>
                        <TableCell>{row.customer_type}</TableCell>
                        <TableCell>{row.visitor}</TableCell>
                        <TableCell>{row.visitor_role}</TableCell>
                        <TableCell>{row.total_visits}</TableCell>
                        <TableCell>{row.visit_days}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {visitSummaryDataLoading == FetchState.SUCCESS &&
          visitSummaryData &&
          visitSummaryData.length === 0 && (
            <Typography variant="h6">No Records Found</Typography>
          )}
      </Box>
    </Stack>
  );
}
