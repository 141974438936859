import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useEffect, useState } from "react";
import CustomerVisitReport from "./CustomerVisitReport";
import MonthlyPOBReportBarGraph from "./sub-components/MonthlyPOBReportBarGraph";
import AdditionInformation from "./sub-components/AdditionalInformation";
import moment from "moment";
import UserDeviationReportLoading from "./sub-components/loading/UserDeviationReportLoading";
import MonthSummaryLoading from "./sub-components/loading/MonthSummaryLoading";
import CustomerTypeID from "../../../../common/constants/CustomerTypeID";
import { FetchState } from "../../../../domain/models/fetch-state";
import {
  DeviationTourType,
  VisitedBrickType,
  DeviationReportOrderType,
  DeviationTableData,
} from "../../../../domain/models/user-summary-report/deviation-report";
import {
  AttendenceType,
  VisitsType,
} from "../../../../domain/models/user-summary-report/summary-report";
import { UserSummaryReportStore } from "../../../../store/main/UserSummaryReportStore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const tableHeading: string[] = [
  "DATE",
  "WORK TYPE",
  "TOUR PLAN",
  "VISITED BRICK",
  "TP (WORKING WITH)",
  "WORKING WITH",
];
const UserDeviationReport = () => {
  const {
    deviationReportData,
    fetchDeviationReportState,
    monthsReportDataState,
    monthSummaryState,
  } = UserSummaryReportStore();

  const handleRowToggle = (index: number) => {
    const newOpen = new Array(tableData.length).fill(false);
    newOpen[index] = !open[index];
    setOpen(newOpen);
  };

  const cellBorder = {
    border: "none",
    padding: "8px",
    margin: "0",
  };

  const [tableData, setTableData] = useState<any[]>([]);
  const [customerVisitData, setCustomerVisitData] = useState<any[]>([]);

  useEffect(() => {
    const tourPlanArray = deviationReportData?.tours?.map(
      (data: DeviationTourType) => ({
        date: data.date,
        tourPlan: data.tour_plan ? data.tour_plan : "-",
        tpWorkingWith: data.tour_plan ? data.tour_plan : "-",
      })
    );
    const attandanceArray = tourPlanArray?.map((tableDate: any) => {
      const data = deviationReportData?.attendances?.find(
        (attendance: AttendenceType) => attendance.date === tableDate.date
      );
      if (data) {
        return {
          ...tableDate,
          work_type:
            data.status === "working"
              ? data.work_type.name
              : data.status === "leave"
              ? data.leave_type.name
              : "-",
          workingWith:
            data.status === "working"
              ? data.working_with
                ? data.working_with
                : "-"
              : "-",
          status: data.status,
        };
      } else {
        return tableDate;
      }
    });
    const visitedBrickArray = attandanceArray?.map((tableDate: any) => {
      const data = deviationReportData?.visited_brick?.find(
        (visitedBrickData: VisitedBrickType) =>
          visitedBrickData.visit_date === tableDate.date
      );

      if (data) {
        return {
          ...tableDate,
          visitedBrick: data.visited_brick || "-",
          visitCount: data.visit_count || "-",
        };
      } else {
        return tableDate;
      }
    });
    const ordersArray = visitedBrickArray?.map((tableDate: any) => {
      const dataMatch = deviationReportData?.orders?.find(
        (data: DeviationReportOrderType) => tableDate.date === data.order_date
      );

      if (dataMatch) {
        return {
          ...tableDate,
          pob: dataMatch.order_day_total_count
            ? dataMatch.order_day_total_count
            : "-",
        };
      } else {
        return tableDate;
      }
    });
    let customerTypeArray: any[] = [];
    const visitsArray = deviationReportData?.visits?.map((data: VisitsType) => {
      const dateMatch = ordersArray.find(
        (tableDate: any) => tableDate.date === data.visit_date
      );
      const customerType = CustomerTypeID.find(
        (type) => type.id === data.customer_type_id
      );
      if (dateMatch && customerType) {
        const newDataObject = {
          date: dateMatch.date,
          name: customerType.name,
          count: data.visit_count,
        };

        customerTypeArray = [...customerTypeArray, newDataObject];
      }
    });

    setCustomerVisitData(customerTypeArray);
    setTableData(ordersArray);
  }, [deviationReportData]);

  const [open, setOpen] = useState(new Array(tableData?.length).fill(false));
  return (
    <>
      <Stack marginTop={"20px"} height={"69vh"}>
        <Typography variant="h6">User Deviation Report</Typography>
        <Stack overflow={"scroll"}>
          <Stack
            height={
              fetchDeviationReportState === FetchState.ERROR ||
              fetchDeviationReportState === FetchState.DEFAULT
                ? "20vh"
                : "65vh"
            }
            paddingY={1}
          >
            {fetchDeviationReportState === FetchState.SUCCESS &&
              tableData?.length != 0 && (
                <TableContainer>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {tableHeading.map((data: string) => {
                          return (
                            <TableCell>
                              <Typography
                                color={"#828282"}
                                fontSize={12}
                                fontWeight={600}
                                textAlign={"center"}
                              >
                                {data}
                              </Typography>
                            </TableCell>
                          );
                        })}
                        <TableCell
                          sx={{ boxShadow: "-1px 0 0 rgba(0, 0, 0, 0.1)" }}
                        >
                          <Typography
                            color={"#828282"}
                            fontSize={12}
                            fontWeight={600}
                            textAlign={"center"}
                            noWrap
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            DAILY REPORT
                          </Typography>
                          <Typography
                            color={"#828282"}
                            fontSize={12}
                            textAlign={"center"}
                            noWrap
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            (Total Visits | POB)
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData
                        ?.sort(
                          (a: DeviationTableData, b: DeviationTableData) =>
                            new Date(a.date).getTime() -
                            new Date(b.date).getTime()
                        )
                        .map((data: DeviationTableData, index: number) => {
                          return (
                            <>
                              <TableRow
                                key={index}
                                sx={{
                                  height: "4vh",
                                  backgroundColor:
                                    data.status === "leave"
                                      ? data.workingWith &&
                                        data.tpWorkingWith &&
                                        data.workingWith.includes(
                                          data.tpWorkingWith
                                        )
                                        ? ""
                                        : "#FFE2E5"
                                      : data.visitedBrick &&
                                        data.tourPlan &&
                                        data.visitedBrick.includes(
                                          data.tourPlan
                                        )
                                      ? ""
                                      : "#FFE2E5",
                                }}
                              >
                                <TableCell sx={cellBorder}>
                                  <IconButton
                                    size="small"
                                    onClick={() => handleRowToggle(index)}
                                  >
                                    {open[index] ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                                </TableCell>
                                <TableCell sx={cellBorder}>
                                  <Typography
                                    textAlign={"center"}
                                    fontSize={12}
                                    noWrap
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                  >
                                    {data.date
                                      ? moment(data.date).format("DD MMM")
                                      : "-"}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={cellBorder}>
                                  <Typography
                                    textAlign={"center"}
                                    fontSize={12}
                                    noWrap
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                  >
                                    {data?.work_type ? data.work_type : "-"}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={cellBorder}>
                                  <Typography
                                    textAlign={"center"}
                                    fontSize={12}
                                  >
                                    {data?.tourPlan ? data.tourPlan : "-"}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={cellBorder}>
                                  <Typography
                                    textAlign={"center"}
                                    fontSize={12}
                                  >
                                    {data?.visitedBrick
                                      ? data.visitedBrick
                                      : "-"}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={cellBorder}>
                                  <Typography
                                    textAlign={"center"}
                                    fontSize={12}
                                  >
                                    {data?.tpWorkingWith
                                      ? data.tpWorkingWith
                                      : "-"}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={cellBorder}>
                                  <Typography
                                    textAlign={"center"}
                                    fontSize={12}
                                  >
                                    {data.workingWith ? data.workingWith : "-"}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "none",
                                    boxShadow: " -1px 0 0 rgba(0, 0, 0, 0.1)",
                                    paddingY: "0px",
                                  }}
                                >
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"space-evenly"}
                                    spacing={0.1}
                                  >
                                    <Box
                                      bgcolor={"#A7C7E7"}
                                      width={"100%"}
                                      borderRight={"1px solid white"}
                                      borderRadius={"10px 0 0 10px"}
                                      paddingX={"10px"}
                                      height={"25px"}
                                    >
                                      <Typography
                                        textAlign={"center"}
                                        variant="body1"
                                        color={"white"}
                                      >
                                        {data.visitCount ? data.visitCount : 0}
                                      </Typography>
                                    </Box>

                                    <Box
                                      bgcolor={"#A7C7E7"}
                                      width={"80%"}
                                      borderRadius={"0 10px 10px 0"}
                                      paddingX={"10px"}
                                    >
                                      <Typography
                                        textAlign={"center"}
                                        variant="body1"
                                        color={"white"}
                                      >
                                        {!isNaN(parseFloat(data.pob))
                                          ? (
                                              parseFloat(data.pob) / 1000
                                            ).toFixed(1) + "K"
                                          : "0"}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                              {open[index] ? (
                                <TableRow>
                                  <TableCell colSpan={8} sx={cellBorder}>
                                    <Collapse
                                      in={open[index]}
                                      timeout={"auto"}
                                      unmountOnExit
                                    >
                                      <Box
                                        padding={2}
                                        borderRadius={"10px"}
                                        boxShadow={"0 0 4px rgba(0, 0, 0, 0.1)"}
                                      >
                                        <CustomerVisitReport
                                          date={data.date}
                                          customerVisitData={customerVisitData}
                                        />
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            {fetchDeviationReportState === FetchState.ERROR && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
              >
                <HighlightOffIcon sx={{ fontSize: 50 }} color="error" />
                <Typography variant="h6">NO RECORD FOUND</Typography>
              </Box>
            )}
            {fetchDeviationReportState === FetchState.LOADING && (
              <UserDeviationReportLoading />
            )}
          </Stack>
          {(monthSummaryState === FetchState.SUCCESS ||
            monthsReportDataState === FetchState.SUCCESS) && (
            <Typography variant="h6" padding={"10px"}>
              Month Summary
            </Typography>
          )}
          {monthsReportDataState === FetchState.LOADING ? (
            <MonthSummaryLoading />
          ) : (
            <Stack
              direction={"row"}
              spacing={2}
              margin={"20px 20px 3px 20px"}
              alignItems={"center"}
            >
              {monthsReportDataState === FetchState.SUCCESS && (
                <Stack spacing={2}>
                  <MonthlyPOBReportBarGraph />
                </Stack>
              )}
              <AdditionInformation />
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default UserDeviationReport;
