import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteFetchUserChildren } from "../../../data/usecases/user-summary-report/remote-fetch-user-children";
import { FetchUserChildren } from "../../../domain/useages/user-summary-report/fetch-user-children";
import { UserChildrenType } from "../../../domain/models/user-summary-report/user-children";
import { FetchState } from "../../../domain/models/fetch-state";

export interface FetchUserChildrenInterface {
  fetchUserChildren: Function;
  userData: UserChildrenType[];
  fetchUserChildrenState: FetchState;
}

const initialStates = {
  userData: [],
  fetchUserChildrenState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchUserChildrenSlice: StateCreator<
  FetchUserChildrenInterface
> = (set, get) => ({
  ...initialStates,
  fetchUserChildren: async (filters: FetchUserChildren.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    set({ fetchUserChildrenState: FetchState.LOADING });

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchUserChildren: FetchUserChildren =
      new RemoteFetchUserChildren(
        `${BASE_URL}${Endpoints.FETCH_USER_CHILDREN}`,
        axiosHttpClient
      );
    let result = await remoteFetchUserChildren.fetch(filters);

    if (result.users.length != 0) {
      set({
        userData: result.users,
        fetchUserChildrenState: FetchState.SUCCESS,
      });
    } else if (result.users.length == 0) {
      set({ userData: result.users, fetchUserChildrenState: FetchState.ERROR });
    }
  },
});
