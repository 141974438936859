import {
  Box,
  Button,
  Paper,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
} from "@mui/material";
import React from "react";
import { FunctionalModalStyle } from "../../../../common/constants/custom-style";
import { PrimarySalesInvoiceStore } from "../../../../store/main/PrimarySalesInvoiceStore";

type props = {
  open: boolean;
  setShowDetails: Function;
  selectedIndex: number;
};

const Columns = [
  { name: "Batch No.", id: 1 },
  { name: "LR Date", id: 3 },
  { name: "LR No", id: 7 },
  { name: "Product", id: 4 },
  { name: "UOM", id: 8 },
  { name: "Quantity", id: 5 },
  { name: "Amount", id: 9 },
];
const InvoiceDetailsModal: React.FC<props> = ({
  open,
  setShowDetails,
  selectedIndex,
}) => {
  const { invoiceData } = PrimarySalesInvoiceStore();
  return (
    <>
      <Modal open={open} onClose={() => setShowDetails(false)}>
        <Box sx={FunctionalModalStyle}>
          <Stack textAlign={"center"} marginX={"auto"} spacing={2}>
            <Typography variant="h5">Invoice Details</Typography>

            <TableContainer component={Paper}>
              <Box sx={{ overflow: "scroll", height: "70vh" }}>
                <Table size="small" padding="normal" stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Columns.map((column: any) => {
                        return (
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#E9EDF5",
                              color: "#687182",
                            }}
                          >
                            {column.name}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceData.length > 0 &&
                      invoiceData[selectedIndex].invoice_details.map((val) => {
                        return (
                          <TableRow>
                            <TableCell align="center" sx={{ paddingY: 2 }}>
                              <Typography
                                fontWeight={500}
                                color={"#687182"}
                                variant="caption"
                              >
                                {val.batch_no}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                fontWeight={550}
                                color={"#687182"}
                                variant="caption"
                              >
                                {val.lr_date ? val.lr_date : "-"}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                fontWeight={550}
                                color={"#687182"}
                                variant="caption"
                              >
                                {val.lr_no ? val.lr_no : "-"}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                fontWeight={550}
                                color={"#687182"}
                                variant="caption"
                              >
                                {val.product.name}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                fontWeight={550}
                                color={"#687182"}
                                variant="caption"
                              >
                                {val.uom}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                fontWeight={550}
                                color={"#687182"}
                                variant="caption"
                              >
                                {val.qty}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                fontWeight={550}
                                color={"#687182"}
                                variant="caption"
                              >
                                {val.net_amt ? val.net_amt : "-"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            </TableContainer>
            <Stack>
              <Button
                variant="contained"
                color="error"
                onClick={() => setShowDetails(false)}
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default InvoiceDetailsModal;
