import "webdatarocks/webdatarocks.css";
import * as WebDataRocksReact from "react-webdatarocks";
import { Box } from "@mui/material";

type PivotTableProps = {
  formattedData: any;
  toolbar: boolean;
  customizeToolbar: Function;
  height?: string;
  customizeCell?: Function;
};

const PivotTable: React.FC<PivotTableProps> = ({
  formattedData,
  toolbar,
  customizeToolbar,
  height = "70vh",
  customizeCell = () => {},
}) => {
  return (
    <Box sx={{ overflow: "hidden", height: height }}>
      <WebDataRocksReact.Pivot
        toolbar={toolbar}
        container="#wdr-container"
        componentFolder="https://cdn.webdatarocks.com/"
        width="100%"
        height="100%"
        report={formattedData}
        beforetoolbarcreated={customizeToolbar}
        customizeCell={customizeCell}
      />
    </Box>
  );
};

export default PivotTable;
