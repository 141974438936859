import { Stack } from "@mui/material";
import Header from "../components/Header";
import PrimarySalesTabs from "../components/desktop/primary-sales/PrimarySalesTabs";

export default function PrimarySales() {
  return (
    <>
      <Stack gap={2} position={"fixed"} width={"100%"}>
        <Header title="Primary Sales" />
        <PrimarySalesTabs />
      </Stack>
    </>
  );
}
