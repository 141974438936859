import { StateCreator } from "zustand";
import { RemoteFetchUserRoles } from "../../data/usecases/remote-fetch-user-roles";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { TerritoryData } from "../../domain/models/territory-data";
import Endpoints from "../../domain/endpoints";

export interface FetchUserRolesInterface {
  userRolesMasterData: TerritoryData[];
  fetchUserRolesMaster: Function;
}

const initialStates = {
  userRolesMasterData: [],
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchUserRolesSlice: StateCreator<FetchUserRolesInterface> = (
  set, get
) => ({
  ...initialStates,
  fetchUserRolesMaster: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchUserRoles = new RemoteFetchUserRoles(
      `${BASE_URL}${Endpoints.FETCH_ROLES}`,
      axiosHttpClient
    );
    let result = await remoteFetchUserRoles.fetch();
    if (result.success) {
      set(() => ({ userRolesMasterData: result.roles }));
    }
  },
});
