import { Skeleton, Stack } from "@mui/material";

type Props = {};

const UserListLoading = (props: Props) => {
  return (
    <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{borderRadius: '0.7rem'}}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{borderRadius: '0.7rem'}}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{borderRadius: '0.7rem'}}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{borderRadius: '0.7rem'}}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{borderRadius: '0.7rem'}}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{borderRadius: '0.7rem'}}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={90}
        width={"90%"}
        sx={{borderRadius: '0.7rem'}}
      />
    </Stack>
  );
};

export default UserListLoading;
