import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import CreateNewBrickModal from "./Modals/CreateNewBrickModal";
import BrickTableDetail from "./BrickTableDetail";
import { CustomerDatabaseStore } from "../../../../../store/main/CustomerDatabaseStore";
import { BrickManagementStore } from "../../../../../store/main/BrickManagementStore";
import { FetchState } from "../../../../../domain/models/fetch-state";

const BrickTable = () => {
  const { filterStore, userDetails } = CustomerDatabaseStore();
  const { BrickManagementData, fetchBrickManagementState } =
    BrickManagementStore();
  const [openCreateNewBrick, setOpenCreateNewBrick] = useState<boolean>(false);
  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"} paddingY={2}>
        <Stack direction={"row"} spacing={1}>
          <Stack
            height={"20px"}
            width={"40px"}
            border={"2px solid #a7a7a8"}
            bgcolor={"error.dark"}
          ></Stack>
          <Typography>*Note: Need to Update Clusters</Typography>
        </Stack>
        <Stack>
          <span>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ borderRadius: "30px" }}
              disabled={
                userDetails.button_access === 0 ||
                filterStore.headquarter_ids === undefined ||
                filterStore.headquarter_ids?.length === 0
              }
              color="primary"
              onClick={() => setOpenCreateNewBrick(true)}
            >
              <Typography variant="subtitle2" textTransform={"none"}>
                Create Brick
              </Typography>
            </Button>
          </span>
        </Stack>
      </Stack>
      {fetchBrickManagementState === FetchState.SUCCESS && (
        <Stack height={"65vh"} overflow={"scroll"}>
          <TableContainer>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      color={"#828282"}
                      fontSize={12}
                      fontWeight={600}
                      textAlign={"center"}
                    >
                      Headquarter
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={"#828282"}
                      fontSize={12}
                      fontWeight={600}
                      textAlign={"center"}
                    >
                      District
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={"#828282"}
                      fontSize={12}
                      fontWeight={600}
                      textAlign={"center"}
                    >
                      Brick
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={"#828282"}
                      fontSize={12}
                      fontWeight={600}
                      textAlign={"center"}
                    >
                      HQ/OS/EX
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={"#828282"}
                      fontSize={12}
                      fontWeight={600}
                      textAlign={"center"}
                    >
                      Working Days
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={"#828282"}
                      fontSize={12}
                      fontWeight={600}
                      textAlign={"center"}
                    >
                      Expected Business
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={"#828282"}
                      fontSize={12}
                      fontWeight={600}
                      textAlign={"center"}
                    >
                      Distance From HQ
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={"#828282"}
                      fontSize={12}
                      fontWeight={600}
                      textAlign={"center"}
                    >
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {BrickManagementData.map((zone, key) => (
                  <>
                    {zone.regions.map((region, key) => (
                      <>
                        {region.areas.map((areas, key) => (
                          <>
                            {areas.headquarters.map((hq, key) => (
                              <>
                                {hq.bricks.map((bricks, key) => (
                                  <>
                                    <BrickTableDetail
                                      row={bricks}
                                      hq={hq.headquarter}
                                      hq_id={hq.headquarter_id}
                                    />
                                  </>
                                ))}
                              </>
                            ))}
                          </>
                        ))}
                      </>
                    ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}

      {userDetails.button_access !== 0 &&
        filterStore.headquarter_ids !== undefined &&
        filterStore?.headquarter_ids?.length > 0 &&
        openCreateNewBrick && (
          <CreateNewBrickModal
            open={openCreateNewBrick}
            handleClose={() => setOpenCreateNewBrick(false)}
            successCallback={() => {}}
            hq_id={filterStore.headquarter_ids[0]}
          />
        )}
    </>
  );
};
export default BrickTable;
