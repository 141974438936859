import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import Endpoints from "../../domain/endpoints";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { FetchServiceProviders } from "../../domain/useages/fetch-service-providers";
import { RemoteFetchServiceProviders } from "../../data/usecases/remote-fetch-service-providers";
import { ServiceProvider } from "../../domain/models/service-provider";

export interface FetchServiceProviderInterface {
  serviceProvider: ServiceProvider[];
  fetchServiceProvider: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

const initialStates = {
  serviceProvider: [],
};

export const useFetchServiceProviderSlice: StateCreator<
  FetchServiceProviderInterface
> = (set, get) => ({
  ...initialStates,
  fetchServiceProvider: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchServiceProviders: FetchServiceProviders =
      new RemoteFetchServiceProviders(
        `${BASE_URL}${Endpoints.FETCH_SERVICE_PROVIDER}`,
        axiosHttpClient
      );

    let result = await remoteFetchServiceProviders.get();
    if (result) {
      set({ serviceProvider: result.service_providers });
    }
  },
});
