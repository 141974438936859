import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Collapse } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  BricksMasterType,
  HeadquarterMasterType,
} from "../../../../../domain/models/zone-master/zone-master-data";

type RowProps = {
  row: HeadquarterMasterType;
  openHQ: boolean;
};
const BrickTableData: React.FC<RowProps> = ({ row, openHQ }) => {
  return (
    <>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={openHQ} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <TableContainer component={Paper}>
                <Table size="small" padding="none">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#E9EDF5",
                          color: "#687182",
                        }}
                      >
                        District
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#E9EDF5",
                          color: "#687182",
                        }}
                      >
                        Brick
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#E9EDF5",
                          color: "#687182",
                        }}
                      >
                        HQ/OS/EX
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#E9EDF5",
                          color: "#687182",
                        }}
                      >
                        No Of Work Days
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#E9EDF5",
                          color: "#687182",
                        }}
                      >
                        Expected Business
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#E9EDF5",
                          color: "#687182",
                        }}
                      >
                        Distance From HQ
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#E9EDF5",
                          color: "#687182",
                        }}
                      >
                        Approval
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#E9EDF5",
                          color: "#687182",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.bricks?.map((data: BricksMasterType) => (
                      <>
                        <TableRow
                          sx={{
                            "& > *": {
                              borderBottom: "unset",
                            },
                          }}
                        >
                          <TableCell align="center">{data.district}</TableCell>
                          <TableCell align="center">{data.name}</TableCell>
                          <TableCell align="center">{data.station}</TableCell>
                          <TableCell align="center">
                            {data.no_of_work_days}
                          </TableCell>
                          <TableCell align="center">
                            {data.expected_business}
                          </TableCell>
                          <TableCell align="center">
                            {data.distance_from_hq}
                          </TableCell>
                          <TableCell align="center">
                            {data.status === "active" ? (
                              <CheckCircleIcon
                                color="success"
                                fontSize="small"
                              />
                            ) : (
                              <CancelIcon color="secondary" fontSize="small" />
                            )}
                          </TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>  
      </TableRow>
    </>
  );
};

export default BrickTableData;
