export const DailySalesReportDataInLakhCondition = [
  {
    measure: "target",
    format: {
      position: "fixed",
    },
  },
  {
    formula: "#value >= 0 ",
    measure: "1",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('1_cumulative_frequency')/100000 < #value",
    measure: "2",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('1_cumulative_frequency')/100000 >= #value",
    measure: "2",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('2_cumulative_frequency')/100000 < #value",
    measure: "3",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('2_cumulative_frequency')/100000 >= #value",
    measure: "3",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('3_cumulative_frequency')/100000 < #value",
    measure: "4",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('3_cumulative_frequency')/100000 >= #value",
    measure: "4",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('4_cumulative_frequency')/100000 < #value",
    measure: "5",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('4_cumulative_frequency')/100000 >= #value",
    measure: "5",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('5_cumulative_frequency')/100000 < #value",
    measure: "6",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('5_cumulative_frequency')/100000 >= #value",
    measure: "6",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('6_cumulative_frequency')/100000 < #value",
    measure: "7",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('6_cumulative_frequency')/100000 >= #value",
    measure: "7",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('7_cumulative_frequency')/100000 < #value",
    measure: "8",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('7_cumulative_frequency')/100000 >= #value",
    measure: "8",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('8_cumulative_frequency')/100000 < #value",
    measure: "9",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('8_cumulative_frequency')/100000 >= #value",
    measure: "9",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('9_cumulative_frequency')/100000 < #value",
    measure: "10",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula:
      "sum('9_cumulative_frequency_cumulative_frequency')/100000 >= #value",
    measure: "10",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('10_cumulative_frequency')/100000 < #value",
    measure: "11",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('10_cumulative_frequency')/100000 >= #value",
    measure: "11",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('11_cumulative_frequency')/100000 < #value",
    measure: "12",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('11_cumulative_frequency')/100000 >= #value",
    measure: "12",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('12_cumulative_frequency')/100000 < #value",
    measure: "13",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('12_cumulative_frequency')/100000 >= #value",
    measure: "13",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('13_cumulative_frequency')/100000 < #value",
    measure: "14",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('13_cumulative_frequency')/100000 >= #value",
    measure: "14",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('14_cumulative_frequency')/100000 < #value",
    measure: "15",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('14_cumulative_frequency')/100000 >= #value",
    measure: "15",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('15_cumulative_frequency')/100000 < #value",
    measure: "16",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('15_cumulative_frequency')/100000 >= #value",
    measure: "16",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('16_cumulative_frequency')/100000 < #value",
    measure: "17",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('16_cumulative_frequency')/100000 >= #value",
    measure: "17",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('17_cumulative_frequency')/100000 < #value",
    measure: "18",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('17_cumulative_frequency')/100000 >= #value",
    measure: "18",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('18_cumulative_frequency')/100000 < #value",
    measure: "19",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('18_cumulative_frequency')/100000 >= #value",
    measure: "19",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('19_cumulative_frequency')/100000 < #value",
    measure: "20",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('19_cumulative_frequency')/100000 >= #value",
    measure: "20",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('20_cumulative_frequency')/100000 < #value",
    measure: "21",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('20_cumulative_frequency')/100000 >= #value",
    measure: "21",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('21_cumulative_frequency')/100000 < #value",
    measure: "22",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('21_cumulative_frequency')/100000 >= #value",
    measure: "22",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('22_cumulative_frequency')/100000 < #value",
    measure: "23",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('22_cumulative_frequency')/100000 >= #value",
    measure: "23",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('23_cumulative_frequency')/100000 < #value",
    measure: "24",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('23_cumulative_frequency')/100000 >= #value",
    measure: "24",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('24_cumulative_frequency')/100000 < #value",
    measure: "25",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('24_cumulative_frequency')/100000 >= #value",
    measure: "25",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('25_cumulative_frequency')/100000 < #value",
    measure: "26",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('25_cumulative_frequency')/100000 >= #value",
    measure: "26",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('26_cumulative_frequency')/100000 < #value",
    measure: "27",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('26_cumulative_frequency')/100000 >= #value",
    measure: "27",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('27_cumulative_frequency')/100000 < #value",
    measure: "28",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('27_cumulative_frequency')/100000 >= #value",
    measure: "28",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('28_cumulative_frequency')/100000 < #value",
    measure: "29",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('28_cumulative_frequency')/100000 >= #value",
    measure: "29",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('29_cumulative_frequency')/100000 < #value",
    measure: "30",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('29_cumulative_frequency')/100000 >= #value ",
    measure: "30",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('30_cumulative_frequency')/100000 < #value",
    measure: "31",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('30_cumulative_frequency')/100000 >= #value",
    measure: "31",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
];
