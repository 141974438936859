import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";

import { FetchState } from "../../../domain/models/fetch-state";
import { FetchStockistWise } from "../../../domain/useages/primary-sales-invoice-reports/fetch-stockist-wise";
import { RemoteFetchStockistWise } from "../../../data/usecases/primary-sales-invoice/remote-fetch-stockist-wise";
import { StockistWise } from "../../../domain/models/primary-sales-invoice/stockist-wise";

export interface FetchStockistWiseInterface {
  stockistWiseData: StockistWise[];
  fetchStockistWiseData: Function;
  stockistWiseDataLoading: FetchState;
  handleStockistPageNumber: Function;
  dataLengthStockist: number;
  currentStockistPageNumber: number;
}

const initialStates = {
  stockistWiseData: [],
  stockistWiseDataLoading: FetchState.DEFAULT,
  dataLengthStockist: 0,
  currentStockistPageNumber: 0,
};

export const useFetchStockistWiseDataSlice: StateCreator<
  FetchStockistWiseInterface
> = (set, get) => ({
  ...initialStates,
  fetchStockistWiseData: async (month: number, year: number, payload: any) => {
    set(() => ({ stockistWiseDataLoading: FetchState.LOADING }));
    const axiosHttpClient = AxiosHttpClient.getInstance();
    const storage = LocalJsonStorage.getInstance();

    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchStockistWise: FetchStockistWise =
      new RemoteFetchStockistWise(
        `${BASE_URL}${Endpoints.PRIMARY_CUSTOMER}`,
        axiosHttpClient
      );

    set(() => ({ stockistWiseDataLoading: FetchState.LOADING }));
    let result = await remoteFetchStockistWise.fetch(month, year, payload);
    if (result) {
      set(() => ({
        dataLengthStockist: result.total,
        stockistWiseData: result.invoice_details,
        stockistWiseDataLoading: FetchState.SUCCESS,
      }));
    }
  },
  handleStockistPageNumber: (pageNo: number) => {
    set(() => ({ currentStockistPageNumber: pageNo }));
  },
});
