enum ReportScreen {
  CUSTOMER_DATABASE = "customerDatabase",
  COVERAGE_REPORT = "coverageReport",
  MONTHY_HQ_WISE_SUMMARY_REPORT = "monthlyHQWiseSummary",
  MONTHY_CUSTOMER_VISIT_SUMMARY = "monthlyCustomerVisitSummary",
  MONTHY_SPECIALITY_SUMMARY = "monthlySpecialitySummary",
  BRICK_WORKING_REPORT = "brickWorkingReport",
  INPUT_UTILIZATION = "inputUtilization",
  INPUT_UTILIZATION_BATCH_WISE_SUMMARY = "inputUtilizationBatchWiseSummary",
  INPUT_UTILIZATION_CUSTOMER_WISE_SUMMARY = "inputUtilizationCustomerWiseSummary",
  STANDARD_TOUR_PROGRAM = "standardTourProgram",
  PRIMARY_SALES_HQ_PRODUCT_WISE_SUMMARY = "primarySalesHQandProductWiseReport",
  DAILY_SALES_REPORT = "dailySalesReport",
  USER_MANAGEMENT = "userManagementReport",
  DATA_DASHBOARD_REPORT = "dataDashboardReport",
  USER_SUMMARY_REPORT = "userSummaryReport",
  BUSINESS_PLANNING = "businessPlanning",
  CUSTOMER_MISSING = "customerMissing",
  CUSTOMER_DETAILING_REPORT = "customerDetailingReport",
  USER_INPUT_PLANNING = "userInputPlanning",
  BRICK_MANAGEMENT = "brickManagement",
  PRODUCT_CATLOG = "productCatlog",
  ZONE_MASTER = "zoneMaster",
  UNMAPPED_STOCKIST = "unmappedStockist",
  PRIMARY_SALES_INVOICE = "primarySalesInvoice",
  HQ_DISTANCE_REPORT = "hqDistanceReport",
}

export default ReportScreen;
