import { Box } from "@mui/material";
import UnmappedStockistDesktop from "../components/desktop/unmapped-stockist/UnmappedStockistDesktop";

const UnmappedStockist = () => {
  return (
    <Box padding={2}>
      <UnmappedStockistDesktop />
    </Box>
  );
};

export default UnmappedStockist;
