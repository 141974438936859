import { StateCreator } from "zustand";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteFetchZoneMaster } from "../../../data/usecases/remote-fetch-zone";
import Endpoints from "../../../domain/endpoints";
import { TerritoryData } from "../../../domain/models/territory-data";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";

export interface FetchZoneMasterInterface {
  zoneMasterData: TerritoryData[];
  fetchZoneMaster: Function;
  clearZoneMaster: Function;
}

const initialStates = {
  zoneMasterData: [],
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchZoneMasterSlice: StateCreator<FetchZoneMasterInterface> = (
  set
) => ({
  ...initialStates,
  fetchZoneMaster: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchMasters = new RemoteFetchZoneMaster(
      `${BASE_URL}${Endpoints.FETCH_ZONE_MASTER}`,
      axiosHttpClient
    );
    set(() => ({ zoneMasterData: [] }));
    let result = await remoteFetchMasters.fetch();
    if (result) {
      set(() => ({ zoneMasterData: result.zones }));
    }
  },
  clearZoneMaster: () => {
    set(() => ({ zoneMasterData: [] }));
  },
});
