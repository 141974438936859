import Swal from "sweetalert2";
import { AddMetadata } from "../../../domain/useages/customer-database/add-metadata";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteAddMetadata implements AddMetadata {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async add(params: AddMetadata.Params): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status === 200) {
      return httpResponse.data;
    } else if (httpResponse.status === 400) {
      if (httpResponse.data.success === false) {
        Swal.fire({
          icon: "error",
          timer: 3000,
          text: httpResponse.data.errors.message,
          customClass: {
            container: "swal2-style",
          },
          confirmButtonColor: "#3085d6",
        });
      }
    }else {
      if (httpResponse.data.errors) {
        Swal.fire({
          icon: "error",
          timer: 3000,
          text: httpResponse.data.message,
          customClass: {
            container: "swal2-style",
          },
          confirmButtonColor: "#3085d6",
        });
      }
    }
  }
}
