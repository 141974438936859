import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import HQandProductWiseReport from "./HQandProductWiseReport";
import { PrimarySalesStore } from "../../../../store/main/PrimarySalesStore";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 3, mt: 2 }} bgcolor={"#f9f9f9"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function PrimarySalesTabs() {
  const {
    fetchHQandProductWise,
    HQandProductWiseFilters,
  } = PrimarySalesStore();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchTableData = (name: string) => {
    if (name === "HQAndProductWiseSummary") {
      HQandProductWiseFilters.monthYear = dayjs(new Date());
      fetchHQandProductWise();
    } else if (name === "SpecialtySummary") {
    } else if (name == "visitSummary") {
    }
  };
  React.useEffect(() => {
    fetchHQandProductWise();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box paddingX={2}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="HQ And Product Wise Summary"
            value={0}
            onClick={() => {
              fetchTableData("HQAndProductWiseSummary");
            }}
          />
          <Tab
            label="Product Wise Summary"
            value={1}
            onClick={() => {
              fetchTableData("visitSummary");
            }}
          />
          <Tab
            label="Invoice Summary"
            value={2}
            onClick={() => {
              fetchTableData("SpecialtySummary");
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <HQandProductWiseReport />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* <VisitSummaryTable /> */}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <SpecialtySummaryTable /> */}
      </TabPanel>
    </Box>
  );
}
