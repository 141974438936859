import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { FetchState } from "../../../domain/models/fetch-state";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { RemoteFetchProductCatlog } from "../../../data/usecases/product-catlog/remote-fetch-product-catlog";
import { ProductCatlogType } from "../../../domain/models/product-catlog/product-catlog";
import { FetchProductCatlog } from "../../../domain/useages/product-catlog/fetch-product-catlog";

export interface FetchProductCatlogInterface {
  ProductCatlog: ProductCatlogType;
  ProductCatlogState: FetchState;
  fetchProductCatlog: Function;
}

const initialStates = {
  ProductCatlog: {} as ProductCatlogType,
  ProductCatlogState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchProductCatlogSlice: StateCreator<
  FetchProductCatlogInterface
> = (set) => ({
  ...initialStates,
  fetchProductCatlog: async (filter: FetchProductCatlog.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteProductCatlog = new RemoteFetchProductCatlog(
      `${BASE_URL}${Endpoints.FETCH_PRODUCT_CATLOG}`,
      axiosHttpClient
    );

    set(() => ({ ProductCatlogState: FetchState.LOADING }));
    let result = await remoteProductCatlog.fetch(filter);
    if (result && result.products.length > 0) {
      set(() => ({
        ProductCatlog: result,
        ProductCatlogState: FetchState.SUCCESS,
      }));
    } else {
      set(() => ({ ProductCatlogState: FetchState.ERROR }));
    }
  },
});
