import {
  Box,
  Button,
  Grid,
  MenuItem,
  Modal,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { EditCustomerModalStyle } from "../../../../../../common/constants/custom-style";
import Roles from "../../../../../../common/enums/Roles";
import theme from "../../../../../../custom-theme";
import { EdiitCustomerDetails } from "../../../../../../domain/models/customer-database/edit-customer-details";
import { CustomerDatabaseStore } from "../../../../../../store/main/CustomerDatabaseStore";
import { TerritoryDataStore } from "../../../../../../store/main/TerritoryDataStore";
import { CustomerDetails } from "../../../../../../domain/models/customer-database/customer-details";

interface Props {
  data: CustomerDetails;
  open: boolean;
  handleClose: Function;
}

const EditCustomerModal: React.FC<Props> = ({ open, handleClose, data }) => {
  const { handleSubmit, control, setValue } = useForm<EdiitCustomerDetails>({
    mode: "onChange",
  });

  const [roleLevel, setRoleLevel] = useState<number>(0);

  const { userRole, editCustomer, fetchCustomerData } = CustomerDatabaseStore();

  const {
    regionMasterData,
    fetchAreaMaster,
    areaMasterData,
    fetchHeadquarterMaster,
    headquarterMasterData,
    fetchBricksMaster,
    bricksMasterData,
  } = TerritoryDataStore();

  const SubmitEditCustomer = (formData: EdiitCustomerDetails) => {
    let payload = {
      hq_zone_id: data.mappings.zone_id,
      hq_region_id: formData.hq_region_id,
      hq_area_id: formData.hq_area_id,
      hq_headquarter_id: formData.hq_headquarter_id,
      hq_brick_id: formData.hq_brick_id,
      address_id: data.address_id,
      line: formData.line,
      landmark: formData.landmark,
      pincode: formData.pincode,
      reason: formData.reason,
    };

    editCustomer(data.id, payload, handleClose, fetchCustomerData);
  };
  const [ids, setIds] = useState<number[]>([]);

  const handleEditTerritory = (e: any, name: string) => {
    setIds([]);
    ids.push(e.target.value);
    if (name === "area") {
      fetchAreaMaster(ids);
    } else if (name === "hq") {
      fetchHeadquarterMaster(ids);
    } else if (name === "brick") {
      fetchBricksMaster(ids);
    }
  };

  useEffect(() => {
    setValue("hq_region_id", data.mappings.region_id);
    setValue("hq_area_id", data.mappings.area_id);
    setValue("hq_headquarter_id", data.mappings.headquarter_id);
    setValue("hq_brick_id", data.mappings.brick_id);
    setValue("line", data?.address?.line);
    setValue("landmark", data?.address?.landmark);
    setValue("pincode", data?.address?.pincode);

    if (
      userRole.role === Roles.COUTNRY_MANAGER ||
      userRole.role === Roles.ZONE_MANAGER
    ) {
      setRoleLevel(1);
    } else if (userRole.role === Roles.REGION_MANAGER) {
      setRoleLevel(2);
    } else if (userRole.role === Roles.AREA_MANAGER) {
      setRoleLevel(3);
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Modal open={open}>
          <Box sx={EditCustomerModalStyle}>
            <Stack>
              <Typography
                textAlign="center"
                variant="h6"
                fontFamily="Segoe UI"
                fontWeight={600}
                color="#4D4D4D"
              >
                Update Customer Details
              </Typography>
              <Grid container xs={12} spacing={2}>
                <Grid item container xs={6} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Firm Name</Typography>
                    <Controller
                      name="name"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          disabled={true}
                          value={data.firm_name}
                          error={error && true}
                          helperText={error?.message}
                          onChange={onChange}
                        />
                      )}
                      // rules={{
                      //   required: { value: true, message: "Field Required" },
                      // }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Email ID</Typography>
                    <Controller
                      name="email"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          disabled={true}
                          value={""}
                          error={error && true}
                          helperText={error?.message}
                          onChange={onChange}
                        />
                      )}
                      // rules={{
                      //   required: { value: true, message: "Field Required" },
                      // }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Mobile Number</Typography>
                    <Controller
                      name="mobileno"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          disabled={true}
                          value={data.mobile}
                          error={error && true}
                          helperText={error?.message}
                          onChange={onChange}
                        />
                      )}
                      // rules={{
                      //   required: { value: true, message: "Field Required" },
                      // }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Select Region</Typography>
                    <Controller
                      name="hq_region_id"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          select
                          size="small"
                          variant="outlined"
                          defaultValue={data.mappings.region_id}
                          disabled={roleLevel === 1 ? false : true}
                          value={value}
                          error={error && true}
                          helperText={error?.message}
                          onChange={(event: any) => {
                            if (event.target && event.target.value) {
                              if (roleLevel === 1) {
                                handleEditTerritory(event, "area");
                                onChange(event.target.value);
                              }
                            }
                          }}
                        >
                          {regionMasterData?.length > 0 &&
                            regionMasterData?.map((regionData) => (
                              <MenuItem value={regionData.id}>
                                <Typography
                                  color="secondary.light"
                                  variant="caption"
                                >
                                  {regionData.name}
                                </Typography>
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                      // rules={{
                      //   required: { value: true, message: "Field Required" },
                      // }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Select Area</Typography>
                    <Controller
                      name="hq_area_id"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          select
                          defaultValue={data.mappings.area_id}
                          size="small"
                          variant="outlined"
                          value={value}
                          disabled={
                            roleLevel === 1 || roleLevel === 2 ? false : true
                          }
                          error={error && true}
                          helperText={error?.message}
                          onChange={(event: any) => {
                            if (event.target && event.target.value) {
                              handleEditTerritory(event, "hq");
                              onChange(event.target.value);
                            }
                          }}
                        >
                          {areaMasterData?.length > 0 &&
                            areaMasterData?.map((regionData) => (
                              <MenuItem value={regionData.id}>
                                <Typography
                                  color="secondary.light"
                                  variant="caption"
                                >
                                  {regionData.name}
                                </Typography>
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                      // rules={{
                      //   required: { value: true, message: "Field Required" },
                      // }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Select HQ</Typography>
                    <Controller
                      name="hq_headquarter_id"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          select
                          defaultValue={data.mappings.headquarter_id}
                          size="small"
                          variant="outlined"
                          disabled={
                            roleLevel === 1 ||
                            roleLevel === 2 ||
                            roleLevel === 3
                              ? false
                              : true
                          }
                          value={value}
                          error={error && true}
                          helperText={error?.message}
                          onChange={(event: any) => {
                            if (event.target && event.target.value) {
                              handleEditTerritory(event, "brick");
                              onChange(event.target.value);
                            }
                          }}
                        >
                          {headquarterMasterData?.length > 0 &&
                            headquarterMasterData?.map((regionData) => (
                              <MenuItem value={regionData.id}>
                                <Typography
                                  color="secondary.light"
                                  variant="caption"
                                >
                                  {regionData.name}
                                </Typography>
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                      // rules={{
                      //   required: { value: true, message: "Field Required" },
                      // }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Select Brick</Typography>
                    <Controller
                      name="hq_brick_id"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          defaultValue={data.mappings.brick_id}
                          select
                          size="small"
                          variant="outlined"
                          value={value}
                          error={error && true}
                          helperText={error?.message}
                          onChange={onChange}
                        >
                          {bricksMasterData?.length > 0 &&
                            bricksMasterData?.map((regionData) => (
                              <MenuItem value={regionData.id}>
                                <Typography
                                  color="secondary.light"
                                  variant="caption"
                                >
                                  {regionData.name}
                                </Typography>
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                      // rules={{
                      //   required: { value: true, message: "Field Required" },
                      // }}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={6} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Address Line</Typography>
                    <Controller
                      name="line"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          multiline
                          minRows={4}
                          variant="outlined"
                          value={value}
                          error={error && true}
                          helperText={error?.message}
                          onChange={onChange}
                        />
                      )}
                      // rules={{
                      //   required: { value: true, message: "Field Required" },
                      // }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Landmark</Typography>
                    <Controller
                      name="landmark"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={value}
                          error={error && true}
                          helperText={error?.message}
                          onChange={onChange}
                        />
                      )}
                      // rules={{
                      //   required: { value: true, message: "Field Required" },
                      // }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Pincode</Typography>
                    <Controller
                      name="pincode"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={value}
                          error={error && true}
                          helperText={error?.message}
                          onChange={onChange}
                        />
                      )}
                      // rules={{
                      //   required: { value: true, message: "Field Required" },
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Reason</Typography>
                    <Controller
                      name="reason"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={value}
                          error={error && true}
                          helperText={error?.message}
                          onChange={onChange}
                        />
                      )}
                      rules={{
                        required: { value: true, message: "Field Required" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Stack
                direction="row"
                gap={3}
                justifyContent="center"
                marginTop={3}
              >
                <Button
                  variant="contained"
                  sx={{ textTransform: "unset", borderRadius: "30px" }}
                  onClick={handleSubmit(SubmitEditCustomer)}
                >
                  <Typography variant="subtitle1">Update Details</Typography>
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "unset",
                    borderRadius: "30px",
                    minWidth: "20%",
                  }}
                  onClick={() => handleClose()}
                >
                  <Typography variant="subtitle1">Cancel</Typography>
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Modal>
      </ThemeProvider>
    </>
  );
};

export default EditCustomerModal;
