import { useMediaQuery, useTheme } from "@mui/material";
import CustomerDatabaseDesktop from "../components/desktop/customer-database/CustomerDatabaseDesktop";
import CustomerDatabaseMobile from "../components/mobile/customer-database/CustomerDatabaseMobile";

const CustomerDatabase = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>{isMobile ? <CustomerDatabaseMobile /> : <CustomerDatabaseDesktop />}</>
  );
};

export default CustomerDatabase;
