import SearchIcon from "@mui/icons-material/Search";
import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";
import { useEffect, useState } from "react";
import { ReportFilters } from "../../../domain/models/report-filters";
import { UserDetails } from "../../../domain/models/uset-details";
import { TerritoryDataStore } from "../../../store/main/TerritoryDataStore";
import { DailySalesStore } from "../../../store/main/DailySalesStore";
import { CoverageReportsStore } from "../../../store/main/CoverageReportsStore";
import { InputUsageSummaryStore } from "../../../store/main/InputUsageReportStore";
import dayjs, { Dayjs } from "dayjs";
import ReportScreen from "../../../common/enums/ReportScreens";
import Roles from "../../../common/enums/Roles";
import {
  TextField,
  InputAdornment,
  Button,
  Typography,
  Grid,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  Stack,
  Checkbox,
  CircularProgress,
  debounce,
} from "@mui/material";
import Territory from "../../../common/enums/Territory";
import {
  BrickFieldDisable,
  BrickFieldVisible,
  DisableSubmitButton,
  HQFieldDisable,
  HQFieldVisible,
  RenderMultiSelectValues,
  StatusFieldVisible,
  areaFieldDisable,
  areaFieldVisible,
  brandsFieldVisible,
  customerTypeFieldVisible,
  departmentFieldVisible,
  inputproductFieldVisible,
  monthYearDateFieldVisible,
  productFieldVisible,
  regionFieldDisable,
  regionFieldVisible,
  roleFieldVisible,
  searchFieldVisible,
  specialityFieldVisible,
  yearFieldVisible,
  zoneFieldDisable,
  zoneFieldVisible,
  segementFieldDisable,
  segementFieldVisible,
} from "../../../common/utils/ReportsFilters";
import FilterFieldLoading from "../desktop/FilterFieldLoading";
import { UserSummaryReportStore } from "../../../store/main/UserSummaryReportStore";
import moment from "moment";
import { CustomerDatabaseStore } from "../../../store/main/CustomerDatabaseStore";

const searchBoxStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  "& .MuiFormLabel-root": {
    fontSize: "12px",
    color: "primary.light",
  },
  borderRadius: "10px",
  backgroundColor: "white",
};

type Props = {
  type: string;
  successCallback: Function;
  clearSuccessCallBack: Function;
  gridColumns?: number;
  userRole?: string;
  userDetails?: UserDetails;
  dateType?: string[];
  justifyContent?: "end" | "start" | "center" | "space-around" | "space-evenly";
  territoryMultiSelect?: boolean;
};

const ReportFiltersComponentMobile: React.FC<Props> = ({
  type,
  successCallback,
  clearSuccessCallBack,
  gridColumns,
  userRole = "",
  userDetails = {} as UserDetails,
  justifyContent = "center",
  territoryMultiSelect = true,
}) => {
  const [filterInputValues, setFilterInputValues] = useState<ReportFilters>({
    customerType: "",
    speciality: [],
    products: [],
    inputProducts: [],
    productTypes: "",
    role: "",
    date: dayjs(new Date()),
    year: dayjs(new Date()),
    zone: [],
    region: [],
    area: [],
    headquarter: [],
    brick: [],
    search: "",
    brandType: [],
    department: "",
    status: "active",
    segement: [],
    template: "",
    user: "",
  });

  const [loadData, setLoadData] = useState<boolean>(true);
  const {
    zoneMasterData,
    areaMasterData,
    regionMasterData,
    headquarterMasterData,
    bricksMasterData,
    fetchZoneMaster,
    fetchRegionMaster,
    fetchAreaMaster,
    fetchHeadquarterMaster,
    fetchBricksMaster,
    fetchCustomerTypes,
    customerTypesData,
    fetchProductsMaster,
    productsMasterData,
    brandData,
    fetchBrandsData,
    fetchDepartment,
    departmentData,
  } = TerritoryDataStore();

  const { dailySalesFilters, fetchDailySales } = DailySalesStore();
  const { fetchSpecialities, specialitiesData } = CoverageReportsStore();
  const {
    fetchInputBasedOnProductType,
    inputBasedOnProductTypeFilters,
    inputBasedOnProductTypeData,
    fetchInputUsageSummary,
    inputUsageSummaryFilters,
    fetchUserRolesMaster,
    userRolesMasterData,
  } = InputUsageSummaryStore();

  const { userSummaryFilter } = UserSummaryReportStore();
  const { customerMetadata, handleDepartmentCode, filterStore } =
    CustomerDatabaseStore();

  const inputTypes = [
    { id: 1, name: "Sample" },
    { id: 2, name: "Promotional Input" },
  ];
  const clearAll = () => {
    const filters = { ...filterInputValues };
    if (userRole === Roles.COUTNRY_MANAGER) {
      filters.zone = [];
      filters.area = [];
      filters.region = [];
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole === Roles.ZONE_MANAGER) {
      filters.area = [];
      filters.region = [];
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole === Roles.REGION_MANAGER) {
      filters.area = [];
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole === Roles.AREA_MANAGER) {
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole === Roles.HEADQUATER_MANAGER) {
      filters.brick = [];
    }
    filters.date = dayjs(new Date());
    filters.speciality = [];
    filters.products = [];
    filters.customerType = "";
    filters.search = "";
    filters.department = "";
    filters.brandType = [];
    filters.segement = [];
    filters.template = "";
    setFilterInputValues(filters);
    clearSuccessCallBack();
  };

  useEffect(() => {
    if (
      type === ReportScreen.INPUT_UTILIZATION &&
      inputBasedOnProductTypeData.length > 0
    ) {
      let filters = { ...filterInputValues };
      let selectedProduct: string[] = [];
      for (let i = 0; i < 3; i++) {
        if (inputBasedOnProductTypeData[i]) {
          selectedProduct.push(inputBasedOnProductTypeData[i].id.toString());
          inputUsageSummaryFilters.input_ids.push(
            inputBasedOnProductTypeData[i].id.toString()
          );
        }
      }
      filters.inputProducts = selectedProduct.filter((item) => item !== "All");
      setFilterInputValues(filters);
      if (loadData) {
        fetchInputUsageSummary();
        setLoadData(false);
      }
    }
  }, [inputBasedOnProductTypeData.length]);

  useEffect(() => {
    if (type === ReportScreen.DAILY_SALES_REPORT && departmentData.length > 0) {
      let filters = { ...filterInputValues };
      filters.department = departmentData[0].id.toString();

      dailySalesFilters.department_id = departmentData[0].id.toString();
      setFilterInputValues(filters);
      fetchDailySales();
    }
  }, [departmentData.length]);

  useEffect(() => {
    if (type === ReportScreen.INPUT_UTILIZATION_BATCH_WISE_SUMMARY) {
      fetchUserRolesMaster();
      fetchInputBasedOnProductType();
      setFilterInputValues((prevValues) => ({
        ...prevValues,
        role: userRolesMasterData[0]?.id.toString(),
        inputProducts: [
          inputBasedOnProductTypeData[0]?.id.toString(),
          inputBasedOnProductTypeData[1]?.id.toString(),
          inputBasedOnProductTypeData[2]?.id.toString(),
        ],
      }));
    } else if (type === ReportScreen.INPUT_UTILIZATION_CUSTOMER_WISE_SUMMARY) {
      fetchUserRolesMaster();
      fetchInputBasedOnProductType();
      setFilterInputValues((prevValues) => ({
        ...prevValues,
        role: userRolesMasterData[0]?.id.toString(),
        inputProducts: [
          inputBasedOnProductTypeData[0]?.id.toString(),
          inputBasedOnProductTypeData[1]?.id.toString(),
          inputBasedOnProductTypeData[2]?.id.toString(),
        ],
      }));
    } else if (type === ReportScreen.INPUT_UTILIZATION) {
      setLoadData(true);
      fetchUserRolesMaster();
      let filters = { ...filterInputValues };
      filters.productTypes = inputTypes[0].name;
      filters.inputProducts = [
        inputBasedOnProductTypeData[0]?.id.toString(),
        inputBasedOnProductTypeData[1]?.id.toString(),
        inputBasedOnProductTypeData[2]?.id.toString(),
      ];

      inputBasedOnProductTypeFilters.inputType = inputTypes[0].name;
      fetchInputBasedOnProductType();
      setFilterInputValues(filters);
    }
    if (type === ReportScreen.MONTHY_SPECIALITY_SUMMARY) {
      fetchSpecialities();
    }
    if (type === ReportScreen.BRICK_WORKING_REPORT) {
      fetchCustomerTypes();
      fetchProductsMaster();
    }
    if (type === ReportScreen.DAILY_SALES_REPORT) {
      fetchBrandsData();
      fetchDepartment();
    }
    if (type === ReportScreen.CUSTOMER_DETAILING_REPORT) {
      fetchBrandsData();
    }
    if (
      type === ReportScreen.MONTHY_CUSTOMER_VISIT_SUMMARY ||
      type === ReportScreen.CUSTOMER_DATABASE ||
      type === ReportScreen.STANDARD_TOUR_PROGRAM ||
      type === ReportScreen.DAILY_SALES_REPORT ||
      type == ReportScreen.USER_MANAGEMENT
    ) {
      roleBasedFilterValues();
    }
  }, []);

  const roleBasedFilterValues = () => {
    let filters = { ...filterInputValues };
    if (userRole === Roles.COUTNRY_MANAGER) {
      fetchZoneMaster();
    } else if (userRole === Roles.ZONE_MANAGER) {
      fetchZoneMaster();
      filters.zone = [userDetails.hq_zone_id.toString()];
      fetchRegionMaster([userDetails.hq_zone_id]);
    } else if (userRole === Roles.REGION_MANAGER) {
      fetchRegionMaster([userDetails.hq_zone_id]);
      // executiveSummaryFilters.zoneID = userDetails.hq_zone_id.toString();
      filters.region = [userDetails.hq_region_id.toString()];
      fetchAreaMaster([userDetails.hq_region_id]);
    } else if (userRole === Roles.AREA_MANAGER) {
      fetchAreaMaster([userDetails.hq_region_id]);
      // executiveSummaryFilters.zoneID = userDetails.hq_zone_id.toString();
      filters.area = [userDetails.hq_area_id.toString()];
      fetchHeadquarterMaster([userDetails.hq_area_id]);
    } else if (userRole === Roles.HEADQUATER_MANAGER) {
      fetchHeadquarterMaster([userDetails.hq_area_id]);
      // executiveSummaryFilters.zoneID = userDetails.hq_zone_id.toString();
      filters.headquarter = [userDetails.hq_headquarter_id.toString()];
      fetchBricksMaster([userDetails.hq_headquarter_id]);
    }

    setFilterInputValues(filters);
  };

  // useEffect(() => {
  //   if (type == ReportScreen.EXECUTIVE_REPORT && zoneMasterData.length > 0) {
  //     const filters = { ...filterInputValues };
  //     console.log(zoneMasterData[0]?.id.toString(), "zone");
  //     filters.zone = [zoneMasterData[0]?.id.toString()];
  //     executiveSummaryFilters.zoneID = zoneMasterData[0]?.id.toString();
  //     hqWiseVisitsFilters.zoneID = zoneMasterData[0]?.id.toString();

  //     setFilterInputValues(filters);
  //     fetchExecutiveSummary();
  //     fetchHqWiseVisits();
  //   }
  // }, [zoneMasterData]);

  const applySearchFilter = (e: any) => {
    const filters = { ...filterInputValues };
    filters.search = e.target.value;
    setFilterInputValues(filters);
    successCallback(filters);
  };

  const debouncedOnChange = debounce(applySearchFilter, 1000);

  const onSubmitFilters = () => {
    const filters = { ...filterInputValues };
    userSummaryFilter.month = parseInt(
      moment(filters.date?.toString()).format("MM")
    );
    userSummaryFilter.year = parseInt(
      moment(filters.date?.toString()).format("yy")
    );
    successCallback(filterInputValues);
  };
  const handleSelectChange = (
    event: SelectChangeEvent<string | string[]>,
    name: string
  ) => {
    const filters = { ...filterInputValues };
    if (name === "speciality") {
      if (
        event.target.value[event.target.value.length - 1] === "All" &&
        event.target.value.length > 1
      ) {
        filters.speciality = [];
      } else {
        const selectedSpecialities =
          typeof event.target.value === "string"
            ? event.target.value.split(",")
            : event.target.value;
        filters.speciality = selectedSpecialities.filter(
          (item) => item !== "All"
        );
      }
    } else if (name === "brand") {
      if (
        event.target.value[event.target.value.length - 1] === "All" &&
        event.target.value.length > 1
      ) {
        filters.brandType = [];
      } else {
        const brandFilter =
          typeof event.target.value === "string"
            ? event.target.value.split(",")
            : event.target.value;
        filters.segement = brandFilter.filter((item) => item !== "All");
      }
    } else if (name === "segement") {
      if (
        event.target.value[event.target.value.length - 1] === "All" &&
        event.target.value.length > 1
      ) {
        filters.segement = [];
      } else {
        const segementFilter =
          typeof event.target.value === "string"
            ? event.target.value.split(",")
            : event.target.value;
        filters.segement = segementFilter.filter((item) => item !== "All");
      }
    } else if (name === "products") {
      if (
        event.target.value[event.target.value.length - 1] === "All" &&
        event.target.value.length > 1
      ) {
        filters.products = [];
      } else {
        const selectedProducts =
          typeof event.target.value === "string"
            ? event.target.value.split(",")
            : event.target.value;
        filters.products = selectedProducts.filter((item) => item !== "All");
      }
    } else if (name === "inputProducts") {
      setLoadData(false);
      if (
        event.target.value[event.target.value.length - 1] === "All" ||
        event.target.value.length < 1
      ) {
        let selectedProduct: string[] = [];
        for (let i = 0; i < 3; i++) {
          if (inputBasedOnProductTypeData[i]) {
            selectedProduct.push(inputBasedOnProductTypeData[i].id.toString());
          }
        }
        filters.inputProducts = selectedProduct.filter(
          (item) => item !== "All"
        );
      } else {
        const selectedProducts =
          typeof event.target.value === "string"
            ? event.target.value.split(",")
            : event.target.value;
        if (selectedProducts.length <= 3) {
          filters.inputProducts = selectedProducts.filter(
            (item) => item !== "All"
          );
        }
      }
    } else if (name === Territory.ZONE) {
      if (
        event.target.value[event.target.value.length - 1] === "All" &&
        event.target.value.length > 1
      ) {
        filters.zone = [];
      } else {
        const selectedZone =
          typeof event.target.value === "string"
            ? event.target.value.split(",")
            : event.target.value;
        filters.zone = selectedZone.filter((item) => item !== "All");
      }
      filters.region = [];
      filters.area = [];
      filters.headquarter = [];
      filters.brick = [];
      // if (type != ReportScreen.EXECUTIVE_REPORT) {
      fetchRegionMaster(filters.zone);
      // }
    } else if (name === Territory.REGION) {
      if (
        event.target.value[event.target.value.length - 1] === "All" &&
        event.target.value.length > 1
      ) {
        filters.region = [];
      } else {
        const selectedZone =
          typeof event.target.value === "string"
            ? event.target.value.split(",")
            : event.target.value;
        filters.region = selectedZone.filter((item) => item !== "All");
      }
      filters.area = [];
      filters.headquarter = [];
      filters.brick = [];
      fetchAreaMaster(filters.region);
    } else if (name === Territory.AREA) {
      if (
        event.target.value[event.target.value.length - 1] === "All" &&
        event.target.value.length > 1
      ) {
        filters.area = [];
      } else {
        const selectedZone =
          typeof event.target.value === "string"
            ? event.target.value.split(",")
            : event.target.value;
        filters.area = selectedZone.filter((item) => item !== "All");
      }
      filters.headquarter = [];
      filters.brick = [];
      if (type != ReportScreen.USER_MANAGEMENT) {
        fetchHeadquarterMaster(filters.area);
      }
    } else if (name === Territory.HEADQUATER) {
      if (
        event.target.value[event.target.value.length - 1] === "All" &&
        event.target.value.length > 1
      ) {
        filters.headquarter = [];
      } else {
        const selectedZone =
          typeof event.target.value === "string"
            ? event.target.value.split(",")
            : event.target.value;
        filters.headquarter = selectedZone.filter((item) => item !== "All");
        filterStore.headquarter_ids = selectedZone.filter(
          (item) => item !== "All"
        );
        const temp = headquarterMasterData.find(
          (id) => id.id.toString() === event.target.value
        );
        filterStore.headquarter_code = temp?.ga_code;
      }
      filters.brick = [];
      fetchBricksMaster(filters.headquarter);
    } else if (name === Territory.BRICKS) {
      if (
        event.target.value[event.target.value.length - 1] === "All" &&
        event.target.value.length > 1
      ) {
        filters.brick = [];
      } else {
        const selectedZone =
          typeof event.target.value === "string"
            ? event.target.value.split(",")
            : event.target.value;
        filters.brick = selectedZone.filter((item) => item !== "All");
      }
    }
    setFilterInputValues(filters);
  };
  const handleSingleSelectChange = (event: SelectChangeEvent, name: string) => {
    const filters = { ...filterInputValues };

    if (name === "customerType") {
      if (event.target.value === "All") {
        filters.customerType = "" as string;
      } else {
        filters.customerType = event.target.value as string;
      }
    } else if (name === "productType") {
      if (event.target.value === "All") {
        filters.productTypes = "";
        filters.inputProducts = [];
        inputBasedOnProductTypeFilters.inputType = "";
        fetchInputBasedOnProductType();
      } else {
        filters.productTypes = event.target.value;
        filters.inputProducts = [];
        inputBasedOnProductTypeFilters.inputType = event.target.value;
        fetchInputBasedOnProductType();
      }
    } else if (name === "role") {
      if (event.target.value === "All") {
        filters.role = "";
      } else {
        filters.role = event.target.value;
      }
    } else if (name === "status") {
      if (event.target.value == "All") {
        filters.status = "";
      } else {
        filters.status = event.target.value;
      }
    } else if (name === "department") {
      if (event.target.value === "All") {
        filters.department = "";
      } else {
        handleDepartmentCode(event.target.value);
        filters.department = event.target.value;
      }
    }
    // else if (name === "hq") {
    //   if (event.target.value === "All") {
    //     filters.headquarter = "";
    //   } else {
    //     filters.headquarter = event.target.value;
    //   }
    //   filters.brick = [];
    //   userSummaryFilter.headquarter_ids = event.target.value;
    //   fetchBricksMaster([filters.headquarter]);
    // }
    setFilterInputValues(filters);
  };

  const handleMonthChange: DatePickerProps["onChange"] = (date, dateString) => {
    const filter = { ...filterInputValues };
    filter.date = date;
    setFilterInputValues(filter);
  };
  const handleYearChange: DatePickerProps["onChange"] = (date, dateString) => {
    const filter = { ...filterInputValues };
    filter.year = date;
    setFilterInputValues(filter);
  };
  const minDate = dayjs(new Date()).subtract(2, "year");
  const maxDate = dayjs(new Date());
  const disabledDate = (current: Dayjs) => {
    return current && (current < minDate || current > maxDate);
  };
  const disabledMonthPicker = (current: Dayjs) => {
    return current && current > maxDate;
  };

  return (
    <>
      <Grid container gap={1.5} justifyContent={"center"} columns={gridColumns}>
        {searchFieldVisible(type) && (
          <Grid item xs={12}>
            <TextField
              onChange={debouncedOnChange}
              fullWidth
              size="small"
              placeholder="Search"
              sx={searchBoxStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {<SearchIcon />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        {monthYearDateFieldVisible(type) && (
          <Grid item xs={6} alignItems={"center"}>
            <Space direction="vertical">
              <DatePicker
                onChange={handleMonthChange}
                picker="month"
                defaultValue={maxDate}
                placeholder="Select Month"
                size="large"
                style={{
                  borderColor: "#A4AEB9",
                }}
                format={"MMM-YYYY"}
                disabledDate={disabledMonthPicker}
              />
            </Space>
          </Grid>
        )}
        {yearFieldVisible(type) && (
          <Grid item xs={6}>
            <Space direction="vertical">
              <DatePicker
                picker="year"
                size="large"
                defaultValue={maxDate}
                style={{ borderColor: "#A4AEB9" }}
                disabledDate={disabledDate}
                onChange={handleYearChange}
              />
            </Space>
          </Grid>
        )}
        {brandsFieldVisible(type) && (
          <Grid item xs={4} width={100}>
            <FormControl fullWidth size="small">
              <InputLabel>Brand</InputLabel>
              <Select
                fullWidth
                value={
                  filterInputValues.brandType
                    ? filterInputValues.brandType
                    : "All"
                }
                size="small"
                label="Brand"
                onChange={(e) => handleSelectChange(e, "brand")}
              >
                <MenuItem key={"All"} value={"All"}>
                  All
                </MenuItem>
                {brandData?.map((data: any) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {departmentFieldVisible(type) && (
          <Grid item xs={8}>
            <FormControl fullWidth size="small">
              <InputLabel>Department</InputLabel>
              <Select
                fullWidth
                value={
                  filterInputValues.department
                    ? filterInputValues.department
                    : "All"
                }
                size="small"
                label="Department"
                onChange={(e) => handleSingleSelectChange(e, "department")}
              >
                <MenuItem key={"All"} value={"All"}>
                  All
                </MenuItem>
                {departmentData?.map((data: any) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {specialityFieldVisible(type) && (
          <Grid item xs={6} width={200}>
            {specialitiesData.length > 0 ? (
              <FormControl fullWidth size="small">
                <InputLabel>Speciality</InputLabel>
                <Select
                  multiple
                  fullWidth
                  value={
                    filterInputValues.speciality.length > 0
                      ? filterInputValues.speciality
                      : ["All"]
                  }
                  size="small"
                  label="Speciality"
                  onChange={(e) => handleSelectChange(e, "speciality")}
                  renderValue={(selected) => selected.join(", ")}
                >
                  <MenuItem value={"All"}>
                    <Checkbox
                      checked={
                        !(filterInputValues.speciality.length > 0) ||
                        filterInputValues.speciality.includes("All")
                      }
                    />
                    All
                  </MenuItem>
                  {specialitiesData?.map((data: any) => (
                    <MenuItem key={data.id} value={data.name}>
                      <Checkbox
                        checked={
                          filterInputValues.speciality.indexOf(data.name) >
                            -1 ||
                          filterInputValues.speciality.includes("All") ||
                          !(filterInputValues.speciality.length > 0)
                        }
                      />
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <FilterFieldLoading />
            )}
          </Grid>
        )}
        {customerTypeFieldVisible(type) && (
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Customer Type</InputLabel>
              <Select
                fullWidth
                value={
                  filterInputValues.customerType
                    ? filterInputValues.customerType
                    : "All"
                }
                size="small"
                label="Customer Type"
                onChange={(e) => handleSingleSelectChange(e, "customerType")}
              >
                <MenuItem key={"All"} value={"All"}>
                  {"All"}
                </MenuItem>
                {customerTypesData?.map((data: any) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {productFieldVisible(type) && (
          <Grid item xs={4} width={250}>
            <FormControl fullWidth size="small">
              <InputLabel>Product</InputLabel>
              <Select
                multiple
                fullWidth
                value={
                  filterInputValues.products.length > 0
                    ? filterInputValues.products
                    : ["All"]
                }
                size="small"
                label="Products"
                renderValue={(selected) =>
                  RenderMultiSelectValues(selected, productsMasterData)
                }
                onChange={(e) => handleSelectChange(e, "products")}
              >
                <MenuItem value={"All"}>
                  <Checkbox
                    checked={
                      !(filterInputValues.products.length > 0) ||
                      filterInputValues.products.includes("All")
                    }
                  />
                  All
                </MenuItem>
                {productsMasterData?.map((data: any) => (
                  <MenuItem key={data.id} value={data.id.toString()}>
                    <Checkbox
                      checked={
                        filterInputValues.products.indexOf(data.id.toString()) >
                          -1 ||
                        filterInputValues.products.includes("All") ||
                        !(filterInputValues.products.length > 0)
                      }
                    />
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {roleFieldVisible(type) && (
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Select Role</InputLabel>
              <Select
                fullWidth
                value={filterInputValues.role ? filterInputValues.role : "All"}
                size="small"
                label="Select Role"
                onChange={(e) => handleSingleSelectChange(e, "role")}
              >
                <MenuItem key={"All"} value={"All"}>
                  All
                </MenuItem>
                {userRolesMasterData.length > 0 &&
                  userRolesMasterData.map((data: any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {StatusFieldVisible(type) && (
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Status</InputLabel>
              <Select
                fullWidth
                value={
                  filterInputValues.status.length == 0
                    ? "All"
                    : filterInputValues.status
                }
                size="small"
                label="Status"
                onChange={(e) => handleSingleSelectChange(e, "status")}
              >
                <MenuItem value={"All"} key={"All"}>
                  All
                </MenuItem>
                <MenuItem value={"active"} key={"active"}>
                  Active
                </MenuItem>
                <MenuItem value={"in_active"} key={"in_active"}>
                  In-Active
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {inputproductFieldVisible(type) && (
          <Grid item xs={4} width={250}>
            <FormControl fullWidth size="small">
              <InputLabel>Inputs(Max 3)</InputLabel>

              <Select
                multiple
                fullWidth
                value={filterInputValues.inputProducts}
                size="small"
                label="Inputs(Max 3)"
                onChange={(e) => handleSelectChange(e, "inputProducts")}
                renderValue={(selected) =>
                  RenderMultiSelectValues(selected, inputBasedOnProductTypeData)
                }
              >
                {inputBasedOnProductTypeData?.map((data: any) => (
                  <MenuItem key={data.id} value={data.id.toString()}>
                    <Checkbox
                      checked={
                        filterInputValues.inputProducts.indexOf(
                          data.id.toString()
                        ) > -1 ||
                        filterInputValues.inputProducts[data.id] === data.id
                      }
                    />
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {zoneFieldVisible(userRole, type) && (
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Zone</InputLabel>
              <Select
                multiple={territoryMultiSelect}
                fullWidth
                disabled={zoneFieldDisable(userRole)}
                value={
                  filterInputValues.zone.length > 0
                    ? filterInputValues.zone
                    : ["All"]
                }
                size="small"
                label="Zone"
                onChange={(e) => handleSelectChange(e, Territory.ZONE)}
              >
                <MenuItem
                  value={"All"}
                  sx={{ justifyItems: "center", alignItems: "center" }}
                >
                  All
                </MenuItem>
                {zoneMasterData.length > 0 ? (
                  zoneMasterData?.map((name: any) => (
                    <MenuItem value={name.id.toString()}>{name.name}</MenuItem>
                  ))
                ) : (
                  <MenuItem
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <CircularProgress size={20} />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        )}
        {regionFieldVisible(userRole, type) && (
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Region</InputLabel>
              <Select
                multiple={territoryMultiSelect}
                fullWidth
                disabled={
                  regionFieldDisable(userRole, filterInputValues.zone.length) ||
                  filterInputValues.zone.includes("All")
                }
                value={
                  filterInputValues.region.length > 0
                    ? filterInputValues.region
                    : ["All"]
                }
                size="small"
                label="Region"
                onChange={(e) => handleSelectChange(e, Territory.REGION)}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {regionMasterData.length > 0 ? (
                  regionMasterData?.map((name: any) => (
                    <MenuItem value={name.id.toString()}>{name.name}</MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <CircularProgress size={20} />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        )}
        {areaFieldVisible(userRole, type) && (
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Area</InputLabel>
              <Select
                multiple={territoryMultiSelect}
                fullWidth
                disabled={
                  areaFieldDisable(userRole, filterInputValues.region.length) ||
                  filterInputValues.region.includes("All") ||
                  filterInputValues.zone.includes("All")
                }
                value={
                  filterInputValues.area.length > 0
                    ? filterInputValues.area
                    : ["All"]
                }
                size="small"
                label="Area"
                onChange={(e) => handleSelectChange(e, Territory.AREA)}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {areaMasterData.length > 0 ? (
                  areaMasterData?.map((name: any) => (
                    <MenuItem value={name.id.toString()}>{name.name}</MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <CircularProgress size={20} />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        )}
        {HQFieldVisible(userRole, type) && (
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Headquarter</InputLabel>
              <Select
                fullWidth
                disabled={
                  HQFieldDisable(userRole, filterInputValues.area.length) ||
                  filterInputValues.area.includes("All") ||
                  filterInputValues.region.includes("All") ||
                  filterInputValues.zone.includes("All")
                }
                value={
                  filterInputValues.headquarter.length > 0
                    ? filterInputValues.headquarter
                    : ["All"]
                }
                size="small"
                label="Headquarter"
                onChange={(e) => handleSelectChange(e, Territory.HEADQUATER)}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {headquarterMasterData.length > 0 ? (
                  headquarterMasterData?.map((name: any) => (
                    <MenuItem value={name.id.toString()}>{name.name}</MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <CircularProgress size={20} />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        )}

        {BrickFieldVisible(userRole, type) && (
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Brick</InputLabel>
              <Select
                multiple={territoryMultiSelect}
                fullWidth
                disabled={
                  BrickFieldDisable(filterInputValues.headquarter.length) ||
                  filterInputValues.headquarter.includes("All") ||
                  filterInputValues.area.includes("All") ||
                  filterInputValues.region.includes("All") ||
                  filterInputValues.zone.includes("All")
                }
                value={
                  filterInputValues.brick.length > 0
                    ? filterInputValues.brick
                    : ["All"]
                }
                size="small"
                label="Brick"
                onChange={(e) => handleSelectChange(e, Territory.BRICKS)}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {bricksMasterData.length > 0 ? (
                  bricksMasterData?.map((name: any) => (
                    <MenuItem value={name.id.toString()}>{name.name}</MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <CircularProgress size={20} />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        )}
        {segementFieldVisible(type) && (
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Segment</InputLabel>
              <Select
                fullWidth
                disabled={
                  segementFieldDisable(filterInputValues.headquarter.length) ||
                  segementFieldDisable(filterInputValues.department.length)
                }
                value={
                  filterInputValues.segement.length > 0
                    ? filterInputValues.segement
                    : ["All"]
                }
                size="small"
                label="Segement"
                onChange={(e) => handleSelectChange(e, "segement")}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {customerMetadata.map((data) => (
                  <MenuItem value={data.code}>{data.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} justifyContent={"center"}>
          <Stack direction="row" spacing={1} justifyContent={"center"}>
            <Button
              disabled={DisableSubmitButton(filterInputValues, userRole, type)}
              variant="contained"
              onClick={onSubmitFilters}
              size="small"
            >
              <Typography color={"white"}>Submit</Typography>
            </Button>
            {type !== ReportScreen.INPUT_UTILIZATION &&
              type !== ReportScreen.INPUT_UTILIZATION_BATCH_WISE_SUMMARY &&
              type !== ReportScreen.INPUT_UTILIZATION_CUSTOMER_WISE_SUMMARY && (
                <Button
                  variant="text"
                  onClick={clearAll}
                  color="error"
                  size="small"
                >
                  <Typography>Clear</Typography>
                </Button>
              )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
export default ReportFiltersComponentMobile;
