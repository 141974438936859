import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Chip, IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React from "react";
import {
  AreaMasterType,
  HeadquarterMasterType,
  TitleType,
} from "../../../../../domain/models/zone-master/zone-master-data";
import HQDataTable from "./HQDataTable";

type RowProps = {
  selectedTerritoryName: TitleType;
  row: AreaMasterType;
  titleChange: Function;
  setTitle: Function;
};
const AreaDataTable: React.FC<RowProps> = ({
  row,
  setTitle,
  selectedTerritoryName,
}) => {
  const [openRegion, setOpenRegion] = React.useState(false);

  return (
    <>
      <TableRow sx={{ width: "17%" }}>
        <TableCell>
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <IconButton
              size="small"
              sx={{ marginLeft: "20px" }}
              onClick={() => {
                selectedTerritoryName.region = row.name;
                {
                  openRegion
                    ? setTitle(`${selectedTerritoryName.zone}`)
                    : setTitle(`${selectedTerritoryName.zone} > ${row.name}`);
                }
                setOpenRegion(!openRegion);
              }}
            >
              {openRegion ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Chip
              label="A"
              sx={{
                color: "#687182",
                backgroundColor: "#E9EDF5",
                fontWeight: 600,
                width: "30px",
              }}
              size="small"
            />
            <Typography fontWeight={500} color={"#687182"}>
              {row.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            1
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {row.vacantArea}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {row.hqCount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {row.totalVacantHQ}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {row.brickCount}
          </Typography>
        </TableCell>
      </TableRow>
      {openRegion &&
        row.headquarters.map((row: HeadquarterMasterType, key: number) => (
          <HQDataTable
            row={row}
            setTitle={setTitle}
            selectedTerritoryName={selectedTerritoryName}
          />
        ))}
    </>
  );
};

export default AreaDataTable;
