import { useEffect, useState } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import ReportFiltersComponent from "../ReportFiltersComponent";
import ReportFiltersLoading from "../ReportFiltersLoading";
import ReportScreen from "../../../../common/enums/ReportScreens";
import Roles from "../../../../common/enums/Roles";
import { FetchState } from "../../../../domain/models/fetch-state";
import { ReportFilters } from "../../../../domain/models/report-filters";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { InputUsageSummaryStore } from "../../../../store/main/InputUsageReportStore";
import PivotTable from "../../PivotTable";

const InputUtilizationPivot = () => {
  const {
    fetchInputUsageSummary,
    inputUsageSummaryData,
    inputUsageSummaryLoading,
    inputBasedOnProductTypLoading,
    inputBasedOnProductTypeData,
    inputUsageSummaryFilters,
  } = InputUsageSummaryStore();

  const { decodeToken, userDetails, userRole } = CustomerDatabaseStore();

  useEffect(() => {
    decodeToken();
  }, []);

  const customizeCellFunction = (cell: any, data: any) => {
    if (data.type === "row" && data.label === "") {
      cell.css({
        width: "100px",
        color: "red",
      });
    }
  };

  const formattedData: any = {
    data: [],

    rows: [
      {
        uniqueName: "zone",
      },
      {
        uniqueName: "region",
      },
      {
        uniqueName: "area",
      },
      {
        uniqueName: "headquarter",
      },
      {
        uniqueName: "full_name",
        caption: "Empolyee Name",
      },
      {
        uniqueName: "input_name",
        caption: "Product Name",
      },
    ],
    columns: [{ uniqueName: ["Measures"] }],
    measures: [
      {
        uniqueName: "input_name",
        caption: "Product Name",
      },
      {
        uniqueName: "dispatched",
        caption: "Dispatched",
        formula: "sum('dispatched')",
        format: "quantity",
      },
      {
        uniqueName: "quantity_received",
        caption: "Quantity Received",
        formula: "sum('quantity_received')",
        format: "quantity",
      },
      {
        uniqueName: "difference",
        caption: "Dispatch and received difference",
        formula: "sum('dispatched')-sum('quantity_received')",
        format: "quantity",
      },
      {
        uniqueName: "distributed",
        caption: "Distributed",
        formula: "sum('distributed')",
        format: "quantity",
      },
      {
        uniqueName: "distributed_performance",
        caption: "Distributed Performance",
        formula: "(sum('distributed')*100/sum('quantity_received'))",
        format: "precision",
        aggregation: "percent",
      },
      {
        uniqueName: "ack_months",
        caption: "Acknowledge Months",
      },
      {
        uniqueName: "month_of_use",
        caption: "Month of use",
      },
    ],
    expands: {
      expandAll: "true",
    },
    grandTotalCaption: "",
    options: {
      grid: {
        showHeaders: false,
        showHierarchyCaptions: true,
        type: "flat",
      },
      showCalculatedValuesButton: true,
      sorting: "on",
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
      defaultHierarchySortName: "asc",
    },
    conditions: [
      {
        formula: "#value!=0",

        measure: "difference",
        format: {
          color: "red",
        },
      },
      {
        formula: "#value >= 50 & #value <= 80",
        measure: "distributed_performance",
        format: {
          color: "orange",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value >= 80",
        measure: "distributed_performance",
        format: {
          color: "green",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value < 50",
        measure: "distributed_performance",
        format: {
          color: "red",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
    ],
    formats: [
      {
        name: "precision",
        decimalPlaces: 2,
        thousandsSeparator: ",",
      },

      {
        name: "quantity",
        thousandsSeparator: ",",
      },
    ],
  };

  useEffect(() => {
    if (inputUsageSummaryData.length > 0) {
      inputUsageSummaryData.map((data, key) => {
        if (data.full_name) {
          let rowData = {
            zone: data.zone,
            region: data.region,
            area: data.area,
            headquarter: data.headquarter,
            dispatched: data.dispatched ? parseInt(data.dispatched) : 0,
            distributed: data.distributed ? data.distributed : 0,
            quantity_received: data.quantity_received
              ? parseInt(data.quantity_received)
              : 0,
            ack_months: data.ack_months ? data.ack_months : "-",
            month_of_use: data.month_of_use ? data.month_of_use : "-",
            full_name: `${data.full_name} (${data.role && data.role.name})`,
            input_name: data.input_name,
            difference:
              (data.dispatched ? parseInt(data.dispatched) : 0) -
              (data.quantity_received ? parseInt(data.quantity_received) : 0),
          };

          formattedData.data.push(rowData);
        }
      });
    }
  }, [inputUsageSummaryData]);

  const customizeToolbar = (toolbar: any) => {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      userDetails &&
        userDetails.role &&
        userDetails.role.visibility == 0 &&
        delete tabs[3];
      return tabs;
    };
  };

  const filterInputUtilization = (filter: ReportFilters) => {
    inputUsageSummaryFilters.role_id = filter.role;
    inputUsageSummaryFilters.input_ids = filter.inputProducts;
    fetchInputUsageSummary();
  };

  const clearInputUtilization = (filter: ReportFilters) => {
    inputUsageSummaryFilters.role_id = "";
    inputUsageSummaryFilters.input_ids = [];
    fetchInputUsageSummary();
  };
  return (
    <Stack spacing={2}>
      {Object.keys(userDetails).length > 0 ? (
        <ReportFiltersComponent
          type={ReportScreen.INPUT_UTILIZATION}
          successCallback={filterInputUtilization}
          clearSuccessCallBack={clearInputUtilization}
          gridColumns={20.5}
          userRole={Roles.COUTNRY_MANAGER}
          userDetails={userDetails}
        />
      ) : (
        <ReportFiltersLoading />
      )}
      {inputBasedOnProductTypLoading == FetchState.SUCCESS &&
        inputBasedOnProductTypeData.length == 0 && (
          <Typography variant="subtitle2" color={"error"} marginTop={5}>
            No inputs available for product type selected
          </Typography>
        )}
      {inputUsageSummaryLoading == FetchState.LOADING && (
        <Stack alignItems={"center"} height={100} justifyContent={"center"}>
          <CircularProgress />
        </Stack>
      )}
      {inputUsageSummaryLoading == FetchState.SUCCESS &&
        (inputUsageSummaryData.length > 0 ? (
          <PivotTable
            formattedData={formattedData}
            toolbar={true}
            customizeToolbar={customizeToolbar}
            height="80vh"
            customizeCell={customizeCellFunction}
          />
        ) : (
          <Typography variant="h6">No Records Found</Typography>
        ))}
    </Stack>
  );
};

export default InputUtilizationPivot;
