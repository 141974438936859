import { useEffect } from "react";
import { CustomerDetailingReportStore } from "../../store/main/CustomerDetailingReportStore";
import CustomerDetailingReportDesktop from "../components/desktop/customer-detailing-report/CustomerdetailingReportDesktop";

const CustomerDetailingReport = () => {
  const { fetchCustomerDetailingReport } = CustomerDetailingReportStore();
  useEffect(() => {
    fetchCustomerDetailingReport();
  }, []);
  return <CustomerDetailingReportDesktop />;
};
export default CustomerDetailingReport;
