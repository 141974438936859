import { StateCreator } from "zustand";
import { TerritoryData } from "../../../domain/models/territory-data";
import { RemoteFetchHeadquarterMaster } from "../../../data/usecases/remote-fetch-headquarter";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";

export interface HeadquarterSliceInterface {
  headquarterMasterData: TerritoryData[];
  fetchHeadquarterMaster: Function;
  clearHeadquarterMaster: Function;
}

const initialStates = {
  headquarterMasterData: [],
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

const useFetchHeadquarterMasterSlice: StateCreator<
  HeadquarterSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchHeadquarterMaster: async (id: string) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchHeadquarterMaster = new RemoteFetchHeadquarterMaster(
      `${BASE_URL}${Endpoints.FETCH_HEADQUARTER_MASTER}`,
      axiosHttpClient
    );
    let payload = { area_ids: id };
    set(() => ({ headquarterMasterData: [] }));
    let result = await remoteFetchHeadquarterMaster.fetch(payload);
    if (result) {
      set(() => ({ headquarterMasterData: result.headquarters }));
    }
  },
  clearHeadquarterMaster: () => {
    set(() => ({ headquarterMasterData: [] }));
  },
});
export default useFetchHeadquarterMasterSlice;
