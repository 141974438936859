import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { DeactivateUser } from "../../../domain/useages/user-management/deactivate-user";
import { RemoteDeactivateUser } from "../../../data/usecases/user-management/remote-deactivate-user";

export interface DeactivateUserInterface {
  deactivateUser: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useDeactivateUserSlice: StateCreator<DeactivateUserInterface> = (
  set,
  get
) => ({
  deactivateUser: async (
    id: number,
    payload: DeactivateUser.Params,
    handleClose: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteDeactivateUser = new RemoteDeactivateUser(
      `${BASE_URL}${Endpoints.DEACTIVATE_USER}`,
      axiosHttpClient
    );
    let result = await remoteDeactivateUser.deactivate(id.toString(), payload);

    if (result) {
      handleClose();
    } else {
      handleClose();
    }
  },
});
