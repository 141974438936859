import { useEffect } from "react";
import { CustomerMissingStore } from "../../../../store/main/CustomerMissingReportStore";
import PivotTable from "../../PivotTable";
import { FetchState } from "../../../../domain/models/fetch-state";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  CustomerMissing,
  CustomerTypes,
} from "../../../../domain/models/customer-missing-report/customer-missing-report";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Roles from "../../../../common/enums/Roles";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";

const CustomerMissingTable = () => {
  const { customerMissingData, fetchCustomerMissingState } =
    CustomerMissingStore();
  const { userDetails } = CustomerDatabaseStore();
  const formattedData: any = {
    data: [],
    slice: {
      rows: [
        {
          uniqueName: "region",
        },
        {
          uniqueName: "area",
        },
        {
          uniqueName: "headquarter",
        },
        {
          uniqueName: "brick",
        },
      ],
      columns: [
        {
          uniqueName: "customer_type",
        },
        {
          uniqueName: "Measures",
        },
      ],
      measures: [
        {
          uniqueName: "total_active",
          caption: "Total Active",
          formula: "sum('total_active')",
          format: "separator",
        },
        {
          uniqueName: "total_missed",
          caption: "Total Missed",
          formula: "sum('total_missed')",
          format: "separator",
        },
      ],
    },

    grandTotalCaption: "",
    options: {
      grid: {
        showHeaders: false,
        showHierarchyCaptions: true,
      },
      showCalculatedValuesButton: true,
      sorting: "on",
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
      defaultHierarchySortName: "asc",
    },
    formats: [
      {
        name: "separator",
        thousandsSeparator: ",",
      },
    ],
    customizeCell: function (cell: any, data: any) {
      if (cell.type === "value" && cell.value === null) {
        cell.text = "0";
        cell.value = 0;
      }
    },
  };
  useEffect(() => {
    if (fetchCustomerMissingState === FetchState.SUCCESS) {
      customerMissingData.forEach((data: CustomerMissing, key: number) => {
        if (data.customer_types.length > 0) {
          data.customer_types.forEach(
            (customer: CustomerTypes, key: number) => {
              const rowData = {
                region: data.region,
                area: data.area,
                headquarter: data.headquarter,
                brick: data.brick,
                customer_type: customer.customer_type,
                total_active: customer.total_active,
                total_missed:
                  customer.total_active - customer.visited_customers < 0
                    ? 0
                    : customer.total_active - customer.visited_customers,
              };
              formattedData.data.push(rowData);
            }
          );
        }
      });
    }
  }, [customerMissingData]);

  const customizeToolbar = (toolbar: any) => {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      userDetails &&
        userDetails.role &&
        userDetails.role.visibility == 0 &&
        delete tabs[3];
      return tabs;
    };
  };
  const customizeCell = (cell: any, data: any) => {
    if (
      (cell.type === "total_customers" || cell.type === "total_missed") &&
      cell.value === null
    ) {
      cell.text = "0";
      cell.value = 0;
    }
  };
  return (
    <>
      {fetchCustomerMissingState === FetchState.SUCCESS && (
        <PivotTable
          formattedData={formattedData}
          toolbar={true}
          customizeToolbar={customizeToolbar}
          customizeCell={customizeCell}
        />
      )}
      {fetchCustomerMissingState === FetchState.LOADING && (
        <Box
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {fetchCustomerMissingState === FetchState.ERROR && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="60vh"
        >
          <HighlightOffIcon sx={{ fontSize: 50 }} color="error" />
          <Typography variant="h6">NO RECORD FOUND</Typography>
        </Box>
      )}
    </>
  );
};
export default CustomerMissingTable;
