import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ReportFiltersComponent from "../components/desktop/ReportFiltersComponent";
import ReportScreen from "../../common/enums/ReportScreens";
import { CustomerDatabaseStore } from "../../store/main/CustomerDatabaseStore";
import { useEffect } from "react";
import ReportFiltersLoading from "../components/desktop/ReportFiltersLoading";
import { TerritoryDataStore } from "../../store/main/TerritoryDataStore";
import { ReportFilters } from "../../domain/models/report-filters";
import { TerritoryData } from "../../domain/models/territory-data";
import moment from "moment";
import { BusinessPlanningStore } from "../../store/main/BusinessPlanningStore";
import { FetchState } from "../../domain/models/fetch-state";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const BusinessPlanning = () => {
  const { userDetails, userRole, decodeToken, selectedPanel } =
    CustomerDatabaseStore();
  const { fetchDepartment, headquarterMasterData } = TerritoryDataStore();
  const { getBusinessTemplateData, BusinessTemplateData, fetchTemplateState } =
    BusinessPlanningStore();
  useEffect(() => {
    fetchDepartment();
    decodeToken();
  }, []);
  const filterData = (filters: ReportFilters) => {
    const arr = headquarterMasterData.find(
      (name: TerritoryData) => name.id === parseInt(filters.headquarter[0])
    );
    const year = moment(filters.date?.toString()).format("yyyyMM");
    const payload = {
      department_name: userRole.dept_code,
      ga_code: arr?.ga_code,
      template_name: filters.template,
      month_year: year,
    };
    getBusinessTemplateData(payload);
  };
  const clearData = () => {};
  return (
    <Stack spacing={2} padding={2}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack width={"100vw"}>
          {!selectedPanel &&
            (Object.keys(userDetails).length > 0 ? (
              <ReportFiltersComponent
                type={ReportScreen.BUSINESS_PLANNING}
                successCallback={filterData}
                clearSuccessCallBack={clearData}
                userRole={userRole.role}
                userDetails={userDetails}
                gridColumns={17}
                territoryMultiSelect={false}
              />
            ) : (
              <ReportFiltersLoading />
            ))}
        </Stack>
      </Stack>

      {BusinessTemplateData.length > 0 && (
        <Box sx={{ height: "85vh" }}>
          <iframe
            id="dashboard"
            height="100%"
            width="100%"
            src={BusinessTemplateData[0].url}
            frameBorder={0}
            allowFullScreen
          />
        </Box>
      )}
      {fetchTemplateState == FetchState.DEFAULT && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="85vh"
        >
          <Typography variant="h6" textAlign="center">
            PLEASE APPLY FILTER FOR DISPLAY
          </Typography>
        </Box>
      )}
      {fetchTemplateState == FetchState.LOADING && (
        <Box
          sx={{
            height: "85vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {BusinessTemplateData.length <= 0 &&
        fetchTemplateState == FetchState.SUCCESS && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="85vh"
          >
            <HighlightOffIcon sx={{ fontSize: 50 }} color="error" />
            <Typography variant="h6">NO RECORD FOUND</Typography>
          </Box>
        )}
    </Stack>
  );
};
export default BusinessPlanning;
