import { create } from "zustand";
import { useFetchMonthlyHQSpecialitiesSummarySlice } from "../slices/CoverageReportsSlices/fetchMonthlyHeadquarterSpecialitiesSummarySlice";
import { useFetchMonthlyHeadquarterSummarySlice } from "../slices/CoverageReportsSlices/fetchMonthlyHeadquarterSummarySlice";
import { useFetchVisitSummarySlice } from "../slices/CoverageReportsSlices/fetchVisitSummarySlice";
import { useFetchSpecialitiesSlice } from "../slices/fetchSpecalitiesSlice";

interface CoverageReportsInterface
  extends ReturnType<typeof useFetchSpecialitiesSlice>,
    ReturnType<typeof useFetchMonthlyHeadquarterSummarySlice>,
    ReturnType<typeof useFetchMonthlyHQSpecialitiesSummarySlice>,
    ReturnType<typeof useFetchVisitSummarySlice> {}

export const CoverageReportsStore = create<CoverageReportsInterface>(
  (...a) => ({
    ...useFetchMonthlyHQSpecialitiesSummarySlice(...a),
    ...useFetchMonthlyHeadquarterSummarySlice(...a),
    ...useFetchSpecialitiesSlice(...a),
    ...useFetchVisitSummarySlice(...a),
  })
);
