import { FetchInvoice } from "../../../domain/useages/primary-sales-invoice-reports/fetch-invoices";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchInvoice implements FetchInvoice {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    month: number,
    year: number,
    params?: FetchInvoice.Params
  ): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url
        .replace(":month", month.toString())
        .replace(":year", year.toString()),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status === 200) {
      return httpResponse.data;
    }
  }
}
