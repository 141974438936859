import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Chip, IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React from "react";
import {
  HeadquarterMasterType,
  TitleType,
} from "../../../../../domain/models/zone-master/zone-master-data";
import BrickTable from "./BrickTable";

type RowProps = {
  row: HeadquarterMasterType;
  setTitle: Function;
  selectedTerritoryName: TitleType;
};
const HQDataTable: React.FC<RowProps> = ({
  row,
  setTitle,
  selectedTerritoryName,
}) => {
  const [openArea, setOpenArea] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <Stack direction="row" alignItems={"Center"} spacing={1}>
            <IconButton
              size="small"
              sx={{ marginLeft: "40px" }}
              onClick={() => {
                selectedTerritoryName.area = row.name;
                {
                  openArea
                    ? setTitle(
                        `${selectedTerritoryName.zone} > ${selectedTerritoryName.region}`
                      )
                    : setTitle(
                        `${selectedTerritoryName.zone} > ${selectedTerritoryName.region} > ${row.name}`
                      );
                }
                setOpenArea(!openArea);
              }}
            >
              {openArea ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Chip
              label="HQ"
              sx={{
                color: "#687182",
                backgroundColor: "#E9EDF5",
                fontWeight: 600,
                width: "40px",
              }}
              size="small"
            />
            <Typography fontWeight={500} color={"#687182"}>
              {row.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">-</TableCell>
        <TableCell align="center">-</TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            1
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {row.vacantHQ}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {row.brickCount}
          </Typography>
        </TableCell>
      </TableRow>

      {openArea && (
        <BrickTable
          row={row}
          setTitle={setTitle}
          selectedTerritoryName={selectedTerritoryName}
        />
      )}
    </>
  );
};

export default HQDataTable;
