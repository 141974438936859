import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { RemoteResetUserPassword } from "../../../data/usecases/user-management/remote-reset-user-password";
import { ResetUserPassword } from "../../../domain/useages/user-management/reset-user-password";

export interface ResetUserPasswordInterface {
  resetUserPassword: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useResetUserPasswordSlice: StateCreator<
  ResetUserPasswordInterface
> = (set, get) => ({
  resetUserPassword: async (
    id: number,
    payload: ResetUserPassword.Params,
    handleClose: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteResetUserPassword = new RemoteResetUserPassword(
      `${BASE_URL}${Endpoints.RESET_USER_MANAGEMEMT_PASSWORD}`,
      axiosHttpClient
    );
    let result = await remoteResetUserPassword.reset(id.toString(), payload);

    if (result) {
      handleClose();
    }
  },
});
