import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { DeactivateBrick } from "../../../domain/useages/brick-management/deactivate_brick";
import { AUTH_TOKEN_KEY, BASE_URL_KEY, AUTH_HEADER } from "../../../base";
import { RemoteDeactivateBrick } from "../../../data/usecases/brick-management/remote-deactivate-brick";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { BrickManagementStore } from "../../main/BrickManagementStore";
import { FetchState } from "../../../domain/models/fetch-state";

export interface DeactivateBrickInterface {
  deactivateBrick: Function;
  deactivateBrickState: FetchState;
}

const initialState = {
  deactivateBrickState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useDeactivateBrickSlice: StateCreator<DeactivateBrickInterface> = (
  set,
  get
) => ({
  ...initialState,
  deactivateBrick: async (id: number, payload: DeactivateBrick.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    set({ deactivateBrickState: FetchState.LOADING });
    const remoteDeactivateBrick: DeactivateBrick = new RemoteDeactivateBrick(
      `${BASE_URL}${Endpoints.DEACTIVATE_BRICK}`,
      axiosHttpClient
    );
    let result = await remoteDeactivateBrick.remove(id, payload);
    if (result.success) {
      Swal.fire({
        icon: "success",
        timer: 3000,
        text: result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
      set({ deactivateBrickState: FetchState.SUCCESS });
      BrickManagementStore.getState().fetchBrickManagement(
        BrickManagementStore.getState().brickManagementFilter
      );
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: result.errors.message ?? result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
      set({ deactivateBrickState: FetchState.SUCCESS });
    }
  },
});
