import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import PivotTable from "../../PivotTable";
import { useEffect } from "react";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import ReportScreen from "../../../../common/enums/ReportScreens";
import ReportFiltersComponent from "../ReportFiltersComponent";
import { CustomerDetailingReportStore } from "../../../../store/main/CustomerDetailingReportStore";
import { ReportFilters } from "../../../../domain/models/report-filters";
import dayjs from "dayjs";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { FetchState } from "../../../../domain/models/fetch-state";
import ReportFiltersLoading from "../ReportFiltersLoading";
import { CustomerDetailingReportType } from "../../../../domain/models/customer-detailing-report/customer-detailing-report";
import Roles from "../../../../common/enums/Roles";

const CustomerDetailingReportDesktop = () => {
  const { decodeToken, userDetails, userRole } = CustomerDatabaseStore();
  const {
    fetchCustomerDetailingReport,
    CustomerDetailingReportFilter,
    CustomerDetailingReport,
    CustomerDetailingReportState,
  } = CustomerDetailingReportStore();

  const formattedData: any = {
    data: [],
    slice: {
      rows: [
        {
          uniqueName: "zone",
        },
        {
          uniqueName: "region",
        },
        {
          uniqueName: "area",
        },
        {
          uniqueName: "headquarter",
        },
      ],
      columns: [
        {
          uniqueName: "speciality",
        },
        {
          uniqueName: "priorities",
        },
        {
          uniqueName: "Measures",
        },
      ],
      measures: [
        {
          uniqueName: "doctor",
        },
      ],
    },

    grandTotalCaption: "",
    options: {
      grid: {
        showHeaders: false,
        showHierarchyCaptions: true,
      },
      showCalculatedValuesButton: true,
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
    },
  };
  const customizeToolbar = (toolbar: any) => {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      userDetails &&
        userDetails.role &&
        userDetails.role.visibility == 0 &&
        delete tabs[3];
      return tabs;
    };
  };

  const getData = (filters: ReportFilters) => {
    CustomerDetailingReportFilter.monthYear = filters.date;
    CustomerDetailingReportFilter.brandIds = filters.brandType;
    fetchCustomerDetailingReport();
  };

  const clearData = (filters: ReportFilters) => {
    CustomerDetailingReportFilter.monthYear = dayjs(new Date());
    CustomerDetailingReportFilter.brandIds = filters.brandType;
    fetchCustomerDetailingReport();
  };
  useEffect(() => {
    if (CustomerDetailingReportState === FetchState.SUCCESS) {
      CustomerDetailingReport.forEach(
        (item: CustomerDetailingReportType, key: number) => {
          const rowData = {
            zone: item.zone,
            region: item.region,
            area: item.area,
            headquarter: item.headquarter,
            speciality: item.speciality,
            priorities: item.priorities,
            doctor: item.customer_count,
          };
          formattedData.data.push(rowData);
        }
      );
    }
    decodeToken();
  }, [CustomerDetailingReportState]);

  return (
    <>
      <Box padding={3}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6">Customer Detailing Report</Typography>
          <Stack>
            {Object.keys(userDetails).length > 0 ? (
              <ReportFiltersComponent
                type={ReportScreen.CUSTOMER_DETAILING_REPORT}
                successCallback={getData}
                clearSuccessCallBack={clearData}
                gridColumns={10}
                userRole={userRole.role}
                userDetails={userDetails}
                territoryMultiSelect={true}
              />
            ) : (
              <ReportFiltersLoading />
            )}
          </Stack>
        </Stack>
        {CustomerDetailingReportState === FetchState.SUCCESS && (
          <PivotTable
            formattedData={formattedData}
            toolbar={true}
            customizeToolbar={customizeToolbar}
          />
        )}
        {CustomerDetailingReportState === FetchState.ERROR && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="80vh"
          >
            <HighlightOffIcon sx={{ fontSize: 50 }} color="error" />
            <Typography variant="h6">NO RECORD FOUND</Typography>
          </Box>
        )}
        {CustomerDetailingReportState === FetchState.LOADING && (
          <Box
            sx={{
              height: "85vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default CustomerDetailingReportDesktop;
