import { create } from "zustand";
import { useFetchBrickWiseDataSlice } from "../slices/StandardTourProgramSlices/fetchBrickWise";

interface StandardProgramTourInterface
  extends ReturnType<typeof useFetchBrickWiseDataSlice> {}

export const StandardProgramTourStore = create<StandardProgramTourInterface>(
  (...a) => ({
    ...useFetchBrickWiseDataSlice(...a),
  })
);
