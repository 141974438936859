import {
  Box,
  Button,
  FormControl,
  Modal,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { BrickManagementStore } from "../../../../../../store/main/BrickManagementStore";
import { useEffect } from "react";
import { FetchState } from "../../../../../../domain/models/fetch-state";
import { TerritoryDataStore } from "../../../../../../store/main/TerritoryDataStore";

interface Props {
  open: boolean;
  handleClose: Function;
  successCallback: Function;
  hq_id: string;
}

type FormFields = {
  brick: string;
  distance: string;
  station: string;
  expected: string;
  headquarter: string;
  cluster: (number | string)[];
  district: string;
  workdays: string;
};

const CreateNewBrickModal: React.FC<Props> = ({
  open,
  handleClose,
  successCallback,
  hq_id,
}) => {
  const {
    fetchDistrictState,
    fetchCluster,
    addBrick,
    districtData,
    clusterData,
  } = BrickManagementStore();
  const { headquarterMasterData } = TerritoryDataStore();

  const hq = headquarterMasterData.find((hq) => hq_id === hq.id.toString());
  const handleUpdate = () => {
    const payload = {
      hq_headquarter_id: hq_id,
      no_of_work_days: watch("workdays"),
      name: watch("brick"),
      expected_business: watch("expected"),
      distance_from_hq: watch("station") === "HQ" ? "0" : watch("distance"),
      station: watch("station"),
      district: watch("district"),
      clusters:
        watch("cluster")[0] === "All"
          ? clusterData.map((item) => item.id)
          : watch("cluster"),
    };
    addBrick(payload);
    successCallback();
    handleClose();
  };
  const { handleSubmit, control, watch, setValue } = useForm<FormFields>({
    mode: "onChange",
  });
  useEffect(() => {
    setValue("cluster", ["All"]);
  }, [open]);

  useEffect(() => {
    if (fetchDistrictState === FetchState.SUCCESS && watch("district")) {
      fetchCluster(watch("district"));
    }
  }, [watch("district"), fetchDistrictState]);
  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box
        position={"absolute" as "absolute"}
        top={"50%"}
        left={"50%"}
        bgcolor={"background.paper"}
        borderRadius={"22px"}
        sx={{ transform: "translate(-50%, -50%)" }}
        width={"25%"}
        p={3}
      >
        <Typography
          fontWeight={500}
          variant="h6"
          textAlign="center"
          marginBottom={2}
        >
          Create New Brick
        </Typography>
        <Stack spacing={2}>
          <Controller
            name="brick"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Brick Name"
                  value={value}
                  size="small"
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                />
              </FormControl>
            )}
            rules={{
              pattern: {
                value: /^(?!.*[^a-zA-Z0-9 ]).{5,}$/,
                message: "Enter correct Brick Name",
              },
            }}
          />
          <Controller
            name="headquarter"
            control={control}
            defaultValue={hq?.name}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  id="outlined-disabled"
                  label="Headquarter Name"
                  value={value}
                  size="small"
                  disabled
                />
              </FormControl>
            )}
          />
          <Controller
            name="workdays"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="No of Work Days"
                  value={value}
                  size="small"
                  onChange={onChange}
                  placeholder="No of Work Days"
                  error={!!error}
                  helperText={error?.message}
                />
              </FormControl>
            )}
            rules={{
              pattern: {
                value: /^[0-9]+$/,
                message: "Enter number only",
              },
            }}
          />
          <Stack direction={"row"} gap={2}>
            <Controller
              name="station"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-label">EX/OS/HQ</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select"
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    label="EX/OS/HQ"
                  >
                    <MenuItem value="EX">EX</MenuItem>
                    <MenuItem value="OS">OS</MenuItem>
                    <MenuItem value="HQ">HQ</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="distance"
              control={control}
              defaultValue={watch("station") === "HQ" ? "0" : ""}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl size="small" fullWidth>
                  <TextField
                    fullWidth
                    focused
                    id="outlined-required"
                    label="Distance from HQ"
                    value={watch("station") === "HQ" ? "0" : value}
                    size="small"
                    disabled={watch("station") === "HQ"}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                  />
                </FormControl>
              )}
              rules={{
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Only Number allowed",
                },
              }}
            />
          </Stack>
          <Controller
            name="expected"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <TextField
                  fullWidth
                  value={value}
                  id="outlined-basic"
                  label="Expected Business"
                  size="small"
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            )}
            rules={{
              pattern: {
                value: /^[0-9]+$/,
                message: "Enter number only",
              },
            }}
          />
          <Stack direction={"row"} gap={2}>
            <Controller
              name="district"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-label2">District</InputLabel>
                  <Select
                    labelId="select-label2"
                    id="select"
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    label="District"
                  >
                    {districtData?.map((name) => (
                      <MenuItem value={name.district_code}>
                        {name.district_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="cluster"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth size="small">
                  <InputLabel id="select-label3">Cluster</InputLabel>
                  <Select
                    labelId="select-label3"
                    id="select"
                    multiple
                    label="Cluster"
                    value={value || []}
                    onChange={(e) => {
                      const selectedValues = Array.isArray(e.target.value)
                        ? e.target.value
                        : [e.target.value];
                      if (selectedValues[e.target.value.length - 1] === "All") {
                        onChange(["All"]);
                      } else {
                        const filteredValues = selectedValues.filter(
                          (val) => val !== "All"
                        );
                        onChange(filteredValues);
                      }
                    }}
                    displayEmpty
                    disabled={!watch("district")}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {clusterData?.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Stack>
        </Stack>
        <Stack
          paddingTop={3}
          direction={"row"}
          gap={3}
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleUpdate)}
            disabled={
              !(
                watch("brick") &&
                watch("headquarter") &&
                (watch("station") === "HQ" ? true : watch("distance")) &&
                watch("station") &&
                watch("expected") &&
                watch("district") &&
                watch("cluster")
              )
            }
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Create
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClose()}
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CreateNewBrickModal;
