import { Skeleton, Stack, TableCell, Typography } from "@mui/material";
import React from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
type Props = {
  columns: any[];
  height?: string;
};
const UserManagementDataLoading: React.FC<Props> = ({
  columns,
  height = "90vh",
}) => {
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: height }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      height: 2,
                      minWidth: column.minWidth,
                      backgroundColor: "lightgrey",
                      border: "1px solid #ccc",
                    }}
                  >
                    <Typography fontWeight={600} variant="subtitle2">
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
          <Stack alignItems="center" spacing={2}>
            <Skeleton width="100%" height={50}></Skeleton>
            <Skeleton width="100%" height={50}></Skeleton>
            <Skeleton width="100%" height={50}></Skeleton>
            <Skeleton width="100%" height={50}></Skeleton>
            <Skeleton width="100%" height={50}></Skeleton>
            <Skeleton width="100%" height={50}></Skeleton>
            <Skeleton width="100%" height={50}></Skeleton>
          </Stack>
        </TableContainer>
      </Paper>
    </>
  );
};

export default UserManagementDataLoading;
