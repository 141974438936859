import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { UserSummaryReportStore } from "../../store/main/UserSummaryReportStore";
import { useEffect } from "react";
import { TerritoryDataStore } from "../../store/main/TerritoryDataStore";
import UserSummaryMobile from "../components/mobile/user-summary-report/UserSummaryMobile";
import CustomerCount from "../components/desktop/user-summary-report/CustomerCount";
import Filters from "../components/desktop/user-summary-report/Filters";
import UserDeviationReport from "../components/desktop/user-summary-report/UserDeviationReport";
import UserList from "../components/desktop/user-summary-report/UserList";
import { FetchState } from "../../domain/models/fetch-state";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

const UserSummaryReport = () => {
  const {
    fetchUserChildren,
    fetchDeviationReportState,
    monthsReportDataState,
    monthSummaryState,
  } = UserSummaryReportStore();
  const { fetchDepartment } = TerritoryDataStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchUserChildren();
    fetchDepartment();
  }, []);
  return (
    <>
      {isMobile ? (
        <UserSummaryMobile />
      ) : (
        <Grid container direction={"row"}>
          <Grid item xs={2.8} marginX={"auto"} bgcolor={"#fbfbfb"}>
            <Stack>
              <Filters />
              <UserList />
            </Stack>
          </Grid>
          <Grid item xs={9.2}>
            {fetchDeviationReportState == FetchState.DEFAULT &&
            monthsReportDataState == FetchState.DEFAULT &&
            monthSummaryState == FetchState.DEFAULT ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
              >
                <PermIdentityOutlinedIcon
                  sx={{ fontSize: 148 }}
                  color="primary"
                />
                <Typography variant="h5">PLEASE SELECT USER</Typography>
                <Typography variant="h5">FOR REVIEW</Typography>
              </Box>
            ) : (
              <Box paddingTop="20px" paddingX="20px">
                <CustomerCount />
                <UserDeviationReport />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default UserSummaryReport;
