import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import { CoverageReportsStore } from "../../../../../store/main/CoverageReportsStore";
import { CustomerDatabaseStore } from "../../../../../store/main/CustomerDatabaseStore";
import { ReportFilters } from "../../../../../domain/models/report-filters";
import ReportFiltersComponent from "../../ReportFiltersComponent";
import ReportFiltersLoading from "../../ReportFiltersLoading";
import ReportScreen from "../../../../../common/enums/ReportScreens";
import { FetchState } from "../../../../../domain/models/fetch-state";
import PivotTable from "../../../PivotTable";
import Roles from "../../../../../common/enums/Roles";

export default function MonthlyHeadquarterSummaryTable() {
  const {
    monthlyHeadquarterSummaryData,
    fetchMonthlyHeadquarterSummary,
    monthlyHeadquarterSummaryDataLoading,
    monthlyHeadquarterFilters,
  } = CoverageReportsStore();
  const { decodeToken, userDetails, userRole } = CustomerDatabaseStore();

  const getMonthlyHeadquarterSummary = (filters: ReportFilters) => {
    monthlyHeadquarterFilters.monthYear = filters.date;
    fetchMonthlyHeadquarterSummary();
  };

  const clearMonthlyHeadquarterSummary = (filters: ReportFilters) => {
    monthlyHeadquarterFilters.monthYear = dayjs(new Date());
    fetchMonthlyHeadquarterSummary();
  };
  const formattedData: any = {
    data: [],
    tableSizes: {
      columns: [
        {
          idx: 2,
          width: 70,
        },
        {
          idx: 3,
          width: 70,
        },
        {
          idx: 4,
          width: 70,
        },
        {
          idx: 5,
          width: 70,
        },
        {
          idx: 6,
          width: 85,
        },
        {
          idx: 8,
          width: 70,
        },
        {
          idx: 9,
          width: 70,
        },
        {
          idx: 10,
          width: 70,
        },
        {
          idx: 11,
          width: 70,
        },
        {
          idx: 12,
          width: 85,
        },
        {
          idx: 14,
          width: 70,
        },
        {
          idx: 15,
          width: 70,
        },
        {
          idx: 16,
          width: 70,
        },
        {
          idx: 17,
          width: 70,
        },
        {
          idx: 18,
          width: 85,
        },
        {
          idx: 20,
          width: 70,
        },
        {
          idx: 21,
          width: 70,
        },
        {
          idx: 22,
          width: 70,
        },
        {
          idx: 23,
          width: 70,
        },
        {
          idx: 24,
          width: 85,
        },
        {
          idx: 26,
          width: 70,
        },
        {
          idx: 27,
          width: 70,
        },
        {
          idx: 28,
          width: 70,
        },
        {
          idx: 29,
          width: 70,
        },
        {
          idx: 30,
          width: 85,
        },
        {
          idx: 32,
          width: 70,
        },
        {
          idx: 33,
          width: 70,
        },
        {
          idx: 34,
          width: 70,
        },
        {
          idx: 35,
          width: 70,
        },
        {
          idx: 36,
          width: 85,
        },
        {
          idx: 38,
          width: 70,
        },
        {
          idx: 39,
          width: 70,
        },
        {
          idx: 40,
          width: 70,
        },
        {
          idx: 41,
          width: 70,
        },
        {
          idx: 42,
          width: 85,
        },
        {
          idx: 44,
          width: 70,
        },
        {
          idx: 45,
          width: 70,
        },
        {
          idx: 46,
          width: 70,
        },
        {
          idx: 47,
          width: 70,
        },
        {
          idx: 48,
          width: 85,
        },
      ],
    },
    rows: [
      {
        uniqueName: "zone",
      },
      {
        uniqueName: "region",
      },
      {
        uniqueName: "area",
      },
      {
        uniqueName: "headquarter",
      },
    ],
    columns: [
      {
        uniqueName: "customer_type",
      },
      { uniqueName: "Measures" },
    ],
    measures: [
      {
        uniqueName: "total_active",
        caption: "Total",
        formula: "sum('total_active')",
        format: "separator",
      },
      {
        uniqueName: "visited_customers",
        caption: "Meet",
        formula: "sum('visited_customers')",
        format: "separator",
      },
      {
        uniqueName: "covg_1",
        caption: "Cov (%)",
        formula: "(sum('visited_customers') / sum('total_active')) * 100",
        format: "precision",
      },
      {
        uniqueName: "total_missed",
        caption: "Missed",
        formula: "(sum('total_active')-sum('visited_customers'))",
        format: "separator",
      },
      {
        uniqueName: "twice_visited_customers",
        caption: "V2",
        formula: "sum('twice_visited_customers')",
        format: "separator",
      },
      {
        uniqueName: "covg_2",
        caption: "V2 Cov (%)",
        formula: "(sum('twice_visited_customers') / sum('total_active')) * 100",
        format: "precision",
      },
    ],
    expands: {
      expandAll: "true",
    },
    grandTotalCaption: "",
    options: {
      grid: {
        showHeaders: false,
        showHierarchyCaptions: true,
      },
      showCalculatedValuesButton: true,
      sorting: "on",
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
      defaultHierarchySortName: "asc",
    },
    formats: [
      {
        name: "precision",
        decimalPlaces: 2,
        thousandsSeparator: ",",
      },
      {
        name: "separator",
        thousandsSeparator: ",",
      },
    ],
    conditions: [
      {
        formula: "#value >= 50 & #value <= 80",
        measure: "covg_1",
        format: {
          color: "orange",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value >= 80",
        measure: "covg_1",
        format: {
          color: "green",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value < 50",
        measure: "covg_1",
        format: {
          color: "red",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value >= 50 && #value <= 80",
        measure: "covg_2",
        format: {
          color: "orange",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value >= 80",
        measure: "covg_2",
        format: {
          color: "green",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
      {
        formula: "#value < 50",
        measure: "covg_2",
        format: {
          color: "red",
          fontFamily: "Arial",
          fontSize: "12px",
        },
      },
    ],
  };

  useEffect(() => {
    decodeToken();
    if (monthlyHeadquarterSummaryData.length > 0) {
      monthlyHeadquarterSummaryData.map((val, key) => {
        val.customer_types.forEach(function (customerType) {
          if (
            val.headquarter_id &&
            customerType.total_active &&
            customerType.total_active != "0"
          ) {
            var rowData = {
              zone: val.zone,
              region: val.region,
              area: val.area,
              headquarter: val.headquarter,
              customer_type: customerType.customer_type,
              visited_customers: customerType.visited_customers
                ? customerType.visited_customers
                : 0,
              total_active: customerType.total_active
                ? customerType.total_active
                : 0,
              total_missed:
                parseInt(customerType.total_active) -
                customerType.visited_customers,
              covg_1:
                (customerType.visited_customers /
                  parseInt(customerType.total_active)) *
                100,
              twice_visited_customers: customerType.twice_visited_customers
                ? customerType.twice_visited_customers
                : 0,
              covg_2:
                (customerType.twice_visited_customers /
                  parseInt(customerType.total_active)) *
                100,
            };
            formattedData.data.push(rowData);
          }
        });
      });
    }
  }, [monthlyHeadquarterSummaryData]);

  const customizeToolbar = (toolbar: any) => {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      userDetails &&
        userDetails.role &&
        userDetails.role.visibility == 0 &&
        delete tabs[3];
      return tabs;
    };
  };

  return (
    <Stack spacing={2}>
      {Object.keys(userDetails).length > 0 ? (
        <ReportFiltersComponent
          type={ReportScreen.MONTHY_HQ_WISE_SUMMARY_REPORT}
          successCallback={getMonthlyHeadquarterSummary}
          clearSuccessCallBack={clearMonthlyHeadquarterSummary}
          gridColumns={13.5}
          userRole={userRole.role}
          userDetails={userDetails}
        />
      ) : (
        <ReportFiltersLoading />
      )}
      {monthlyHeadquarterSummaryDataLoading == FetchState.LOADING && (
        <Stack alignItems={"center"} height={100} justifyContent={"center"}>
          <CircularProgress />
        </Stack>
      )}
      {monthlyHeadquarterSummaryDataLoading == FetchState.SUCCESS &&
        (monthlyHeadquarterSummaryData.length > 0 ? (
          // formattedData.data.length > 0 ? (
          <PivotTable
            formattedData={formattedData}
            toolbar={true}
            customizeToolbar={customizeToolbar}
          />
        ) : (
          // ) : (
          //   <Typography variant="h6">No Total Active Visits </Typography>
          // )
          <Typography variant="h6">No Records Found</Typography>
        ))}
    </Stack>
  );
}
