import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import Panels from "../../../../common/enums/Panels";
import Status from "../../../../common/enums/Status";
import { CustomerSummaryType } from "../../../../domain/models/customer-database/customer-summary";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { TerritoryDataStore } from "../../../../store/main/TerritoryDataStore";
import ReportScreen from "../../../../common/enums/ReportScreens";

type Props = {
  setIsRestricted: Function;
  setButtonAccess: Function;
  type: string;
};
const CustomerSummary: React.FC<Props> = ({
  setButtonAccess,
  setIsRestricted,
  type,
}) => {
  const {
    fetchCustomerData,
    filterStore,
    customerSummaryCounts,
    panelName,
    handlePageNumber,
    handleClearSelectedCustomers,
  } = CustomerDatabaseStore();

  const { customerTypesData } = TerritoryDataStore();

  const [selectedSummaryCard, setSelectedSummaryCard] = useState<
    number | string
  >(0);
  const [selectedCustomerType, setSelectedCustomerType] = useState<string>("");
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const fetchStockists = (
    id: number | string,
    customerType: string,
    cardName?: string
  ) => {
    handleClearSelectedCustomers();

    let value = customerTypesData.find((type) => type.id === id);
    if (value?.button_access == 1) {
      setButtonAccess(true);
    } else {
      setButtonAccess(false);
    }
    if (value?.is_restricted == 1) {
      setIsRestricted(true);
    } else {
      setIsRestricted(false);
    }
    if (cardName === "ALL") {
      setIsAllSelected(!isAllSelected);
      setIsRestricted(true);
    } else {
      setIsAllSelected(false);
    }
    handlePageNumber(1);

    if (selectedCustomerType === customerType) {
      if (isSelected === false) {
        filterStore.customer_type_id = id;
      } else if (isSelected) {
        filterStore.customer_type_id = "";
      }
      setIsSelected(!isSelected);
    } else {
      setIsSelected(true);
      filterStore.customer_type_id = id;
    }

    filterStore.page = 1;
    if (panelName === Panels.PENDING_MAPPING) {
      filterStore.status = Status.ACTIVE;
      filterStore.mapping_status = Status.PENDING;
    } else if (panelName === Panels.PENDING_APPROVAL) {
      filterStore.status = Status.PENDING;
      filterStore.mapping_status = "";
      filterStore.status = Status.PENDING;
    } else if (panelName === Panels.ARCHIVED) {
      filterStore.status = Status.ACTIVE;
      filterStore.mapping_status = "";
      filterStore.status = Status.IN_ACTIVE;
    }
    setSelectedSummaryCard(id);
    fetchCustomerData();
    setSelectedCustomerType(customerType);
  };

  const stockistCount: number | undefined =
    customerSummaryCounts.customer_type.find(
      (count) => count.id === 1
    )?.total_customers;

  return (
    <Stack direction="row" justifyContent="space-evenly">
      <Stack
        padding={2}
        onClick={() => fetchStockists("", "", "ALL")}
        sx={{ cursor: "pointer" }}
        boxShadow={
          isAllSelected || !isSelected ? "rgba(0, 0, 0, 0.24) 0px 3px 8px" : ""
        }
      >
        <Typography
          variant="caption"
          color="primary.light"
          overflow="visible"
          sx={{ whiteSpace: "nowrap" }}
        >
          All
        </Typography>
        <Typography variant="h6" fontWeight={600} color="primary">
          {type === ReportScreen.CUSTOMER_DATABASE
            ? customerSummaryCounts.total_customers
              ? customerSummaryCounts.total_customers
              : "0"
            : customerSummaryCounts.total_customers &&
              typeof stockistCount != "undefined"
            ? customerSummaryCounts.total_customers - stockistCount
            : customerSummaryCounts.total_customers}
        </Typography>
        <Typography variant="caption" color="primary.light">
          {customerSummaryCounts.coverage
            ? `Coverage ${customerSummaryCounts.coverage}%`
            : `Coverage 0.00%`}
        </Typography>
      </Stack>
      {customerSummaryCounts.customer_type.map(
        (count: CustomerSummaryType, index: number) => {
          return (
            <>
              {!(type === ReportScreen.BRICK_MANAGEMENT && count.id === 1) && (
                <>
                  <Box borderRight="1px solid lightgrey" />

                  <Stack
                    padding={2}
                    onClick={() => fetchStockists(count.id, count.name)}
                    sx={{ cursor: "pointer" }}
                    boxShadow={
                      customerSummaryCounts.customer_type[index].id ===
                        selectedSummaryCard && isSelected
                        ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                        : ""
                    }
                  >
                    <Typography
                      variant="caption"
                      color="primary.light"
                      overflow="visible"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {count.name ? count.name : ""}
                    </Typography>
                    <Typography variant="h6" fontWeight={600} color="primary">
                      {count.total_customers ? count.total_customers : "0"}
                    </Typography>
                    <Typography variant="caption" color="primary.light">
                      {count.coverage
                        ? `Coverage ${count.coverage}%`
                        : `Coverage 0.00%`}
                    </Typography>
                  </Stack>
                </>
              )}
            </>
          );
        }
      )}
    </Stack>
  );
};
export default CustomerSummary;
