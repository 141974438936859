import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteFetchDailySales } from "../../../data/usecases/daily-sales-report/remote-fetch-daily-sales";
import Endpoints from "../../../domain/endpoints";
import { DailySalesReportType } from "../../../domain/models/daily-sales-report/daily-sales-report-data";
import { FetchState } from "../../../domain/models/fetch-state";
import Swal from "sweetalert2";

export interface DailySalesInterface {
  fetchDailySales: Function;
  dailySalesData: DailySalesReportType;
  dailySalesStatus: FetchState;
  dailySalesFilters: {
    brand_id?: string;
    department_id?: string;
    zone_id?: string;
    region_id?: string;
    area_id?: string;
    headquarter_id?: string;
  };
}

const initialStates = {
  dailySalesData: {} as DailySalesReportType,
  dailySalesStatus: FetchState.DEFAULT,
  dailySalesFilters: {},
};

export const useFetchDailySalesSlice: StateCreator<DailySalesInterface> = (
  set,
  get
) => ({
  ...initialStates,
  fetchDailySales: async () => {
    const axiosHttpClient = AxiosHttpClient.getInstance();
    const storage = LocalJsonStorage.getInstance();

    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchDailySales = new RemoteFetchDailySales(
      `${BASE_URL}${Endpoints.FETCH_DAILY_SALES}`,
      axiosHttpClient
    );
    set(() => ({ dailySalesStatus: FetchState.LOADING }));
    let result = await remoteFetchDailySales.fetch(get().dailySalesFilters);
    if (result) {
      set(() => ({
        dailySalesData: result,
        dailySalesStatus: FetchState.SUCCESS,
      }));
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: "Error Occured While Fetching Daily Sales Data",
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },
});
