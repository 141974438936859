import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import Endpoints from "../../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { FetchHqDistance } from "../../../domain/useages/hq-distance-report/fetch-hq-distance";
import { RemoteFetchHqDistanceReport } from "../../../data/usecases/hq-distance-report/remote-fetch-hq-distance";
import { HeadquartersType } from "../../../domain/models/hq-distance-report/hq-distance-data";

export interface fetchHqDistanceInterface {
  HqDistanceData: HeadquartersType[];
  fetchHqDistance: Function;
  fetchHqDistanceState: FetchState;
  hqDistanceFilter: FetchHqDistance.Params;
}

const initialStates = {
  fetchHqDistanceState: FetchState.DEFAULT,
  HqDistanceData: {} as HeadquartersType[],
  hqDistanceFilter: {} as FetchHqDistance.Params,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const usefetchHqDistanceSlice: StateCreator<fetchHqDistanceInterface> = (
  set
) => ({
  ...initialStates,
  fetchHqDistance: async (filters: FetchHqDistance.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    set({
      fetchHqDistanceState: FetchState.LOADING,
    });

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remotefetchHqDistance: FetchHqDistance =
      new RemoteFetchHqDistanceReport(
        `${BASE_URL}${Endpoints.HQ_DISTANCE}`,
        axiosHttpClient
      );
    let result = await remotefetchHqDistance.fetch(filters);
    if (result.success && result.headquarters.length > 0) {
      set({
        HqDistanceData: result.headquarters,
        fetchHqDistanceState: FetchState.SUCCESS,
      });
    } else {
      set({ fetchHqDistanceState: FetchState.ERROR });
    }
  },
});
