import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchMonthsReport } from "./../../../domain/useages/user-summary-report/fetch-months-report";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteFetchMonthsReport } from "../../../data/usecases/user-summary-report/remote-fetch-months-report";
import Endpoints from "../../../domain/endpoints";
import { MonthsReportType } from "../../../domain/models/user-summary-report/months-report";
import { FetchState } from "../../../domain/models/fetch-state";

export interface FetchMonthsReportInterface {
  fetchMonthsReport: Function;
  monthsReportData: MonthsReportType;
  monthsReportDataState: FetchState;
}

const initialStates = {
  monthsReportData: {} as MonthsReportType,
  monthsReportDataState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchMonthsReportSlice: StateCreator<
  FetchMonthsReportInterface
> = (set, get) => ({
  ...initialStates,
  fetchMonthsReport: async (
    year: number,
    user_id: number,
    department_id: number
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    set({ monthsReportDataState: FetchState.LOADING });

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchMonthsReport: FetchMonthsReport =
      new RemoteFetchMonthsReport(
        `${BASE_URL}${Endpoints.MONTHS_REPORT}`,
        axiosHttpClient
      );
    let result = await remoteFetchMonthsReport.fetch(
      year,
      user_id,
      department_id
    );

    if (result.orders.length != 0 || result.all_visits.length != 0) {
      set({
        monthsReportData: result,
        monthsReportDataState: FetchState.SUCCESS,
      });
    } else if (result.orders.length == 0) {
      set({
        monthsReportData: result,
        monthsReportDataState: FetchState.ERROR,
      });
    }
  },
});
