import { StateCreator } from "zustand";
import { InvoiceData } from "../../../domain/models/primary-sales-invoice/invoice";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { FetchState } from "../../../domain/models/fetch-state";
import { RemoteFetchInvoice } from "../../../data/usecases/primary-sales-invoice/remote-fetch-invoice";
import { FetchInvoice } from "../../../domain/useages/primary-sales-invoice-reports/fetch-invoices";
import Endpoints from "../../../domain/endpoints";

export interface FetchInvoiceInterface {
  invoiceData: InvoiceData[];
  dataLengthInvoice: number;
  currentInvoicePageNumber: number;
  fetchInvoiceData: Function;
  invoiceDataLoading: FetchState;
  handlePageNumber: Function;
}

const initialStates = {
  invoiceData: [],
  invoiceDataLoading: FetchState.DEFAULT,
  dataLengthInvoice: 0,
  currentInvoicePageNumber: 1,
};

export const useFetchInvoiceSlice: StateCreator<FetchInvoiceInterface> = (
  set,
  get
) => ({
  ...initialStates,
  fetchInvoiceData: async (month: number, year: number, payload: any) => {
    set(() => ({ invoiceDataLoading: FetchState.LOADING }));
    const axiosHttpClient = AxiosHttpClient.getInstance();
    const storage = LocalJsonStorage.getInstance();

    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchInvoiceData: FetchInvoice = new RemoteFetchInvoice(
      `${BASE_URL}${Endpoints.PRIMARY_INVOICE}`,
      axiosHttpClient
    );

    let result = await remoteFetchInvoiceData.fetch(month, year, payload);
    if (result) {
      set(() => ({
        dataLengthInvoice: result.total,
        invoiceData: result.primary_sales,
        invoiceDataLoading: FetchState.SUCCESS,
      }));
    }
  },
  handlePageNumber: (pageNo: number) => {
    set(() => ({ currentInvoicePageNumber: pageNo }));
  },
});
