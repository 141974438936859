import { FetchMonthSummaryReport } from "../../../domain/useages/user-summary-report/fetch-month-summary-report";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteMonthlySummaryReport implements FetchMonthSummaryReport {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    month: number,
    year: number,
    user_id: number,
    department_id: number,
    params: FetchMonthSummaryReport.Params
  ): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url
        .replace(":month", month.toString())
        .replace(":year", year.toString())
        .replace(":user_id", user_id.toString())
        .replace(":department_id", department_id.toString()),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status === 200) {
      return httpResponse.data;
    }
  }
}
