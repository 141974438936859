import { Skeleton, Stack } from "@mui/material";

type Props = {};

const RegionTabsLoading = (props: Props) => {
  return (
    <Stack direction={"row"} spacing={2} margin={2}>
      <Stack direction={"row"} spacing={1}>
        <Skeleton variant="circular" width={30} height={30}></Skeleton>
        <Skeleton variant="rectangular" width={100} height={30}></Skeleton>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Skeleton variant="circular" width={30} height={30}></Skeleton>
        <Skeleton variant="rectangular" width={100} height={30}></Skeleton>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Skeleton variant="circular" width={30} height={30}></Skeleton>
        <Skeleton variant="rectangular" width={100} height={30}></Skeleton>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <Skeleton variant="circular" width={30} height={30}></Skeleton>
        <Skeleton variant="rectangular" width={100} height={30}></Skeleton>
      </Stack>
    </Stack>
  );
};

export default RegionTabsLoading;
