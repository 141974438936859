import ProductCatlogDesktop from "../components/desktop/product-catlog/ProductCatlogDesktop";

const ProductCatlog = () => {
  return (
    <>
      <ProductCatlogDesktop />
    </>
  );
};
export default ProductCatlog;
