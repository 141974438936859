import BrickManagementDesktop from "../components/desktop/brick-management/BrickManagementDesktop";

const BrickManagement = () => {
  return (
    <>
      <BrickManagementDesktop />
    </>
  );
};
export default BrickManagement;
