import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

interface Props {
  open: boolean;
  handleClose: Function;
  type?: string;
  reasonOptions: string[];
  successCallback: Function;
}

type FormFields = {
  reasons: string;
  reasonString: string;
};
const RemoveActivityChemistModal: React.FC<Props> = ({
  open,
  handleClose,
  type,
  successCallback,
  reasonOptions,
}) => {
  const { handleSubmit, control, watch } = useForm<FormFields>({
    mode: "onChange",
  });

  const handleRemoveModal = (data: FormFields) => {
    if (data.reasons !== "other") {
      successCallback(data.reasons);
    } else {
      successCallback(data.reasonString);
    }
    handleClose();
  };
  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box
        position={"absolute" as "absolute"}
        top={"50%"}
        left={"50%"}
        bgcolor={"background.paper"}
        borderRadius={"22px"}
        sx={{ transform: "translate(-50%, -50%)" }}
        width={"30%"}
        p={3}
      >
        <Typography
          fontWeight={500}
          variant="h6"
          textAlign="center"
          marginBottom={2}
        >
          Are you sure you want to remove Activity Chemist?
        </Typography>
        <Stack spacing={2}>
          <Controller
            name="reasons"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <InputLabel>Select reason</InputLabel>
                <Select
                  value={value}
                  size="small"
                  fullWidth
                  label="Select reason"
                  onChange={onChange}
                >
                  <MenuItem value={"Not An Activity Chemist"}>
                    Not An Activity Chemist
                  </MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {watch("reasons") === "other" && (
            <Controller
              name="reasonString"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  value={value}
                  size="small"
                  onChange={onChange}
                  placeholder="Please type reason..."
                  error={!!error}
                  helperText={error?.message}
                />
              )}
              rules={{
                pattern: {
                  value: /^(?!.*[^a-zA-Z0-9 ]).{5,}$/,
                  message:
                    "Enter valid reason. No special characters and minimum 5 characters",
                },
              }}
            />
          )}
        </Stack>
        <Stack
          paddingTop={3}
          direction={"row"}
          gap={3}
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleRemoveModal)}
            disabled={
              !(
                (watch("reasons") && watch("reasons") != "Other") ||
                (watch("reasons") == "Other" &&
                  watch("reasonString") &&
                  watch("reasonString").length > 4)
              )
            }
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Remove
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClose()}
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default RemoveActivityChemistModal;
