import { StateCreator } from "zustand";
import { CustomerFetchDetails } from "../../../domain/models/customer-database/customerFetchDetails";
import { MapCustomerData } from "../../../domain/models/customer-database/map-customer-data";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AUTHORIZATION_HEADER,
  AUTH_TOKEN_KEY,
  CUSTOMER_SERVICE,
} from "../../../base";
import { CustomerFetchData } from "../../../domain/useages/customer-fetch-data";
import { RemoteCustomerFetchData } from "../../../data/usecases/remote-customer-fetch-data";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";

export interface CustomerFetchDataInterface {
  customerFetchData: Function;
  customerFetchedData: CustomerFetchDetails[];
  mapCustomerData: MapCustomerData;
}

const initialStates = {
  customerFetchedData: [] as CustomerFetchDetails[],
  mapCustomerData: {
    q: "",
    page: 1,
    type: "",
    limit: 10,
    includePoolCustomers: true,
  },
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useCustomerFetchDataSlice: StateCreator<
  CustomerFetchDataInterface
> = (set, get) => ({
  ...initialStates,
  customerFetchData: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTHORIZATION_HEADER]: `Bearer ${atob(token)}`,
    });
    const remoteCustomerFetchData: CustomerFetchData =
      new RemoteCustomerFetchData(
        `${CUSTOMER_SERVICE}${Endpoints.CUSTOMER_FETCH_DATA}`,
        axiosHttpClient
      );

    let result = await remoteCustomerFetchData.fetch(get().mapCustomerData);

    if (result.success) {
      set(() => ({ customerFetchedData: result.data.result }));
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: "Error Occured While Fetching Customer's Data",
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },
});
