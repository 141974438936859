import { create } from "zustand";
import { useFetchZoneMasterSlice } from "../slices/TerritorySlices/fetchZoneMasterSlice";
import { useFetchRegionMasterSlice } from "../slices/TerritorySlices/fetchRegionMasterSlice";
import { useFetchAreaMasterSlice } from "../slices/TerritorySlices/fetchAreaMasterSlice";
import useFetchHeadquarterMasterSlice from "../slices/TerritorySlices/fetchHeadquarterMasterSlice";
import { useFetchBricksMasterSlice } from "../slices/TerritorySlices/fetchBricksMasterSlice";
import { useFetchCustomerTypesSlice } from "../slices/fetchCustomerTypes";
import { useFetchProductsMasterSlice } from "../slices/fetchProductsMaster";
import { useFetchBrandSlice } from "../slices/fetchBrandsData";
import { useFetchDepartmentSlice } from "../slices/fetchDepartment";

interface TerritoryDataInterface
  extends ReturnType<typeof useFetchRegionMasterSlice>,
    ReturnType<typeof useFetchAreaMasterSlice>,
    ReturnType<typeof useFetchHeadquarterMasterSlice>,
    ReturnType<typeof useFetchBricksMasterSlice>,
    ReturnType<typeof useFetchZoneMasterSlice>,
    ReturnType<typeof useFetchCustomerTypesSlice>,
    ReturnType<typeof useFetchProductsMasterSlice>,
    ReturnType<typeof useFetchBrandSlice> ,
    ReturnType<typeof useFetchDepartmentSlice>  {}

export const TerritoryDataStore = create<TerritoryDataInterface>((...a) => ({
  ...useFetchRegionMasterSlice(...a),
  ...useFetchAreaMasterSlice(...a),
  ...useFetchHeadquarterMasterSlice(...a),
  ...useFetchBricksMasterSlice(...a),
  ...useFetchZoneMasterSlice(...a),
  ...useFetchCustomerTypesSlice(...a),
  ...useFetchProductsMasterSlice(...a),
  ...useFetchBrandSlice(...a),
  ...useFetchDepartmentSlice(...a)
}));
