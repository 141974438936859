import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { VerifyOTP } from "../../../domain/useages/verify-otp";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteVerifyOTP } from "../../../data/usecases/remote-verify-otp";
import { verifyOTPResponse } from "../../../domain/models/verify-otp-response";
import Swal from "sweetalert2";

export interface VerifyOTPInterface {
  verifyOtp: Function;
  verifyOTPResponse: verifyOTPResponse;
  verifiedMobile: boolean;
  verifiedWhatsapp: boolean;
  verifiedEmail: boolean;
  setEmpty: Function;
}

const initialStates = {
  verifyOTPResponse: {} as verifyOTPResponse,
  verifiedMobile: false,
  verifiedWhatsapp: false,
  verifiedEmail: false,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();
export const useVerifyOTPSlice: StateCreator<VerifyOTPInterface> = (
  set,
  get
) => ({
  ...initialStates,
  verifyOtp: async (payload: VerifyOTP.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchImageUrl = new RemoteVerifyOTP(
      `${BASE_URL}${Endpoints.VERIFY_OTP}`,
      axiosHttpClient
    );
    let result = await remoteFetchImageUrl.verify(payload);
    set(() => ({ verifyOTPResponse: result }));

    if (result.success) {
      if (payload.type === "mobile") {
        set(() => ({ verifiedMobile: true }));
      } else if (payload.type === "whatsapp") {
        set(() => ({ verifiedWhatsapp: true }));
      } else if (payload.type === "email") {
        set(() => ({ verifiedEmail: true }));
      }
    } else {
      if (typeof result.errors === "object") {
        let errorText = "";
        for (let x in result.errors) {
          errorText += result.errors[x] + "\n";
        }
        Swal.fire({
          timer: 2000,
          icon: "error",
          text: errorText.replace(/\w+/g, function (w: any) {
            return w[0].toUpperCase() + w.slice(1).toLowerCase();
          }),
          customClass: {
            container: "swal2-style",
          },
        });
      } else {
        Swal.fire({
          timer: 2000,
          icon: "error",
          text: result.errors.replace(/\w+/g, function (w: any) {
            return w[0].toUpperCase() + w.slice(1).toLowerCase();
          }),
          customClass: {
            container: "swal2-style",
          },
        });
      }
    }
  },
  setEmpty: () => {
    set(() => ({ verifiedMobile: false }));
    set(() => ({ verifiedWhatsapp: false }));
    set(() => ({ verifiedEmail: false }));
  },
});
