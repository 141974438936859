import dayjs, { Dayjs } from "dayjs";
import { FetchState } from "../../../domain/models/fetch-state";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { StateCreator } from "zustand";
import { AUTH_TOKEN_KEY, BASE_URL_KEY, AUTH_HEADER } from "../../../base";
import moment from "moment";
import { RemoteFetchCustomerDetailingReport } from "../../../data/usecases/customer-detailing-report/remoter-fetch-customer-detailing-report";
import Endpoints from "../../../domain/endpoints";
import { FetchCustomerDetailingReport } from "../../../domain/useages/customer-detailing-report/fetch-customer-detailing-report";
import { CustomerDetailingReportType } from "../../../domain/models/customer-detailing-report/customer-detailing-report";

export interface FetchCustomerDetailingReportInterface {
  fetchCustomerDetailingReport: Function;
  CustomerDetailingReportFilter: {
    monthYear: Dayjs | null;
    brandIds: string[];
  };
  CustomerDetailingReportState: FetchState;
  CustomerDetailingReport: CustomerDetailingReportType[];
}

const initialStates = {
  CustomerDetailingReportFilter: {
    monthYear: dayjs(new Date()),
    brandIds: [],
  },
  CustomerDetailingReportState: FetchState.DEFAULT,
  CustomerDetailingReport: [],
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchCustomerDetailingReportSlice: StateCreator<
  FetchCustomerDetailingReportInterface
> = (set, get) => ({
  ...initialStates,
  fetchCustomerDetailingReport: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    set(() => ({ CustomerDetailingReportState: FetchState.LOADING }));

    let filterParams = {
      month_year: moment(
        (get().CustomerDetailingReportFilter.monthYear as Dayjs).toString()
      ).format("YYYYMM"),
      brand_ids: get().CustomerDetailingReportFilter.brandIds,
    };

    const remoteFetchCustomerDetailingReport: FetchCustomerDetailingReport =
      new RemoteFetchCustomerDetailingReport(
        `${BASE_URL}${Endpoints.CUSTOMER_DETAILING_REPORT}`,
        axiosHttpClient
      );

    let result = await remoteFetchCustomerDetailingReport.fetch(filterParams);
    if (result.success && result.customerPriorityReports.length > 0) {
      set(() => ({
        CustomerDetailingReport: result.customerPriorityReports,
        CustomerDetailingReportState: FetchState.SUCCESS,
      }));
    } else {
      set(() => ({
        CustomerDetailingReportState: FetchState.ERROR,
      }));
    }
  },
});
