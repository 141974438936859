import {
  TableRow,
  TableCell,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import { HeadquartersType } from "../../../../domain/models/hq-distance-report/hq-distance-data";
import { useState } from "react";
import AddHqDistanceModal from "./Modals/AddHqDistanceModal";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  data: HeadquartersType;
};

const HqDistanceDetail: React.FC<Props> = ({ data }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <TableRow>
        <TableCell align="center">
          <Typography fontWeight={500} color={"#687182"} variant="caption">
            {data.region}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={500} color={"#687182"} variant="caption">
            {data.area}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={500} color={"#687182"} variant="caption">
            {data.headquarter}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={500} color={"#687182"} variant="caption">
            {data.headquarter_status}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={500} color={"#687182"} variant="caption">
            {data.headquarter_type}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={500} color={"#687182"} variant="caption">
            {data.distance_from_headquarter}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {data.distance_from_headquarter === undefined && (
            <Tooltip title="Add Distance">
              <Button onClick={() => setOpen(true)} sx={{ padding: 0 }}>
                <AddIcon />
              </Button>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      {open && (
        <AddHqDistanceModal
          open={open}
          handleClose={() => setOpen(false)}
          hq_code={data.headquarter_ga_code}
          hq_name={data.headquarter}
        />
      )}
    </>
  );
};

export default HqDistanceDetail;
