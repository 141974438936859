import { create } from "zustand";
import { useFetchInputUsageSummary } from "../slices/InputUtilizationSlices/fetchInputUsageSummary";
import { useFetchInputsBasedOnProductType } from "../slices/InputUtilizationSlices/fetchInputsBasedOnProductType";
import { useFetchUserRolesSlice } from "../slices/fetchUserRolesSlice";
import { useFetchBatchWiseInputReportSlice } from "../slices/InputUtilizationSlices/fetchBatchWiseInputReportSlice";
import { useFetchCustomerWiseInputReportSlice } from "../slices/InputUtilizationSlices/fetchCustomerWiseInputReportSlice";

interface InputUsageSummaryInterface
  extends ReturnType<typeof useFetchInputUsageSummary>,
    ReturnType<typeof useFetchInputsBasedOnProductType>,
    ReturnType<typeof useFetchUserRolesSlice>,
    ReturnType<typeof useFetchBatchWiseInputReportSlice>,
    ReturnType<typeof useFetchCustomerWiseInputReportSlice> {}

export const InputUsageSummaryStore = create<InputUsageSummaryInterface>(
  (...a) => ({
    ...useFetchInputUsageSummary(...a),
    ...useFetchInputsBasedOnProductType(...a),
    ...useFetchUserRolesSlice(...a),
    ...useFetchBatchWiseInputReportSlice(...a),
    ...useFetchCustomerWiseInputReportSlice(...a),
  })
);
