import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import moment from "moment";
import { Typography } from "@mui/material";
import Status from "../../../../../common/enums/Status";
import { CustomerLogs } from "../../../../../domain/models/customer-database/customer-subdetails";
import { CustomerDetails } from "../../../../../domain/models/customer-database/customer-details";

type Props = {
  row: CustomerDetails;
};

const CustomerHistoryMobile: React.FC<Props> = ({ row }) => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        backgroundColor:
          row.status === "active"
            ? row.mapping_status === Status.APPROVED &&
              row.approved_status === Status.APPROVED
              ? "error.light"
              : "error.dark"
            : "#e8ebe8",
      }}
    >
      {row?.customer_logs?.length > 0 ? (
        row.customer_logs.map((val: CustomerLogs, index: number) => {
          return (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ width: 12, height: 12 }} variant="outlined">
                  {val.action === "edit" && (
                    <EditOutlinedIcon sx={{ width: 12, height: 15 }} />
                  )}
                  {val.action === "remove_mapping" && (
                    <RemoveCircleOutlineOutlinedIcon
                      sx={{ width: 12, height: 15 }}
                    />
                  )}
                  {val.action === "deactivate" && (
                    <CancelOutlinedIcon sx={{ width: 12, height: 15 }} />
                  )}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography fontWeight={600} variant="body2" display="inline">
                  {val.updated_by + " "}
                </Typography>
                <Typography variant="body2" display="inline">
                  performed
                </Typography>

                <Typography
                  fontWeight={500}
                  variant="body2"
                  color="blue"
                  display="inline"
                >
                  {" " + val.action + " "}
                </Typography>
                <Typography variant="body2" display="inline">
                  on
                </Typography>
                <Typography fontWeight={600} variant="body2" display="inline">
                  {" " + row.firm_name + " "}
                </Typography>
                <Typography variant="body2" display="inline">
                  on
                </Typography>

                <Typography
                  fontWeight={500}
                  variant="body2"
                  color="gray"
                  display="inline"
                >{` ${moment(val.created_at.toString()).format(
                  "DD-MMM-YYYY"
                )} @ ${moment(val.created_at.toString()).format(
                  "hh:mm:ss"
                )} `}</Typography>
                <br />
                <Typography variant="caption" fontWeight={500}>
                  {row.firm_name + " "}
                </Typography>
                <Typography variant="caption">was mapped with</Typography>
                <Typography variant="caption" fontWeight={500}>
                  {" " + val.headquarter}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })
      ) : (
        <>
          <TimelineItem>
            <TimelineDot></TimelineDot>
            <TimelineContent>
              <Typography fontWeight={500}>No Customer History</Typography>
            </TimelineContent>
          </TimelineItem>
        </>
      )}
    </Timeline>
  );
};

export default CustomerHistoryMobile;
