import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { UnmappedStockistStore } from "../../../../../store/main/UnmappedStockistStore";
import { SearchHeadquarterType } from "../../../../../domain/models/unmapped-stockist/unmapped-stockist-data";
import moment from "moment";

interface Props {
  open: boolean;
  handleClose: () => void;
  code: string;
  name: string;
}

type FormFields = {
  brick: string;
  hq: SearchHeadquarterType;
};

const MapStockistModal: React.FC<Props> = ({
  open,
  handleClose,
  code,
  name,
}) => {
  const {
    searchHeadquarter,
    fetchSearchHeadquarter,
    fetchBrick,
    brick,
    mapStockist,
    clearBrick,
    clearSearchHeadquarter,
  } = UnmappedStockistStore();
  const { handleSubmit, control, watch } = useForm<FormFields>({
    mode: "onChange",
  });
  useEffect(() => {
    if (open) {
      clearBrick();
      clearSearchHeadquarter();
    }
  }, [open]);

  useEffect(() => {
    if (watch("hq")) fetchBrick(watch("hq").id);
  }, [watch("hq")]);

  const handleMap = (data: FormFields) => {
    const payload = {
      code: code,
      firm_name: name,
      headquarter_id: data.hq.id,
      brick_id: data.brick,
      month_year: moment(new Date().toString()).format("yyyyMM"),
    };
    mapStockist(payload);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        bgcolor="background.paper"
        borderRadius="22px"
        sx={{ transform: "translate(-50%, -50%)" }}
        width="25%"
        p={3}
      >
        <Typography
          fontWeight={500}
          variant="h6"
          textAlign="center"
          marginBottom={2}
        >
          Stockist Mapping
        </Typography>
        <Stack spacing={2}>
          <Controller
            name="hq"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <Autocomplete
                  options={searchHeadquarter}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  value={value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter first three characters"
                      label="Search Headquarter"
                      error={!!error}
                      helperText={error ? error.message : null}
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        if (e.target.value.length > 2) {
                          fetchSearchHeadquarter({ q: e.target.value });
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            )}
          />
          <Controller
            name="brick"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Brick
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="select"
                  value={value}
                  onChange={onChange}
                  disabled={typeof watch("hq") === "undefined"}
                  label="Select Brick"
                >
                  {brick.map((brick) => (
                    <MenuItem value={brick.brick_id}>
                      {brick.brick_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Stack>
        <Stack paddingTop={3} direction="row" gap={3} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleMap)}
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Map Stockist
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default MapStockistModal;
