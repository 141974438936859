import DashboardData from "../components/desktop/data-dashboard-report/DashboardData";

const DataDashboardReport = () => {
  return (
    <>
      <DashboardData />
    </>
  );
};
export default DataDashboardReport;
