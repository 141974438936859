import { Box, Pagination, Stack, Typography } from "@mui/material";
import ReportFiltersComponent from "../ReportFiltersComponent";
import ReportScreen from "../../../../common/enums/ReportScreens";
import ReportFiltersLoading from "../ReportFiltersLoading";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { useEffect } from "react";
import BrickTable from "./sub-components/BrickTable";
import { ReportFilters } from "../../../../domain/models/report-filters";
import { BrickManagementStore } from "../../../../store/main/BrickManagementStore";
import { FetchState } from "../../../../domain/models/fetch-state";
import CustomerTableLoading from "../CustomerTableLoading";

const BrickManagementDesktop = () => {
  const {
    userDetails,
    userRole,
    decodeToken,
    filterStore,
    fetchCustomerSummaryCount,
  } = CustomerDatabaseStore();
  const {
    fetchDistrict,
    addBrickState,
    updateBrickState,
    deactivateBrickState,
    fetchBrickManagement,
    fetchBrickManagementState,
    brickManagementFilter,
    pageData,
    currentPageNumber,
    handlePageNumber,
  } = BrickManagementStore();
  useEffect(() => {
    filterStore.zone_ids = [];
    filterStore.region_ids = [];
    filterStore.area_ids = [];
    filterStore.headquarter_ids = [];
    filterStore.territory_ids = [];
    filterStore.download = 0;
    filterStore.brick_ids = [];
    filterStore.q = "";
    filterStore.department = "";
    filterStore.segement = [];
    fetchBrickManagement({ page: 1 });
    fetchCustomerSummaryCount(filterStore);
    decodeToken();
  }, []);
  const filterData = (filters: ReportFilters) => {
    filterStore.zone_ids = filters.zone;
    filterStore.region_ids = filters.region;
    filterStore.area_ids = filters.area;
    filterStore.headquarter_ids = filters.headquarter;
    filterStore.customer_type_id = "";
    filterStore.department = filters.department;
    filterStore.segement = filters.segement;
    brickManagementFilter.q = filters.search;
    brickManagementFilter.zone_ids = filters.zone;
    brickManagementFilter.region_ids = filters.region;
    brickManagementFilter.area_ids = filters.area;
    brickManagementFilter.headquarter_ids = filters.headquarter;
    brickManagementFilter.page = 1;
    fetchBrickManagement(brickManagementFilter);
    if (filters.headquarter.length > 0) {
      fetchDistrict(parseInt(filters.headquarter.join(",")));
    }
  };
  const clearData = (filters: ReportFilters) => {
    filterStore.zone_ids = [];
    filterStore.region_ids = [];
    filterStore.area_ids = [];
    filterStore.headquarter_ids = [];
    filterStore.q = "";
    filterStore.customer_type_id = "";
    filterStore.department = "";
    filterStore.segement = [];
    const payload = {
      q: "",
      zone_ids: [],
      region_ids: [],
      area_ids: [],
      headquarter_ids: [],
      page: 1,
    };
    fetchBrickManagement(payload);
  };

  const callBrickManagement = (value: number) => {
    const payload = {
      q: brickManagementFilter.q,
      zone_ids: brickManagementFilter.zone_ids,
      region_ids: brickManagementFilter.region_ids,
      area_ids: brickManagementFilter.area_ids,
      headquarter_ids: brickManagementFilter.headquarter_ids,
      page: value,
    };
    fetchBrickManagement(payload);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    console.log(value);
    handlePageNumber(value);
    callBrickManagement(value);
  };
  return (
    <>
      <Box padding={2}>
        <Typography variant="h5" fontWeight={600}>
          Brick Management
        </Typography>
        <Stack paddingY={2}>
          {Object.keys(userDetails).length > 0 ? (
            <ReportFiltersComponent
              type={ReportScreen.BRICK_MANAGEMENT}
              successCallback={filterData}
              clearSuccessCallBack={clearData}
              userRole={userRole.role}
              userDetails={userDetails}
              gridColumns={14}
              territoryMultiSelect={false}
            />
          ) : (
            <ReportFiltersLoading />
          )}
        </Stack>
        {fetchBrickManagementState === FetchState.SUCCESS ||
        addBrickState === FetchState.SUCCESS ||
        updateBrickState === FetchState.SUCCESS ||
        deactivateBrickState === FetchState.SUCCESS ? (
          <BrickTable />
        ) : (
          <CustomerTableLoading />
        )}
      </Box>
      {Math.ceil(pageData.total / pageData.per_page) !== 1 && (
        <Stack alignItems="center" pb={1}>
          <Pagination
            count={Math.ceil(pageData.total / pageData.per_page)}
            variant="outlined"
            page={currentPageNumber}
            onChange={handlePageChange}
          />
        </Stack>
      )}
    </>
  );
};
export default BrickManagementDesktop;
