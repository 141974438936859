import { StateCreator } from "zustand";
import {
  ProductInvoiceDetails,
  ProductWiseData,
} from "../../../domain/models/primary-sales-invoice/product-wise";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { FetchState } from "../../../domain/models/fetch-state";
import { FetchProductWise } from "../../../domain/useages/primary-sales-invoice-reports/fetch-product-wise";
import { RemoteFetchProductWise } from "../../../data/usecases/primary-sales-invoice/remote-fetch-product-wise";

export interface FetchProductWiseInterface {
  productWiseData: ProductInvoiceDetails[];
  fetchProductWiseData: Function;
  productWiseDataLoading: FetchState;
  productWiseFilters: {
    monthYear: Dayjs | null;
  };
}

const initialStates = {
  productWiseData: [],
  productWiseDataLoading: FetchState.DEFAULT,
  productWiseFilters: { monthYear: dayjs(new Date()) },
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchProductWiseDataSlice: StateCreator<
  FetchProductWiseInterface
> = (set, get) => ({
  ...initialStates,
  fetchProductWiseData: async (month: number, year: number, payload: any) => {
    set(() => ({ productWiseDataLoading: FetchState.LOADING }));
    const axiosHttpClient = AxiosHttpClient.getInstance();
    const storage = LocalJsonStorage.getInstance();

    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchProductWiseData: FetchProductWise =
      new RemoteFetchProductWise(
        `${BASE_URL}${Endpoints.PRIMARY_PRODUCT}`,
        axiosHttpClient
      );

    set(() => ({ productWiseDataLoading: FetchState.LOADING }));
    let result = await remoteFetchProductWiseData.fetch(month, year, payload);
    if (result) {
      set(() => ({
        productWiseData: result.invoice_details,
        productWiseDataLoading: FetchState.SUCCESS,
      }));
    }
  },
});
