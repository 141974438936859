export class Constants {
  static REACTIVATE_REASONS = [
    "Reactivate customer",
    "Inactive brick",
    "Inactive headquarter",
    "Other",
  ];
  static TRANSFER_REASONS = [
    "Transfer customer",
    "Inactive brick",
    "Inactive headquarter",
    "Other",
  ];
  static MOBILE = "(min-width:1000px)";
  static DEACTIVATE_REASONS = ["Deactivate customer", "Other"];
  static REJECT_REASON = ["Reject Customer", "Other"];
  static BULK_APPROVE_REJECT_CUSTOMERS = [
    "approve customer",
    "reject customer",
    "Other",
  ];
}
