import { Skeleton, Stack } from "@mui/material";

const ZoneMasterDataLoading = () => {
  return (
    <>
      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center"
        marginX={"auto"}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={50}
          width="97vw"
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={50}
          width="97vw"
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={50}
          width="97vw"
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={50}
          width="97vw"
        />
      </Stack>
      );
    </>
  );
};

export default ZoneMasterDataLoading;
