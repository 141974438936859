import { useTheme, useMediaQuery } from "@mui/material";

import UserManagementReportDesktop from "../components/desktop/user-management-report/UserManagementReportDesktop";
import UserManagementReportMobile from "../components/mobile/user-management-report/UserManagementReportMobile";

export default function UserManagementReport() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {isMobile ? (
        <UserManagementReportMobile />
      ) : (
        <UserManagementReportDesktop />
      )}
    </>
  );
}
