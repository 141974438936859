import { create } from "zustand";
import { usefetchHqDistanceSlice } from "../slices/HqDistanceSlices/fetchHqDistance";
import { useAddHqDistanceSlice } from "../slices/HqDistanceSlices/addHqDistance";

interface HqDistanceInterface
  extends ReturnType<typeof usefetchHqDistanceSlice>,
    ReturnType<typeof useAddHqDistanceSlice> {}

export const HqDistanceStore = create<HqDistanceInterface>((...a) => ({
  ...usefetchHqDistanceSlice(...a),
  ...useAddHqDistanceSlice(...a),
}));
