import { DownloadExcel } from "../../domain/useages/download-excel";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "./../protocols/http/http-get-client";

export class RemoteDownloadExcel implements DownloadExcel {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) { }
  async download(params?: DownloadExcel.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status === 200) {
      return httpResponse.data;
    }
  }
}