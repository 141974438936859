import {
  Avatar,
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { UserSummaryReportStore } from "../../../../store/main/UserSummaryReportStore";
import { useEffect, useState } from "react";
import RolesId from "../../../../common/enums/RoleIds";
import { FetchState } from "../../../../domain/models/fetch-state";
import { UserChildrenType } from "../../../../domain/models/user-summary-report/user-children";
import UserListLoading from "./sub-components/loading/UserListLoading";
import NoRecordFound from "../NoRecordFound";

const UserList = () => {
  const {
    userData,
    fetchDeviationReport,
    fetchSummaryReport,
    fetchMonthSummaryReport,
    fetchMonthsReport,
    userSummaryFilter,
    fetchUserChildrenState,
  } = UserSummaryReportStore();
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const [allActive, setAllActive] = useState<boolean>(true);
  useEffect(() => {
    const active = userData.find((data) => data.status === "active");
    if (active) {
      setAllActive(true);
    } else {
      setAllActive(false);
    }
  }, [userData]);
  const fetchUserData = (user_id: number, organization_id: number) => {
    const currentDate = new Date();

    const currentMonth = userSummaryFilter?.month
      ? userSummaryFilter.month
      : currentDate.getMonth() + 1;

    const currentYear = userSummaryFilter?.year
      ? userSummaryFilter.year
      : currentDate.getFullYear();

    userSummaryFilter.user_id = user_id;
    userSummaryFilter.department = organization_id;

    fetchDeviationReport(currentMonth, currentYear, user_id, organization_id);

    fetchSummaryReport(currentMonth, currentYear, user_id, organization_id);
    fetchMonthSummaryReport(
      currentMonth,
      currentYear,
      user_id,
      organization_id
    );
    fetchMonthsReport(currentYear, user_id, organization_id);
    setSelectedCard(user_id);
  };
  return (
    <Box overflow="scroll" height="82vh">
      {fetchUserChildrenState === FetchState.ERROR && (
        <NoRecordFound height="60vh" message="NO USER FOUND" />
      )}
      {fetchUserChildrenState === FetchState.SUCCESS && !allActive && (
        <NoRecordFound height="60vh" message="NO USER FOUND" />
      )}
      {fetchUserChildrenState === FetchState.LOADING && <UserListLoading />}
      {userData.length !== 0 &&
        fetchUserChildrenState === FetchState.SUCCESS &&
        allActive &&
        userData.map((data: UserChildrenType) => {
          if (data.status === "active") {
            return (
              <Card
                sx={{
                  width: "90%",
                  boxShadow: "none",
                  borderRadius: "0.7rem",
                  marginX: "auto",
                  marginTop: "10px",
                }}
                onClick={() => fetchUserData(data.id, data.organization_id)}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    backgroundColor:
                      selectedCard === data.id ? "#A7C7E7" : "#F0F3F2",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#d9dedc",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      marginY: "auto",
                      marginLeft: "15px",
                    }}
                  >
                    {data?.full_name[0]
                      ? data?.full_name[0].toUpperCase()
                      : "A"}
                  </Avatar>
                  <CardContent>
                    <Typography component="div" variant="subtitle1">
                      {data?.full_name ? data?.full_name : "-"}
                    </Typography>
                    {data?.role_id === RolesId.COUTNRY_MANAGER && (
                      <Typography variant="caption">ADMIN</Typography>
                    )}
                    {data?.role_id === RolesId.ZONE_MANAGER && (
                      <Typography variant="caption">ZBM</Typography>
                    )}
                    {data?.role_id === RolesId.REGION_MANAGER && (
                      <>
                        <Stack direction={"column"}>
                          <Typography variant="caption">RBM</Typography>
                          <Typography variant="caption" color="text.secondary">
                            {data?.hq_region?.name}
                          </Typography>
                        </Stack>
                      </>
                    )}

                    {data?.role_id === RolesId.AREA_MANAGER && (
                      <>
                        <Typography variant="caption">ABM</Typography>
                        <Stack
                          alignItems={"Center"}
                          direction={"row"}
                          spacing={1}
                        >
                          <Typography variant="caption" color="text.secondary">
                            {data?.hq_region?.name
                              ? data?.hq_region?.name
                              : "-"}
                          </Typography>
                          <FiberManualRecordIcon
                            sx={{ fontSize: "10px", color: "lightGray" }}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {data?.hq_area?.name ? data?.hq_area?.name : "-"}
                          </Typography>
                        </Stack>
                      </>
                    )}

                    {data?.role_id === RolesId.HEADQUATER_MANAGER && (
                      <>
                        <Typography variant="caption">TSO</Typography>
                        <Stack
                          alignItems={"Center"}
                          direction={"row"}
                          spacing={0.2}
                        >
                          <Typography variant="caption" color="text.secondary">
                            {data?.hq_region?.name}
                          </Typography>
                          <FiberManualRecordIcon
                            sx={{ fontSize: "10px", color: "lightGray" }}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {data?.hq_area?.name}
                          </Typography>
                          <FiberManualRecordIcon
                            sx={{ fontSize: "10px", color: "lightGray" }}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {data?.hq_headquarter?.name}
                          </Typography>
                        </Stack>
                      </>
                    )}
                  </CardContent>
                </Stack>
              </Card>
            );
          } else {
            return "";
          }
        })}
    </Box>
  );
};
export default UserList;
