import { StateCreator } from "zustand";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteFetchZoneMasterReport } from "../../../data/usecases/zone-master-report/remote-fetch-zone-master-report";
import { FetchState } from "../../../domain/models/fetch-state";
import { customerDatabaseFilters } from "../../../domain/models/customer-database/customer-database-filters";
import { RegionMasterType } from "../../../domain/models/zone-master/zone-master-data";

export interface ZoneMasterInterface {
  fetchZoneMaster: Function;
  zoneMasterData: RegionMasterType[];
  zoneMasterState: FetchState;
}

const initialStates = {
  zoneMasterData: [],
  zoneMasterState: FetchState.DEFAULT,
};

export const useZoneMasterSlice: StateCreator<ZoneMasterInterface> = (set) => ({
  ...initialStates,
  fetchZoneMaster: async (filters: customerDatabaseFilters) => {
    set(() => ({ zoneMasterState: FetchState.LOADING }));

    const axiosHttpClient = AxiosHttpClient.getInstance();
    const storage = LocalJsonStorage.getInstance();

    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchZoneMasterReport = new RemoteFetchZoneMasterReport(
      `${BASE_URL}${Endpoints.ZONE_MASTER}`,
      axiosHttpClient
    );
    let payload = {
      zone_ids: filters.zone_ids,
      region_ids: filters.region_ids,
      area_ids: filters.area_ids,
      headquarter_ids: filters.headquarter_ids,
    };

    let result = await remoteFetchZoneMasterReport.fetch(payload);
    if (result) {
      set(() => ({ zoneMasterData: result.regions }));
      set(() => ({ zoneMasterState: FetchState.SUCCESS }));
    }
  },
});
