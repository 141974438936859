import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Chip, IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React from "react";
import BrickTableData from "./BricksData";
import {
  HeadquarterMasterType,
  TitleType,
} from "../../../../../domain/models/zone-master/zone-master-data";
type RowProps = {
  row: HeadquarterMasterType;
  setTitle: Function;
  selectedTerritoryName: TitleType;
};

const BrickTable: React.FC<RowProps> = ({
  row,
  selectedTerritoryName,
  setTitle,
}) => {
  const [openHQ, setOpenHQ] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <Stack direction="row" alignItems={"Center"} spacing={1}>
            <IconButton
              size="small"
              sx={{ marginLeft: "60px" }}
              onClick={() => {
                selectedTerritoryName.hq = row.name;
                {
                  openHQ
                    ? setTitle(
                        `${selectedTerritoryName.zone} > ${selectedTerritoryName.region} > ${selectedTerritoryName.area}`
                      )
                    : setTitle(
                        `${selectedTerritoryName.zone} > ${selectedTerritoryName.region} > ${selectedTerritoryName.area} > ${row.name}`
                      );
                }
                setOpenHQ(!openHQ);
              }}
            >
              {openHQ ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Chip
              label="B"
              sx={{
                color: "#687182",
                backgroundColor: "#E9EDF5",
                fontWeight: 600,
                width: "30px",
              }}
              size="small"
            />
            <Typography fontWeight={500} color={"#687182"}>
              {row.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">-</TableCell>
        <TableCell align="center">-</TableCell>
        <TableCell align="center">-</TableCell>
        <TableCell align="center">-</TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {row.brickCount}
          </Typography>
        </TableCell>
      </TableRow>
      {openHQ && <BrickTableData openHQ={openHQ} row={row} />}
    </>
  );
};

export default BrickTable;
