import { StateCreator } from "zustand";
import Swal from "sweetalert2";
import { DashboardDataType } from "../../../domain/models/data-dashboard-report/dashboard-data";
import { FetchState } from "../../../domain/models/fetch-state";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, DASHBOARD_AUTH_URL } from "../../../base";
import { RemoteGetDashboardLink } from "../../../data/usecases/data-dashboard-report/remote-get-dashboard-link";
import Endpoints from "../../../domain/endpoints";
import { CustomerDatabaseStore } from "../../main/CustomerDatabaseStore";

export interface GetDashboardLinkInterface {
  getSelectedDashboardData: Function;
  formattedLink: string;
  dashboardData: DashboardDataType[];
  fetchDashboardState: FetchState;
}

const initialStates = {
  formattedLink: "",
  dashboardData: [],
  fetchDashboardState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchDashboardDataSlice: StateCreator<
  GetDashboardLinkInterface
> = (set) => ({
  ...initialStates,
  getSelectedDashboardData: async () => {
    set(() => ({ fetchDashboardState: FetchState.LOADING }));

    let payload = {
      user_id: CustomerDatabaseStore.getState().userDetails.id,
      department_name: CustomerDatabaseStore.getState().userDetails.departments[0].customer_service_org_code,
      role: CustomerDatabaseStore.getState().userDetails.role.name,
      name: CustomerDatabaseStore.getState().userDetails.full_name
    };
    const token = storage.get(AUTH_TOKEN_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteGetDashboardLink = new RemoteGetDashboardLink(
      `${DASHBOARD_AUTH_URL}${Endpoints.DASHBOARD_LINK}`,
      axiosHttpClient
    );

    let result = await remoteGetDashboardLink.get(payload);

    if (result) {
      set(() => ({ fetchDashboardState: FetchState.SUCCESS }));
      set(() => ({ dashboardData: result }));
    } else {
      set(() => ({ fetchDashboardState: FetchState.ERROR }));
      Swal.fire({
        icon: "error",
        title: "No Dashboard Links Found",
        timer: 3000,
      });
    }
  },
  
});
