import "webdatarocks/webdatarocks.css";
import * as WebDataRocksReact from "react-webdatarocks";
import { Box } from "@mui/material";
import { CustomerDatabaseStore } from "../../../../../../store/main/CustomerDatabaseStore";
import Roles from "../../../../../../common/enums/Roles";

const HQSummaryData = () => {
  const { hqSummaryDate, userDetails } = CustomerDatabaseStore();

  function customizeToolbar(toolbar: any) {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      userDetails &&
        userDetails.role &&
        userDetails.role.visibility == 0 &&
        delete tabs[3];
      return tabs;
    };
  }

  return (
    <Box sx={{ overflow: "scroll", height: "70vh" }}>
      <WebDataRocksReact.Pivot
        beforetoolbarcreated={customizeToolbar}
        toolbar={true}
        container="#wdr-container"
        componentFolder="https://cdn.webdatarocks.com/"
        width="100%"
        report={{
          dataSource: {
            data: hqSummaryDate,
          },
          slice: {
            rows: [
              {
                uniqueName: "zone",
              },
              {
                uniqueName: "region",
              },
              {
                uniqueName: "area",
              },
              {
                uniqueName: "headquarter",
              },
            ],
            columns: [
              {
                uniqueName: "type",
              },
              {
                uniqueName: "Measures",
                filter: {
                  members: ["total"],
                },
              },
            ],
            measures: [
              {
                uniqueName: "total",
                aggregation: "sum",
                format: "numberFormat",
              },
              {
                uniqueName: "unmapped_customers",
                aggregation: "sum",
                format: "numberFormat",
              },
            ],
            expands: {
              expandAll: "true",
            },
          },
          options: {
            grid: {
              showHeaders: false,
              showTotals: true,
              showGrandTotals: "on",
              showHierarchyCaptions: true,
            },
            showAggregations: true,
            showCalculatedValuesButton: true,
            sorting: "on",
            datePattern: "dd/MM/yyyy",
            dateTimePattern: "dd/MM/yyyy HH:mm:ss",
            defaultHierarchySortName: "asc",
          },
          conditions: [
            {
              formula: "#value > 0",
              measure: "unmapped_customers",
              format: {
                backgroundColor: "#fafa66",
                color: "black",
                fontFamily: "Arial",
                fontSize: "12px",
              },
            },
            {
              formula: "'norm_value' > 'total'",
              measure: "total",
              format: {
                backgroundColor: "#ffadb6",
                color: "black",
                fontFamily: "Arial",
                fontSize: "12px",
              },
            },
          ],
          formats: [
            {
              name: "numberFormat",
              thousandsSeparator: ",",
            },
          ],
        }}
      />
    </Box>
  );
};

export default HQSummaryData;
