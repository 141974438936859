import { StateCreator } from "zustand";
import { TokenData } from "../../../domain/models/token-data";
import jwt_decode from "jwt-decode";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { UserDetails } from "../../../domain/models/uset-details";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import { RemoteFetchUserDetails } from "../../../data/usecases/remote-fetch-user-details";
import { FetchState } from "../../../domain/models/fetch-state";

export interface selectedCustomerSlice {
  customerIds: number[];
  selectedPanel: boolean;
  handleSelectedPanel: Function;
  panelName: string;
  handleSelectedCustomers: Function;
  handleClearSelectedCustomers: Function;
  userRole: TokenData;
  decodeToken: Function;
  userDetails: UserDetails;
  userDetailsStatus: FetchState;
  userLogDetails: UserDetails;
  getUserDetails: Function;
  currentPageNumber: number;
  handlePageNumber: Function;
  headquarterCode: string;
  handleHeadquarterCode: Function;
}

const initialStates = {
  customerIds: [],
  selectedPanel: false,
  userRole: {} as TokenData,
  userDetails: {} as UserDetails,
  userLogDetails: {} as UserDetails,
  panelName: "All",
  currentPageNumber: 1,
  userDetailsStatus: FetchState.DEFAULT,
  headquarterCode: "",
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useSelectedCustomerSlice: StateCreator<selectedCustomerSlice> = (
  set,
  get
) => ({
  ...initialStates,
  handleSelectedPanel: (value: boolean, name: string) => {
    set(() => ({ selectedPanel: value }));
    set(() => ({ panelName: name }));
  },

  handleSelectedCustomers: (value: number[]) => {
    set(() => ({ customerIds: value }));
  },

  handleHeadquarterCode: (value: string) => {
    set(() => ({ headquarterCode: value }));
  },

  handleClearSelectedCustomers: () => {
    set(() => ({ customerIds: [] }));
  },

  decodeToken: () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    let decoded: TokenData = jwt_decode(atob(token));
    set(() => ({ userRole: decoded }));
    get().getUserDetails();
  },

  getUserDetails: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    set(() => ({ userDetailsStatus: FetchState.LOADING }));
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchUserDetails = new RemoteFetchUserDetails(
      `${BASE_URL}${Endpoints.FETCH_USER_DETAILS}`,
      axiosHttpClient
    );

    let id = get().userRole.user_id;
    let result = await remoteFetchUserDetails.get({ id });
    if (result) {
      set(() => ({
        userDetails: result.user,
      }));
      set(() => ({
        userDetailsStatus: FetchState.SUCCESS,
      }));
    }
  },
  handlePageNumber: (pageNo: number) => {
    set(() => ({ currentPageNumber: pageNo }));
  },
});
