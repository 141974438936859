import { Box, Button, Modal, Stack, Typography } from "@mui/material";

interface Props {
  open: boolean;
  handleClose: Function;
  type?: string;
  reasonOptions: string[];
  successCallback: Function;
}
const AddActivityChemistModal: React.FC<Props> = ({
  open,
  handleClose,
  type,
  reasonOptions,
  successCallback,
}) => {
  const handleSubmt = () => {
    successCallback();
    handleClose();
  };

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box
        position={"absolute" as "absolute"}
        top={"50%"}
        left={"50%"}
        bgcolor={"background.paper"}
        borderRadius={"22px"}
        sx={{ transform: "translate(-50%, -50%)" }}
        width={"30%"}
        p={3}
      >
        <Typography
          fontWeight={500}
          variant="h6"
          textAlign="center"
          marginBottom={2}
        >
          Are you sure you want to add Activity Chemist?
        </Typography>
        <Stack
          direction={"row"}
          gap={3}
          paddingTop={3}
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmt}
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClose()}
            sx={{
              textTransform: "unset",
              borderRadius: "30px",
              minWidth: "30%",
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default AddActivityChemistModal;
