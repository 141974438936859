import { create } from "zustand";
import { useSelectedCustomerSlice } from "../slices/CustomerDataSlices/selectedCustomerSlice";
import { useFetchCustomersSlice } from "../slices/CustomerDataSlices/fetchCustomersSlice";
import { useRemoveMapping } from "../slices/removeMappingSlice";
import { useDeactivateCustomer } from "../slices/deactivateUserSlice";
import { useFetchCustomerSummaryCountSlice } from "../slices/CustomerDataSlices/fetchCustomerSummaryCountSlice";
import { useEditCustomerSlice } from "../slices/editCustomerSlice";
import { useDownloadExcelSlice } from "../slices/downloadExcelSlice";
import { useMapCustomerToBrickSlice } from "../slices/mapCustomerToBrickSlice";
import { useFetchHQSummarySlice } from "../slices/CustomerDataSlices/hqSummarySlice";
import { useCustomerFetchDataSlice } from "../slices/CustomerDataSlices/customerFetchDataSlice";
import { useUpdateSpecialitySlice } from "../slices/CustomerDataSlices/updateSpecialitySlice";
import { useReactivateUserSlice } from "../slices/CustomerDataSlices/reactivateUserSlice";
import { useUpdateCustomerMappingSlice } from "../slices/CustomerDataSlices/updateCustomerMapping";
import { useFindCustomerByIdSlice } from "../slices/CustomerDataSlices/findCustomerByIdSlice";
import { useApproveCustomerSlice } from "../slices/CustomerDataSlices/approveCustomerSlice";
import { useRejectCustomerSlice } from "../slices/CustomerDataSlices/rejectCustomerSlice";
import { useMetadataSlice } from "../slices/CustomerDataSlices/customerMetadataSlice";
import { useDownloadExcelTableSlice } from "../slices/downloadExcelTable";
import { useRaiseTicketSlice } from "../slices/raiseTicketSlice";

interface CustomerDatabaseInterface
  extends ReturnType<typeof useSelectedCustomerSlice>,
    ReturnType<typeof useDownloadExcelSlice>,
    ReturnType<typeof useMapCustomerToBrickSlice>,
    ReturnType<typeof useFetchHQSummarySlice>,
    ReturnType<typeof useFetchCustomersSlice>,
    ReturnType<typeof useCustomerFetchDataSlice>,
    ReturnType<typeof useRemoveMapping>,
    ReturnType<typeof useDeactivateCustomer>,
    ReturnType<typeof useFetchCustomerSummaryCountSlice>,
    ReturnType<typeof useEditCustomerSlice>,
    ReturnType<typeof useUpdateSpecialitySlice>,
    ReturnType<typeof useReactivateUserSlice>,
    ReturnType<typeof useUpdateCustomerMappingSlice>,
    ReturnType<typeof useFindCustomerByIdSlice>,
    ReturnType<typeof useApproveCustomerSlice>,
    ReturnType<typeof useRejectCustomerSlice>,
    ReturnType<typeof useDownloadExcelTableSlice>,
    ReturnType<typeof useRaiseTicketSlice>,
    ReturnType<typeof useMetadataSlice> {}

export const CustomerDatabaseStore = create<CustomerDatabaseInterface>(
  (...a) => ({
    ...useSelectedCustomerSlice(...a),
    ...useFetchCustomersSlice(...a),
    ...useRemoveMapping(...a),
    ...useDeactivateCustomer(...a),
    ...useFetchCustomerSummaryCountSlice(...a),
    ...useEditCustomerSlice(...a),
    ...useDownloadExcelSlice(...a),
    ...useMapCustomerToBrickSlice(...a),
    ...useFetchHQSummarySlice(...a),
    ...useCustomerFetchDataSlice(...a),
    ...useUpdateSpecialitySlice(...a),
    ...useReactivateUserSlice(...a),
    ...useUpdateCustomerMappingSlice(...a),
    ...useFindCustomerByIdSlice(...a),
    ...useApproveCustomerSlice(...a),
    ...useRejectCustomerSlice(...a),
    ...useMetadataSlice(...a),
    ...useRaiseTicketSlice(...a),
    ...useDownloadExcelTableSlice(...a),
  })
);
