import TablePagination from "@mui/material/TablePagination";

type FiltersProps = {
  totalPages: number;
  rowsPerPageOptions: number[];
  currenPage: number;
  rowsPerPage: number;
  successCallback: Function;
};

const Pagination: React.FC<FiltersProps> = ({
  totalPages,
  currenPage,
  rowsPerPage,
  rowsPerPageOptions,
  successCallback,
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    successCallback(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    successCallback(1, parseInt(event.target.value));
  };

  return (
    <TablePagination
      component="div"
      count={totalPages}
      page={currenPage - 1}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default Pagination;
