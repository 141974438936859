export const DailySalesReportPercentDataCondition = [
  {
    measure: "target",
    format: {
      position: "fixed",
    },
  },
  {
    formula: "#value >= 0 ",
    measure: "1",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('1') < #value",
    measure: "2",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('1') >= #value",
    measure: "2",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('2') < #value",
    measure: "3",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('2') >= #value",
    measure: "3",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('3') < #value",
    measure: "4",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('3') >= #value",
    measure: "4",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('4') < #value",
    measure: "5",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('4') >= #value",
    measure: "5",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('5') < #value",
    measure: "6",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('5') >= #value",
    measure: "6",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('6') < #value",
    measure: "7",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('6') >= #value",
    measure: "7",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('7') < #value",
    measure: "8",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('7') >= #value",
    measure: "8",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('8') < #value",
    measure: "9",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('8') >= #value",
    measure: "9",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('9') < #value",
    measure: "10",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('9') >= #value",
    measure: "10",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('10') < #value",
    measure: "11",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('10') >= #value",
    measure: "11",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('11') < #value",
    measure: "12",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('11') >= #value",
    measure: "12",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('12') < #value",
    measure: "13",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('12') >= #value",
    measure: "13",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('13') < #value",
    measure: "14",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('13') >= #value",
    measure: "14",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('14') < #value",
    measure: "15",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('14') >= #value",
    measure: "15",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('15') < #value",
    measure: "16",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('15') >= #value",
    measure: "16",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('16') < #value",
    measure: "17",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('16') >= #value",
    measure: "17",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('17') < #value",
    measure: "18",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('17') >= #value",
    measure: "18",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('18') < #value",
    measure: "19",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('18') >= #value",
    measure: "19",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('19') < #value",
    measure: "20",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('19') >= #value",
    measure: "20",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('20') < #value",
    measure: "21",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('20') >= #value",
    measure: "21",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('21') < #value",
    measure: "22",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('21') >= #value",
    measure: "22",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('22') < #value",
    measure: "23",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('22') >= #value",
    measure: "23",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('23') < #value",
    measure: "24",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('23') >= #value",
    measure: "24",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('24') < #value",
    measure: "25",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('24') >= #value",
    measure: "25",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('25') < #value",
    measure: "26",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('25') >= #value",
    measure: "26",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('26') < #value",
    measure: "27",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('26') >= #value",
    measure: "27",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('27') < #value",
    measure: "28",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('27') >= #value",
    measure: "28",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('28') < #value",
    measure: "29",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('28') >= #value",
    measure: "29",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('29') < #value",
    measure: "30",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('29') >= #value ",
    measure: "30",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('30') < #value",
    measure: "31",
    format: {
      color: "white",
      backgroundColor: "#2ccb74",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
  {
    formula: "sum('30') >= #value",
    measure: "31",
    format: {
      color: "white",
      backgroundColor: "#e44d3e",
      fontFamily: "Arial",
      fontSize: "12px",
    },
  },
];
