import VisitSummaryTable from "./sub-components/VisitSummaryTable";
import SpecialtySummaryTable from "./sub-components/SpecialtySummaryTable";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MonthlyHeadquarterSummaryTable from "./sub-components/MonthlyHeadquarterSummaryTable";
import dayjs from "dayjs";
import { CoverageReportsStore } from "../../../../store/main/CoverageReportsStore";
import { useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 3, mt: 2 }} bgcolor={"#f9f9f9"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function CoverageReportsTabs() {
  const {
    fetchMonthlySpecialitySummary,
    fetchMonthlyHeadquarterSummary,
    fetchVisitSummary,
    specialitySummaryFilters,
    monthlyHeadquarterFilters,
    visitSummaryFilters,
  } = CoverageReportsStore();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchTableData = (name: string) => {
    if (name === "HQWiseSummary") {
      monthlyHeadquarterFilters.monthYear = dayjs(new Date());
      fetchMonthlyHeadquarterSummary();
    } else if (name === "SpecialtySummary") {
      specialitySummaryFilters.monthYear = dayjs(new Date());
      fetchMonthlySpecialitySummary();
    } else if (name == "visitSummary") {
      visitSummaryFilters.monthYear = dayjs(new Date());
      visitSummaryFilters.page = 1;
      fetchVisitSummary();
    }
  };

  const [downloadData, setDownloadData] = useState<any[]>([]);
  React.useEffect(() => {
    fetchMonthlyHeadquarterSummary(downloadData);
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box paddingX={2}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label="HQ Wise Summary"
            value={0}
            onClick={() => {
              fetchTableData("HQWiseSummary");
            }}
          />
          <Tab
            label="Visit Summary"
            value={1}
            onClick={() => {
              fetchTableData("visitSummary");
            }}
          />
          <Tab
            label="Specialty Summary"
            value={2}
            onClick={() => {
              fetchTableData("SpecialtySummary");
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MonthlyHeadquarterSummaryTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <VisitSummaryTable />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SpecialtySummaryTable />
      </TabPanel>
    </Box>
  );
}
