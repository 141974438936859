import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { FetchState } from "../../../domain/models/fetch-state";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { RemoteFetchBrickReport } from "../../../data/usecases/brick-working-report/remote-fetch-brick-report";
import { FetchBrickReport } from "../../../domain/useages/brick-working-report/fetch-brick-report";
import { BrickWorkingData } from "../../../domain/models/bricks-working-report/brick-working-data";

export interface FetchBricWorkingkReportInterface {
  brickReportData: BrickWorkingData[];
  fetchBrickWorkingReport: Function;
  downloadBrickWorkingReport: Function;
  brickReportLoading: FetchState;
  brickReportFilters: {
    zone?: string;
    region?: string;
    customer_type_id?: string;
    product_ids?: string[];
    year: Dayjs | null;
  };
}

const initialStates = {
  brickReportData: [],
  brickReportLoading: FetchState.DEFAULT,
  brickReportFilters: { year: dayjs(new Date()) },
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchBricWorkingkReportSlice: StateCreator<
  FetchBricWorkingkReportInterface
> = (set, get) => ({
  ...initialStates,
  fetchBrickWorkingReport: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    let filterParams = {
      zone: get().brickReportFilters.zone,
      region: get().brickReportFilters.region,
      customer_type_id: get().brickReportFilters.customer_type_id,
      product_ids: get().brickReportFilters.product_ids,
    };

    const remoteFetchBrickReport: FetchBrickReport = new RemoteFetchBrickReport(
      `${BASE_URL}${Endpoints.FETCH_BRICK_WORKING_DATA}`,
      axiosHttpClient
    );

    set(() => ({ brickReportLoading: FetchState.LOADING }));

    let result = await remoteFetchBrickReport.fetch(
      moment((get().brickReportFilters.year as Dayjs).toString()).format(
        "YYYY"
      ),
      filterParams
    );
    if (result) {
      set(() => ({
        brickReportData: result,
        brickReportLoading: FetchState.SUCCESS,
      }));
    }
  },
  downloadBrickWorkingReport: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    let filterParams = {
      zone: get().brickReportFilters.zone,
      region: get().brickReportFilters.region,
      customer_type_id: get().brickReportFilters.customer_type_id,
      product_ids: get().brickReportFilters.product_ids,
      download: 1,
    };

    const remoteFetchBrickReport: FetchBrickReport = new RemoteFetchBrickReport(
      `${BASE_URL}${Endpoints.FETCH_BRICK_WORKING_DATA}`,
      axiosHttpClient
    );


    try {
      let response = await remoteFetchBrickReport.fetch(
        moment((get().brickReportFilters.year as Dayjs).toString()).format(
          "YYYY"
        ),
        filterParams
      );

      // if (response) {
      //   set(() => ({ downloadingExcel: false }));
      // }
      const pdfBlob = new Blob([response], { type: "application/text" });
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "brick_working_report.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error(error);
    }
  },
});
