import { Skeleton, Stack } from "@mui/material";

type Props = {};

const ZoneTabsLoading = (props: Props) => {
  return (
    <Stack direction={"row"} spacing={2}>
      <Skeleton variant="rectangular" width={150} height={30}></Skeleton>
      <Skeleton variant="rectangular" width={150} height={30}></Skeleton>
      <Skeleton variant="rectangular" width={150} height={30}></Skeleton>
      <Skeleton variant="rectangular" width={150} height={30}></Skeleton>
    </Stack>
  );
};

export default ZoneTabsLoading;
