import Chart from "chart.js/auto";
import React, { useEffect, useRef } from "react";
type Props = {
  data: any;
};
const CanvasStyle = {
  padding: "10px",
  borderRadius: "10px",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
};
const ChartView: React.FC<Props> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    const createChart = () => {
      if (chartRef.current != null) {
        const ctx = chartRef.current.getContext(
          "2d"
        ) as CanvasRenderingContext2D;

        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
        chartInstance.current = new Chart(ctx, data);
      }
    };

    createChart();
  }, [data]);

  return (
    <div style={CanvasStyle}>
      <canvas ref={chartRef} width="550" height="370"></canvas>
    </div>
  );
};

export default ChartView;
