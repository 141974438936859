import { UpdateUserManagementData } from "../../../domain/useages/user-management/update-user-management-data";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteUpdateUserManagementData
  implements UpdateUserManagementData
{
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async edit(
    id: string,
    params: UpdateUserManagementData.Params
  ): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url.replace(":id", id.toString()),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    return httpResponse.data;
  }
}
