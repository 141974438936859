import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteGenerateOTP } from "../../../data/usecases/remote-generate-otp";
import Endpoints from "../../../domain/endpoints";
import { GenerateOTP } from "../../../domain/useages/generate-otp";
import Swal from "sweetalert2";

export interface GenerateOTPInterface {
  generateOtp: Function;
  OtpStatus: any;
}

const intitalState = {
  OtpStatus: {},
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useGenerateOTPSlice: StateCreator<GenerateOTPInterface> = (
  set,
  get
) => ({
  ...intitalState,
  generateOtp: async (payload: GenerateOTP.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteUpdateFulfilmentAgent = new RemoteGenerateOTP(
      `${BASE_URL}${Endpoints.GENERATE_OTP}`,
      axiosHttpClient
    );

    let result = await remoteUpdateFulfilmentAgent.generate(payload);
    if (result) {
      set(() => ({ OtpStatus: result }));
      if (result.success) {
        Swal.fire({
          icon: "success",
          timer: 2000,
          text: result.message,
          customClass: {
            container: "swal2-style",
          },
        });
      }
    }
    if (!result.success) {
      if (result.errors) {
        let errorText = "";
        for (let x in result.errors) {
          errorText += result.errors[x] + "\n";
        }
        Swal.fire({
          timer: 2000,
          icon: "error",
          text: errorText.replace(/\w+/g, function (w) {
            return w[0].toUpperCase() + w.slice(1).toLowerCase();
          }),
          customClass: {
            container: "swal2-style",
          },
        });
      } else {
        Swal.fire({
          timer: 2000,
          icon: "error",
          text: result.message.replace(/\w+/g, function (w: any) {
            return w[0].toUpperCase() + w.slice(1).toLowerCase();
          }),
          customClass: {
            container: "swal2-style",
          },
        });
      }
    }
  },
});
