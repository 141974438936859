import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { CustomerDetails } from "../../../../../../domain/models/customer-database/customer-details";
import Roles from "../../../../../../common/enums/Roles";
import { TerritoryDataStore } from "../../../../../../store/main/TerritoryDataStore";
import { CustomerDatabaseStore } from "../../../../../../store/main/CustomerDatabaseStore";
import theme from "../../../../../../custom-theme";
import { MobileModalStyle } from "../../../../../../common/constants/custom-style";

interface Props {
  data: CustomerDetails;
  open: boolean;
  customerID: number[];
  handleClose: Function;
  title: string;
  reasonOptions: string[];
}

type FormFields = {
  zone: string;
  region: string;
  area: string;
  hq: string;
  brick: string;
  department: number;
  reason: string;
  reasonSelect: string;
};

const ReactivateCustomerModalMobile: React.FC<Props> = ({
  open,
  handleClose,
  data,
  customerID,
  reasonOptions,
  title,
}) => {
  const { handleSubmit, control, setValue, watch, getValues } =
    useForm<FormFields>({
      mode: "onChange",
    });

  const [roleLevel, setRoleLevel] = useState<number>(0);

  const {
    userRole,
    reactivateUser,
    fetchCustomerData,
    fetchCustomerSummaryCount,
  } = CustomerDatabaseStore();

  const {
    regionMasterData,
    fetchAreaMaster,
    areaMasterData,
    fetchHeadquarterMaster,
    headquarterMasterData,
    fetchBricksMaster,
    fetchRegionMaster,
    zoneMasterData,
    bricksMasterData,
    fetchDepartment,
    departmentData,
  } = TerritoryDataStore();

  const onSubmitReactivateCustomer = (formData: FormFields) => {
    let payload: any = {
      customer_ids: customerID,
      old_headquarter_id: data.mappings.headquarter_id,
      brick_id: formData.brick,
      headquarter_id: formData.hq,
      department_id: formData.department,
      reason:
        formData.reasonSelect == "Other"
          ? formData.reason
          : formData.reasonSelect,
    };

    reactivateUser(
      payload,
      fetchCustomerData,
      handleClose,
      fetchCustomerSummaryCount
    );
    handleClose();
  };
  const [ids, setIds] = useState<number[]>([]);

  const handleEditTerritory = (e: any, name: string) => {
    setIds([]);
    ids.push(e.target.value);
    if (name === "region") {
      fetchRegionMaster(ids);
    } else if (name === "area") {
      fetchAreaMaster(ids);
    } else if (name === "hq") {
      fetchHeadquarterMaster(ids);
    } else if (name === "brick") {
      fetchBricksMaster(ids);
    }
  };

  useEffect(() => {
    fetchDepartment();
    setValue(
      "zone",
      data.mappings.zone_id ? data.mappings.zone_id.toString() : ""
    );
    setValue(
      "region",
      data.mappings.region_id ? data.mappings.region_id.toString() : ""
    );
    setValue(
      "area",
      data.mappings.area_id ? data.mappings.area_id.toString() : ""
    );
    setValue(
      "hq",
      data.mappings.headquarter_id
        ? data.mappings.headquarter_id.toString()
        : ""
    );
    setValue(
      "brick",
      data.mappings.brick_id ? data.mappings.brick_id.toString() : ""
    );

    if (
      userRole.role === Roles.COUTNRY_MANAGER ||
      userRole.role === Roles.ZONE_MANAGER
    ) {
      setRoleLevel(1);
    } else if (userRole.role === Roles.REGION_MANAGER) {
      setRoleLevel(2);
    } else if (userRole.role === Roles.AREA_MANAGER) {
      setRoleLevel(3);
    }
  }, [regionMasterData]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Modal open={open}>
          <Box sx={MobileModalStyle} height={"80vh"} overflow={"scroll"}>
            <Stack>
              <Typography
                textAlign="center"
                variant="h6"
                fontFamily="Segoe UI"
                fontWeight={600}
                color="#4D4D4D"
              >
                {title} Customer
              </Typography>

              <Typography variant="subtitle2">Firm Name</Typography>

              <TextField
                fullWidth
                size="small"
                variant="outlined"
                disabled={true}
                value={data ? data.firm_name : ""}
              />
              <Typography variant="subtitle2">Email ID</Typography>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                disabled={true}
                value={""}
              />
              <Typography variant="subtitle2">Mobile Number</Typography>

              <TextField
                fullWidth
                size="small"
                variant="outlined"
                disabled={true}
                value={data ? data.mobile : ""}
              />
              {(userRole.role === Roles.COUTNRY_MANAGER ||
                userRole.role === Roles.ZONE_MANAGER) && (
                <Stack>
                  <Typography variant="subtitle2">Select Zone</Typography>
                  <Controller
                    name="zone"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        select
                        size="small"
                        variant="outlined"
                        defaultValue={data.mappings.region_id}
                        disabled={roleLevel === 1 ? false : true}
                        value={value}
                        error={error && true}
                        helperText={error?.message}
                        onChange={(event: any) => {
                          if (event.target && event.target.value) {
                            if (roleLevel === 1) {
                              handleEditTerritory(event, "region");
                              onChange(event.target.value);
                            }
                          }
                        }}
                      >
                        {zoneMasterData?.length > 0 &&
                          zoneMasterData?.map((zoneData) => (
                            <MenuItem value={zoneData.id}>
                              <Typography
                                color="secondary.light"
                                variant="caption"
                              >
                                {zoneData.name}
                              </Typography>
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                    rules={{
                      required: { value: true, message: "Field Required" },
                    }}
                  />
                </Stack>
              )}
              {(userRole.role === Roles.COUTNRY_MANAGER ||
                userRole.role === Roles.ZONE_MANAGER ||
                userRole.role === Roles.REGION_MANAGER) && (
                <Stack>
                  <Typography variant="subtitle2">Select Region</Typography>
                  <Controller
                    name="region"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        select
                        size="small"
                        variant="outlined"
                        disabled={roleLevel === 1 ? false : true}
                        value={value}
                        error={error && true}
                        helperText={error?.message}
                        onChange={(event: any) => {
                          if (event.target && event.target.value) {
                            if (roleLevel === 1) {
                              handleEditTerritory(event, "area");
                              onChange(event.target.value);
                            }
                          }
                        }}
                      >
                        {regionMasterData?.length > 0 &&
                          regionMasterData?.map((regionData) => (
                            <MenuItem value={regionData.id}>
                              <Typography
                                color="secondary.light"
                                variant="caption"
                              >
                                {regionData.name}
                              </Typography>
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                    rules={{
                      required: { value: true, message: "Field Required" },
                    }}
                  />
                </Stack>
              )}
              {userRole.role != Roles.HEADQUATER_MANAGER && (
                <Stack>
                  <Typography variant="subtitle2">Select Area</Typography>
                  <Controller
                    name="area"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        select
                        size="small"
                        variant="outlined"
                        value={value}
                        disabled={
                          roleLevel === 1 || roleLevel === 2 ? false : true
                        }
                        error={error && true}
                        helperText={error?.message}
                        onChange={(event: any) => {
                          if (event.target && event.target.value) {
                            handleEditTerritory(event, "hq");
                            onChange(event.target.value);
                          }
                        }}
                      >
                        {areaMasterData?.length > 0 &&
                          areaMasterData?.map((regionData) => (
                            <MenuItem value={regionData.id}>
                              <Typography
                                color="secondary.light"
                                variant="caption"
                              >
                                {regionData.name}
                              </Typography>
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                    rules={{
                      required: { value: true, message: "Field Required" },
                    }}
                  />
                </Stack>
              )}
              <Typography variant="subtitle2">Select HQ</Typography>
              <Controller
                name="hq"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    select
                    size="small"
                    variant="outlined"
                    disabled={
                      roleLevel === 1 || roleLevel === 2 || roleLevel === 3
                        ? false
                        : true
                    }
                    value={value}
                    error={error && true}
                    helperText={error?.message}
                    onChange={(event: any) => {
                      if (event.target && event.target.value) {
                        handleEditTerritory(event, "brick");
                        onChange(event.target.value);
                      }
                    }}
                  >
                    {headquarterMasterData?.length > 0 &&
                      headquarterMasterData?.map((regionData) => (
                        <MenuItem value={regionData.id}>
                          <Typography color="secondary.light" variant="caption">
                            {regionData.name}
                          </Typography>
                        </MenuItem>
                      ))}
                  </TextField>
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              <Typography variant="subtitle2">Select Brick</Typography>
              <Controller
                name="brick"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    select
                    size="small"
                    variant="outlined"
                    value={value}
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  >
                    {bricksMasterData?.length > 0 &&
                      bricksMasterData?.map((regionData) => (
                        <MenuItem value={regionData.id}>
                          <Typography color="secondary.light" variant="caption">
                            {regionData.name}
                          </Typography>
                        </MenuItem>
                      ))}
                  </TextField>
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              <Typography variant="subtitle2">Select Department</Typography>
              <Controller
                name="department"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    select
                    size="small"
                    variant="outlined"
                    value={value}
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  >
                    {departmentData?.length > 0 &&
                      departmentData?.map((department) => (
                        <MenuItem value={department.id}>
                          <Typography color="secondary.light" variant="caption">
                            {department.name}
                          </Typography>
                        </MenuItem>
                      ))}
                  </TextField>
                )}
                rules={{
                  required: { value: true, message: "Field Required" },
                }}
              />
              <Typography variant="subtitle2">Address Line</Typography>

              <TextField
                fullWidth
                size="small"
                multiline
                minRows={4}
                disabled={true}
                variant="outlined"
                value={data.address ? data.address.line : ""}
              />
              <Typography variant="subtitle2">Landmark</Typography>

              <TextField
                fullWidth
                disabled={true}
                size="small"
                variant="outlined"
                value={data.address ? data.address.landmark : ""}
              />

              <Typography variant="subtitle2">Pincode</Typography>
              <TextField
                fullWidth
                disabled
                size="small"
                variant="outlined"
                value={data.address ? data.address.pincode : ""}
              />
              <Controller
                name="reasonSelect"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel>Select reason</InputLabel>
                    <Select
                      value={value}
                      size="small"
                      label="Select reason"
                      onChange={onChange}
                    >
                      {reasonOptions?.map((name) => (
                        <MenuItem value={name}>{name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              {watch("reasonSelect") == "Other" && (
                <Controller
                  name="reason"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      value={value}
                      size="small"
                      onChange={onChange}
                      placeholder="Please type reason..."
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: /^(?!.*[^a-zA-Z0-9 ]).{5,}$/,
                      message:
                        "Enter valid reason.  No special characters and minimum 5 characters",
                    },
                  }}
                />
              )}
              <Stack
                direction="row"
                gap={3}
                justifyContent="center"
                marginTop={3}
              >
                <Button
                  variant="contained"
                  disabled={
                    !(
                      watch("brick") &&
                      watch("department") &&
                      ((watch("reasonSelect") &&
                        watch("reasonSelect") != "Other") ||
                        (watch("reasonSelect") == "Other" &&
                          watch("reason") &&
                          watch("reason").length > 4))
                    )
                  }
                  sx={{ textTransform: "unset", borderRadius: "30px" }}
                  onClick={handleSubmit(onSubmitReactivateCustomer)}
                >
                  <Typography variant="subtitle1">{title}</Typography>
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "unset",
                    borderRadius: "30px",
                    minWidth: "20%",
                  }}
                  onClick={() => handleClose()}
                >
                  <Typography variant="subtitle1">Cancel</Typography>
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Modal>
      </ThemeProvider>
    </>
  );
};

export default ReactivateCustomerModalMobile;
