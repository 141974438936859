import { Box, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

type Props = {
  height: string;
  message?: string;
};

const NoRecordFound: React.FC<Props> = ({
  height,
  message = "NO RECORD FOUND",
}) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={height}
      >
        <HighlightOffIcon sx={{ fontSize: 50 }} color="error" />
        <Typography variant="h6">{message}</Typography>
      </Box>
    </>
  );
};

export default NoRecordFound;
