import { FetchServiceProviders } from "../../domain/useages/fetch-service-providers";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";
export class RemoteFetchServiceProviders implements FetchServiceProviders {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(params: FetchServiceProviders.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status === 200) {
      return httpResponse.data;
    }
  }
}
