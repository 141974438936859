import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteUpdateFulfilmentAgent } from "../../../data/usecases/remote-update-fulfilment-agent";
import { UpdateFulfilment } from "../../../domain/models/update-fulfilment";
import Swal from "sweetalert2";

export interface UpdateFulfilmentAgentInterface {
  updateFulfilmentAgent: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useUpdateFulfilmentAgentSlice: StateCreator<
  UpdateFulfilmentAgentInterface
> = (set, get) => ({
  updateFulfilmentAgent: async (
    payload: UpdateFulfilment,
    handleClose: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteUpdateFulfilmentAgent = new RemoteUpdateFulfilmentAgent(
      `${BASE_URL}${Endpoints.UPDATE_FULFILMENT_AGENT}`,
      axiosHttpClient
    );
    let result = await remoteUpdateFulfilmentAgent.update(payload);

    if (result.success) {
      handleClose();

      Swal.fire({
        timer: 3000,
        icon: "success",
        text: "Updated Successfully",
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },
});
