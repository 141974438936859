import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { UpdateSpeciality } from "../../../domain/useages/customer-database/update-speciality";
import { RemoteUpdateSpeciality } from "../../../data/usecases/customer-database/remote-update-speciality";

export interface UpdateSpecialitySliceInterface {
  updateCustomerSpeciality: Function;
  handleLoading: Function;
 
}

const initialStates = {
  inputUsageSummaryData: [],
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useUpdateSpecialitySlice: StateCreator<
  UpdateSpecialitySliceInterface
> = (set, get) => ({
  ...initialStates,
  handleLoading: (value: boolean)=>{
    if(value){
      Swal.fire({
        didOpen: () => {
          if (value) {
            Swal.showLoading();
          }
        },

        title: "Please wait... Speciality update request is sending ",
      })
      
    }
  },
    
    
    
  
  updateCustomerSpeciality: async (
    payload: UpdateSpeciality.Params,
    fetchCustomerData: Function,
    handleClearSelectedCustomers: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteUpdateSpeciality: UpdateSpeciality = new RemoteUpdateSpeciality(
      `${BASE_URL}${Endpoints.UPDATE_SPECIALITY}`,

      axiosHttpClient
    );
    get().handleLoading(true)
    let response = await remoteUpdateSpeciality.update(payload);
   
    if (response.success) {
      get().handleLoading(false)
      Swal.fire({
        icon: "success",
        title: response.message,
        timer: 3000,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: '#3085d6',
      });
      handleClearSelectedCustomers();
      fetchCustomerData();
    } else {
      get().handleLoading(false)
      Swal.fire("Speciality Update Failed", "", "error");
    }
  },
});
