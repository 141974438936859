export const textfieldStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
  "& .MuiFormLabel-root": {
    fontSize: "12px",
    color: "primary.light",
  },
  borderRadius: "8px",
  backgroundColor: "white",
};

export const menuItemsStyle = {
  height: "30px",
  borderRadius: "8px",
  backgroundColor: "transparent",
};

export const FunctionalModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  borderRadius: "22px",
  p: 4,
};
export const EditCustomerModalStyle = {
  position: "absolute" as "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  borderRadius: "22px",
  boxShadow: 24,
  p: 4,
};

export const MobileModalStyle = {
  position: "absolute" as "absolute",
  bottom: "0",
  left: "50%",
  transform: "translateX(-50%)",
  width: "90%",
  bgcolor: "background.paper",
  borderRadius: "10px 10px 0 0",
  boxShadow: 24,
  p: 2,
};
