import Swal from "sweetalert2";
import { ResetUserPassword } from "../../../domain/useages/user-management/reset-user-password";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteResetUserPassword implements ResetUserPassword {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async reset(id: string, params: ResetUserPassword.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", id.toString()),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == 200) {
      Swal.fire({
        timer: 3000,
        icon: "success",
        text: "Password Changed Successfully",
      });
      return httpResponse.body ? httpResponse.body : httpResponse.data;
    } else {
      Swal.fire({
        timer: 3000,
        icon: "error",
        text: "Some Error Occured",
      });
      return "";
    }
  }
}
