import { Stack } from "@mui/material";
import Header from "../components/Header";
import StandardTourProgramPivot from "../components/desktop/standard-tour-program/StandardTourProgramPivot";

export default function StandardTourProgram() {
  return (
    <>
      <Stack gap={2} position={"fixed"} width={"100%"}>
        <Header title="Standard Tour Program" />
        <StandardTourProgramPivot />
      </Stack>
    </>
  );
}
