import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { ApproveCustomer } from "../../../domain/useages/customer-database/approve-customer";
import { RemoteApproveCustomer } from "../../../data/usecases/customer-database/remote-approve-customer";
import { CustomerDatabaseStore } from "../../main/CustomerDatabaseStore";

export interface ApproveCustomerInterface {
  approveCustomer: Function;
  handleApproveLoading: Function;
}

const initialStates = {};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useApproveCustomerSlice: StateCreator<ApproveCustomerInterface> = (
  set,
  get
) => ({
  ...initialStates,
  handleApproveLoading: (value: boolean) => {
    if (value) {
      Swal.fire({
        didOpen: () => {
          if (value) {
            Swal.showLoading();
          }
        },
        customClass: {
          container: "swal2-style",
        },
        title: "Please wait... Approved customer request is sending ",
      });
    }
  },

  approveCustomer: async (
    payload: ApproveCustomer.Params,
    handleClose: Function,
    title: string,
    fetchCustomerData: Function,
    handleClearSelectedCustomers: Function,
    fetchCustomerSummaryCount: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteApproveCustomer: ApproveCustomer = new RemoteApproveCustomer(
      `${BASE_URL}${Endpoints.APPROVE_CUSTOMER}`,

      axiosHttpClient
    );
    get().handleApproveLoading(true);
    let response = await remoteApproveCustomer.update(payload);

    if (response.success) {
      get().handleApproveLoading(false);
      fetchCustomerData();
      handleClearSelectedCustomers();
      fetchCustomerSummaryCount(CustomerDatabaseStore.getState().filterStore);
      Swal.fire({
        icon: "success",
        title: response.message,
        timer: 3000,
        customClass: {
          container: "swal2-style",
        },
      });
      handleClose(title);
    } else {
      get().handleApproveLoading(false);
      Swal.fire("Approve customer request failed", "", "error");
    }
  },
});
