import { Skeleton, Stack } from "@mui/material";

type Props = {};

const ReportFiltersLoading = (props: Props) => {
  return (
    <Stack direction={"row"} spacing={1} alignItems={"center"}>
      <Skeleton width={200} height={80}></Skeleton>
      <Skeleton width={200} height={80}></Skeleton>
      <Skeleton width={200} height={80}></Skeleton>
      <Skeleton width={200} height={80}></Skeleton>
      <Skeleton width={200} height={80}></Skeleton>
      <Skeleton width={100} height={80}></Skeleton>
      <Skeleton width={100} height={80}></Skeleton>
    </Stack>
  );
};

export default ReportFiltersLoading;
