import { CheckEligibility } from "../../domain/useages/check-eligiblity";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteCheckEligibility implements CheckEligibility {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(id: string, params?: CheckEligibility.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":uu_id", id),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    // if (httpResponse.status === 200 ) {
    return httpResponse.data;
    // }
  }
}
