import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { RemoteReactivateUser } from "../../../data/usecases/customer-database/remote-reactivate-user";
import { ReactivateUser } from "../../../domain/useages/customer-database/reactivate-user";
import { CustomerDatabaseStore } from "../../main/CustomerDatabaseStore";

export interface ReactivateUserInterface {
  reactivateUser: Function;
  handleReactivateLoading: Function;
}

const initialStates = {};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useReactivateUserSlice: StateCreator<ReactivateUserInterface> = (
  set,
  get
) => ({
  ...initialStates,
  handleReactivateLoading: (value: boolean) => {
    if (value) {
      Swal.fire({
        didOpen: () => {
          if (value) {
            Swal.showLoading();
          }
        },
        customClass: {
          container: "swal2-style",
        },

        title: "Please wait... Customer reactivate request is sending ",
      });
    }
  },

  reactivateUser: async (
    payload: ReactivateUser.Params,
    fetchCustomerData: Function,
    handleCloseModal: Function,
    fetchCustomerSummaryCount: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteReactivateUser: ReactivateUser = new RemoteReactivateUser(
      `${BASE_URL}${Endpoints.REACTIVATE_USER}`,

      axiosHttpClient
    );
    get().handleReactivateLoading(true);
    let response = await remoteReactivateUser.reactivate(payload);

    if (response.success) {
      get().handleReactivateLoading(false);
      Swal.fire({
        icon: "success",
        title: response.message,
        timer: 2000,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
      handleCloseModal();
      fetchCustomerSummaryCount(CustomerDatabaseStore.getState().filterStore);
      fetchCustomerData();
    } else {
      get().handleReactivateLoading(false);
      Swal.fire({
        icon: "error",
        timer: 3000,
        title: "Reactivate customer request failed",
        text: response.errors.message,
      });
    }
  },
});
