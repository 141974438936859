import { HttpConstants } from '../protocols/http/http-constants';
import { FetchRegionMaster } from './../../domain/useages/fetch-region';
import { HttpGetClient } from './../protocols/http/http-get-client';

export class RemoteFetchRegionMaster implements FetchRegionMaster {
    constructor(
        private readonly ulr: string,
        private readonly httpGetClient: HttpGetClient
    ) { }
    async fetch(params?: FetchRegionMaster.Params): Promise<any>{
        const httpResponse = await this.httpGetClient.get({
            url: this.ulr,
            query: params,
            headers: {
                [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
                [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
            },
            authHeaders:true,
            
        })
        if (httpResponse.status === 200) {
            return httpResponse.data;
        }
    }
}
