import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import { UnmappedStockistStore } from "../../../../store/main/UnmappedStockistStore";
import { useEffect, useState } from "react";
import { FetchState } from "../../../../domain/models/fetch-state";
import dayjs from "dayjs";
import moment from "moment";
import MapStockistModal from "./modals/MapStockistModal";
import CustomerTableLoading from "../CustomerTableLoading";
import NoRecordFound from "../NoRecordFound";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import InfoIcon from "@mui/icons-material/Info";
import Roles from "../../../../common/enums/Roles";

const UnmappedStockistDesktop = () => {
  const { fetchUnmappedStockist, unmappedStockist, unmappedStockistState } =
    UnmappedStockistStore();
  const { downloadExcelTable, userDetails } = CustomerDatabaseStore();
  useEffect(() => {
    const filter = {
      month_year: moment(dayjs(new Date()).toString()).format("yyyyMM"),
    };
    fetchUnmappedStockist(filter);
  }, []);

  const [openMap, setOpenMap] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [name, setName] = useState<string>("");

  const updateOpen = (code: string, name: string) => {
    setCode(code);
    setName(name);
    setOpenMap(true);
  };
  const downloadFile = () => {
    if (unmappedStockist.length != 0) downloadExcelTable(unmappedStockist);
  };
  return (
    <Stack spacing={5}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5" fontWeight={600}>
          Unmapped Stockist
        </Typography>
        {userDetails &&
          userDetails.role &&
          userDetails.role.visibility == 1 && (
            <Button
              variant="contained"
              startIcon={<FileDownloadOutlinedIcon />}
              sx={{ borderRadius: "10px", width: "180px", height: "40px" }}
              onClick={downloadFile}
              color="primary"
            >
              <Typography variant="subtitle2" textTransform={"none"}>
                Download Excel
              </Typography>
            </Button>
          )}
      </Stack>

      {unmappedStockistState === FetchState.SUCCESS && (
        <>
          <Stack
            direction={"row"}
            gap={2}
            sx={{
              backgroundColor: "#ffbbbd",
              borderRadius: "10px",
              p: 2,
            }}
          >
            <InfoIcon />
            <Typography variant="subtitle2">
              After completing the stockist mapping, it may take up to one hour
              for the sales to be reflected.
            </Typography>
          </Stack>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      padding: 10,
                    }}
                    align="center"
                  >
                    Stockist Code
                  </TableCell>
                  <TableCell
                    style={{
                      padding: 10,
                    }}
                    align="center"
                  >
                    Stockist Name
                  </TableCell>
                  <TableCell
                    style={{
                      padding: 10,
                    }}
                    align="center"
                  >
                    Stockist City
                  </TableCell>
                  <TableCell
                    style={{
                      padding: 10,
                    }}
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unmappedStockist.map((stockist) => (
                  <TableRow>
                    <TableCell
                      style={{
                        padding: 10,
                        borderRight: "none",
                      }}
                      align="center"
                      sx={{ padding: 1 }}
                    >
                      {stockist.stockist_code}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: 10,
                      }}
                      align="center"
                      sx={{ padding: 1 }}
                    >
                      {stockist.stockist_name}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: 10,
                      }}
                      align="center"
                      sx={{ padding: 1 }}
                    >
                      {stockist.stockist_city}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: 10,
                      }}
                      align="center"
                      sx={{ padding: 1 }}
                    >
                      <Button
                        variant="contained"
                        onClick={() =>
                          updateOpen(
                            stockist.stockist_code,
                            stockist.stockist_name
                          )
                        }
                      >
                        Map Stockist
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {unmappedStockistState === FetchState.LOADING && <CustomerTableLoading />}
      {unmappedStockistState === FetchState.ERROR && (
        <NoRecordFound height="70vh" />
      )}
      {openMap && (
        <MapStockistModal
          open={openMap}
          handleClose={() => setOpenMap(false)}
          code={code}
          name={name}
        />
      )}
    </Stack>
  );
};

export default UnmappedStockistDesktop;
