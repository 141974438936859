import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { RemoteAddMetadata } from "../../../data/usecases/customer-database/remote-add-metadata";
import { AddMetadata } from "../../../domain/useages/customer-database/add-metadata";
import { MetadataMaster } from "../../../domain/useages/customer-database/metadata-master";
import { RemoteMetadataMaster } from "../../../data/usecases/customer-database/remote-fetch-metadata-master";
import { RemoveMetadata } from "../../../domain/useages/customer-database/remove-metadata";
import { RemoteRemoveMetadata } from "../../../data/usecases/customer-database/remote-remove-metadata";
import { CustomerMetadata } from "../../../domain/models/customer-database/customer-metadata";
import Swal from "sweetalert2";
import { CustomerDatabaseStore } from "../../main/CustomerDatabaseStore";

export interface CustoerMetadataSliceInterface {
  removeMetaData: Function;
  addMetaData: Function;
  fetchMetadata: Function;
  customerMetadata: CustomerMetadata[];
  showDepartmentSegementFilter: boolean;
}

const initialState = {
  customerMetadata: [],
  showDepartmentSegementFilter: true,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();
const token = storage.get(AUTH_TOKEN_KEY);
const BASE_URL = storage.get(BASE_URL_KEY);

axiosHttpClient.setAuthHeaders({
  [AUTH_HEADER]: atob(token),
});

export const useMetadataSlice: StateCreator<CustoerMetadataSliceInterface> = (
  set,
  get
) => ({
  ...initialState,
  removeMetaData: async (
    paylaod: RemoveMetadata.Params,
    handleClearSelectedCustomers: Function
  ) => {
    const remoteRemoveMetaData: RemoveMetadata = new RemoteRemoveMetadata(
      `${BASE_URL}${Endpoints.REMOVE_METADATA}`,
      axiosHttpClient
    );
    let result = await remoteRemoveMetaData.remove(paylaod);
    if (result.success) {
      handleClearSelectedCustomers();
      CustomerDatabaseStore.getState().fetchCustomerData();
      Swal.fire({
        icon: "success",
        timer: 3000,
        text: result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
    } else if (result.errors) {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
    }
  },
  addMetaData: async (
    paylaod: AddMetadata.Params,
    handleClearSelectedCustomers: Function
  ) => {
    const remoteAddMetaData: AddMetadata = new RemoteAddMetadata(
      `${BASE_URL}${Endpoints.ADD_METADATA}`,
      axiosHttpClient
    );
    let result = await remoteAddMetaData.add(paylaod);
    if (result.success) {
      handleClearSelectedCustomers();
      CustomerDatabaseStore.getState().fetchCustomerData();
      Swal.fire({
        icon: "success",
        timer: 3000,
        text: result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
    } else if (result.errors) {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
    }
  },
  fetchMetadata: async () => {
    const remoteFetchMetaData: MetadataMaster = new RemoteMetadataMaster(
      `${BASE_URL}${Endpoints.METADATA_MASTER}`,
      axiosHttpClient
    );
    let result = await remoteFetchMetaData.fetch({});
    if (result.success) {
      if (result.customerMetadatas.length == 0) {
        set(() => ({ showDepartmentSegementFilter: false }));
      } else {
        set(() => ({ showDepartmentSegementFilter: true }));
      }
      set(() => ({ customerMetadata: result.customerMetadatas }));
    }
  },
});
