import React from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { FetchState } from "../../../../domain/models/fetch-state";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { DashboardLinkStore } from "../../../../store/main/DashboardLinkStore";
import { CustomizeDepartmentName } from "../../../../common/utils/ReportsFilters";

const DashboardData = () => {
  const [dashboardUrl, setDashboardUrl] = React.useState<string>("");
  const [selectedDashboard, setSelectedDashboard] = React.useState<string>("");
  const { getSelectedDashboardData, dashboardData, fetchDashboardState } =
    DashboardLinkStore();
  const { decodeToken, userRole, userDetailsStatus, userDetails } =
    CustomerDatabaseStore();

  React.useEffect(() => {
    if (userRole.dept_code && userDetailsStatus == FetchState.SUCCESS) {
      getSelectedDashboardData();
    }
  }, [userDetailsStatus]);
  React.useEffect(() => {
    decodeToken();
  }, [userRole.dept_code]);

  React.useEffect(() => {
    if (dashboardData[0]?.name) {
      setSelectedDashboard(dashboardData[0]?.name);
      let link = dashboardData[0]?.url + "?" + "params=";
      let obj: any = {};
      for (let i = 0; i <= 100; i++) {
        obj[`ds${i}.division`] = userDetails.departments[0]
          .customer_service_org_code
          ? `${CustomizeDepartmentName(
              userDetails.departments[0].customer_service_org_code
            )}`
          : "%";
        obj[`ds${i}.gacode`] = userRole.hq_code ? `${userRole.hq_code}%` : "%";
      }

      const jsonString = JSON.stringify(obj);

      let linkParam = link + encodeURI(jsonString);
      setDashboardUrl(linkParam);
    }
  }, [dashboardData]);

  const onDashboardChange = (e?: any) => {
    setSelectedDashboard(e?.target.value);
    const selectedURL = dashboardData.find(
      (obj) => obj.name === e.target.value
    );

    if (selectedURL) {
      let link = selectedURL?.url + "?" + "params=";
      let obj: any = {};
      for (let i = 0; i <= 100; i++) {
        obj[`ds${i}.division`] = userDetails.departments[0]
          .customer_service_org_code
          ? `${CustomizeDepartmentName(
              userDetails.departments[0].customer_service_org_code
            )}`
          : "%";
        obj[`ds${i}.gacode`] = userRole.hq_code ? `${userRole.hq_code}%` : "%";
      }

      const jsonString = JSON.stringify(obj);

      let linkParam = link + encodeURI(jsonString);

      setDashboardUrl(linkParam);
    }
  };

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        padding={2}
      >
        <Typography variant="h5" fontWeight={660}>
          Dashboard Portal
        </Typography>
        <FormControl
          size="small"
          sx={{
            width: "75%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {dashboardData.length > 0 && (
            <Select
              value={selectedDashboard}
              sx={{
                width: "80%",
                alignItems: "center",
                justifyContent: "center",
              }}
              size="small"
              onChange={(e) => onDashboardChange(e)}
            >
              {dashboardData.map((data) => {
                return <MenuItem value={data.name}>{data.name}</MenuItem>;
              })}
            </Select>
          )}

          {dashboardData.length <= 0 && (
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={35}
              sx={{
                width: "80%",
              }}
            />
          )}
        </FormControl>
      </Stack>
      {dashboardUrl.length > 0 && (
        <Box sx={{ height: "85vh" }}>
          <iframe
            id="dashboard"
            height="100%"
            width="100%"
            src={dashboardUrl}
            frameBorder={0}
            allowFullScreen
          />
        </Box>
      )}
      {fetchDashboardState == FetchState.LOADING && (
        <Box
          sx={{
            height: "85vh",
            justifyContent: "Center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {dashboardUrl.length <= 0 &&
        fetchDashboardState == FetchState.SUCCESS && (
          <Typography variant="h6" textAlign="center">
            No Dashboards Found
          </Typography>
        )}
    </Stack>
  );
};

export default DashboardData;
