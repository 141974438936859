import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useEffect, useState } from "react";
import CustomerActionsModal from "./CustomerActionsModal";
import { CustomerDetails } from "../../../../../../domain/models/customer-database/customer-details";
import { Constants } from "../../../../../../common/constants";
import { EditCustomerModalStyle } from "../../../../../../common/constants/custom-style";
import Actions from "../../../../../../common/enums/Actions";
import { FetchState } from "../../../../../../domain/models/fetch-state";
import { CustomerDatabaseStore } from "../../../../../../store/main/CustomerDatabaseStore";

interface Props {
  open: boolean;
  handleClose: Function;
  title: string;
  customer: CustomerDetails;
}
const CustomerViewModal: React.FC<Props> = ({
  open,
  title,
  handleClose,
  customer,
}) => {
  const {
    customerDataById,
    approveCustomer,
    fetchCustomer,
    fetchCustomerData,
    fetchCustomerSummaryCount,
    customerDatafetchState,
    handleClearSelectedCustomers,
  } = CustomerDatabaseStore();

  const [rejectReasonOpen, setRejectReasonOpen] = useState(false);

  const style = {
    position: "absolute" as "absolute",
    top: "5%",
    right: "1%",
    cursor: "pointer",
    ":hover": { backgroundColor: "transparent" },
  };

  const { handleSubmit } = useForm();

  const onSubmitApproveCustomer = () => {
    let payload: any = {
      customer_ids: [customer.id],
    };
    approveCustomer(
      payload,
      handleClose,
      title,
      fetchCustomerData,
      handleClearSelectedCustomers,
      fetchCustomerSummaryCount
    );
  };

  const handleModal = (modalName: string) => {
    if (modalName === Actions.REJECT_CUSTOMER) {
      setRejectReasonOpen(!rejectReasonOpen);
    }
  };

  useEffect(() => {
    fetchCustomer(customer.id);
  }, []);

  return (
    <>
      {customerDatafetchState === FetchState.SUCCESS && (
        <Modal open={open}>
          <Box sx={EditCustomerModalStyle}>
            <Typography
              fontWeight={500}
              variant="h5"
              textAlign="center"
              marginBottom={2}
            >
              Customer Details
            </Typography>
            <Button
              endIcon={<CloseRoundedIcon color="secondary" />}
              sx={style}
              onClick={() => handleClose()}
            />
            {customer.customer_type_id === 5 && customerDataById.data && (
              <>
                <Stack
                  spacing={3}
                  direction="row"
                  justifyContent="center"
                  useFlexGap
                  flexWrap="wrap"
                >
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      Firm Name
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={customerDataById.data.name}
                    />
                  </Stack>
                  {customerDataById.data.contacts
                    .filter((contact) => contact.type === "mobile")
                    .slice(0, 1)
                    .map((contact) => (
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          Contact
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={contact.value}
                        />
                      </Stack>
                    ))}
                  {customerDataById.data.contacts
                    .filter((contact) => contact.type === "email")
                    .slice(0, 1)
                    .map((contact) => (
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          Email
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={contact.value}
                        />
                      </Stack>
                    ))}
                  {customerDataById.data.addresses && (
                    <>
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          Line
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={customerDataById.data.addresses[0].line}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          Landmark
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={customerDataById.data.addresses[0].landmark}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          City
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={customerDataById.data.addresses[0].city}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          State
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={customerDataById.data.addresses[0].state}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          Pincode
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={customerDataById.data.addresses[0].pincode}
                        />
                      </Stack>
                    </>
                  )}
                  {customerDataById.data.speciality && (
                    <Stack>
                      <Typography variant="subtitle2" color="info.error">
                        Speciality
                      </Typography>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={customerDataById.data.speciality}
                      />
                    </Stack>
                  )}
                  {customerDataById.data.qualification && (
                    <Stack>
                      <Typography variant="subtitle2" color="info.error">
                        Qualification
                      </Typography>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={customerDataById.data.qualification
                          .map((item) => item.value)
                          .join(", ")}
                      />
                    </Stack>
                  )}
                  {customerDataById.data.no_of_patients && (
                    <Stack>
                      <Typography variant="subtitle2" color="info.error">
                        No Of Patients
                      </Typography>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={customerDataById.data.no_of_patients}
                      />
                    </Stack>
                  )}
                </Stack>
              </>
            )}
            {customer.customer_type_id === 8 && customerDataById.data && (
              <Stack
                spacing={3}
                direction="row"
                justifyContent="center"
                useFlexGap
                flexWrap="wrap"
              >
                <Stack>
                  <Typography variant="subtitle2" color="info.error">
                    Firm Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={customerDataById.data.name}
                  />
                </Stack>
                {customerDataById.data.contacts.map((contact) => (
                  <div>
                    {contact.type === "mobile" ||
                      (contact.type === "landline" && (
                        <Stack>
                          <Typography variant="subtitle2" color="info.error">
                            Contact
                          </Typography>
                          <TextField
                            variant="outlined"
                            size="small"
                            value={contact.value}
                          />
                        </Stack>
                      ))}
                    {contact.type === "email" && (
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          Email
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={contact.value}
                        />
                      </Stack>
                    )}
                  </div>
                ))}
                {customerDataById.data.hospital_type && (
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      Hospital Type
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={customerDataById.data.hospital_type}
                    />
                  </Stack>
                )}
                {customerDataById.data.no_of_beds && (
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      Number Of Beds
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={customerDataById.data.no_of_beds}
                    />
                  </Stack>
                )}
                {customerDataById.data.no_of_patients && (
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      Number Of Patients
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={customerDataById.data.no_of_patients}
                    />
                  </Stack>
                )}
                {customerDataById.data.potential_per_month && (
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      potential_per_month
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={customerDataById.data.potential_per_month}
                    />
                  </Stack>
                )}
              </Stack>
            )}
            {customer.customer_type_id !== 5 &&
              customer.customer_type_id !== 8 &&
              customerDataById.data && (
                <Stack
                  spacing={3}
                  direction="row"
                  justifyContent="center"
                  useFlexGap
                  flexWrap="wrap"
                >
                  <Stack>
                    <Typography variant="subtitle2" color="info.error">
                      Firm Name
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={customerDataById.data.name}
                    />
                  </Stack>
                  {customerDataById.data.gst && (
                    <Stack>
                      <Typography variant="subtitle2" color="info.error">
                        GST Number
                      </Typography>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={customerDataById.data.gst}
                      />
                    </Stack>
                  )}
                  {customerDataById.data.pan && (
                    <Stack>
                      <Typography variant="subtitle2" color="info.error">
                        PAN Number
                      </Typography>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={customerDataById.data.pan}
                      />
                    </Stack>
                  )}
                  {customerDataById.data.contacts
                    .filter((contact) => contact.type === "mobile")
                    .slice(0, 1) // Take the first mobile contact
                    .map((contact) => (
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          Contact
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={contact.value}
                        />
                      </Stack>
                    ))}
                  {customerDataById.data.contacts
                    .filter((contact) => contact.type === "email")
                    .slice(0, 1) // Take the first mobile contact
                    .map((contact) => (
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          Contact
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={contact.value}
                        />
                      </Stack>
                    ))}
                  {customerDataById.data.addresses && (
                    <>
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          Line
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={customerDataById.data.addresses[0].line}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          Landmark
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={customerDataById.data.addresses[0].landmark}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          City
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={customerDataById.data.addresses[0].city}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          State
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={customerDataById.data.addresses[0].state}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2" color="info.error">
                          Pincode
                        </Typography>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={customerDataById.data.addresses[0].pincode}
                        />
                      </Stack>
                    </>
                  )}
                </Stack>
              )}
            <Stack>
              <Stack direction="row" justifyContent="center" spacing={2} mt={3}>
                <Box borderRadius="5px">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleSubmit(onSubmitApproveCustomer)}
                  >
                    Approve
                  </Button>
                </Box>
                <Box borderRadius="5px">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setRejectReasonOpen(true)}
                  >
                    Reject
                  </Button>
                </Box>
                <CustomerActionsModal
                  open={rejectReasonOpen}
                  handleClose={handleModal}
                  customer_id={customer.id}
                  title={Actions.REJECT_CUSTOMER}
                  button="Reject Customer"
                  reasonOptions={Constants.REJECT_REASON}
                />
              </Stack>
            </Stack>
          </Box>
        </Modal>
      )}
    </>
  );
};
export default CustomerViewModal;
