import { CircularProgress, Stack } from "@mui/material";
import { BatchWiseInputReportTableMobile } from "./BatchWiseInputReportTableMobile";
import { CustomerWiseInputReportTableMobile } from "./CustomerWiseInputReportTableMobile";
import { FetchState } from "../../../../domain/models/fetch-state";
import { InputUsageSummaryStore } from "../../../../store/main/InputUsageReportStore";

type Props = {
  type: string;
};

export const InputUtilizationReportComponentMobile: React.FC<Props> = ({
  type,
}) => {
  const { fetchCustomerWiseDataState, fetchBatchWiseDataState } =
    InputUsageSummaryStore();

  return (
    <>
      <Stack justifyContent={"center"} alignItems={"center"}>
        {type == "batchWise" &&
        fetchBatchWiseDataState == FetchState.SUCCESS ? (
          <Stack width={"100%"}>
            <BatchWiseInputReportTableMobile />
          </Stack>
        ) : (
          <Stack>{type == "batchWise" && <CircularProgress />}</Stack>
        )}
        {type == "customerWise" &&
        fetchCustomerWiseDataState == FetchState.SUCCESS ? (
          <Stack width={"100%"}>
            <CustomerWiseInputReportTableMobile />
          </Stack>
        ) : (
          type == "customerWise" && <CircularProgress />
        )}
      </Stack>
    </>
  );
};
