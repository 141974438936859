import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import InputUtilizationPivot from "./InputUtilizationPivotMobile";
import { InputUsageSummaryStore } from "../../../../store/main/InputUsageReportStore";
import { InputUtilizationReportComponentMobile } from "./InputUtilizationReportComponentMobile";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 2, mt: 2 }} bgcolor={"#f9f9f9"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const InputUtilizationTabsMobile = () => {
  const {
    fetchInputUsageSummary,
    userRolesMasterData,
    inputBasedOnProductTypeData,
  } = InputUsageSummaryStore();
  const {
    fetchBatchWiseInputReport,
    fetchCustomerWiseInputReport,
    batchWiseDataFilters,
    customerWiseDataFilters,
  } = InputUsageSummaryStore();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchTableData = (name: string) => {
    if (name === "batchWiseSummary") {
      batchWiseDataFilters.role_id = userRolesMasterData[0].id.toString();
      batchWiseDataFilters.input_ids = [
        inputBasedOnProductTypeData[0]?.id.toString(),
        inputBasedOnProductTypeData[1]?.id.toString(),
        inputBasedOnProductTypeData[2]?.id.toString(),
      ];
      fetchBatchWiseInputReport();
    } else if (name === "customerWiseSummary") {
      customerWiseDataFilters.page = 1;
      customerWiseDataFilters.length = 50;
      customerWiseDataFilters.role_id = userRolesMasterData[0].id.toString();
      customerWiseDataFilters.input_ids = [
        inputBasedOnProductTypeData[0]?.id.toString(),
        inputBasedOnProductTypeData[1]?.id.toString(),
        inputBasedOnProductTypeData[2]?.id.toString(),
      ];
      fetchCustomerWiseInputReport();
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label={
            <Typography fontWeight={550} variant="caption">
              Input Summary
            </Typography>
          }
          value={0}
          onClick={() => fetchInputUsageSummary()}
        />
        <Tab
          onClick={() => fetchTableData("batchWiseSummary")}
          label={
            <Typography fontWeight={550} variant="caption">
              Batch wise summary
            </Typography>
          }
          value={1}
        />
        <Tab
          onClick={() => fetchTableData("customerWiseSummary")}
          label={
            <Typography fontWeight={550} variant="caption">
              Customer wise summary
            </Typography>
          }
          value={2}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <InputUtilizationPivot />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InputUtilizationReportComponentMobile type={"batchWise"} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <InputUtilizationReportComponentMobile type={"customerWise"} />
      </TabPanel>
    </Box>
  );
};
