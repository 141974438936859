enum Territory {
  SINGLE_ZONE= "singleZone",
  SINGLE_REGION= "singleRegion",
  ZONE = "zone",
  REGION = "region",
  AREA = "area",
  HEADQUATER = "hq",
  BRICKS = "bricks",
}

export default Territory;
