import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_TOKEN_KEY, BASE_URL_KEY, AUTH_HEADER } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteAddBrick } from "../../../data/usecases/brick-management/remote-add-brick";
import { AddBrick } from "../../../domain/useages/brick-management/add-brick";
import Swal from "sweetalert2";
import { BrickManagementStore } from "../../main/BrickManagementStore";
import { FetchState } from "../../../domain/models/fetch-state";

export interface AddBrickInterface {
  addBrick: Function;
  addBrickState: FetchState;
}

const initialState = {
  addBrickState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useAddBrickSlice: StateCreator<AddBrickInterface> = (
  set,
  get
) => ({
  ...initialState,
  addBrick: async (payload: AddBrick.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    set({ addBrickState: FetchState.LOADING });

    const remoteAddBrick: AddBrick = new RemoteAddBrick(
      `${BASE_URL}${Endpoints.ADD_BRICK}`,
      axiosHttpClient
    );
    let result = await remoteAddBrick.add(payload);
    if (result.success === true) {
      Swal.fire({
        icon: "success",
        timer: 3000,
        text: result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
      set({ addBrickState: FetchState.SUCCESS });
      BrickManagementStore.getState().fetchBrickManagement(
        BrickManagementStore.getState().brickManagementFilter
      );
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: result.errors.message ?? result.message,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });
      set({ addBrickState: FetchState.SUCCESS });
    }
  },
});
