import React from "react";
import CustomerDatabase from "./presentation/pages/CustomerDatabase";
import AuthPage from "./presentation/pages/AuthPage";
import CoverageReports from "./presentation/pages/CoverageReports";
import InputUtilizationReport from "./presentation/pages/InputUtilizationReport";
import StandardTourProgram from "./presentation/pages/StandardTourProgram";
import PrimarySales from "./presentation/pages/PrimarySales";
import BrickWorkingReport from "./presentation/pages/BrickWorkingReport";
import DailySalesReport from "./presentation/pages/DailySalesReport";
import UserManagementReport from "./presentation/pages/UserManagementReport";
import DataDashboardReport from "./presentation/pages/DataDashboardReport";
import UserSummaryReport from "./presentation/pages/UserSummaryReport";
import BusinessPlanning from "./presentation/pages/BusinessPlanning";
import CustomerMissing from "./presentation/pages/CustomerMissing";
import CustomerDetailingReport from "./presentation/pages/CustomerDetailingReport";
import BrickManagement from "./presentation/pages/BrickManagement";
import ProductCatlog from "./presentation/pages/ProductCatlog";
import UnmappedStockist from "./presentation/pages/UnmappedStockist";
import UserInputPlanning from "./presentation/pages/UserInputPlanning";
import ZoneMasterReport from "./presentation/pages/ZoneMasterReport";
import PrimarySalesInvoiceReport from "./presentation/pages/PrimarySalesInvoiceReport";
import HQDistanceReport from "./presentation/pages/HQDistanceReport";

export const pageRoutes = {
  CustomerDatabase: "/customer-database",
  CoverageReport: "/coverage-report",
  InputUtilizationReport: "/input-utilization-report",
  StandardTourProgram: "/standard-tour-program",
  BrickWorkingReport: "/brick-working-report",
  Authenticate: "/auth/:authToken",
  PrimarySales: "/primary-sales",
  DailySalesReport: "/daily-sales-report",
  UserManagementReport: "/user-management-report",
  ExecutiveReport: "/executive-report",
  DataDashboardReport: "/dashboard-report",
  UserSummaryReport: "/user-summary-report",
  BusinessPlanning: "/business-planning",
  CustomerMissing: "/customer-missing",
  CustomerDetailingReport: "/customer-detailing-report",
  BrickManagement: "/brick-management",
  ProductCatlog: "/product-catlog",
  UnmappedStockist: "/unmapped-stockist",
  UserInputPanning: "/user-input-planning",
  ZoneMasterReport: "/zone-master",
  PrimarySalesInvoice: "/primary-sales-invoice",
  HQDistanceReport: "/hq-distance-report",
};
export interface AppRoute {
  path: string;
  name: string;
  component: React.FC;
  children?: AppRoute[];
}

let indexRoutes: AppRoute[] = [
  {
    path: pageRoutes.InputUtilizationReport,
    name: "InputUtilizationReport",
    component: InputUtilizationReport,
  } as AppRoute,
  {
    path: pageRoutes.BrickWorkingReport,
    name: "BrickWorkingReport",
    component: BrickWorkingReport,
  } as AppRoute,
  {
    path: pageRoutes.PrimarySales,
    name: "PrimarySales",
    component: PrimarySales,
  } as AppRoute,
  {
    path: pageRoutes.StandardTourProgram,
    name: "StandardTourProgram",
    component: StandardTourProgram,
  } as AppRoute,
  {
    path: pageRoutes.CoverageReport,
    name: "CoverageReport",
    component: CoverageReports,
  } as AppRoute,
  {
    path: pageRoutes.CustomerDatabase,
    name: "CustomerDatabase",
    component: CustomerDatabase,
  } as AppRoute,
  {
    path: pageRoutes.Authenticate,
    name: "Authenticate",
    component: AuthPage,
  } as AppRoute,
  {
    path: pageRoutes.DailySalesReport,
    name: "DailySalesReport",
    component: DailySalesReport,
  } as AppRoute,
  {
    path: pageRoutes.UserManagementReport,
    name: "UserManagementReport",
    component: UserManagementReport,
  } as AppRoute,
  {
    path: pageRoutes.DataDashboardReport,
    name: "DataDashboardReport",
    component: DataDashboardReport,
  } as AppRoute,
  {
    path: pageRoutes.UserSummaryReport,
    name: "UserSummaryReport",
    component: UserSummaryReport,
  } as AppRoute,
  {
    path: pageRoutes.BusinessPlanning,
    name: "BusinessPlanning",
    component: BusinessPlanning,
  } as AppRoute,
  {
    path: pageRoutes.CustomerDetailingReport,
    name: "CustomerDetailingReport",
    component: CustomerDetailingReport,
  } as AppRoute,
  {
    path: pageRoutes.CustomerMissing,
    name: "CustomerMissing",
    component: CustomerMissing,
  } as AppRoute,
  {
    path: pageRoutes.BrickManagement,
    name: "BrickManagement",
    component: BrickManagement,
  } as AppRoute,
  {
    path: pageRoutes.ProductCatlog,
    name: "ProductCatlog",
    component: ProductCatlog,
  } as AppRoute,
  {
    path: pageRoutes.UnmappedStockist,
    name: "UnmappedStockist",
    component: UnmappedStockist,
  } as AppRoute,
  {
    path: pageRoutes.UserInputPanning,
    name: "UserInputPlanning",
    component: UserInputPlanning,
  } as AppRoute,
  {
    path: pageRoutes.ZoneMasterReport,
    name: "ZoneMaster",
    component: ZoneMasterReport,
  } as AppRoute,
  {
    path: pageRoutes.PrimarySalesInvoice,
    name: "PrimarySalesInvoice",
    component: PrimarySalesInvoiceReport,
  } as AppRoute,
  {
    path: pageRoutes.HQDistanceReport,
    name: "HQDistanceReport",
    component: HQDistanceReport,
  } as AppRoute,
];

export default indexRoutes;
