import { StateCreator } from "zustand";
import { MonthlyHeadquarterSummary } from "../../../domain/models/coverage-reports/monthly-headquarter-summary";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  BASE_URL_KEY,
} from "../../../base";
import { FetchMonthlyHeadquarterSummary } from "../../../domain/useages/coverage-reports/fetch-monthly-headquarter-summary";
import { RemoteFetchMonthlyHeadquarterSummary } from "../../../data/usecases/coverage-reports/remote-fetch-monthly-headquarter-summary";
import Endpoints from "../../../domain/endpoints";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { FetchState } from "../../../domain/models/fetch-state";

export interface FetchMonthlyHeaduqarterSummaryInterface {
  monthlyHeadquarterSummaryData: MonthlyHeadquarterSummary[];
  fetchMonthlyHeadquarterSummary: Function;
  monthlyHeadquarterSummaryDataLoading: FetchState;
  monthlyHeadquarterFilters: {
    monthYear: Dayjs | null;
  };
}

const initialStates = {
  monthlyHeadquarterSummaryData: [],
  monthlyHeadquarterSummaryDataLoading: FetchState.DEFAULT,
  monthlyHeadquarterFilters: { monthYear: dayjs(new Date()) },
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchMonthlyHeadquarterSummarySlice: StateCreator<
  FetchMonthlyHeaduqarterSummaryInterface
> = (set, get) => ({
  ...initialStates,
  fetchMonthlyHeadquarterSummary: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    let filterParams = {
      month_year: moment(
        (get().monthlyHeadquarterFilters.monthYear as Dayjs).toString()
      ).format("YYYYMM"),
      visitor_role: "HQ_MNG",
    };

    const remoteFetchMonthlyHeadquarterSummary: FetchMonthlyHeadquarterSummary =
      new RemoteFetchMonthlyHeadquarterSummary(
        `${BASE_URL}${Endpoints.FETCH_MONTHLY_HEADQUARTER_SUMMARY}`,
        axiosHttpClient
      );

    set(() => ({ monthlyHeadquarterSummaryDataLoading: FetchState.LOADING }));

    let result = await remoteFetchMonthlyHeadquarterSummary.fetch(filterParams);
    if (result) {
      set(() => ({
        monthlyHeadquarterSummaryData: result,
        monthlyHeadquarterSummaryDataLoading: FetchState.SUCCESS,
      }));
    }
  },
});
