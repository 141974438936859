import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { UserManagementStore } from "../../../../../store/main/UserManagementStore";

type ResetPassword = {
  newPassword: string;
  confirmNewPassword: string;
};

type Props = {
  resetPasswordModal: boolean;
  closeModals: Function;
  selectedUser: number;
};

const ResetPasswordModal: React.FC<Props> = ({
  resetPasswordModal,
  closeModals,
  selectedUser,
}) => {
  const { resetUserPassword, fetchUserManagementData } = UserManagementStore();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    bgcolor: "background.paper",
    borderRadius: "22px",
    boxShadow: 24,
    p: 4,
  };

  const { handleSubmit, control, setValue, watch } = useForm<ResetPassword>({
    mode: "onChange",
  });

  useEffect(() => {
    setValue("newPassword", "");
    setValue("confirmNewPassword", "");
  }, [resetPasswordModal]);

  const confirmResetPassword = (data: ResetPassword) => {
    if (data.newPassword === data.confirmNewPassword) {
      let payload = {
        new_password: data.newPassword,
        confirm_new_password: data.confirmNewPassword,
      };
      resetUserPassword(
        selectedUser,
        payload,
        closeModals("resetPasswordModal")
      );
      fetchUserManagementData();
    }
  };

  return (
    <>
      <Modal
        open={resetPasswordModal}
        onClose={() => {
          closeModals("resetPasswordModal");
        }}
      >
        <Box sx={style}>
          <Typography variant="h6" fontWeight={600} gutterBottom>
            Reset Password
          </Typography>
          <Stack spacing={2} marginTop={2}>
            <Controller
              name="newPassword"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="New Password"
                  value={value}
                  onChange={onChange}
                  size="small"
                  error={error && true}
                  helperText={error?.message}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />

            <Controller
              name="confirmNewPassword"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Confirm New Password"
                  value={value}
                  onChange={onChange}
                  size="small"
                  error={error && true}
                  helperText={error?.message}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />

            {watch("confirmNewPassword") != "" &&
              watch("newPassword") !== watch("confirmNewPassword") && (
                <Typography variant="caption" color="error.main">
                  New Password and Confirm Password should be the Same!
                </Typography>
              )}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            marginTop={3}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={
                watch("confirmNewPassword") == "" ||
                watch("newPassword") !== watch("confirmNewPassword")
              }
              onClick={handleSubmit(confirmResetPassword)}
            >
              Reset
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => closeModals("resetPasswordModal")}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ResetPasswordModal;

