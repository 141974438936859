import { Skeleton, Stack } from "@mui/material";
import React from "react";

type Props = {};

const ReportFiltersLoadingMobile = (props: Props) => {
  return (
    <Stack alignItems={"center"} spacing={0}>
      <Skeleton width={300} height={50}></Skeleton>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Skeleton width={150} height={50}></Skeleton>
        <Skeleton width={150} height={50}></Skeleton>
      </Stack>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Skeleton width={100} height={50}></Skeleton>
        <Skeleton width={100} height={50}></Skeleton>
        <Skeleton width={100} height={50}></Skeleton>
      </Stack>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Skeleton width={100} height={50}></Skeleton>
        <Skeleton width={100} height={50}></Skeleton>
      </Stack>
    </Stack>
  );
};

export default ReportFiltersLoadingMobile;
