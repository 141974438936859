import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IconButton, Stack, TableCell, Tooltip, styled } from "@mui/material";
import moment from "moment";
import { Dayjs } from "dayjs";
import { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { AreaFilterModal } from "./Filtermodal/AreaFilterModal";
import { HeadquarterFilterModal } from "./Filtermodal/HeadquarterFilterModal";
import { BrickFilterModal } from "./Filtermodal/BrickFilterModal";
import { BrickWorkingReportStore } from "../../../../store/main/BrickWorkingReportStore";
import { SortedDataType } from "../../../../domain/models/bricks-working-report/sorted-data-type";

interface Column {
  label: string;
  border: "0.5px";
  format?: (value: number) => string;
  style?: any;
  icon?: any;
}
const columns: Column[] = [
  {
    label: "Area",
    border: "0.5px",
    style: {
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
      minWidth: 75,
    },
    icon: <FilterAltIcon fontSize="small" color="secondary" />,
  },
  {
    label: "Headquarter",
    border: "0.5px",
    style: {
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
      minWidth: 75,
    },
    icon: <FilterAltIcon fontSize="small" color="secondary" />,
  },
  {
    label: "Brick",
    border: "0.5px",
    style: {
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
      minWidth: 75,
      position: "sticky",
      left: 0,
      zIndex: 999,
    },
    icon: <FilterAltIcon fontSize="small" color="secondary" />,
  },
  {
    label: "Brick Potential",
    border: "0.5px",
    format: (value: number) => value.toLocaleString("en-US"),
    style: {
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
      minWidth: 70,
    },
  },
  {
    label: "Avg POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
      minWidth: 50,
      maxWidth: 50,
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",

    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },

  {
    label: "POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "POB",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      maxWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Visit Days",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "POB",
    border: "0.5px",

    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
  {
    label: "Total Visits",
    border: "0.5px",
    format: (value: number) => value.toFixed(2),
    style: {
      minWidth: 50,
      border: 0.25,
      borderColor: "black",
      backgroundColor: "#f5f5f5",
    },
  },
];

const BrickWorkingReportTable = () => {
  const { brickReportFilters, brickReportData, brickReportLoading } =
    BrickWorkingReportStore();
  const [areaFilter, setAreaFilter] = useState<boolean>(false);
  const [hqFilter, setHqFilter] = useState<boolean>(false);
  const [brickFilter, setBrickFilter] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  const handleSelectedOptions = (arr: any[], value: string) => {
    let temp: SortedDataType = [];
    if (value == "Area") {
      sortDataByArea().filter((data) => {
        for (let i = 0; i < arr.length; i++) {
          if (data.area === arr[i]) {
            temp.push(data);
          }
        }
      });
      setAreaFilter(false);
    }
    if (value == "Headquarter") {
      sortDataByArea().forEach((data) => {
        for (let i = 0; i < arr?.length; i++) {
          if (data.headquarter == arr[i]) {
            temp.push(data);
          }
        }
      });
      setHqFilter(false);
    }
    if (value == "Brick") {
      sortDataByArea().forEach((data) => {
        for (let i = 0; i < arr?.length; i++) {
          if (data.brick == arr[i]) {
            temp.push(data);
          }
        }
      });
      setBrickFilter(false);
    }
    setSelectedOptions(temp);
  };

  useEffect(() => {
    setSelectedOptions(sortDataByArea());
  }, []);

  const handleFilter = (name: string, value: boolean) => {
    if (name == "Area") {
      setAreaFilter(value);
    } else if (name == "Headquarter") {
      setHqFilter(value);
    } else if (name == "Brick") {
      setBrickFilter(value);
    }
  };

  const filterDataOnSelect = () => {
    let temp: SortedDataType = sortDataByArea();
    if (selectedOptions.length == 0) {
      temp = [];
    }
    if (selectedOptions.length > 0) {
      temp = selectedOptions;
    }
    return temp;
  };

  const uniqueList = (value: string) => {
    const list: any = new Set();
    const hqByArea: any = {};
    const brickByHq: any = {};
    if (value == "area") {
      sortDataByArea().forEach((item) => {
        list.add(item.area);
      });
    }
    if (value == "hq") {
      sortDataByArea().forEach((item) => {
        const { area, headquarter } = item;
        if (hqByArea[area]) {
          hqByArea[area].push(headquarter);
        } else {
          hqByArea[area] = [headquarter];
        }
      });
      {
        selectedOptions.length == 0
          ? sortDataByArea().forEach((item) => {
              list.add(item.headquarter);
            })
          : selectedOptions.forEach((option) => {
              if (hqByArea.hasOwnProperty(option.area)) {
                hqByArea[option.area].forEach((hq: any) => {
                  list.add(hq);
                });
              }
            });
      }
    }
    if (value == "brick") {
      sortDataByArea().forEach((item) => {
        const { headquarter, brick } = item;
        if (brickByHq[headquarter]) {
          brickByHq[headquarter].push(brick);
        } else {
          brickByHq[headquarter] = [brick];
        }
      });
      {
        selectedOptions.length == 0
          ? sortDataByArea().forEach((item) => {
              list.add(item.brick);
            })
          : selectedOptions.forEach((option) => {
              if (brickByHq.hasOwnProperty(option.headquarter)) {
                brickByHq[option.headquarter].forEach((brick: any) => {
                  list.add(brick);
                });
              }
            });
      }
    }
    return Array.from(list);
  };

  const filteredData = brickReportData.filter(
    (item) =>
      item.brick_id !== null &&
      item.month_year !== null &&
      item.brick !== null &&
      item.brick_code !== null
  );

  const newArray: {
    brick_id: number;
    brick: string | null;
    brick_code: string | null;
    country: string;
    zone: string;
    region: string;
    area: string;
    headquarter_id: number;
    headquarter: string;
    ga_code: string;
    expected_business: number;
    data: {
      visitor_id: number;
      visitor_role: string;
      visitor: string;
      month_year: number;
      visit_days: string;
      total_visits: number;
      order_total_count: number;
      order_count: number;
    }[];
  }[] = Object.values(
    filteredData.reduce(
      (acc, item) => {
        const brickId = item.brick_id!;
        const monthYear = item.month_year!;

        if (!acc[brickId]) {
          acc[brickId] = {
            brick_id: brickId,
            country: item.country,
            brick_code: item.brick_code,
            brick: item.brick,
            zone: item.zone,
            region: item.region,
            area: item.area,
            headquarter_id: item.headquarter_id,
            headquarter: item.headquarter,
            ga_code: item.ga_code,
            expected_business: item.expected_business ?? 0,
            data: [],
          };
        }

        const existingIndex = acc[brickId].data.findIndex(
          (entry) => entry.month_year === monthYear
        );

        if (existingIndex !== -1) {
          acc[brickId].data[existingIndex].visit_days += "|" + item.visit_days;
          acc[brickId].data[existingIndex].total_visits += parseInt(
            item.total_visits
          );
          acc[brickId].data[existingIndex].order_total_count += parseInt(
            item.order_total_count as string
          );
          acc[brickId].data[existingIndex].order_count += item.order_count;
        } else {
          acc[brickId].data.push({
            visitor_id: item.visitor_id,
            visitor_role: item.visitor_role,
            visitor: item.visitor,
            month_year: monthYear,
            visit_days: item.visit_days,
            total_visits: parseInt(item.total_visits),
            order_total_count: parseInt(item.order_total_count as string),
            order_count: item.order_count,
          });
        }
        return acc;
      },
      {} as {
        [key: number]: {
          brick_id: number;
          brick: string | null;
          brick_code: string | null;
          country: string;
          zone: string;
          region: string;
          area: string;
          headquarter_id: number;
          headquarter: string;
          ga_code: string;
          expected_business: number;
          data: {
            visitor_id: number;
            visitor_role: string;
            visitor: string;
            month_year: number;
            visit_days: string;
            total_visits: number;

            order_total_count: number;
            order_count: number;
          }[];
        };
      }
    )
  );

  const BorderedTD = styled(TableCell)({
    color: "darkslategray",
    padding: 9,
    component: "th",
    scope: "row",
  });

  const cellStyle = {
    border: 0.25,
    borderColor: "black",
  };

  const createArrayOfObjects = (data: any[]) => {
    let year = moment((brickReportFilters.year as Dayjs).toString()).format(
      "YYYY"
    );
    const months = [];

    for (let month = 1; month <= 12; month++) {
      const formattedMonth = `${year}${month.toString().padStart(2, "0")}`;
      months.push(formattedMonth);
    }

    const arrayOfObjects = [];

    for (let i = 0; i < 12; i++) {
      const obj = {
        visitor_id: "-",
        visitor_role: "-",
        visitor: "-",
        month_year: months[i],
        visit_days: "-",
        total_visits: "-",
        expected_business: "-",
        order_total_count: "-",
        order_count: "-",
      };

      for (let j = 0; j < data.length; j++) {
        if (months[i] === data[j].month_year.toString()) {
          obj.visitor_id = data[j].visitor_id.toString();
          obj.visitor_role = data[j].visitor_role.toString();
          obj.visitor = data[j].visitor.toString();
          obj.month_year = data[j].month_year.toString();
          obj.visit_days = data[j].visit_days.toString();
          obj.total_visits = data[j].total_visits.toString();
          obj.order_total_count = data[j].order_total_count.toString();
          obj.order_count = data[j].order_count.toString();
          break;
        }
      }
      arrayOfObjects.push(obj);
    }
    return arrayOfObjects;
  };

  const sortDataByArea = () => {
    const sortedData = [...newArray].sort((a, b) =>
      a.area.localeCompare(b.area)
    );
    return sortedData;
  };

  const calculateTotalVisits = (days: string) => {
    if (days != "-") {
      const numbersArray = days.split("|").map(Number);
      return numbersArray.length;
    }
    return 0;
  };

  const calculateAveragePOB = (data: any[]) => {
    if (createArrayOfObjects(data).length === 0) return 0;

    const validOrderCounts = createArrayOfObjects(data)
      .filter(
        (entry) =>
          entry.order_total_count !== "-" && entry.order_total_count !== "0"
      )
      .map((entry) => parseInt(entry.order_total_count));

    const averageOrderTotalCount =
      validOrderCounts.length > 0
        ? validOrderCounts.reduce((acc, count) => acc + count, 0) /
          validOrderCounts.length
        : 0;
    return averageOrderTotalCount;
  };

  const compareAvgPOBWithMonthPOB = (monthPOB: number, avgPOB: number) => {
    if (monthPOB < avgPOB) {
      return true;
    } else {
      return false;
    }
  };

  const showVisitDaysInAscendingOrder = (value: string) => {
    if (value != "-") {
      const numberArray = value.split("|");
      const numericArray = numberArray.map(Number);
      numericArray.sort((a, b) => a - b);
      const sortedString = numericArray.join(",");
      return sortedString;
    }
    return value;
  };
  return (
    <Stack spacing={2}>
      <TableContainer
        component={Paper}
        sx={{
          overflow: "scroll",
          width: "100%",
          height: "70vh",
          padding: 0,
        }}
      >
        <Table stickyHeader>
          <TableHead sx={{ backgroundColor: "#e0e0e0" }}>
            <TableRow>
              <BorderedTD
                sx={{
                  ...cellStyle,
                  backgroundColor: "#e0e0e0",
                }}
                align="center"
                colSpan={5}
              >
                Brick Working Report -{" "}
                {moment((brickReportFilters.year as Dayjs).toString()).format(
                  "YYYY"
                )}
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>January</span>
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>February</span>
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>March</span>
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>April</span>
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>May</span>
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>June</span>
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>July</span>
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>August</span>
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>September</span>
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>October</span>
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>November</span>
              </BorderedTD>
              <BorderedTD
                sx={{ ...cellStyle, backgroundColor: "#e0e0e0" }}
                align="center"
                colSpan={3}
              >
                <span style={{ fontWeight: "bold" }}>December</span>
              </BorderedTD>
            </TableRow>
            <TableRow>
              {columns.map((column, index) => (
                <BorderedTD
                  key={index}
                  align={"center"}
                  sx={column.style}
                  style={{ fontWeight: "bold", top: 43 }}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {column.label}
                    <IconButton
                      onClick={() => handleFilter(column.label, true)}
                    >
                      <Tooltip title={`${column.label} Filter`}>
                        {column.icon && column.icon}
                      </Tooltip>
                    </IconButton>
                  </Stack>
                </BorderedTD>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {filterDataOnSelect().length !== 0 &&
              brickReportData.length !== 0 &&
              filterDataOnSelect().map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <BorderedTD
                      align={"center"}
                      sx={{
                        ...cellStyle,
                        backgroundColor: "white",
                      }}
                    >
                      {row.area}
                    </BorderedTD>
                    <BorderedTD
                      align={"center"}
                      sx={{
                        ...cellStyle,
                        backgroundColor: "white",
                      }}
                    >
                      {row.headquarter}
                    </BorderedTD>
                    <BorderedTD
                      align={"center"}
                      sx={{
                        ...cellStyle,
                        position: "sticky",
                        left: 0,
                        backgroundColor: "white",
                      }}
                    >
                      {row.brick}
                    </BorderedTD>
                    <BorderedTD
                      align={"center"}
                      sx={{
                        ...cellStyle,
                        backgroundColor: "white",
                      }}
                    >
                      {row.expected_business}
                    </BorderedTD>
                    <BorderedTD
                      align={"center"}
                      sx={{
                        ...cellStyle,
                        backgroundColor: "white",
                      }}
                    >
                      {calculateAveragePOB(row.data).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </BorderedTD>
                    {createArrayOfObjects(row.data).map((monthData) => {
                      return (
                        <>
                          <BorderedTD align={"center"} sx={{ ...cellStyle }}>
                            {showVisitDaysInAscendingOrder(
                              monthData.visit_days
                            )}
                          </BorderedTD>
                          <BorderedTD
                            align={"center"}
                            sx={{
                              ...cellStyle,
                              color: compareAvgPOBWithMonthPOB(
                                parseInt(monthData.order_total_count),
                                calculateAveragePOB(row.data)
                              )
                                ? "red"
                                : "black",
                            }}
                          >
                            {monthData.order_total_count}
                          </BorderedTD>
                          <BorderedTD align={"center"} sx={{ ...cellStyle }}>
                            {calculateTotalVisits(monthData.visit_days)}
                          </BorderedTD>
                        </>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <AreaFilterModal
        openFilter={areaFilter}
        handleFilter={handleFilter}
        uniqueList={uniqueList}
        handleSelectedOptions={handleSelectedOptions}
        filteredArray={filterDataOnSelect()}
      />
      <HeadquarterFilterModal
        openFilter={hqFilter}
        handleFilter={handleFilter}
        uniqueList={uniqueList}
        handleSelectedOptions={handleSelectedOptions}
        filteredArray={filterDataOnSelect()}
      />
      <BrickFilterModal
        openFilter={brickFilter}
        handleFilter={handleFilter}
        uniqueList={uniqueList}
        handleSelectedOptions={handleSelectedOptions}
        filteredArray={filterDataOnSelect()}
      />
    </Stack>
  );
};

export default BrickWorkingReportTable;
