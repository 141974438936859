import moment from "moment";
import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { InputUsageSummaryStore } from "../../../../store/main/InputUsageReportStore";
import { BatchWiseInputReportDataType } from "../../../../domain/models/input-utilization/batchwise-input-report-type";
import TableComponent from "../../TableComponent";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import ReportFiltersComponent from "../ReportFiltersComponent";
import ReportScreen from "../../../../common/enums/ReportScreens";
import Roles from "../../../../common/enums/Roles";
import { ReportFilters } from "../../../../domain/models/report-filters";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

interface Column {
  id:
    | "batch_no"
    | "input_name"
    | "emp_code"
    | "status"
    | "territory_code"
    | "sample_f_code"
    | "inventory_brought_forward"
    | "dispatched_date"
    | "total_inventory"
    | "retun_damage_broken"
    | "closing_inventory"
    | "remarks"
    | "dispatched"
    | "month_of_use"
    | "expiry_date"
    | "usage_months"
    | "distributed"
    | "ack_date"
    | "ack_months"
    | "acknowledged"
    | "country"
    | "zone"
    | "region"
    | "area"
    | "headquarter"
    | "full_name"
    | "ack_pending"
    | "grand_total_percentage";
  label: string;
  minWidth?: number;
  align?: "center" | "right";
  format?: (value: number) => string;
}

export const BatchWiseInputReportTable = () => {
  const {
    batchWiseInputReportData,
    batchWiseDataFilters,
    fetchBatchWiseInputReport,
  } = InputUsageSummaryStore();
  const { userDetails, downloadExcelTable, userRole } = CustomerDatabaseStore();
  const [roleName, setRoleName] = useState<string>("Area Code");
  useEffect(() => {
    if (batchWiseDataFilters?.role_id == "3") {
      setRoleName("HQ Code");
    } else if (batchWiseDataFilters?.role_id == "4") {
      setRoleName("Area Code");
    }
  }, [batchWiseDataFilters]);
  const column: Column[] = [
    { id: "zone", label: "Zone", align: "center" },
    { id: "region", label: "Region", align: "center" },
    { id: "area", label: "Area", align: "center" },
    { id: "headquarter", label: "Headquarter", align: "center" },
    { id: "territory_code", label: "Territory Code", align: "center" },
    { id: "emp_code", label: "Emp Code", align: "center" },
    { id: "status", label: "Status", align: "center" },
    { id: "full_name", label: "Emp Name", align: "center" },
    { id: "sample_f_code", label: "Sample F Code", align: "center" },
    { id: "input_name", label: "Product Name", align: "center", minWidth: 100 },
    { id: "batch_no", label: "Batch No", align: "center" },
    {
      id: "total_inventory",
      label: "Total Inventory",
      align: "center",
    },
    { id: "expiry_date", label: "Expiry date", align: "center", minWidth: 90 },
    {
      id: "dispatched_date",
      label: "Dispatched Date",
      align: "center",
      minWidth: 90,
    },
    { id: "ack_date", label: "Ack date", align: "center", minWidth: 90 },
    {
      id: "inventory_brought_forward",
      label: "Inventory Brought Forward",
      align: "center",
    },
    { id: "dispatched", label: "Dispatched", align: "center" },
    {
      id: "acknowledged",
      label: "Acknowledged",
      format: (value: number) => value.toLocaleString("en-US"),
      align: "center",
    },
    { id: "ack_pending", label: "Ack Pending" },
    {
      id: "distributed",
      label: "Distributed",
      format: (value: number) => value.toLocaleString("en-US"),
      align: "center",
    },
    {
      id: "closing_inventory",
      label: "Closing Inventory",
      align: "center",
    },
    {
      id: "retun_damage_broken",
      label: "Retun Damage Broken",
      align: "center",
    },
    { id: "ack_months", label: "Ack months", align: "center" },
    {
      id: "grand_total_percentage",
      label: "% of grand total of distributed performance",
    },
    { id: "remarks", label: "Remarks", align: "center" },
  ];
  let temp: any = [];
  const createData = (row: BatchWiseInputReportDataType[]) => {
    for (let i = 0; i < row.length; i++) {
      if (row[i].full_name && row[i].dispatched) {
        temp.push({
          zone: row[i].zone ? row[i].zone : "-",
          batch_no: row[i].batch_no ? row[i].batch_no : "-",
          input_name: row[i].input_name ? row[i].input_name : "-",
          dispatched: row[i].dispatched ? row[i].dispatched : 0,
          month_of_use: row[i].month_of_use ? row[i].month_of_use : "-",
          headquarter: row[i].headquarter ? row[i].headquarter : "-",
          ack_months: row[i].ack_months ? row[i].ack_months : "-",
          usage_months: row[i].usage_months,
          area: row[i].area ? row[i].area : "-",
          region: row[i].region ? row[i].region : "-",
          full_name: row[i].full_name,
          acknowledged: row[i].quantity_received ? row[i].quantity_received : 0,
          distributed: row[i].distributed ? row[i].distributed : 0,
          expiry_date: row[i].expiry_date
            ? moment(
                row[i].expiry_date as string,
                "YYYY/M/D" || "D/M/YYYY" || "YYYY-MM-DD" || "YYYY-MM-DD"
              ).format("ll")
            : "-",
          ack_date: row[i].ack_date
            ? moment(
                row[i].ack_date as string,
                "YYYY/M/D" || "D/M/YYYY" || "YYYY-MM-DD" || "YYYY-MM-DD"
              ).format("ll")
            : "-",
          country: row[i].country ? row[i].country : "-",
          ack_pending:
            row[i].dispatched && row[i].quantity_received
              ? parseInt(row[i].dispatched) - parseInt(row[i].quantity_received)
              : "-",
          grand_total_percentage:
            row[i].distributed && row[i].quantity_received
              ? (
                  (parseInt(row[i].distributed) * 100) /
                  parseInt(row[i].quantity_received)
                ).toFixed(2)
              : "-",
          territory_code:
            batchWiseDataFilters?.role_id == "4" ? row[i].area_code : "-",
          emp_code: row[i].emp_code ? row[i].emp_code : "-",
          status: row[i].status ? row[i].status : "-",
          sample_f_code: row[i].input_code ? row[i].input_code : "-",
          inventory_brought_forward:
            Math.abs(
              parseInt(row[i].quantity_received) - parseInt(row[i].distributed)
            ) || 0,
          total_inventory:
            Math.abs(
              parseInt(row[i].dispatched) - parseInt(row[i].distributed)
            ) || 0,

          closing_inventory:
            Math.abs(
              parseInt(row[i].quantity_received) - parseInt(row[i].distributed)
            ) || 0,

          dispatched_date: !(row[i].dispatch_date === "0000-00-00")
            ? moment(
                row[i].dispatch_date as string,
                "YYYY/M/D" || "D/M/YYYY" || "YYYY-MM-DD" || "YYYY-MM-DD"
              ).format("ll")
            : "-",
          retun_damage_broken: row[i].returned ? row[i].returned : "-",
          remarks: "-",
        });
      }
    }

    return temp;
  };
  // downloadFile(createData(batchWiseInputReportData));
  const successCallback = (values: ReportFilters) => {
    batchWiseDataFilters.input_ids = values.inputProducts;
    batchWiseDataFilters.role_id = values.role;
    fetchBatchWiseInputReport();
  };

  const downloadFile = () => {
    if (temp.length != 0) downloadExcelTable(temp);
  };
  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"} mb={4}>
        <ReportFiltersComponent
          clearSuccessCallBack={() => {}}
          type={ReportScreen.INPUT_UTILIZATION_BATCH_WISE_SUMMARY}
          successCallback={successCallback}
          gridColumns={20.5}
          userRole={Roles.COUTNRY_MANAGER}
          userDetails={userDetails}
        />
        {userDetails &&
          userDetails.role &&
          userDetails.role.visibility == 1 && (
            <Button
              variant="contained"
              startIcon={<FileDownloadOutlinedIcon />}
              sx={{ borderRadius: "10px", width: "180px", height: "40px" }}
              onClick={downloadFile}
              color="primary"
            >
              <Typography variant="subtitle2" textTransform={"none"}>
                Download Excel
              </Typography>
            </Button>
          )}
      </Stack>
      {(batchWiseInputReportData && batchWiseInputReportData.length === 0) ||
      createData(batchWiseInputReportData).length == 0 ? (
        <Typography variant="h6" fontWeight={600}>
          Input utilization not found for the given filter
        </Typography>
      ) : (
        <TableComponent
          columns={column}
          rows={createData(batchWiseInputReportData)}
          height="70vh"
        />
      )}
    </>
  );
};
