import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteCheckEligibility } from "../../../data/usecases/remote-check-eligibility";
import { CheckEligibility } from "../../../domain/useages/check-eligiblity";
import { EligibilityResponse } from "../../../domain/models/eligibility-response";

export interface CheckEligibilityInterface {
  checkEligibility: Function;
  isEligibile: EligibilityResponse;
}

const initialStaes = {
  isEligibile: {} as EligibilityResponse,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useCheckEligibilitySlice: StateCreator<
  CheckEligibilityInterface
> = (set, get) => ({
  ...initialStaes,
  checkEligibility: async (id: string) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteCheckEligibility: CheckEligibility = new RemoteCheckEligibility(
      `${BASE_URL}${Endpoints.ELIGIBLITY}`,
      axiosHttpClient
    );

    let result = await remoteCheckEligibility.fetch(id);
    set(() => ({ isEligibile: result }));
  },
});
