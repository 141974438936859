const Endpoints = {
  //Filters
  FETCH_SPECIALITIES: "/api/v3/specialities",
  FETCH_ZONE_MASTER: "/api/v3/territory/zones",
  FETCH_AREA_MASTER: "/api/v3/territory/areas",
  FETCH_REGION_MASTER: "/api/v3/territory/regions",
  FETCH_HEADQUARTER_MASTER: "/api/v3/territory/headquarters",
  FETCH_BRICK_MASTER: "/api/v3/territory/bricks",
  FETCH_CUSTOMERS: "/api/v3/customers",
  FETCH_CUSTOMER_COUNT: "/api/v3/customers/counts",
  FETCH_CUSTOMER_TYPES: "/api/v3/customer-types",
  FETCH_PRODUCTS: "/api/v1/products",
  FETCH_DEPARTMENTS: "/api/v1/departments",
  FETCH_SERVICE_PROVIDER: "/api/v2/service_providers",

  //Customer Database
  REMOVE_MAPPINGS: "/api/v3/customers/remove-mapping",
  DEACTIVATE_CUSTOMERS: "/api/v3/customers/deactivate",
  EDIT_CUSTOMERS: "/api/v3/customers/:id",
  MAP_BRICK_TO_CUSTOMER: "/api/v1/customers/:customer_id/:brick_id",
  HQ_SUMMARY: "/api/v3/customers/hq-summary",
  FETCH_USER_DETAILS: "/api/v1/users/:id",
  CUSTOMER_FETCH_DATA: "/api/v1/customers",
  FETCH_IMAGES_URL: "/api/v1/customers/id/:id",
  MAP_CUSTOMER: "/api/v2/customers/:id/map-to-customer-service",
  ELIGIBLITY: "/api/v3/customers/eligibility/:uu_id",
  FIND_BY_UUID: "/api/v3/customers/find",
  UPDATE_FULFILMENT_AGENT: "/api/v3/customers/update",
  CREATE_FULFILMENT_AGENT: "/api/v3/customers/create-fulfillmentagent",
  GENERATE_OTP: "/api/v3/verifications",
  VERIFY_OTP: "/api/v3/verifications/verify-otp",
  DOCTOR_OTP: "/api/v1/masters/doctors",
  UPDATE_SPECIALITY: "/api/v3/customers/update-speciality",
  REACTIVATE_USER: "/api/v3/customers/activate",
  UPDATE_CUSTOMER_MAPPINGS: "/api/v3/customers/update-mappings",
  APPROVE_CUSTOMER: "/api/v3/customers/accept",
  REJECT_CUSTOMER: "/api/v3/customers/reject",
  METADATA_MASTER: "/api/v3/customer-metadata",
  ADD_METADATA: "/api/v3/customers/metadata/add",
  REMOVE_METADATA: "/api/v3/customers/metadata/remove",

  //Coverage Report
  VISIT_TABLE: "/reports/api/visits/monthly-customer-summary",
  FETCH_MONTHLY_HEADQUARTER_SUMMARY:
    "/reports/api/visits/monthly-headquarter-summary",
  FETCH_MONTHLY_SPECIALITY_SUMMARY:
    "/reports/api/visits/monthly-speciality-summary",

  //Input Utilization
  FETCH_INPUT_USAGE_SUMMARY: "/api/v1/inputs/usage-summary",
  FETCH_BATCH_WISE_INPUT_REPORT: "/api/v1/inputs/batch-wise-usage-summary",
  FETCH_CUSTOMER_WISE_INPUT_REPORT:
    "/api/v1/inputs/customer-wise-usage-summary",

  //Standard Tour Program
  FETCH_STP_DATA: "/reports/api/customers/monthly-brick-summary",

  //Brick working report
  FETCH_BRICK_WORKING_DATA: "/reports/api/monthly-brick-summary/:year",

  //Primary Sales
  FETCH_HQ_AND_PRODUCT_WISE: "/reports/api/sales/monthly-hq-product-summary",
  FETCH_INPUTS_BASED_ON_PRODUCT_TYPE: "/api/v3/inputs",
  FETCH_ROLES: "/api/v3/users/roles",

  //Daily Sales report
  FETCH_BRANDS: "/api/v1/brands",
  FETCH_DAILY_SALES: "/api/v2/sales/fetchDayWiseYearsPerformance",

  //User Management
  FETCH_USER_MANAGEMENT_DATA: "/api/v2/users",
  UPDATE_USER_MANAGEMENT_DATA: "/api/v2/users/:id",
  RESET_USER_MANAGEMEMT_PASSWORD: "/api/v2/users/:id/change_password",
  DELETE_USER_MANAGEMENT_REPORT: "/api/v2/users/:id/report_delete",
  DEACTIVATE_USER: "/api/v2/users/:id/deactivate",
  CREATE_NEW_USER: "/api/v2/users",
  ROLE_CHILDREN: "/api/v1/users/children",
  UNLOCK_USER: "/api/v3/users/:user_id/unlock",

  //DATA DASHBOADRD REPORT
  DASHBOARD_LINK: "/role-based-dashboards",

  //USER SUMMARY REPORT
  SUMMARY_REPORT:
    "/api/v1/reports/summary/:month/:year/:user_id/:department_id",
  DEVIATION_REPORT:
    "/api/v1/reports/deviation_report/:month/:year/:user_id/:department_id",
  MONTH_SUMMARY_REPORT:
    "/api/v1/reports/month_summary/:month/:year/:user_id/:department_id",
  FETCH_USER_CHILDREN: "/api/v1/users/children",
  MONTHS_REPORT: "/api/v1/reports/months_report/:year/:user_id/:department_id",

  //BUSINESS PLANNING
  BUSINESS_TEMPLATES: "/fetch_business_templates",

  //CUSTOMER DETAILING REPORT
  CUSTOMER_DETAILING_REPORT: "/api/v2/visits/speciality_priority",

  //CUSTOMER MISSING REPORT
  CUSTOMER_MISSING_REPORT:
    "/reports/api/visits/monthly-missing-customer-summary",

  //INPUT PLANNING
  USER_INPUT_PLANNING: "/api/v3/planning/inputs",

  //TICKET FRESHDESK
  RAISE_COMPLAINT: "/api/v2/tickets",

  //BRICK MANAGEMENT
  BRICK_MANAGEMENT: "/api/v3/territory/hierachy",
  FETCH_DISTRICT_MASTER: "/api/v3/territory/clusters/districts",
  FETCH_CLUSTER_MASTER: "/api/v3/territory/clusters",
  ADD_BRICK: "/api/v3/territory/bricks",
  UPDATE_BRICK: "/api/v3/territory/bricks/:id",
  DEACTIVATE_BRICK: "/api/v3/territory/bricks/:id/deactivate",

  //PRODUCT CATLOG
  FETCH_PRODUCT_CATLOG: "/api/v1/products",

  //UNMAPPED STOCKIST
  FETCH_UNMAPPED_STOCKIST: "/api/v3/sales/unmapped-stockist",
  SEARCH_HEADQUARTER: "/api/v3/territory/headquarter",
  FETCH_BRICK: "/api/v3/territory/brick/:id",
  MAP_STOCKIST: "/api/v3/customers/add-stockist",

  //ZONE MASTER
  ZONE_MASTER: "/api/v3/territory/zone_master",

  //PRIMARY SALES INVOICE
  PRIMARY_INVOICE: "/api/v1/sap/invoices/:month/:year",

  //PRIMARY SALES PRODUCT WISE
  PRIMARY_PRODUCT: "/api/v1/sap/invoices/product_wise/:month/:year",

  //PRIMARY SALES CUSTOMER WISE
  PRIMARY_CUSTOMER: "/api/v1/sap/invoices/customer_wise/:month/:year",

  // HQ DISTANCE REPORT
  HQ_DISTANCE: "/api/v3/territory/user-to-headquarter-distance",
  ADD_HQ_DISTANCE: "/api/v3/territory/add-headquarter-distance",
};
export default Endpoints;
