import * as React from "react";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  AreaMasterType,
  RegionMasterType,
  TitleType,
} from "../../../../domain/models/zone-master/zone-master-data";
import { Chip, Stack, Typography } from "@mui/material";
import AreaDataTable from "./sub-components/AreaDataTable";

type RowProps = {
  data: RegionMasterType;
  setTitle: Function;
  title: string;
  setSummaryData: Function;
  selectedTerritoryName: TitleType;
};
export const RegionMasterTable: React.FC<RowProps> = ({
  data,
  setTitle,
  setSummaryData,
  selectedTerritoryName,
}) => {
  const [openZone, setOpenZone] = React.useState(false);

  const titleChange = (name: string) => {
    setTitle(name);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Stack direction="row" alignItems={"Center"} spacing={1}>
            <IconButton
              onClick={() => {
                {
                  openZone
                    ? setTitle(`${data.name}`)
                    : setTitle(`${data.name}`);
                }
                setSummaryData(data);
                selectedTerritoryName.zone = data.name;
                setOpenZone(!openZone);
              }}
            >
              {openZone ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Chip
              label="R"
              sx={{
                color: "#687182",
                backgroundColor: "#E9EDF5",
                fontWeight: 600,
                width: "30px",
              }}
              size="small"
            />
            <Typography fontWeight={500} color={"#687182"}>
              {data.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {data.areaCount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {data.totalVacantArea}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {data.hqCount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {data.totalVacantHQ}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight={550} color={"#687182"}>
            {data.brickCount}
          </Typography>
        </TableCell>
      </TableRow>
      {openZone &&
        data.areas.map((row: AreaMasterType, key: number) => (
          <AreaDataTable
            row={row}
            setTitle={setTitle}
            selectedTerritoryName={selectedTerritoryName}
            titleChange={titleChange}
          />
        ))}
    </>
  );
};
