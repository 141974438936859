const CustomerTypeID = [
  {
    id: 1,
    name: "Stockist",
  },
  {
    id: 2,
    name: "Semi",
  },
  {
    id: 3,
    name: "Retailer",
  },
  {
    id: 4,
    name: "Hub Chemist",
  },
  {
    id: 5,
    name: "Healthcare Provider",
  },
  {
    id: 6,
    name: "Stockist Salesman",
  },
  {
    id: 7,
    name: "OTC Semi",
  },
  {
    id: 8,
    name: "Hospital",
  },
  {
    id: 9,
    name: "Hospital/Nursing Home Pharmac",
  },
];
export default CustomerTypeID;
