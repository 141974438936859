import { StateCreator } from "zustand";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { UserManagementData } from "../../../domain/models/user-management/user-management-data";
import { FetchUserManagementData } from "../../../domain/useages/user-management/fetch-user-management-data";
import { RemoteFetchUserManagementData } from "../../../data/usecases/user-management/remote-fetch-user-management-data";
import Endpoints from "../../../domain/endpoints";
import { FetchState } from "../../../domain/models/fetch-state";
import { UserManagementFilters } from "../../../domain/models/user-management/user-management-filters";

export interface FetchUserManagementDataInterface {
  fetchUserManagementData: Function;
  userManagementData: UserManagementData[];
  userManagementDataStatus: FetchState;
  filterStore: UserManagementFilters;
  DataLength: number;
}

const initialStates = {
  userManagementData: [],
  userManagementDataStatus: FetchState.DEFAULT,
  filterStore: {
    page: 1,
    length: 10,
    status: "active",
  } as UserManagementFilters,
  DataLength: 0,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useUserManagementDataSlice: StateCreator<
  FetchUserManagementDataInterface
> = (set, get) => ({
  ...initialStates,
  fetchUserManagementData: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchCustomerTypes: FetchUserManagementData =
      new RemoteFetchUserManagementData(
        `${BASE_URL}${Endpoints.FETCH_USER_MANAGEMENT_DATA}`,
        axiosHttpClient
      );

    let filters = {
      role_id: get().filterStore.role_id,
      status: get().filterStore.status,
      zone_id: get().filterStore.zone_id,
      region_id: get().filterStore.region_id,
      area_id: get().filterStore.area_id,
      search: get().filterStore.search,
      length: get().filterStore.length,
      page: get().filterStore.page,
    };

    set(() => ({ userManagementDataStatus: FetchState.LOADING }));

    let result = await remoteFetchCustomerTypes.fetch(filters);
    if (result) {
      set(() => ({ userManagementData: result.users }));
      set(() => ({ DataLength: result.total }));
      set(() => ({ userManagementDataStatus: FetchState.SUCCESS }));
    }
  },
});
