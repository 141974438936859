import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchBrickManagement } from "./../../../domain/useages/brick-management/fetch-brick-management";
import { RemoteFetchBrickManagementReport } from "../../../data/usecases/brick-management/remote-fetch-brick-management";
import Endpoints from "../../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import {
  PaginationBrickManagement,
  ZonesType,
  brickManagementFilterType,
} from "../../../domain/models/brick-management-report/brick-management-data";

export interface FetchBrickManagementInterface {
  BrickManagementData: ZonesType[];
  pageData: PaginationBrickManagement;
  fetchBrickManagement: Function;
  fetchBrickManagementState: FetchState;
  brickManagementFilter: brickManagementFilterType;
  currentPageNumber: number;
  handlePageNumber: Function;
}

const initialStates = {
  fetchBrickManagementState: FetchState.DEFAULT,
  BrickManagementData: {} as ZonesType[],
  brickManagementFilter: { page: 1 } as brickManagementFilterType,
  pageData: {} as PaginationBrickManagement,
  currentPageNumber: 1,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchBrickManagementSlice: StateCreator<
  FetchBrickManagementInterface
> = (set) => ({
  ...initialStates,
  handlePageNumber: (pageNo: number) => {
    set(() => ({ currentPageNumber: pageNo }));
  },
  fetchBrickManagement: async (filters: FetchBrickManagement.Params) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    set({
      fetchBrickManagementState: FetchState.LOADING,
      pageData: { total: 0 } as PaginationBrickManagement,
    });

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchBrickManagement: FetchBrickManagement =
      new RemoteFetchBrickManagementReport(
        `${BASE_URL}${Endpoints.BRICK_MANAGEMENT}`,
        axiosHttpClient
      );
    let result = await remoteFetchBrickManagement.fetch(filters);
    if (result) {
      set({
        BrickManagementData: result.zones,
        fetchBrickManagementState: FetchState.SUCCESS,
        pageData: result.paginated,
      });
    } else {
      set({ fetchBrickManagementState: FetchState.ERROR });
    }
  },
});
