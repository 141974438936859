import { StateCreator } from "zustand";

import Swal from "sweetalert2";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { FetchSpecialitiesMaster } from "../../domain/useages/coverage-reports/fetch-specialities";
import { RemoteFetchSpecialitiesMaster } from "../../data/usecases/coverage-reports/remote-fetch-specialities";
import Endpoints from "../../domain/endpoints";
import { Speciality } from "../../domain/models/speciality";

export interface FetchSpecialitiesInterface {
  specialitiesData: Speciality[];
  fetchSpecialities: Function;
}

const initialStates = {
  specialitiesData: [],
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchSpecialitiesSlice: StateCreator<
  FetchSpecialitiesInterface
> = (set, get) => ({
  ...initialStates,
  fetchSpecialities: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchSpecialitiesMaster: FetchSpecialitiesMaster =
      new RemoteFetchSpecialitiesMaster(
        `${BASE_URL}${Endpoints.FETCH_SPECIALITIES}`,
        axiosHttpClient
      );

    let result = await remoteFetchSpecialitiesMaster.fetch();

    if (result.success) {
      set(() => ({ specialitiesData: result.specialities }));
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: "Error Occured While Fetching Speciality Data",
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },

});
