import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { FetchDeviationReport } from "../../../domain/useages/user-summary-report/fetch-deviation-report";
import { RemoteFetchDeviationReport } from "../../../data/usecases/user-summary-report/remote-fetch-deviation-report";
import Endpoints from "../../../domain/endpoints";
import { DeviationReportType } from "../../../domain/models/user-summary-report/deviation-report";
import { FetchState } from "../../../domain/models/fetch-state";
import { UserSummaryReportFilters } from "../../../domain/models/user-summary-report/common-modals";

export interface FetchDeviationReportInterface {
  fetchDeviationReport: Function;
  deviationReportData: DeviationReportType;
  userSummaryFilter: UserSummaryReportFilters;
  fetchDeviationReportState: FetchState;
}

const currentDate = new Date();

const initialStates = {
  deviationReportData: {} as DeviationReportType,
  fetchDeviationReportState: FetchState.DEFAULT,
  userSummaryFilter: {
    department: 0,
    user_id: 1,
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
  },
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchDeviationReportSlice: StateCreator<
  FetchDeviationReportInterface
> = (set, get) => ({
  ...initialStates,
  fetchDeviationReport: async (
    month: number,
    year: number,
    user_id: number,
    department_id: number
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    set({ fetchDeviationReportState: FetchState.LOADING });

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchDeviationReport: FetchDeviationReport =
      new RemoteFetchDeviationReport(
        `${BASE_URL}${Endpoints.DEVIATION_REPORT}`,
        axiosHttpClient
      );
    let result = await remoteFetchDeviationReport.fetch(
      month,
      year,
      user_id,
      department_id
    );
    if (result.attendances.length != 0 || result.tours.length != 0) {
      set({
        deviationReportData: result,
        fetchDeviationReportState: FetchState.SUCCESS,
      });
    } else {
      set({
        deviationReportData: result,
        fetchDeviationReportState: FetchState.ERROR,
      });
    }
  },
});
