import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { CustomerDatabaseStore } from "../../../../../../store/main/CustomerDatabaseStore";
import { CustomerDetails } from "../../../../../../domain/models/customer-database/customer-details";
import { TerritoryDataStore } from "../../../../../../store/main/TerritoryDataStore";
import { RemoveMapping } from "../../../../../../domain/models/remove-mapping";
import { MobileModalStyle } from "../../../../../../common/constants/custom-style";
import Actions from "../../../../../../common/enums/Actions";

interface Props {
  open: boolean;
  handleClose: Function;
  title: string;
  button: string;
  customerDetails?: CustomerDetails;
  reasonOptions: string[];
}

const CustomerActionsModalMobile: React.FC<Props> = ({
  open,
  handleClose,
  title,
  button,
  reasonOptions,
  customerDetails = {} as CustomerDetails,
}) => {
  const {
    removeCustomerMapping,
    customerIds,
    deactivateCustomer,
    rejectCustomer,
    fetchCustomerData,
    fetchCustomerSummaryCount,
    handleClearSelectedCustomers,
  } = CustomerDatabaseStore();

  const { departmentData } = TerritoryDataStore();

  const { handleSubmit, control, setValue, watch } = useForm<RemoveMapping>({
    mode: "onChange",
  });

  const RemoveMappingCall = (data: RemoveMapping) => {
    if (button === "Remove Mapping") {
      let payload = {
        customer_ids: customerDetails.id ? [customerDetails.id] : [],
        reason: data.reasonSelect == "Other" ? data.reason : data.reasonSelect,
      };
      removeCustomerMapping(
        payload,
        handleClose,
        title,
        fetchCustomerData,
        handleClearSelectedCustomers
      );
    } else if (button === "Deactivate") {
      let payload = {
        customer_ids: customerIds,
        reason: data.reasonSelect == "Other" ? data.reason : data.reasonSelect,
        department_id: data.department,
        headquarter_id: customerDetails.mappings.headquarter_id,
      };
      deactivateCustomer(
        payload,
        handleClose,
        title,
        fetchCustomerData,
        handleClearSelectedCustomers,
        fetchCustomerSummaryCount
      );
    } else if (button === "Reject Customer") {
      let payload = {
        customer_ids: customerDetails.id ? [customerDetails.id] : [],
        reason: data.reasonSelect == "Other" ? data.reason : data.reasonSelect,
      };
      rejectCustomer(
        payload,
        handleClose,
        title,
        fetchCustomerData,
        handleClearSelectedCustomers,
        fetchCustomerSummaryCount
      );
    }

    handleClose(title);
  };

  useEffect(() => {
    setValue("reason", "");
    setValue("department", "");
    setValue("reasonSelect", "");
  }, [open]);

  return (
    <>
      <Modal open={open}>
        <Box sx={MobileModalStyle}>
          <Stack gap={2}>
            <Typography
              textAlign="center"
              variant="h6"
              fontFamily="Segoe UI"
              fontWeight={600}
              color="#4D4D4D"
            >
              {title}
            </Typography>
            {(title === Actions.DEACTIVATE_CUSTOMER ||
              title === Actions.BULK_DEACTIVATE_CUSTOMERS) && (
              <Controller
                name="department"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel>Select Department</InputLabel>
                    <Select
                      value={value}
                      size="small"
                      label="Select Department"
                      onChange={onChange}
                    >
                      {departmentData?.map((name) => (
                        <MenuItem value={name.id}>{name.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                rules={{
                  required: { value: true, message: "Field required" },
                }}
              />
            )}

            {/* <Typography fontFamily="Segoe UI" fontWeight={600} color="#4D4D4D">
                Select Reason to {title}
              </Typography> */}

            <Controller
              name="reasonSelect"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth size="small">
                  <InputLabel>Reason</InputLabel>
                  <Select
                    value={value}
                    size="small"
                    label="Reason"
                    onChange={onChange}
                  >
                    {reasonOptions?.map((name) => (
                      <MenuItem value={name}>{name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              rules={{
                required: { value: true, message: "There must be a Reason" },
              }}
            />
            {watch("reasonSelect") == "Other" && (
              <Controller
                name="reason"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    value={value}
                    size="small"
                    onChange={onChange}
                    placeholder="Please type reason..."
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  pattern: {
                    value: /^(?!.*[^a-zA-Z0-9 ]).{5,}$/,
                    message:
                      "Enter valid reason.  No special characters and minimum 5 characters",
                  },
                }}
              />
            )}

            <Stack direction="row" gap={3} justifyContent="center">
              <Button
                disabled={
                  !(
                    (watch("reasonSelect") &&
                      watch("reasonSelect") != "Other") ||
                    (watch("reasonSelect") == "Other" &&
                      watch("reason") &&
                      watch("department") &&
                      watch("reason").length > 4)
                  )
                }
                variant="contained"
                color="error"
                sx={{
                  textTransform: "unset",
                  borderRadius: "30px",
                  height: "40px",
                  bgcolor: "#EF5466",
                  minWidth: "30%",
                }}
                onClick={handleSubmit(RemoveMappingCall)}
              >
                <Typography
                  variant="subtitle2"
                  fontFamily="Segoe UI"
                  color="white"
                >
                  {button}
                </Typography>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "unset",
                  borderRadius: "30px",
                  minWidth: "30%",
                }}
                onClick={() => handleClose(title)}
              >
                <Typography variant="subtitle2" fontFamily="Segoe UI">
                  Cancel
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default CustomerActionsModalMobile;
