import TableCell from "@mui/material/TableCell";
import { Typography, TextField, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useForm, Controller } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import { UserInputPlanningStore } from "../../../../store/main/UserInputPlanningStore";
import { FetchState } from "../../../../domain/models/fetch-state";
import { result } from "lodash";

type Props = {
  cellData: any;
  id: number | string;
  index: number | string;
  handleChangeCell: Function;
  allowEdit: boolean;
};

type Data = {
  newValue: string;
};

const tableCellStyle = {
  borderLeft: "1px solid #B2BEB5",
  padding: 1,
  alignItems: "center",
};
const SingleUserData: React.FC<Props> = ({
  cellData,
  handleChangeCell,
  index,
  allowEdit,
  id,
}) => {
  const { updateStatus, updateResponse } = UserInputPlanningStore();
  const HandleValueChange = async (data: Data) => {
    await handleChangeCell(id, data.newValue, index);
  };
  const [edit, setEdit] = useState<boolean>(false);
  const { handleSubmit, control, setValue, watch } = useForm<Data>({
    mode: "onChange",
  });

  useEffect(() => {
    setEdit(false);
  }, [updateResponse, cellData]);
  useEffect(() => {
    if (
      updateStatus === FetchState.SUCCESS ||
      updateStatus === FetchState.ERROR ||
      updateStatus === FetchState.DEFAULT
    ) {
      setEdit(false);
    }
  }, [updateStatus]);

  return (
    <>
      <TableCell align="center" sx={tableCellStyle}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={1}
          justifyContent={"center"}
        >
          {edit && allowEdit && (
            <Controller
              name="newValue"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  justifyContent={"center"}
                >
                  <TextField
                    placeholder={cellData}
                    value={value}
                    size="small"
                    onChange={onChange}
                    InputProps={{
                      style: {
                        width: "100px",
                        textAlign: "center",
                        fontSize: "13px",
                        height: "25px",
                        padding: 2,
                      },
                    }}
                    defaultValue={cellData}
                  >
                    {cellData}
                  </TextField>

                  {updateStatus != FetchState.LOADING && (
                    <DoneIcon
                      fontSize="small"
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={handleSubmit(HandleValueChange)}
                    />
                  )}
                  {updateStatus == FetchState.LOADING && (
                    <CircularProgress color="success" size={15} />
                  )}
                </Stack>
              )}
              rules={{
                required: { value: true, message: "Field required" },
              }}
            />
          )}

          {!edit && (
            <Typography
              variant="caption"
              paddingX={2}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                allowEdit && setEdit(true);
              }}
            >
              {cellData}
            </Typography>
          )}
        </Stack>
      </TableCell>
    </>
  );
};

export default SingleUserData;
