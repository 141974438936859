import { Stack, Typography } from "@mui/material";
import ColorInfo from "../../desktop/customer-database/sub-component/ColorInfo";
import ReportScreen from "../../../../common/enums/ReportScreens";
import { ReportFilters } from "../../../../domain/models/report-filters";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { TerritoryDataStore } from "../../../../store/main/TerritoryDataStore";
import { CoverageReportsStore } from "../../../../store/main/CoverageReportsStore";
import ReportFiltersLoading from "../../desktop/ReportFiltersLoading";
import { useEffect, useState } from "react";
import ReportFiltersComponentMobile from "../ReportFiltersComponentMobile";
import CustomerSummaryMobile from "./CustomerSummaryMobile";
import { FetchState } from "../../../../domain/models/fetch-state";
import SummaryLoading from "../../desktop/customer-database/sub-component/Loading/SummaryLoading";
import CustomerDetailsMobile from "./CustomerDetailsMobile";
import { TerritoryData } from "../../../../domain/models/territory-data";
import Roles from "../../../../common/enums/Roles";

const CustomerDatabaseMobile = () => {
  const {
    fetchCustomerData,
    fetchCustomerSummaryCount,
    filterStore,
    fetchSummaryState,
    fetchHqSummaryData,
    decodeToken,
    selectedPanel,
    userDetails,
    userRole,
    handleFilterSegement,
    handleDepartmentCode,
    fetchMetadata,
    handleHeadquarterCode,
  } = CustomerDatabaseStore();

  const { fetchCustomerTypes, headquarterMasterData } = TerritoryDataStore();

  const { fetchSpecialities } = CoverageReportsStore();

  useEffect(() => {
    filterStore.zone_ids = [];
    filterStore.region_ids = [];
    filterStore.area_ids = [];
    filterStore.headquarter_ids = [];
    filterStore.territory_ids = [];
    filterStore.download = 0;
    filterStore.brick_ids = [];
    filterStore.q = "";
    filterStore.department = "";
    filterStore.segement = [];
    decodeToken();
    fetchMetadata();
    fetchCustomerSummaryCount(filterStore);
    fetchCustomerData();
    fetchHqSummaryData();
    fetchSpecialities();
    fetchCustomerTypes();
  }, []);

  const setHeadquarterCode = (hq: string) => {
    const arr = headquarterMasterData.find(
      (name: TerritoryData) => name.id === parseInt(hq)
    );
    handleHeadquarterCode(arr?.ga_code);
  };

  const filterData = (filters: ReportFilters) => {
    filterStore.zone_ids = filters.zone;
    filterStore.region_ids = filters.region;
    filterStore.area_ids = filters.area;
    filterStore.headquarter_ids = filters.headquarter;
    filterStore.brick_ids = filters.brick;
    filterStore.q = filters.search;
    filterStore.customer_type_id = "";
    filterStore.department = filters.department;
    filterStore.segement = filters.segement;
    handleFilterSegement(filters.segement);
    handleDepartmentCode(filters.department);
    setHeadquarterCode(filters.headquarter[0]);
    fetchCustomerSummaryCount(filterStore);
    fetchCustomerData();
  };

  const clearData = (filters: ReportFilters) => {
    if (userRole.role === Roles.COUTNRY_MANAGER) {
      filters.zone = [];
      filters.area = [];
      filters.region = [];
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole.role === Roles.ZONE_MANAGER) {
      filters.area = [];
      filters.region = [];
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole.role === Roles.REGION_MANAGER) {
      filters.area = [];
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole.role === Roles.AREA_MANAGER) {
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole.role === Roles.HEADQUATER_MANAGER) {
      filters.brick = [];
    }
    filterStore.q = "";
    filterStore.customer_type_id = "";
    filterStore.department = "";
    filterStore.segement = [];
    handleFilterSegement([]);
    fetchCustomerSummaryCount(filterStore);
    fetchCustomerData();
  };

  const [buttonAccess, setButtonAccess] = useState<boolean>(false);
  const [isRestricted, setIsRestricted] = useState<boolean>(false);
  return (
    <Stack padding="10px 10px 40px" spacing={1}>
      <Stack>
        <Typography variant="h5">Customer Database</Typography>
        <Stack gap={2}>
          <Stack alignItems="center" justifyContent={"center"} gap={1}>
            <ColorInfo
              color="#FFEBCC"
              label="System suggestion: Doctor should be removed from MVL"
            />
          </Stack>
        </Stack>
      </Stack>
      {!selectedPanel &&
        (Object.keys(userDetails).length > 0 ? (
          <ReportFiltersComponentMobile
            type={ReportScreen.CUSTOMER_DATABASE}
            successCallback={filterData}
            clearSuccessCallBack={clearData}
            userRole={userRole.role}
            userDetails={userDetails}
            gridColumns={9}
          />
        ) : (
          <ReportFiltersLoading />
        ))}
      {!selectedPanel && fetchSummaryState === FetchState.LOADING && (
        <SummaryLoading />
      )}
      {!selectedPanel && fetchSummaryState === FetchState.SUCCESS && (
        <CustomerSummaryMobile
          setIsRestricted={setIsRestricted}
          setButtonAccess={setButtonAccess}
        />
      )}

      <CustomerDetailsMobile
        buttonAccess={buttonAccess}
        isRestricted={isRestricted}
      />
    </Stack>
  );
};
export default CustomerDatabaseMobile;
