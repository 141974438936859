import { create } from "zustand";
import { useFetchBricWorkingkReportSlice } from "../slices/BrickWorkingReportSlices/fetchBrickWorkingReportData";

interface BrickWorkingReportInterface
  extends ReturnType<typeof useFetchBricWorkingkReportSlice> {}

export const BrickWorkingReportStore = create<BrickWorkingReportInterface>(
  (...a) => ({
    ...useFetchBricWorkingkReportSlice(...a),
  })
);
