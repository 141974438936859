import { StateCreator } from "zustand";
import { FetchState } from "../../../domain/models/fetch-state";
import { TokenData } from "../../../domain/models/token-data";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, DASHBOARD_AUTH_URL } from "../../../base";
import jwt_decode from "jwt-decode";
import { RemoteGetBusinessTemplate } from "../../../data/usecases/business-planning/remote-fetch-business-templates";
import Endpoints from "../../../domain/endpoints";
import { FetchBusinessTemplate } from "../../../domain/useages/business-planning/fetch-business-template";
import { businessTemplateData } from "../../../domain/models/business-planning/business-template";
import Swal from "sweetalert2";

export interface GetBusinessTemplateInterface {
  getBusinessTemplateData: Function;
  userRole: TokenData;
  decodeToken: Function;
  fetchTemplateState: FetchState;
  BusinessTemplateData: businessTemplateData[];
}

const initialState = {
  userRole: {} as TokenData,
  fetchTemplateState: FetchState.DEFAULT,
  BusinessTemplateData: [],
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchBusinessPlanningSlice: StateCreator<
  GetBusinessTemplateInterface
> = (set, get) => ({
  ...initialState,
  getBusinessTemplateData: async (payload: FetchBusinessTemplate.Params,) => {
    set(() => ({ fetchTemplateState: FetchState.LOADING }));
    const token = storage.get(AUTH_TOKEN_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteGetBusinessTemplateLink = new RemoteGetBusinessTemplate(
      `${DASHBOARD_AUTH_URL}${Endpoints.BUSINESS_TEMPLATES}`,
      axiosHttpClient
    );
    let result = await remoteGetBusinessTemplateLink.get(payload);
    if(result) {
      set(() => ({fetchTemplateState : FetchState.SUCCESS}))
      set(() => ({BusinessTemplateData : result}));
    } else {
      set(() => ({fetchTemplateState : FetchState.ERROR}));
      Swal.fire({
        icon: "error",
        title: "No User Found",
        timer: 3000,
      });
    }
  },
  decodeToken: () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    let decoded: TokenData = jwt_decode(atob(token));
    set(() => ({ userRole: decoded }));
  },
});
