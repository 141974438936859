import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { BrickWorkingReportStore } from "../../../../store/main/BrickWorkingReportStore";
import dayjs from "dayjs";
import { useEffect } from "react";
import ReportScreen from "../../../../common/enums/ReportScreens";
import Roles from "../../../../common/enums/Roles";
import { ReportFilters } from "../../../../domain/models/report-filters";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import ReportFiltersLoading from "../../desktop/ReportFiltersLoading";
import ReportFiltersComponentMobile from "../ReportFiltersComponentMobile";
import BrickWorkingReportMobileTabs from "./BrickWorkingReportMobileTabs";
import { FetchState } from "../../../../domain/models/fetch-state";
import BrickWorkingReportMobileTable from "./BrickworkingReportMobileTable";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const BrickWorkingReportMobileComponent = () => {
  const { decodeToken, userDetails, userRole } = CustomerDatabaseStore();
  const {
    fetchBrickWorkingReport,
    brickReportData,
    brickReportLoading,
    brickReportFilters,
    downloadBrickWorkingReport,
  } = BrickWorkingReportStore();

  useEffect(() => {
    if (
      userRole.role &&
      userRole.role !== Roles.COUTNRY_MANAGER &&
      userRole.role !== Roles.ZONE_MANAGER
    ) {
      fetchBrickWorkingReport();
    }
  }, [userRole.role]);

  useEffect(() => {
    decodeToken();
  }, []);

  const handleFilterBrickWorkingReport = (filters: ReportFilters) => {
    brickReportFilters.year = filters.year;
    brickReportFilters.customer_type_id = filters.customerType;
    brickReportFilters.product_ids = filters.products;
    fetchBrickWorkingReport();
  };

  const clearFilterBrickWorkingReport = (filters: ReportFilters) => {
    brickReportFilters.year = dayjs(new Date());
    brickReportFilters.customer_type_id = "";
    brickReportFilters.product_ids = [];
    fetchBrickWorkingReport();
  };

  const handleDownload = () => {
    downloadBrickWorkingReport();
  };
  return (
    <Stack>
      {Object.keys(userDetails).length > 0 ? (
        <Stack justifyContent={"center"}>
          {(userRole.role == Roles.COUTNRY_MANAGER ||
            userRole.role == Roles.ZONE_MANAGER) && (
            <BrickWorkingReportMobileTabs userDetails={userDetails} />
          )}
          <Stack>
            <ReportFiltersComponentMobile
              type={ReportScreen.BRICK_WORKING_REPORT}
              successCallback={handleFilterBrickWorkingReport}
              clearSuccessCallBack={clearFilterBrickWorkingReport}
              gridColumns={16}
              userRole={userRole.role}
              userDetails={userDetails}
              justifyContent={"end"}
            />
          </Stack>
        </Stack>
      ) : (
        <ReportFiltersLoading />
      )}
      <Stack paddingTop={2}>
        {brickReportLoading === FetchState.LOADING && (
          <Stack alignItems={"center"} height={100} justifyContent={"center"}>
            <CircularProgress />
          </Stack>
        )}
        {brickReportLoading === FetchState.SUCCESS &&
          (brickReportData.length > 0 ? (
            <BrickWorkingReportMobileTable />
          ) : (
            <Box
              paddingY="30px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <HighlightOffIcon sx={{ fontSize: 50 }} color="error" />
              <Typography variant="h6">NO RECORD FOUND</Typography>
            </Box>
          ))}
      </Stack>
    </Stack>
  );
};
export default BrickWorkingReportMobileComponent;
