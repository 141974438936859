import { Stack, useMediaQuery, useTheme } from "@mui/material";
import CoverageReportsTabs from "../components/desktop/coverage-reports/CoverageReportsTabs";
import Header from "../components/Header";
import CoverageReportsTabsMobile from "../components/mobile/coverage-report/CoverageReportsTabsMobile";

export default function CoverageReports() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Stack gap={2} position={"fixed"} width={"100%"}>
        <Header title="Coverage Reports" />
        {isMobile ? <CoverageReportsTabsMobile /> : <CoverageReportsTabs />}
      </Stack>
    </>
  );
}
