import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { RemoteCreateNewUser } from "../../../data/usecases/user-management/remote-create-new-user";
import { CreateNewUser } from "../../../domain/useages/user-management/create-new-user";

export interface CreateNewUserInterface {
  createNewUser: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useCreateNewUserSlice: StateCreator<CreateNewUserInterface> = (
  set,
  get
) => ({
  createNewUser: async (
    payload: CreateNewUser.Params,
    handleClose: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteCreateNewUser = new RemoteCreateNewUser(
      `${BASE_URL}${Endpoints.CREATE_NEW_USER}`,
      axiosHttpClient
    );
    let result = await remoteCreateNewUser.create(payload);

    if (result) {
      handleClose();
    }
  },
});
