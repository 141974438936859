import { MapBrickToCustomer } from "../../domain/useages/map-brick-to-customer";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteMapCustomerToBrick implements MapBrickToCustomer {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async map(params: MapBrickToCustomer.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url
        .replace(":customer_id", params.customer_id.toString())
        .replace(":brick_id", params.brick_id.toString()),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status === 200) {
      return httpResponse.data;
    }
  }
}
