import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { LocalLoggedInUser } from "../../data/usecases/local-logged-in-user";
import Swal from "sweetalert2";
import { CustomerDatabaseStore } from "../../store/main/CustomerDatabaseStore";
import { screenNavigation } from "../../common/utils/ReportsFilters";

const AuthPage = () => {
  const { authToken } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const jsonStorage = LocalJsonStorage.getInstance();
  const loggedInUser = new LocalLoggedInUser(jsonStorage);
  const navigate = useNavigate();
  const { decodeToken } = CustomerDatabaseStore();
  useEffect(() => {
    if (authToken && searchParams.get("baseUrl")) {
      loggedInUser.setToken(authToken);
      loggedInUser.setBaseURL(searchParams.get("baseUrl") as string);
      decodeToken();
      screenNavigation(searchParams.get("type"))
        ? navigate(screenNavigation(searchParams.get("type")))
        : Swal.fire("Unknown Type", "", "error");
    } else {
      Swal.fire("Authentication Failed", "", "error");
    }
  }, [authToken, searchParams.get("baseUrl")]);

  return <div></div>;
};

export default AuthPage;
