import {
  Box,
  CircularProgress,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import ReportFiltersComponent from "../ReportFiltersComponent";
import { useEffect, useState } from "react";
import ReportFiltersLoading from "../ReportFiltersLoading";
import { DailySalesReportPercentDataCondition } from "./DailySalesReportPercentDataCondition";
import { DailySalesReportDataInLakhCondition } from "./DailySalesReportDataInLakhCondition";
import ReportScreen from "../../../../common/enums/ReportScreens";
import { FetchState } from "../../../../domain/models/fetch-state";
import { ReportFilters } from "../../../../domain/models/report-filters";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { DailySalesStore } from "../../../../store/main/DailySalesStore";
import PivotTable from "../../PivotTable";
import Roles from "../../../../common/enums/Roles";

const DailySalesReportComponent = () => {
  const { userDetails, userRole, decodeToken } = CustomerDatabaseStore();
  const {
    fetchDailySales,
    dailySalesData,
    dailySalesStatus,
    dailySalesFilters,
  } = DailySalesStore();
  const [showAggregate, setShowAggregate] = useState(false);
  const [percentData, setPercentData] = useState<any[]>([]);

  useEffect(() => {
    if (showAggregate) {
      setPercentData([]);
    }
  }, [showAggregate]);

  useEffect(() => {
    decodeToken();
  }, []);

  useEffect(() => {
    if (sortedDailySalesData().length > 0) {
      CalculatePercent();
    }
    setPercentData([]);
  }, [dailySalesStatus, showAggregate]);

  const salesData: any[] =
    dailySalesData.daily_sales &&
    Object.entries(dailySalesData?.daily_sales)?.map(
      ([monthYear, sales]: [string, any[]]) => {
        const dailySales: { [day: string]: string } = {};
        let sum = 0;
        sales.forEach((sale, index) => {
          sum = sum + parseInt(sale.sale);
          dailySales["total_sales"] = sum.toString();
          dailySales["target"] = sale.target;
          dailySales[`${sale.days}`] = sale.sale;
          const cumulative_frequency = sales
            .slice(0, index + 1)
            .reduce((acc, curr) => acc + parseInt(curr.sale), 0);
          dailySales[`${sale.days}_cumulative_frequency`] =
            cumulative_frequency.toString();
        });

        return {
          monthYear,
          ...dailySales,
        };
      }
    );

  const monthOrder = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const sortedDailySalesData = () => {
    const currentMonthYear = `${
      monthOrder[new Date().getMonth()]
    } ${new Date().getFullYear()}`;
    const lastYearMonthYear = `${monthOrder[new Date().getMonth()]} ${
      new Date().getFullYear() - 1
    }`;
    const sortedData: any[] = [];
    const currentIndex = salesData?.findIndex(
      (item) => item.monthYear === currentMonthYear
    );

    const lastYearIndex = salesData?.findIndex(
      (item) => item.monthYear === lastYearMonthYear
    );
    if (currentIndex > -1) {
      sortedData.push(salesData[currentIndex]);
    }
    if (lastYearIndex > -1) {
      sortedData.push(salesData[lastYearIndex]);
    }
    const otherData = salesData?.filter(
      (item) => item !== sortedData[0] && item !== sortedData[1]
    );

    otherData?.forEach((item) => {
      const [month, year] = item.monthYear.split(" ");
      item.date = new Date(`${month} 1, ${year}`);
    });
    const sortedOtherData = otherData?.sort((a, b) => b.date - a.date);
    sortedOtherData?.forEach((item) => {
      delete item.date;
    });
    const finalSortedData: any[] = sortedData?.concat(sortedOtherData);
    return finalSortedData;
  };
  const CalculatePercent = () => {
    salesData &&
      sortedDailySalesData().map((val) => {
        if (val.total_sales > 0) {
          const rowData = {
            monthYear: val.monthYear,
            target: val.target,
            sales: val.total_sales,
            1: (val["1_cumulative_frequency"] / val.target) * 100,
            2: (val["2_cumulative_frequency"] / val.target) * 100,
            3: (val["3_cumulative_frequency"] / val.target) * 100,
            4: (val["4_cumulative_frequency"] / val.target) * 100,
            5: (val["5_cumulative_frequency"] / val.target) * 100,
            6: (val["6_cumulative_frequency"] / val.target) * 100,
            7: (val["7_cumulative_frequency"] / val.target) * 100,
            8: (val["8_cumulative_frequency"] / val.target) * 100,
            9: (val["9_cumulative_frequency"] / val.target) * 100,
            10: (val["10_cumulative_frequency"] / val.target) * 100,
            11: (val["11_cumulative_frequency"] / val.target) * 100,
            12: (val["12_cumulative_frequency"] / val.target) * 100,
            13: (val["13_cumulative_frequency"] / val.target) * 100,
            14: (val["14_cumulative_frequency"] / val.target) * 100,
            15: (val["15_cumulative_frequency"] / val.target) * 100,
            16: (val["16_cumulative_frequency"] / val.target) * 100,
            17: (val["17_cumulative_frequency"] / val.target) * 100,
            18: (val["18_cumulative_frequency"] / val.target) * 100,
            19: (val["19_cumulative_frequency"] / val.target) * 100,
            20: (val["20_cumulative_frequency"] / val.target) * 100,
            21: (val["21_cumulative_frequency"] / val.target) * 100,
            22: (val["22_cumulative_frequency"] / val.target) * 100,
            23: (val["23_cumulative_frequency"] / val.target) * 100,
            24: (val["24_cumulative_frequency"] / val.target) * 100,
            25: (val["25_cumulative_frequency"] / val.target) * 100,
            26: (val["26_cumulative_frequency"] / val.target) * 100,
            27: (val["27_cumulative_frequency"] / val.target) * 100,
            28: (val["28_cumulative_frequency"] / val.target) * 100,
            29: (val["29_cumulative_frequency"] / val.target) * 100,
            30: (val["30_cumulative_frequency"] / val.target) * 100,
            31: (val["31_cumulative_frequency"] / val.target) * 100,
          };
          percentData.push(rowData);
        } else {
          const rowData = {
            monthYear: val.monthYear,
            target: val.target,
            sales: val.total_sales,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0,
            19: 0,
            20: 0,
            21: 0,
            22: 0,
            23: 0,
            24: 0,
            25: 0,
            26: 0,
            27: 0,
            28: 0,
            29: 0,
            30: 0,
            31: 0,
          };
          percentData.push(rowData);
        }
      });
  };
  const dailySalesDataInPercent: any = {
    data: percentData,
    tableSizes: {
      columns: [
        {
          idx: 0,
          width: 90,
        },
        {
          idx: 1,
          width: 90,
        },
        {
          idx: 2,
          width: 90,
        },
        {
          idx: 4,
          width: 45,
        },
        {
          idx: 5,
          width: 45,
        },
        {
          idx: 6,
          width: 45,
        },
        {
          idx: 7,
          width: 45,
        },
        {
          idx: 8,
          width: 45,
        },
        {
          idx: 9,
          width: 45,
        },
        {
          idx: 10,
          width: 45,
        },
        {
          idx: 11,
          width: 45,
        },
        {
          idx: 12,
          width: 45,
        },
        {
          idx: 13,
          width: 45,
        },
        {
          idx: 14,
          width: 45,
        },
        {
          idx: 15,
          width: 45,
        },
        {
          idx: 16,
          width: 45,
        },
        {
          idx: 17,
          width: 45,
        },
        {
          idx: 18,
          width: 45,
        },
        {
          idx: 19,
          width: 45,
        },
        {
          idx: 20,
          width: 45,
        },
        {
          idx: 21,
          width: 45,
        },
        {
          idx: 22,
          width: 45,
        },
        {
          idx: 23,
          width: 45,
        },
        {
          idx: 24,
          width: 45,
        },
        {
          idx: 25,
          width: 45,
        },
        {
          idx: 26,
          width: 45,
        },
        {
          idx: 27,
          width: 45,
        },
        {
          idx: 28,
          width: 45,
        },
        {
          idx: 29,
          width: 45,
        },
        {
          idx: 30,
          width: 45,
        },
        {
          idx: 31,
          width: 45,
        },
        {
          idx: 32,
          width: 45,
        },
        {
          idx: 33,
          width: 45,
        },
        {
          idx: 34,
          width: 45,
        },
        {
          idx: 35,
          width: 45,
        },
        {
          idx: 36,
          width: 45,
        },
      ],
    },
    rows: [
      {
        uniqueName: "monthYear",
        sort: "none",
        caption: "Month",
      },
    ],
    columns: [{ uniqueName: "Measures" }],
    measures: [
      {
        uniqueName: "target",
        caption: "Target (Lac)",
        formula: "sum('target')/100000",
        format: "precision",
      },
      {
        uniqueName: "totalSales",

        caption: "Sales (Lac)",

        format: "precision",
        formula: "sum('sales')/100000",
      },
      {
        uniqueName: "achivement",
        caption: "Achivement(%)",
        formula:
          "If(sum('target') = 0, 0, (sum('sales') / sum('target')) * 100)",
        format: "precision",
      },
      {
        uniqueName: "1",
        caption: "1",
        formula: "If(sum('target') = 0, 0, sum('1')",

        format: "precision",
      },
      {
        uniqueName: "2",
        caption: "2",
        formula: "If(sum('target') = 0, 0, sum('2')",
        format: "precision",
      },
      {
        uniqueName: "3",
        caption: "3",
        formula: "If(sum('target') = 0, 0, sum('3')",
        format: "precision",
      },
      {
        uniqueName: "4",
        caption: "4",
        formula: "If(sum('target') = 0, 0, sum('4')",
        format: "precision",
      },
      {
        uniqueName: "5",
        caption: "5",
        formula: "If(sum('target') = 0, 0, sum('5')",
        format: "precision",
      },
      {
        uniqueName: "6",
        caption: "6",
        formula: "If(sum('target') = 0, 0, sum('6')",
        format: "precision",
      },
      {
        uniqueName: "7",
        caption: "7",
        formula: "If(sum('target') = 0, 0, sum('7')",
        format: "precision",
      },
      {
        uniqueName: "8",
        caption: "8",
        formula: "If(sum('target') = 0, 0, sum('8')",
        format: "precision",
      },
      {
        uniqueName: "9",
        caption: "9",
        formula: "If(sum('target') = 0, 0, sum('9')",
        format: "precision",
      },
      {
        uniqueName: "10",
        caption: "10",
        formula: "If(sum('target') = 0, 0, sum('10')",
        format: "precision",
      },
      {
        uniqueName: "11",
        caption: "11",
        formula: "If(sum('target') = 0, 0, sum('11')",
        format: "precision",
      },
      {
        uniqueName: "12",
        caption: "12",
        formula: "If(sum('target') = 0, 0, sum('12')",
        format: "precision",
      },
      {
        uniqueName: "13",
        caption: "13",
        formula: "If(sum('target') = 0, 0, sum('13')",
        format: "precision",
      },
      {
        uniqueName: "14",
        caption: "14",
        formula: "If(sum('target') = 0, 0, sum('14')",
        format: "precision",
      },
      {
        uniqueName: "15",
        caption: "15",
        formula: "If(sum('target') = 0, 0, sum('15')",
        format: "precision",
      },
      {
        uniqueName: "16",
        caption: "16",
        formula: "If(sum('target') = 0, 0, sum('16')",
        format: "precision",
      },
      {
        uniqueName: "17",
        caption: "17",
        formula: "If(sum('target') = 0, 0, sum('17')",
        format: "precision",
      },
      {
        uniqueName: "18",
        caption: "18",
        formula: "If(sum('target') = 0, 0, sum('18')",
        format: "precision",
      },
      {
        uniqueName: "19",
        caption: "19",
        formula: "If(sum('target') = 0, 0, sum('19')",
        format: "precision",
      },
      {
        uniqueName: "20",
        caption: "20",
        formula: "If(sum('target') = 0, 0, sum('20')",
        format: "precision",
      },
      {
        uniqueName: "21",
        caption: "21",
        formula: "If(sum('target') = 0, 0, sum('21')",
        format: "precision",
      },
      {
        uniqueName: "22",
        caption: "22",
        formula: "If(sum('target') = 0, 0, sum('22')",
        format: "precision",
      },
      {
        uniqueName: "23",
        caption: "23",
        formula: "If(sum('target') = 0, 0, sum('23')",
        format: "precision",
      },
      {
        uniqueName: "24",
        caption: "24",
        formula: "If(sum('target') = 0, 0, sum('24')",
        format: "precision",
      },
      {
        uniqueName: "25",
        caption: "25",
        formula: "If(sum('target') = 0, 0, sum('25')",
        format: "precision",
      },
      {
        uniqueName: "26",
        caption: "26",
        formula: "If(sum('target') = 0, 0, sum('26')",
        format: "precision",
      },
      {
        uniqueName: "27",
        caption: "27",
        formula: "If(sum('target') = 0, 0, sum('27')",
        format: "precision",
      },
      {
        uniqueName: "28",
        caption: "28",
        formula: "If(sum('target') = 0, 0, sum('28')",
        format: "precision",
      },
      {
        uniqueName: "29",
        caption: "29",
        formula: "If(sum('target') = 0, 0, sum('29')",
        format: "precision",
      },
      {
        uniqueName: "30",
        caption: "30",
        formula: "If(sum('target') = 0, 0, sum('30')",
        format: "precision",
      },
      {
        uniqueName: "31",
        caption: "31",
        formula: "If(sum('target') = 0, 0, sum('31')",
        format: "precision",
      },
    ],
    formats: [
      {
        name: "precision",
        decimalPlaces: 1,
        thousandsSeparator: ",",
      },
    ],
    expands: {
      expandAll: "true",
    },
    options: {
      grid: {
        showHeaders: false,
        showHierarchyCaptions: true,
        showGrandTotals: "off",
      },
      showCalculatedValuesButton: true,
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
      defaultHierarchySortName: "asc",
    },
    conditions: DailySalesReportPercentDataCondition,
  };
  let dailySalesDataInLakh: any = {
    data: sortedDailySalesData(),
    tableSizes: {
      columns: [
        {
          idx: 0,
          width: 90,
        },
        {
          idx: 1,
          width: 90,
        },
        {
          idx: 2,
          width: 90,
        },
        {
          idx: 4,
          width: 45,
        },
        {
          idx: 5,
          width: 45,
        },
        {
          idx: 6,
          width: 45,
        },
        {
          idx: 7,
          width: 45,
        },
        {
          idx: 8,
          width: 45,
        },
        {
          idx: 9,
          width: 45,
        },
        {
          idx: 10,
          width: 45,
        },
        {
          idx: 11,
          width: 45,
        },
        {
          idx: 12,
          width: 45,
        },
        {
          idx: 13,
          width: 45,
        },
        {
          idx: 14,
          width: 45,
        },
        {
          idx: 15,
          width: 45,
        },
        {
          idx: 16,
          width: 45,
        },
        {
          idx: 17,
          width: 45,
        },
        {
          idx: 18,
          width: 45,
        },
        {
          idx: 19,
          width: 45,
        },
        {
          idx: 20,
          width: 45,
        },
        {
          idx: 21,
          width: 45,
        },
        {
          idx: 22,
          width: 45,
        },
        {
          idx: 23,
          width: 45,
        },
        {
          idx: 24,
          width: 45,
        },
        {
          idx: 25,
          width: 45,
        },
        {
          idx: 26,
          width: 45,
        },
        {
          idx: 27,
          width: 45,
        },
        {
          idx: 28,
          width: 45,
        },
        {
          idx: 29,
          width: 45,
        },
        {
          idx: 30,
          width: 45,
        },
        {
          idx: 31,
          width: 45,
        },
        {
          idx: 32,
          width: 45,
        },
        {
          idx: 33,
          width: 45,
        },
        {
          idx: 34,
          width: 45,
        },
        {
          idx: 35,
          width: 45,
        },
        {
          idx: 36,
          width: 45,
        },
      ],
    },
    rows: [
      {
        uniqueName: "monthYear",
        sort: "none",
        caption: "Month",
      },
    ],
    columns: [{ uniqueName: "Measures" }],
    measures: [
      {
        uniqueName: "target",

        caption: "Target (Lac)",

        formula: "sum('target')/100000",
        format: "precision",
      },
      {
        uniqueName: "totalSales",

        caption: "Sales (Lac)",

        formula: "sum('total_sales')/100000",
        format: "precision",
      },
      {
        uniqueName: "achivement",
        caption: "Achivement(%)",
        formula:
          "If(sum('target') = 0, 0, (sum('total_sales') / sum('target')) * 100)",
        format: "precision",
      },
      {
        uniqueName: "1",
        caption: "1",
        formula: "sum('1_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "2",
        caption: "2",
        formula: "sum('2_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "3",
        caption: "3",
        formula: "sum('3_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "4",
        caption: "4",
        formula: "sum('4_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "5",
        caption: "5",
        formula: "sum('5_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "6",
        caption: "6",
        formula: "sum('6_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "7",
        caption: "7",
        formula: "sum('7_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "8",
        caption: "8",
        formula: "sum('8_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "9",
        caption: "9",
        formula: "sum('9_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "10",
        caption: "10",
        formula: "sum('10_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "11",
        caption: "11",
        formula: "sum('11_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "12",
        caption: "12",
        formula: "sum('12_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "13",
        caption: "13",
        formula: "sum('13_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "14",
        caption: "14",
        formula: "sum('14_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "15",
        caption: "15",
        formula: "sum('15_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "16",
        caption: "16",
        formula: "sum('16_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "17",
        caption: "17",
        formula: "sum('17_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "18",
        caption: "18",
        formula: "sum('18_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "19",
        caption: "19",
        formula: "sum('19_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "20",
        caption: "20",
        formula: "sum('20_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "21",
        caption: "21",
        formula: "sum('21_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "22",
        caption: "22",
        formula: "sum('22_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "23",
        caption: "23",
        formula: "sum('23_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "24",
        caption: "24",
        formula: "sum('24_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "25",
        caption: "25",
        formula: "sum('25_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "26",
        caption: "26",
        formula: "sum('26_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "27",
        caption: "27",
        formula: "sum('27_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "28",
        caption: "28",
        formula: "sum('28_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "29",
        caption: "29",
        formula: "sum('29_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "30",
        caption: "30",
        formula: "sum('30_cumulative_frequency')/100000",
        format: "precision",
      },
      {
        uniqueName: "31",
        caption: "31",
        formula: "sum('31_cumulative_frequency')/100000",
        format: "precision",
      },
    ],
    formats: [
      {
        name: "precision",
        decimalPlaces: 1,
        thousandsSeparator: ",",
      },
    ],
    expands: {
      expandAll: "true",
    },
    grandTotalCaption: "",
    options: {
      grid: {
        showHeaders: false,
        showHierarchyCaptions: true,
        showGrandTotals: "off",
      },
      showCalculatedValuesButton: true,
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
      defaultHierarchySortName: "asc",
    },
    conditions: DailySalesReportDataInLakhCondition,
    customizeCell: function (cell: any, data: any) {
      if (data.type === "header" && data.hierarchy === "YourColumnName") {
        cell.canSort = false;
      }
    },
  };
  const customizeToolbar = (toolbar: any) => {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      userDetails &&
        userDetails.role &&
        userDetails.role.visibility == 0 &&
        delete tabs[3];
      return tabs;
    };
  };

  const successCallback = (filters: ReportFilters) => {
    dailySalesFilters.area_id = filters.area[0];
    dailySalesFilters.zone_id = filters.zone[0];
    dailySalesFilters.region_id = filters.region[0];
    dailySalesFilters.department_id = filters.department;
    dailySalesFilters.headquarter_id = filters.headquarter[0];
    dailySalesFilters.brand_id = filters.brandType[0];
    sortedDailySalesData().splice(0, sortedDailySalesData.length);
    dailySalesDataInPercent.data.splice(0, dailySalesDataInPercent.length);
    setPercentData([]);
    fetchDailySales();
  };

  const clearSuccessCallback = (filters: ReportFilters) => {
    dailySalesFilters.area_id = "";
    dailySalesFilters.zone_id = "";
    dailySalesFilters.region_id = "";
    dailySalesFilters.department_id = "";
    dailySalesFilters.headquarter_id = "";
    dailySalesFilters.brand_id = "";
    sortedDailySalesData().splice(0, sortedDailySalesData.length);
    dailySalesDataInPercent.data.splice(0, dailySalesDataInPercent.length);
    setPercentData([]);
    fetchDailySales();
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        {Object.keys(userDetails).length > 0 ? (
          <ReportFiltersComponent
            type={ReportScreen.DAILY_SALES_REPORT}
            successCallback={successCallback}
            clearSuccessCallBack={clearSuccessCallback}
            gridColumns={17}
            userRole={userRole.role}
            userDetails={userDetails}
            territoryMultiSelect={false}
          />
        ) : (
          <ReportFiltersLoading />
        )}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Switch
          checked={showAggregate}
          onChange={() => setShowAggregate(!showAggregate)}
          name="loading"
          color="primary"
        />
        {showAggregate ? (
          <Typography variant="subtitle1">Sales Value (in Lakhs)</Typography>
        ) : (
          <Typography variant="subtitle1">Sales in Percentage</Typography>
        )}
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center">
        <Box
          component="span"
          sx={{
            bgcolor: "#2ccb74",
            width: 25,
            height: 25,
            borderRadius: "50%",
          }}
        />
        <Typography variant="subtitle2">Sales Growth Increased</Typography>
        <Box
          component="span"
          sx={{
            bgcolor: "#e44d3e",
            width: 25,
            height: 25,
            borderRadius: "50%",
          }}
        />
        <Typography variant="subtitle2">Sales Growth Not-Increased</Typography>
        <Box
          component="span"
          sx={{
            bgcolor: "#ffa31a",
            width: 25,
            height: 25,
            borderRadius: "50%",
          }}
        />
        <Typography variant="subtitle2">Achievement 100%</Typography>
      </Stack>

      {dailySalesStatus === FetchState.LOADING && (
        <Stack alignItems={"center"} height={100} justifyContent={"center"}>
          <CircularProgress />
        </Stack>
      )}
      {dailySalesStatus === FetchState.SUCCESS &&
        ((dailySalesDataInPercent.data.length > 0 && percentData.length > 0) ||
        dailySalesDataInLakh.data.length > 0 ? (
          showAggregate ? (
            <PivotTable
              formattedData={dailySalesDataInLakh}
              toolbar={true}
              customizeToolbar={customizeToolbar}
            />
          ) : (
            <>
              <PivotTable
                formattedData={dailySalesDataInPercent}
                toolbar={true}
                customizeToolbar={customizeToolbar}
              />
            </>
          )
        ) : (
          <Typography variant="h6">No Records Found</Typography>
        ))}
    </Stack>
  );
};

export default DailySalesReportComponent;
