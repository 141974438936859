import { UpdateFulfilmentAgent } from "../../domain/useages/update-fulfilment-agent";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteUpdateFulfilmentAgent implements UpdateFulfilmentAgent {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async update(params: UpdateFulfilmentAgent.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status === 200) {
      return httpResponse.data;
    }
  }
}
