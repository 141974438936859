import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteFetchManagerBasedOnRole } from "../../../data/usecases/user-management/remote-fetch-manager-using-role";
import Endpoints from "../../../domain/endpoints";
import { ManagerData } from "../../../domain/models/user-management/manager-date";
import { FetchState } from "../../../domain/models/fetch-state";

export interface FetchManagerBasedOnUserRoleInterface {
  managerData: ManagerData[];
  fetchManagerBasedOnUserRole: Function;
  fetchManagerStatus: FetchState;
}

const initialStates = {
  managerData: [],
  fetchManagerStatus: FetchState.DEFAULT,
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchManagerBasedOnUserRoleSlice: StateCreator<
  FetchManagerBasedOnUserRoleInterface
> = (set) => ({
  ...initialStates,
  fetchManagerBasedOnUserRole: async (payload: any) => {
    set(() => ({
      fetchManagerStatus: FetchState.LOADING,
    }));
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchManagerBasedOnRole = new RemoteFetchManagerBasedOnRole(
      `${BASE_URL}${Endpoints.ROLE_CHILDREN}`,
      axiosHttpClient
    );
    let result = await remoteFetchManagerBasedOnRole.fetch(payload);

    if (result) {
      set(() => ({ managerData: result.users }));
      set(() => ({
        fetchManagerStatus: FetchState.SUCCESS,
      }));
    }
  },
});
