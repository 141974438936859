import { Stack } from "@mui/material";
import Header from "../components/Header";
import PrimarySalesInvoiceTabs from "../components/desktop/primary-sales-invoice-report/PrimarySalesInvoiceTabs";
import { CustomerDatabaseStore } from "../../store/main/CustomerDatabaseStore";
import ReportFiltersComponent from "../components/desktop/ReportFiltersComponent";
import ReportScreen from "../../common/enums/ReportScreens";
import ReportFiltersLoading from "../components/desktop/ReportFiltersLoading";
import { ReportFilters } from "../../domain/models/report-filters";
import Roles from "../../common/enums/Roles";
import { useEffect } from "react";
import { PrimarySalesInvoiceStore } from "../../store/main/PrimarySalesInvoiceStore";
import moment from "moment";

export default function PrimarySalesInvoiceReport() {
  const { userDetails, userRole, filterStore, decodeToken } =
    CustomerDatabaseStore();
  const { fetchProductWiseData, fetchStockistWiseData, fetchInvoiceData } =
    PrimarySalesInvoiceStore();
  useEffect(() => {
    decodeToken();

    filterStore.zone_ids = [];
    filterStore.area_ids = [];
    filterStore.region_ids = [];
    filterStore.headquarter_ids = [];

    let payload = {
      zone_id: filterStore.zone_ids[0],
      region_id: filterStore.region_ids[0],
      area_id: filterStore.area_ids[0],
      headquarter_id: filterStore.headquarter_ids[0],
      department_id: "",
    };

    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    fetchProductWiseData(month, year, payload);
    fetchInvoiceData(month, year, payload);
    fetchStockistWiseData(month, year, payload);
  }, []);

  const filterData = (filters: ReportFilters) => {
    filterStore.zone_ids = filters.zone;
    filterStore.region_ids = filters.region;
    filterStore.area_ids = filters.area;
    filterStore.headquarter_ids = filters.headquarter;
    filterStore.brick_ids = filters.brick;
    filterStore.q = filters.search;
    filterStore.customer_type_id = "";
    filterStore.department = filters.department;
    let payload = {
      zone_id: filters.zone,
      region_id: filters.region,
      area_id: filters.area,
      headquarter_id: filters.headquarter,
      department_id: "",
      page: 1,
    };

    let month = moment(filters.date?.toString()).format("MM");
    let year = moment(filters.date?.toString()).format("yy");
    fetchProductWiseData(month, year, payload);
    fetchInvoiceData(month, year, payload);
    fetchStockistWiseData(month, year, payload);
  };

  const clearData = (filters: ReportFilters) => {
    if (userRole.role === Roles.COUTNRY_MANAGER) {
      filters.zone = [];
      filters.area = [];
      filters.region = [];
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole.role === Roles.ZONE_MANAGER) {
      filters.area = [];
      filters.region = [];
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole.role === Roles.REGION_MANAGER) {
      filters.area = [];
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole.role === Roles.AREA_MANAGER) {
      filters.brick = [];
      filters.headquarter = [];
    } else if (userRole.role === Roles.HEADQUATER_MANAGER) {
      filters.brick = [];
    }
    filterStore.q = "";
    filterStore.customer_type_id = "";
    filterStore.department = "";
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    let payload = {
      zone_id: filters.zone,
      region_id: filters.zone,
      area_id: filters.area,
      headquarter_id: filters.headquarter,
      department_id: "",
    };
    fetchProductWiseData(month, year, payload);
    fetchInvoiceData(month, year, payload);
    fetchStockistWiseData(month, year, payload);
  };
  return (
    <>
      <Stack gap={2}>
        <Header title="Primary Sales" />
        {Object.keys(userDetails).length > 0 ? (
          <ReportFiltersComponent
            type={ReportScreen.PRIMARY_SALES_INVOICE}
            successCallback={filterData}
            clearSuccessCallBack={clearData}
            userRole={userRole.role}
            userDetails={userDetails}
            gridColumns={22}
            territoryMultiSelect={false}
          />
        ) : (
          <ReportFiltersLoading />
        )}

        <PrimarySalesInvoiceTabs />
      </Stack>
    </>
  );
}
