import { create } from "zustand";
import { useFetchDashboardDataSlice } from "../slices/DataDashboardReportSlices/getDashboardLink";

interface UserDashboardLinkInterface
  extends ReturnType<typeof useFetchDashboardDataSlice> {}

export const DashboardLinkStore = create<UserDashboardLinkInterface>(
  (...a) => ({
    ...useFetchDashboardDataSlice(...a),
  })
);
