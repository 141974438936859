import { create } from "zustand";
import { useFetchUserChildrenSlice } from "../slices/UserSummaryReportSlices/fetchUserChildrenSlice";
import { useFetchDepartmentSlice } from "../slices/fetchDepartment";
import { useFetchDeviationReportSlice } from "../slices/UserSummaryReportSlices/fetchDeviationReportSlice";
import { useFetchMonthSummaryReportSlice } from "../slices/UserSummaryReportSlices/fetchMonthSummaryReportSlice";
import { useFetchSummaryReportSlice } from "../slices/UserSummaryReportSlices/fetchSummaryReportSlice";
import { useFetchMonthsReportSlice } from "../slices/UserSummaryReportSlices/fetchMonthsReportSlices";

interface UserSummaryReportInterface
  extends ReturnType<typeof useFetchUserChildrenSlice>,
    ReturnType<typeof useFetchDepartmentSlice>,
    ReturnType<typeof useFetchDeviationReportSlice>,
    ReturnType<typeof useFetchMonthSummaryReportSlice>,
    ReturnType<typeof useFetchSummaryReportSlice>,
    ReturnType<typeof useFetchMonthsReportSlice> {}

export const UserSummaryReportStore = create<UserSummaryReportInterface>(
  (...a) => ({
    ...useFetchUserChildrenSlice(...a),
    ...useFetchDepartmentSlice(...a),
    ...useFetchDeviationReportSlice(...a),
    ...useFetchMonthSummaryReportSlice(...a),
    ...useFetchSummaryReportSlice(...a),
    ...useFetchMonthsReportSlice(...a),
  })
);
