/* eslint-disable eqeqeq */
import { Card, Grid, Stack, Typography } from "@mui/material";
import { FetchState } from "../../../../domain/models/fetch-state";
import { UserSummaryReportStore } from "../../../../store/main/UserSummaryReportStore";
import CustomerCountLoading from "../../desktop/user-summary-report/sub-components/loading/CustomerCountLoading";
import { CustomerCountDataType } from "../../../../domain/models/user-summary-report/common-modals";

const CustomerCountMobile = () => {
  const {
    deviationReportData,
    monthSummaryReportData,
    monthSummaryState,
    fetchDeviationReportState,
    monthsReportData,
  } = UserSummaryReportStore();

  let data: CustomerCountDataType[] = [];
  const totalFieldWoking = monthSummaryReportData?.attendances?.find(
    (item: any) => item.work_type_id === 2
  )?.att_count;
  const calculateOrder: () => CustomerCountDataType[] = () => {
    data = [
      {
        name: "Stockist",
        callCount: 0,
        coverageCount: 0,
      },
      {
        name: "Semi-Stockist",
        callCount: 0,
        coverageCount: 0,
      },
      {
        name: "Retailer",
        callCount: 0,
        coverageCount: 0,
      },
      {
        name: "Control Chemist",
        callCount: 0,
        coverageCount: 0,
      },
      {
        name: "Healthcare Provider",
        callCount: 0,
        coverageCount: 0,
      },
    ];
    if (deviationReportData?.visits) {
      for (const visits of deviationReportData.visits) {
        const index = visits.customer_type_id - 1;
        if (index >= 0 && index < data.length) {
          data[index].callCount += visits.visit_count;
        }
      }
    }
    if (monthSummaryReportData?.all_visits) {
      for (const visits of monthSummaryReportData.all_visits) {
        const index = visits.customer_type_id - 1;
        if (index >= 0 && index < data.length) {
          data[index].coverageCount += visits.customer_count;
        }
      }
    }
    return data;
  };

  const countSummary = calculateOrder();

  return (
    <>
      {monthSummaryState == FetchState.SUCCESS &&
        fetchDeviationReportState == FetchState.SUCCESS && (
          <Grid container>
            {countSummary.map((data: CustomerCountDataType) => {
              return (
                <>
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        padding: "10px",
                        backgroundColor: "#fafafa",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack>
                          <Typography variant="body1">{data.name}</Typography>
                          <Typography variant="subtitle2">
                            Call Average{" "}
                            {typeof totalFieldWoking !== "undefined" &&
                            totalFieldWoking !== 0
                              ? (data.callCount / totalFieldWoking).toFixed(1)
                              : 0}
                          </Typography>
                          <Typography variant="subtitle2">
                            Coverage{" "}
                            {monthsReportData.customer_total &&
                            data.coverageCount
                              ? (
                                  (data.coverageCount /
                                    monthsReportData.customer_total) *
                                  100
                                ).toFixed(2)
                              : "0"}
                          </Typography>
                        </Stack>
                        <Typography
                          color="primary.main"
                          fontWeight={600}
                          variant="h5"
                          marginY={"auto"}
                        >
                          {data.callCount}
                        </Typography>
                      </Stack>
                    </Card>
                  </Grid>
                </>
              );
            })}
          </Grid>
        )}
      {(monthSummaryState == FetchState.LOADING ||
        fetchDeviationReportState == FetchState.LOADING) && (
        <CustomerCountLoading />
      )}
      {monthSummaryState == FetchState.ERROR &&
        fetchDeviationReportState == FetchState.ERROR && (
          <Grid container>
            {countSummary.map((data: CustomerCountDataType) => {
              return (
                <>
                  <Grid item xs={6}>
                    <Card
                      sx={{
                        padding: "10px",
                        backgroundColor: "#fafafa",
                        margin: "10px",
                        flexGrow: 1,
                      }}
                    >
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack>
                          <Typography variant="body1">{data.name}</Typography>
                          <Typography variant="subtitle2">
                            Call Average -
                          </Typography>
                          <Typography variant="subtitle2">
                            Coverage -
                          </Typography>
                        </Stack>
                        <Typography
                          color="primary.main"
                          fontWeight={600}
                          variant="h5"
                          marginY={"auto"}
                        >
                          -
                        </Typography>
                      </Stack>
                    </Card>
                  </Grid>
                </>
              );
            })}
          </Grid>
        )}
    </>
  );
};

export default CustomerCountMobile;
