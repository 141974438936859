import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { FetchState } from "../../../domain/models/fetch-state";
import { RemoteFetchInputUsageSummary } from "../../../data/usecases/input-utilization/remote-fetch-input-usage-summary";
import { FetchInputUsageSummary } from "../../../domain/useages/input-utilization/fetch-input-usage-summary";
import Endpoints from "../../../domain/endpoints";
import moment from "moment";
import Swal from "sweetalert2";
import { RemoteFetchInputsBasedOnProductType } from "../../../data/usecases/input-utilization/remote-fetch-input-based-on-product-type";
import { FetchInputsBasedOnProductType } from "../../../domain/useages/input-utilization/fetch-inputs-based-on-product-type";
import { InputUBasedOnProductType } from "../../../domain/models/input-utilization/input-based-on-product-type";

export interface FetchInputsBasedOnProductTypeInterface {
  fetchInputBasedOnProductType: Function;
  inputBasedOnProductTypLoading: FetchState;
  inputBasedOnProductTypeFilters: {
    inputType: string;
  };
  inputBasedOnProductTypeData: InputUBasedOnProductType[];
}

const initialStates = {
  inputBasedOnProductTypLoading: FetchState.DEFAULT,
  inputBasedOnProductTypeFilters: {
    inputType: "",
  },
  inputBasedOnProductTypeData: [],
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchInputsBasedOnProductType: StateCreator<
  FetchInputsBasedOnProductTypeInterface
> = (set, get) => ({
  ...initialStates,
  fetchInputBasedOnProductType: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    set(() => ({ inputBasedOnProductTypLoading: FetchState.LOADING }));
    const remoteFetchInputsBasedOnProductType: FetchInputsBasedOnProductType =
      new RemoteFetchInputsBasedOnProductType(
        `${BASE_URL}${Endpoints.FETCH_INPUTS_BASED_ON_PRODUCT_TYPE}`,

        axiosHttpClient
      );
    let params = {
      input_type: get().inputBasedOnProductTypeFilters.inputType,
    };
    let result = await remoteFetchInputsBasedOnProductType.fetch(params);

    if (result.success) {
      set(() => ({ inputBasedOnProductTypeData: result.inputs }));
      set(() => ({ inputBasedOnProductTypLoading: FetchState.SUCCESS }));
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: "Error Occured While Fetching Input based on product type",
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },
});
