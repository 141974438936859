import { create } from "zustand";
import { useFetchUnmappedStockistSlice } from "../slices/UnmappedStockistSlices/fetchUnmappedStockistSlices";
import { useFetchbrickSlice } from "../slices/UnmappedStockistSlices/fetchBrickSlice";
import { useFetchsearchHeadquarterSlice } from "../slices/UnmappedStockistSlices/fetchSearchHeadquarterSlice";
import { usemapStockistSlice } from "../slices/UnmappedStockistSlices/mapStockist";

interface UnmappedStockistInterface
  extends ReturnType<typeof useFetchUnmappedStockistSlice>,
    ReturnType<typeof useFetchbrickSlice>,
    ReturnType<typeof useFetchsearchHeadquarterSlice>,
    ReturnType<typeof usemapStockistSlice> {}

export const UnmappedStockistStore = create<UnmappedStockistInterface>(
  (...a) => ({
    ...useFetchUnmappedStockistSlice(...a),
    ...useFetchbrickSlice(...a),
    ...useFetchsearchHeadquarterSlice(...a),
    ...usemapStockistSlice(...a),
  })
);
