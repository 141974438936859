import { StateCreator } from "zustand";
import { HQSummaryData } from "../../../domain/models/hq-summary-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteFetchHQSummary } from "../../../data/usecases/remote-fetch-hq-summary";
import Endpoints from "../../../domain/endpoints";
import { FetchHQSummary } from "../../../domain/useages/fetch-hq-summary";

export interface FetchHQSummaryInterface {
  hqSummaryDate: HQSummaryData[];
  fetchHqSummaryData: Function;
}

const initialStates = {
  hqSummaryDate: [],
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchHQSummarySlice: StateCreator<FetchHQSummaryInterface> = (
  set,
  get
) => ({
  ...initialStates,
  fetchHqSummaryData: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchHQSummary: FetchHQSummary = new RemoteFetchHQSummary(
      `${BASE_URL}${Endpoints.HQ_SUMMARY}`,
      axiosHttpClient
    );

    let result = await remoteFetchHQSummary.fetch();
    if (result.success) {
      set(() => ({ hqSummaryDate: result.hqSummary }));
    }
  },
});
