enum Roles {
  COUTNRY_MANAGER = "COUNTRY_MNG",
  ZONE_MANAGER = "ZONE_MNG",
  REGION_MANAGER = "REGION_MNG",
  AREA_MANAGER = "AREA_MNG",
  HEADQUATER_MANAGER = "HQ_MNG",
  AUDIT_TEAM = "AUDIT_TEAM",
}

export default Roles;
