import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { RemoteUpdateCustomerMapping } from "../../../data/usecases/customer-database/remote-update-customer-mapping";
import { UpdateCustomerMapping } from "../../../domain/useages/customer-database/update-customer-mapping";
import { CustomerDatabaseStore } from "../../main/CustomerDatabaseStore";

export interface UpdateCustomerMappingInterface {
  updateCustomerMapping: Function;
  handleTransferLoading: Function;
}

const initialStates = {};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useUpdateCustomerMappingSlice: StateCreator<
  UpdateCustomerMappingInterface
> = (set, get) => ({
  ...initialStates,
  handleTransferLoading: (value: boolean) => {
    if (value) {
      Swal.fire({
        didOpen: () => {
          if (value) {
            Swal.showLoading();
          }
        },

        title: "Please wait... Customer transfer request is sending ",
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },

  updateCustomerMapping: async (
    payload: UpdateCustomerMapping.Params,
    fetchCustomerData: Function,
    handleCloseModal: Function,
    fetchCustomerSummaryCount: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteUpdateCustomerMapping: UpdateCustomerMapping =
      new RemoteUpdateCustomerMapping(
        `${BASE_URL}${Endpoints.UPDATE_CUSTOMER_MAPPINGS}`,

        axiosHttpClient
      );
    get().handleTransferLoading(true);
    let response = await remoteUpdateCustomerMapping.update(payload);

    if (response.success) {
      get().handleTransferLoading(false);
      Swal.fire({
        icon: "success",
        title: response.message,
        timer: 2000,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: "#3085d6",
      });

      handleCloseModal();
      fetchCustomerSummaryCount(CustomerDatabaseStore.getState().filterStore);
      fetchCustomerData();
    } else {
      get().handleTransferLoading(false);
      Swal.fire({
        icon: "error",
        timer: 3000,
        title: "Customer Transfer failed",
        text: response.errors.message,
      });
    }
  },
});
