import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { FetchState } from "../../../domain/models/fetch-state";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { BrickType } from "../../../domain/models/unmapped-stockist/unmapped-stockist-data";
import { RemoteFetchBrick } from "../../../data/usecases/unmapped-stockist/remote-fetch-brick";

export interface FetchBrickInterface {
  brick: BrickType[];
  brickState: FetchState;
  fetchBrick: Function;
  clearBrick: Function;
}

const initialStates = {
  brick: [],
  brickState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchbrickSlice: StateCreator<FetchBrickInterface> = (set) => ({
  ...initialStates,
  fetchBrick: async (id: number) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remotebrick = new RemoteFetchBrick(
      `${BASE_URL}${Endpoints.FETCH_BRICK}`,
      axiosHttpClient
    );

    set(() => ({ brickState: FetchState.LOADING }));
    let result = await remotebrick.fetch(id);
    if (result) {
      set(() => ({
        brick: result.bricks,
        brickState: FetchState.SUCCESS,
      }));
    }
  },
  clearBrick: async () => {
    set(() => ({ brick: [] }));
  },
});
