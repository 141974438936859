import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { RemoteDownloadExcel } from "../../data/usecases/remote-download-excel";
import Endpoints from "../../domain/endpoints";
import { DownloadExcel } from "../../domain/useages/download-excel";
import { customerDatabaseFilters } from "../../domain/models/customer-database/customer-database-filters";

export interface DownloadExcelInterface {
  downloadExcel: Function;
  downloadingExcel: Boolean;
}

const initialStates = {
  downloadingExcel: false,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useDownloadExcelSlice: StateCreator<DownloadExcelInterface> = (
  set
) => ({
  ...initialStates,
  downloadExcel: async (filterStore: customerDatabaseFilters) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteDownloadExcel: DownloadExcel = new RemoteDownloadExcel(
      `${BASE_URL}${Endpoints.FETCH_CUSTOMERS}`,
      axiosHttpClient
    );

    try {
      let response = await remoteDownloadExcel.download(filterStore);

      if (response) {
        set(() => ({ downloadingExcel: false }));
      }
      const pdfBlob = new Blob([response], { type: "application/text" });
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Customer_Summary.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error(error);
    }
  },
});
