import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { RemoteFetchBrands } from "../../data/usecases/remote-fetch-brands";
import Endpoints from "../../domain/endpoints";
import { Speciality } from "../../domain/models/speciality";

export interface BrandDataInterface {
  fetchBrandsData: Function;
  brandData: Speciality[];
}

const initialStates = {
  brandData: [],
};

export const useFetchBrandSlice: StateCreator<BrandDataInterface> = (
  set,
) => ({
  ...initialStates,
  fetchBrandsData: async () => {
    const axiosHttpClient = AxiosHttpClient.getInstance();
    const storage = LocalJsonStorage.getInstance();

    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchBrandsMaster = new RemoteFetchBrands(
      `${BASE_URL}${Endpoints.FETCH_BRANDS}`,
      axiosHttpClient
    );

    let result = await remoteFetchBrandsMaster.fetch();
    if (result) {
      set(() => ({ brandData: result.brands }));
    }
  },
});
