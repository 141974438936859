import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { FetchSummaryReport } from "../../../domain/useages/user-summary-report/fetch-summary-report";
import { RemoteFetchSummaryReport } from "../../../data/usecases/user-summary-report/remote-fetch-summary-report";
import Endpoints from "../../../domain/endpoints";

export interface FetchSummaryReportInterface {
  fetchSummaryReport: Function;
  summaryReportData: any;
}

const initialStates = {
  summaryReportData: {},
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchSummaryReportSlice: StateCreator<
  FetchSummaryReportInterface
> = (set, get) => ({
  ...initialStates,
  fetchSummaryReport: async (
    month: number,
    year: number,
    user_id: number,
    department_id: number
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);

    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteFetchSummaryReport: FetchSummaryReport =
      new RemoteFetchSummaryReport(
        `${BASE_URL}${Endpoints.FETCH_USER_CHILDREN}`,
        axiosHttpClient
      );
    let result = await remoteFetchSummaryReport.fetch(
      month,
      year,
      user_id,
      department_id
    );
  },
});
