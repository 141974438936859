import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ReportFiltersComponent from "../ReportFiltersComponent";
import Box from "@mui/material/Box";
import ReportFiltersLoading from "../ReportFiltersLoading";
import StandardTourProgramTabs from "./StandardTourProgramTabs";
import dayjs from "dayjs";
import ReportScreen from "../../../../common/enums/ReportScreens";
import Roles from "../../../../common/enums/Roles";
import { FetchState } from "../../../../domain/models/fetch-state";
import { ReportFilters } from "../../../../domain/models/report-filters";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { StandardProgramTourStore } from "../../../../store/main/StandardTourProgramStore";
import PivotTable from "../../PivotTable";

const StandardTourProgramPivot = () => {
  const {
    brickWiseData,
    fetchBrickWiseData,
    brickWiseDataLoading,
    brickWiseFilters,
  } = StandardProgramTourStore();

  const { decodeToken, userDetails, userRole } = CustomerDatabaseStore();

  const getZoneWiseData = (filters: ReportFilters) => {
    brickWiseFilters.monthYear = filters.date;
    fetchBrickWiseData();
  };

  const clearBrickWiseData = (filters: ReportFilters) => {
    brickWiseFilters.monthYear = dayjs(new Date());
    fetchBrickWiseData();
  };

  interface PanelProps {
    children?: React.ReactNode;
    index?: number;
    value?: number;
  }

  useEffect(() => {
    if (
      userRole.role &&
      userRole.role != Roles.COUTNRY_MANAGER &&
      userRole.role != Roles.ZONE_MANAGER
    ) {
      fetchBrickWiseData();
    }
  }, [userRole.role]);

  useEffect(() => {
    decodeToken();
  }, []);

  function Panel(props: PanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && (
          <Box sx={{ p: 3, mt: 2 }} bgcolor={"#f9f9f9"}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const formattedData: any = {
    data: [],
    rows: [
      {
        uniqueName: "area",
      },
      {
        uniqueName: "headquarter",
      },
      {
        uniqueName: "brick",
      },
    ],
    formats: [
      {
        name: "separator",
        thousandsSeparator: ",",
      },
    ],
    columns: [
      {
        uniqueName: "customer_type",
        caption: "Total Active Per Customer Type",
        format: "separator",
      },
      { uniqueName: "Measures" },
    ],
    measures: [
      {
        uniqueName: "total_active",
        caption: "Total",
        formula: "sum('total_active')",
        format: "separator",
      },
    ],
    options: {
      grid: {
        showHeaders: false,
        showHierarchyCaptions: true,
      },
      showCalculatedValuesButton: true,
      sorting: "on",
      datePattern: "dd/MM/yyyy",
      dateTimePattern: "dd/MM/yyyy HH:mm:ss",
      defaultHierarchySortName: "asc",
    },
  };

  useEffect(() => {
    if (brickWiseData.length > 0) {
      brickWiseData.map((val, key) => {
        var rowData = {
          area: val.area,
          headquarter: val.headquarter,
          brick: val.brick,
          customer_type: val.customer_type,
          total_active: val.total_active,
          total_unmapped: val.total_unmapped,
        };
        formattedData.data.push(rowData);
      });
    }
  }, [brickWiseData]);

  const customizeToolbar = (toolbar: any) => {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      userDetails &&
        userDetails.role &&
        userDetails.role.visibility == 0 &&
        delete tabs[3];

      return tabs;
    };
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2}>
        {Object.keys(userDetails).length > 0 ? (
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {(userRole.role == Roles.COUTNRY_MANAGER ||
              userRole.role == Roles.ZONE_MANAGER) && (
              <StandardTourProgramTabs userDetails={userDetails} />
            )}
            <Stack marginRight={10} width={"100%"}>
              <ReportFiltersComponent
                type={ReportScreen.STANDARD_TOUR_PROGRAM}
                successCallback={getZoneWiseData}
                clearSuccessCallBack={clearBrickWiseData}
                userRole={userRole.role}
                gridColumns={10}
                userDetails={userDetails}
                justifyContent={"end"}
              />
            </Stack>
          </Stack>
        ) : (
          <ReportFiltersLoading />
        )}
        {brickWiseDataLoading == FetchState.LOADING && (
          <Stack alignItems={"center"} height={100} justifyContent={"center"}>
            <CircularProgress />
          </Stack>
        )}
        {brickWiseDataLoading == FetchState.SUCCESS &&
          (brickWiseData.length > 0 ? (
            <PivotTable
              formattedData={formattedData}
              toolbar={true}
              customizeToolbar={customizeToolbar}
            />
          ) : (
            <Typography variant="h6">No Records Found</Typography>
          ))}
      </Stack>
    </Box>
  );
};

export default StandardTourProgramPivot;
