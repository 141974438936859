import moment from "moment";
import {
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { InputUsageSummaryStore } from "../../../../store/main/InputUsageReportStore";
import { CustomerWiseInputReportDataType } from "../../../../domain/models/input-utilization/customerwise-input-report-type";
import TableComponent from "../../TableComponent";
import ReportFiltersComponent from "../ReportFiltersComponent";
import ReportScreen from "../../../../common/enums/ReportScreens";
import Roles from "../../../../common/enums/Roles";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ReportFilters } from "../../../../domain/models/report-filters";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import { Dayjs } from "dayjs";
import Pagination from "../../Pagination";
import { FetchState } from "../../../../domain/models/fetch-state";

interface Column {
  id:
    | "batch_no"
    | "sr_no"
    | "customer_name"
    | "unique_hcp_id"
    | "product_code"
    | "territory_code"
    | "distribution_date"
    | "expiry_date"
    | "address"
    | "landmark"
    | "pincode"
    | "input_name"
    | "usage_months"
    | "distributed"
    | "zone"
    | "region"
    | "area"
    | "headquarter"
    | "user_name"
    | "emp_code"
    | "units";
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: "center" | "right";
  format?: (value: number) => string;
}

export const CustomerWiseInputReportTable = () => {
  const {
    customerWiseInputReportData,
    customerWiseDataFilters,
    fetchCustomerWiseInputReport,
    total_data,
    fetchCustomerWiseDataState,
  } = InputUsageSummaryStore();
  const { userDetails, downloadExcelTable, userRole } = CustomerDatabaseStore();
  const [roleName, setRoleName] = useState<string>("HQ Code");

  useEffect(() => {
    if (customerWiseDataFilters?.role_id == "3") {
      setRoleName("HQ Code");
    } else if (customerWiseDataFilters?.role_id == "4") {
      setRoleName("Area Code");
    }
  }, [customerWiseDataFilters]);

  const column: Column[] = [
    { id: "sr_no", label: "Sr. No.", align: "center", maxWidth: 100 },
    { id: "zone", label: "Zone", align: "center", maxWidth: 100 },
    { id: "region", label: "Region", align: "center", maxWidth: 100 },
    { id: "area", label: "Area", align: "center", maxWidth: 100 },
    { id: "headquarter", label: "Headquarter", align: "center", maxWidth: 100 },
    {
      id: "territory_code",
      label: roleName ? roleName : "HQ Code",
      align: "center",
      maxWidth: 100,
    },
    { id: "user_name", label: "Emp Name", align: "center", maxWidth: 100 },
    { id: "emp_code", label: "Emp Code", align: "center", maxWidth: 100 },
    {
      id: "product_code",
      label: "Product Code",
      align: "center",
      minWidth: 100,
    },
    { id: "input_name", label: "Product Name", align: "center", minWidth: 100 },
    { id: "batch_no", label: "Batch no", align: "center", maxWidth: 100 },
    {
      id: "expiry_date",
      label: "Expiry date",
      align: "center",
      minWidth: 90,
    },
    {
      id: "distribution_date",
      label: "Date of distribution",
      align: "center",
      minWidth: 90,
    },
    {
      id: "units",
      label: "Units",
      align: "center",
      maxWidth: 100,
    },
    { id: "unique_hcp_id", label: "HCP ID", maxWidth: 100 },
    { id: "customer_name", label: "Name of HCP", maxWidth: 100 },
    {
      id: "address",
      label: "Line",
      align: "center",
      maxWidth: 100,
    },
    { id: "landmark", label: "Landmark", align: "center", maxWidth: 100 },
    { id: "pincode", label: "Pincode", align: "center", maxWidth: 100 },
  ];
  const createData = (row: CustomerWiseInputReportDataType[]) => {
    let temp: any = [];
    for (let i = 0; i < row.length; i++) {
      if (row[i].user_name) {
        temp.push({
          sr_no: i + 1,
          batch_no: row[i].batch_no.length > 0 ? row[i].batch_no : "-",
          customer_name: row[i].customer_name ? row[i].customer_name : "-",
          distribution_date: row[i].distribution_date
            ? moment(
                row[i].distribution_date as string,
                "YYYY/M/D" || "D/M/YYYY" || "YYYY-MM-DD" || "YYYY-MM-DD"
              ).format("ll")
            : "-",
          expiry_date: row[i].expiry_date
            ? moment(
                row[i].expiry_date as string,
                "YYYY/M/D" || "D/M/YYYY" || "YYYY-MM-DD" || "YYYY-MM-DD"
              ).format("ll")
            : "-",
          address: row[i].address ? row[i].address : "-",
          landmark: row[i].landmark ? row[i].landmark : "-",
          pincode: row[i].pincode ? row[i].pincode : "-",
          input_name: row[i].input_name ? row[i].input_name : "-",
          usage_months: row[i].usage_months ? row[i].usage_months : "-",
          distributed: row[i].distributed ? row[i].distributed : 0,
          zone: row[i].zone ? row[i].zone : "-",
          region: row[i].region ? row[i].region : "-",
          area: row[i].area ? row[i].area : "-",
          headquarter: row[i].headquarter ? row[i].headquarter : "-",
          user_name: row[i].user_name,
          emp_code: row[i].emp_code ? row[i].emp_code : "-",
          units: row[i].distributed ? row[i].distributed : "-",
          unique_hcp_id: row[i].customer_id ? row[i].customer_id : "-",
          product_code: row[i].input_code ? row[i].input_code : "-",
          territory_code: row[i].headquarter_code
            ? row[i].headquarter_code
            : row[i].area_code,
        });
      }
    }
    return temp;
  };
  const successCallback = (values: ReportFilters) => {
    customerWiseDataFilters.role_id = values.role;
    customerWiseDataFilters.input_ids = values.inputProducts;
    customerWiseDataFilters.month_years = [
      moment((values.date as Dayjs)?.toString()).format("YYYYMM"),
    ];
    fetchCustomerWiseInputReport();
  };
  const handleChangePage = (page: number, length: number) => {
    customerWiseDataFilters.page = page;
    customerWiseDataFilters.length = length;
    fetchCustomerWiseInputReport();
  };
  const downloadFile = () => {
    if (createData(customerWiseInputReportData).length !== 0)
      downloadExcelTable(createData(customerWiseInputReportData));
  };
  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"} mb={4}>
        <Stack direction={"row"} alignItems={"center"} gap={3}>
          {userDetails &&
            userDetails.role &&
            userDetails.role.visibility == 1 && (
              <Tooltip title="This will only download the current Records Visible on this table">
                <Button
                  variant="contained"
                  startIcon={<FileDownloadOutlinedIcon />}
                  sx={{ borderRadius: "10px", width: "180px", height: "40px" }}
                  onClick={downloadFile}
                  color="primary"
                >
                  <Typography variant="subtitle2" textTransform={"none"}>
                    Download Excel
                  </Typography>
                </Button>
              </Tooltip>
            )}
          <ReportFiltersComponent
            clearSuccessCallBack={() => {}}
            type={ReportScreen.INPUT_UTILIZATION_CUSTOMER_WISE_SUMMARY}
            successCallback={successCallback}
            gridColumns={12.5}
            userRole={Roles.COUTNRY_MANAGER}
            userDetails={userDetails}
          />
        </Stack>
        {customerWiseInputReportData &&
          customerWiseInputReportData.length > 0 && (
            <Pagination
              totalPages={Math.ceil(total_data)}
              successCallback={handleChangePage}
              currenPage={customerWiseDataFilters.page}
              rowsPerPage={customerWiseDataFilters.length}
              rowsPerPageOptions={[10, 50, 60, 70, 80, 90, 100]}
            />
          )}
      </Stack>
      {fetchCustomerWiseDataState === FetchState.SUCCESS ? (
        (customerWiseInputReportData &&
          customerWiseInputReportData.length === 0) ||
        createData(customerWiseInputReportData).length === 0 ? (
          <Typography variant="h6" fontWeight={600}>
            Input utilization not found for the given filters
          </Typography>
        ) : (
          <TableComponent
            rows={createData(customerWiseInputReportData)}
            columns={column}
            isPagination={true}
            height="60vh"
          />
        )
      ) : (
        <Stack
          mt={4}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={3}
        >
          <CircularProgress />
        </Stack>
      )}
    </>
  );
};
