import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { PrimarySalesInvoiceStore } from "../../../../store/main/PrimarySalesInvoiceStore";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import moment from "moment";
import Roles from "../../../../common/enums/Roles";

const Columns = [
  { name: "Invoice No.", id: 1 },
  { name: "Invoice Date", id: 3 },
  { name: "Stockist Code", id: 7 },
  { name: "Stockist (Grade)", id: 4 },
  { name: "Brick", id: 8 },
  { name: "District", id: 5 },
  { name: "Amount", id: 9 },
  { name: "Details", id: 10 },
];
type props = {
  setShowDetails: Function;
  setIndex: Function;
};

const InvoiceTable: React.FC<props> = ({ setShowDetails, setIndex }) => {
  const downloadFile = () => {
    let temp: any[] = [];
    invoiceData.map((val) => {
      let downloadData = {
        Invoice_No: val.doc_no,
        Invoice_Date: val.doc_date ? val.doc_date : val.doc_date_value,
        Stockist: val.customer.firm_name,
        Stockist_Code: val.stockist_code,
        Brick: val.customer.hq_brick.name,
        District: val.customer.hq_brick.district,
        Amount: val.net_amt ? val.net_amt : val.gross_amt,
      };
      temp.push(downloadData);
    });

    downloadExcelTable(temp);
  };
  const {
    invoiceData,
    dataLengthInvoice,
    handlePageNumber,
    fetchInvoiceData,
    currentInvoicePageNumber,
  } = PrimarySalesInvoiceStore();
  const { downloadExcelTable, filterStore, userDetails } =
    CustomerDatabaseStore();

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    handlePageNumber(value);
    let payload = {
      zone_id: filterStore.zone_ids,
      region_id: filterStore.region_ids,
      area_id: filterStore.area_ids,
      headquarter_id: filterStore.headquarter_ids,
      department_id: "",
      page: value,
    };

    let month = moment(filterStore.date?.toString()).format("MM");
    let year = moment(filterStore.date?.toString()).format("yy");
    fetchInvoiceData(month, year, payload);
  };
  return (
    <Stack spacing={2}>
      {userDetails && userDetails.role && userDetails.role.visibility == 1 && (
        <Button
          variant="contained"
          startIcon={<FileDownloadOutlinedIcon />}
          sx={{ borderRadius: "10px", width: "180px", height: "40px" }}
          onClick={downloadFile}
          color="primary"
        >
          <Typography variant="subtitle2" textTransform={"none"}>
            Download Excel
          </Typography>
        </Button>
      )}
      <TableContainer component={Paper}>
        <Box sx={{ overflow: "scroll", height: "60vh" }}>
          <Table size="small" padding="normal" stickyHeader>
            <TableHead>
              <TableRow>
                {Columns.map((column: any) => {
                  return (
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#E9EDF5",
                        color: "#687182",
                      }}
                    >
                      {column.name}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData.length > 0 &&
                invoiceData.map((val, key) => {
                  return (
                    <TableRow>
                      <TableCell align="center" sx={{ paddingY: 2 }}>
                        <Typography
                          fontWeight={500}
                          color={"#687182"}
                          variant="caption"
                        >
                          {val.doc_no}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          fontWeight={550}
                          color={"#687182"}
                          variant="caption"
                        >
                          {val.doc_date ? val.doc_date : val.doc_date_value}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          fontWeight={550}
                          color={"#687182"}
                          variant="caption"
                        >
                          {val.stockist_code}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          fontWeight={550}
                          color={"#687182"}
                          variant="caption"
                        >
                          {val.customer.firm_name}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          fontWeight={550}
                          color={"#687182"}
                          variant="caption"
                        >
                          {val.customer.hq_brick.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          fontWeight={550}
                          color={"#687182"}
                          variant="caption"
                        >
                          {val.customer.hq_brick.district}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          fontWeight={550}
                          color={"#687182"}
                          variant="caption"
                        >
                          {val.net_amt ? val.net_amt : val.gross_amt}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setIndex(key);
                            setShowDetails(true);
                          }}
                        >
                          <Typography
                            fontWeight={550}
                            color={"#687182"}
                            variant="caption"
                          >
                            Show Details
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <Stack alignItems={"center"}>
        <Pagination
          count={Math.ceil(dataLengthInvoice / 50)}
          variant="outlined"
          page={currentInvoicePageNumber}
          onChange={handlePageChange}
        />
      </Stack>
    </Stack>
  );
};

export default InvoiceTable;
