import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { CustomerMissingStore } from "../../../../store/main/CustomerMissingReportStore";
import { useEffect } from "react";
import CustomerMissingTable from "./CustomerMissingTable";
import { TerritoryDataStore } from "../../../../store/main/TerritoryDataStore";
import React from "react";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import ReportFiltersComponent from "../ReportFiltersComponent";
import ReportScreen from "../../../../common/enums/ReportScreens";
import { ReportFilters } from "../../../../domain/models/report-filters";
import dayjs from "dayjs";
import ZoneTabsLoading from "../ZoneTabsLoading";
import Roles from "../../../../common/enums/Roles";

const CustomerMissingReportDesktop = () => {
  const { fetchCustomerMissingData, customerMissingFilter } =
    CustomerMissingStore();
  const { userDetails, userRole } = CustomerDatabaseStore();
  const { zoneMasterData, fetchZoneMaster } = TerritoryDataStore();
  const [zone, setZone] = React.useState(0);

  const handleZoneChange = (event: React.SyntheticEvent, newValue: number) => {
    setZone(newValue);
    const filteredZone = zoneMasterData.filter((item) => item.id === newValue);
    customerMissingFilter.zones.length = 0;
    customerMissingFilter.zones.push(filteredZone[0].name);
    fetchCustomerMissingData();
  };

  const filterData = (filter: ReportFilters) => {
    customerMissingFilter.month_year = filter.date;
    fetchCustomerMissingData();
  };
  const clearData = (filter: ReportFilters) => {
    customerMissingFilter.month_year = dayjs(new Date());
    fetchCustomerMissingData();
  };

  const handleZone = () => {
    if (userRole.role == Roles.COUTNRY_MANAGER) {
      setZone(zoneMasterData[0].id);
      customerMissingFilter.zones.length = 0;
      customerMissingFilter.zones.push(zoneMasterData[0].name);
    } else {
      const filteredZone = zoneMasterData.filter(
        (item) => item.id === userDetails.hq_zone_id
      );
      setZone(filteredZone[0].id);
      customerMissingFilter.zones.push(filteredZone[0].name);
    }
    fetchCustomerMissingData();
  };

  useEffect(() => {
    if (zoneMasterData.length > 0) {
      handleZone();
    }
  }, [zoneMasterData]);
  useEffect(() => {
    fetchZoneMaster();
  }, []);
  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h6">Customer Missing</Typography>
        <Stack>
          {Object.keys(userDetails).length > 0 && (
            <ReportFiltersComponent
              type={ReportScreen.CUSTOMER_MISSING}
              successCallback={filterData}
              clearSuccessCallBack={clearData}
              gridColumns={7}
              userRole={userRole.role}
              userDetails={userDetails}
              territoryMultiSelect={false}
            />
          )}
        </Stack>
      </Stack>
      <Box paddingX={2}>
        {zoneMasterData.length > 0 ? (
          <Tabs
            value={zone ? zone : zoneMasterData[0].id}
            onChange={handleZoneChange}
            aria-label="basic tabs example"
          >
            {zoneMasterData.map((zone) => {
              return <Tab key={zone.name} label={zone.name} value={zone.id} />;
            })}
          </Tabs>
        ) : (
          <ZoneTabsLoading />
        )}
      </Box>
      <CustomerMissingTable />
    </>
  );
};
export default CustomerMissingReportDesktop;
