import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { RemoteFetchSpecialitiesMaster } from "../../../data/usecases/coverage-reports/remote-fetch-specialities";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { FetchState } from "../../../domain/models/fetch-state";
import { FetchMonthlySpecialitiesSummary } from "../../../domain/useages/coverage-reports/fetch-monthly-speciality-summary";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { MonthlyHQSpecialitySummary } from "../../../domain/models/coverage-reports/monthly-HQ-speciality-summary";

export interface FetchMonthlyHQSpecialitiesSummaryInterface {
  monthlySpecialitySummaryData: MonthlyHQSpecialitySummary[];
  fetchMonthlySpecialitySummary: Function;
  clearSpecialitySummary: Function;
  specialitySummaryFilters: {
    monthYear: Dayjs | null;
    visitorRole: string;
    customerType_id: number;
    specialities?: string[];
  };
  specialitySummaryStatus: FetchState;
}

const initialStates = {
  monthlySpecialitySummaryData: [],
  specialitySummaryStatus: FetchState.DEFAULT,
  specialitySummaryFilters: {
    monthYear: dayjs(new Date()),
    visitorRole: "HQ_MNG",
    customerType_id: 5,
  },
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchMonthlyHQSpecialitiesSummarySlice: StateCreator<
  FetchMonthlyHQSpecialitiesSummaryInterface
> = (set, get) => ({
  ...initialStates,
  fetchMonthlySpecialitySummary: async () => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    set(() => ({ specialitySummaryStatus: FetchState.LOADING }));
    const remoteFetchSpecialitiesMaster: FetchMonthlySpecialitiesSummary =
      new RemoteFetchSpecialitiesMaster(
        `${BASE_URL}${Endpoints.FETCH_MONTHLY_SPECIALITY_SUMMARY}`,

        axiosHttpClient
      );
    let params = {
      month_year: moment(
        (get().specialitySummaryFilters.monthYear as Dayjs).toString()
      ).format("YYYYMM"),
      visitor_role: get().specialitySummaryFilters.visitorRole,
      customer_type_id: get().specialitySummaryFilters.customerType_id,
      specialities: get().specialitySummaryFilters.specialities,
    };
    let result = await remoteFetchSpecialitiesMaster.fetch(params);

    if (result) {
      set(() => ({ monthlySpecialitySummaryData: result }));
      set(() => ({ specialitySummaryStatus: FetchState.SUCCESS }));
    } else {
      Swal.fire({
        icon: "error",
        timer: 3000,
        text: "Error Occured While Fetching Speciality Data",
        customClass: {
          container: "swal2-style",
        },
      });
    }
  },
  clearSpecialitySummary: () => {
    set(() => ({ monthlySpecialitySummaryData: [] }));
  },
});
