import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../base";
import { RemoteMapCustomerToBrick } from "../../data/usecases/remote-map-customer-to-brick";
import { MapBrickToCustomer } from "../../domain/useages/map-brick-to-customer";
import Endpoints from "../../domain/endpoints";
import Swal from "sweetalert2";

export interface MapCustomerToBrickInterface {
  mapCustomerToBrick: Function;
}

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useMapCustomerToBrickSlice: StateCreator<
  MapCustomerToBrickInterface
> = (set, get) => ({
  mapCustomerToBrick: async (
    payload: MapBrickToCustomer.Params,
    fetchCustomerData: Function,
    handleClearSelectedCustomers: Function
  ) => {
    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });

    const remoteMapCustomerToBrick: MapBrickToCustomer =
      new RemoteMapCustomerToBrick(
        `${BASE_URL}${Endpoints.MAP_BRICK_TO_CUSTOMER}`,
        axiosHttpClient
      );

    let response = await remoteMapCustomerToBrick.map(payload);
    if (response.customer) {
      Swal.fire({
        icon: "success",
        title: "Mapped to Brick Successfully",
        timer: 3000,
        customClass: {
          container: "swal2-style",
        },
        confirmButtonColor: '#3085d6',
      });
      handleClearSelectedCustomers()
      fetchCustomerData();
    } else{
      Swal.fire("Mapping to Brick Failed", "", "error")
    }
  },
});
