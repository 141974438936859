import { StateCreator } from "zustand";
import { AUTH_HEADER, AUTH_TOKEN_KEY, BASE_URL_KEY } from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchBatchwiseInputReport } from "../../../domain/useages/input-utilization/fetch-batchwise-input-report";
import { RemoteFetchBatchWiseInputReport } from "../../../data/usecases/input-utilization/remote-fetch-batchwise-input-report";
import Endpoints from "../../../domain/endpoints";
import { FetchState } from "../../../domain/models/fetch-state";
import { BatchWiseInputReportDataType } from "../../../domain/models/input-utilization/batchwise-input-report-type";
import moment from "moment";
import dayjs from "dayjs";

interface FetchBatchWiseInputReportInterface {
  fetchBatchWiseInputReport: Function;
  batchWiseInputReportData: BatchWiseInputReportDataType[];
  batchWiseDataFilters: {
    input_ids?: string[];
    role_id?: string;
  };
  fetchBatchWiseDataState: FetchState;
}

const initialStates = {
  batchWiseDataFilters: {
  },
  batchWiseInputReportData: [],
  fetchBatchWiseDataState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchBatchWiseInputReportSlice: StateCreator<
  FetchBatchWiseInputReportInterface
> = (set, get) => ({
  ...initialStates,
  fetchBatchWiseInputReport: async () => {
    set(() => ({ fetchBatchWiseDataState: FetchState.LOADING }));

    const token = storage.get(AUTH_TOKEN_KEY);
    const BASE_URL = storage.get(BASE_URL_KEY);
    axiosHttpClient.setAuthHeaders({
      [AUTH_HEADER]: atob(token),
    });
    const remoteFetchBatchwiseInputReport: FetchBatchwiseInputReport =
      new RemoteFetchBatchWiseInputReport(
        `${BASE_URL}${Endpoints.FETCH_BATCH_WISE_INPUT_REPORT}`,
        axiosHttpClient
      );

    let payload = {
      role_id: get().batchWiseDataFilters.role_id,
      input_ids: get().batchWiseDataFilters.input_ids,
    };
    let result = await remoteFetchBatchwiseInputReport.fetch(payload);
    if (result) {
      set(() => ({
        batchWiseInputReportData: result.summary,
        fetchBatchWiseDataState: FetchState.SUCCESS,
      }));
    }
  },
});
