import Swal from "sweetalert2";
import { DeleteUserManagementReport } from "../../../domain/useages/user-management/delete-user-management-report";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteDeleteUserManagementReport
  implements DeleteUserManagementReport
{
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async delete(
    id: string,
    params: DeleteUserManagementReport.Params
  ): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", id.toString()),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    if (httpResponse.status == 200) {
      Swal.fire({
        timer: 3000,
        icon: "success",
        text: "Report Deleted Successfully",
      });
      return httpResponse.body ? httpResponse.body : httpResponse.data;
    } else {
      Swal.fire({
        timer: 3000,
        icon: "error",
        title: "Some Error Occured",
        text: httpResponse.data.errors.error,
      });
      return "";
    }
  }
}
