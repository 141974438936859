import { UserSummaryReportStore } from "../../../../../store/main/UserSummaryReportStore";
import ChartView from "./ChartView";

const MonthlyPOBReportBarGraph = () => {
  const { monthsReportData } = UserSummaryReportStore();
  const orderPOBData = Array(12).fill(0);
  const allVisitData = Array(12).fill(0);

  monthsReportData?.orders?.forEach((order) => {
    const monthIndex = order?.order_month - 1;
    orderPOBData[monthIndex] = (
      parseFloat(order.order_day_total_count) / 1000
    ).toFixed(1);
  });

  monthsReportData?.all_visits?.forEach((visit) => {
    const visitIndex = visit?.visit_month - 1;
    allVisitData[visitIndex] = visit?.all_visit_count;
  });

  const data = {
    type: "line",
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Total visits",
          data: allVisitData,
          backgroundColor: "red",
          borderColor: "red",
          stack: "combined",
        },
        {
          label: "POB (in Thousands)",
          data: orderPOBData,
          backgroundColor: "#0D71B7",
          borderColor: "#0D71B7",
          borderWidth: 1,
          borderRadius: 20,
          barThickness: 20,
          type: "bar",
        },
      ],
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            tickColor: "white",
          },
        },
        x: {
          display: true,
          grid: {
            tickColor: "white",
            color: "white",
          },
        },
      },
      plugins: {
        legend: {
          align: "end",
        },
      },
    },
  };
  return <ChartView data={data} />;
};

export default MonthlyPOBReportBarGraph;
