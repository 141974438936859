import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { UserDetails } from "../../../../domain/models/uset-details";
import { TerritoryDataStore } from "../../../../store/main/TerritoryDataStore";
import { BrickWorkingReportStore } from "../../../../store/main/BrickWorkingReportStore";
import { CustomerDatabaseStore } from "../../../../store/main/CustomerDatabaseStore";
import Roles from "../../../../common/enums/Roles";
import RegionTabsLoading from "../../desktop/RegionTabsLoading";
import ZoneTabsLoading from "../../desktop/ZoneTabsLoading";

type Props = {
  userDetails: UserDetails;
};

const BrickWorkingReportMobileTabs: React.FC<Props> = ({
  userDetails = {} as UserDetails,
}) => {
  const {
    zoneMasterData,
    regionMasterData,
    fetchZoneMaster,
    fetchRegionMaster,
  } = TerritoryDataStore();

  const { brickReportFilters, fetchBrickWorkingReport } =
    BrickWorkingReportStore();
  const { userRole } = CustomerDatabaseStore();

  const [zone, setZone] = React.useState(0);
  const [region, setRegion] = React.useState("");

  const handleZoneChange = (event: React.SyntheticEvent, newValue: number) => {
    setZone(newValue);
    const filteredZone = zoneMasterData.filter((item) => item.id === newValue);
    brickReportFilters.zone = filteredZone[0].name;
  };

  const handleSingleSelectChange = (event: SelectChangeEvent) => {
    setRegion(event.target.value as string);
    brickReportFilters.region = event.target.value as string;
    fetchBrickWorkingReport();
  };

  const handleSelectedTerritory = (name: string) => {
    if (name === "zone") {
      if (userRole.role === Roles.COUTNRY_MANAGER) {
        fetchRegionMaster([zoneMasterData[0].id]);
        setZone(zoneMasterData[0].id);
        brickReportFilters.zone = zoneMasterData[0].name;
      } else {
        const filteredZone = zoneMasterData.filter(
          (item) => item.id === userDetails.hq_zone_id
        );
        fetchRegionMaster([filteredZone[0].id.toString()]);
        setZone(filteredZone[0].id);
        brickReportFilters.zone = filteredZone[0].name;
      }
    } else {
      const filteredRegion = regionMasterData.filter(
        (item) => item.id === userDetails.hq_region_id
      );

      setRegion(
        filteredRegion.length > 0
          ? filteredRegion[0].name
          : regionMasterData[0].name
      );

      brickReportFilters.region =
        filteredRegion.length > 0
          ? filteredRegion[0].name
          : regionMasterData[0].name;
      fetchBrickWorkingReport();
    }
  };

  React.useEffect(() => {
    if (regionMasterData.length > 0) {
      handleSelectedTerritory("region");
    } else if (zoneMasterData.length > 0) {
      handleSelectedTerritory("zone");
    }
  }, [zoneMasterData, regionMasterData]);

  React.useEffect(() => {
    fetchZoneMaster();
  }, []);

  const handleSelectRegionBasedonZone = (id: number) => {
    fetchRegionMaster([id]);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box paddingX={2}>
        {zoneMasterData.length > 0 ? (
          <Tabs
            value={zone ? zone : zoneMasterData[0].id}
            onChange={handleZoneChange}
            aria-label="basic tabs example"
          >
            {zoneMasterData &&
              zoneMasterData.map((zone) => {
                return (
                  <Tab
                    key={zone.id}
                    label={zone.name}
                    value={zone.id}
                    onClick={() => {
                      handleSelectRegionBasedonZone(zone.id);
                    }}
                  />
                );
              })}
          </Tabs>
        ) : (
          <ZoneTabsLoading />
        )}
      </Box>
      {regionMasterData.length > 0 ? (
        <FormControl size="small" sx={{ width: "92%", margin: 2 }}>
          <InputLabel>Select Region</InputLabel>
          <Select
            value={region}
            size="small"
            label="Select Region"
            onChange={(e) => handleSingleSelectChange(e)}
          >
            {regionMasterData.map((data) => (
              <MenuItem key={data.id} value={data.name}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <RegionTabsLoading />
      )}
    </Box>
  );
};
export default BrickWorkingReportMobileTabs;
