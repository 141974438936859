import { Skeleton, Stack } from "@mui/material";

type Props = {};

const SummaryLoading = (props: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={99}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={99}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={99}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={99}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={99}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={99}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={99}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={99}
        width={150}
      />
    </Stack>
  );
};

export default SummaryLoading;
