import TableRow from "@mui/material/TableRow";
import SingleUserData from "./SingleUserDate";
import { TableCell, Typography } from "@mui/material";

type Props = {
  data: (string | number)[];
  value: (string | number)[];
  id: number | string;
  handleChangeCell: Function;
  allowEdit: boolean;
};

const UserInputPlanningTableData: React.FC<Props> = ({
  data,
  handleChangeCell,
  allowEdit,
}) => {
  const tableCellStyle = {
    borderLeft: "1px solid #B2BEB5",
    padding: 1,
  };

  const stepCallback = (id: number, newValue: string, index: number) => {
    handleChangeCell(id, newValue, index, data[2]);
  };
  return (
    <>
      <TableRow
        sx={{
          borderRight: "1px solid #B2BEB5",
        }}
      >
        <TableCell align="center" sx={tableCellStyle}>
          <Typography variant="caption">{data[0]}</Typography>
        </TableCell>
        <TableCell align="center" sx={tableCellStyle}>
          <Typography variant="caption">{data[1]}</Typography>
        </TableCell>
        {data.map((cellData, key) => {
          if (key > 2) {
            return (
              <SingleUserData
                index={key}
                cellData={cellData}
                allowEdit={allowEdit}
                id={data[0]}
                handleChangeCell={stepCallback}
              />
            );
          }
        })}
      </TableRow>
    </>
  );
};
export default UserInputPlanningTableData;
